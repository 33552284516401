import React from 'react';
import CtRh from '../pages-content/Rh-content/Rh.content';

const Rh:React.FC = () => {
  return (
    <>
      <CtRh />
    </>
  )
}

export default Rh;