import React from 'react';
import CtObjectifsCampaign from '../pages-content/Objectifs-content/Objectifs.content';

const Objectifs:React.FC = () => {
  return (
    <>
      <CtObjectifsCampaign />
    </>
  )
}

export default Objectifs;