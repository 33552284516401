import { Box, Grid } from '@mui/material';
import React, { useState,useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import KpiMiniCard from '../../components/Kpi-mini-card/KpiMiniCard';
// import KpiSimpleCard from '../../components/Kpi-simple-card/KpiSimpleCard';
import './Dashboard.content.style.scss';
import DashboardTableauBordCard from '../../components/Dashboard-tableauBord-card/DashboardTableauBordCard';
import DashboardFilterNew from '../../components/Dashboard-filter-new/DashboardFilterNew';
import { getAllChantiersAsync } from '@/store/reducers/chantier/chantier.action';
import { selectChantierItemList } from '@/store/reducers/chantier/chantier.selector';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getAllEnginsAsync } from '@/store/reducers/engin/engin.action';
import { selectEnginItemList } from '@/store/reducers/engin/engin.selector';
import { getAllPersonnelsAsync } from '@/store/reducers/personnel/personnel.action';
import { selectPersonnelItemList } from '@/store/reducers/personnel/personnel.selector';
import { getAllDashboardAsync } from '@/store/reducers/dashboard/dashboard.action';
import { selectDashboardItemList } from '@/store/reducers/dashboard/dashboard.selector';
import { getAllCuvesAsync } from '@/store/reducers/cuve/cuve.action';
import { selectCuveItemList } from '@/store/reducers/cuve/cuve.selector';
import { getAllIncidentsAsync } from '@/store/reducers/incident/incident.action';
import { selectIncidentItemList } from '@/store/reducers/incident/incident.selector';
import chantierIcon from '@/assets/images/chantier.jpg';
import enginIcon from '@/assets/images/png/Airpod.png';
import personIcon from '@/assets/images/png/Deliver.png';

const CtDashboardPage: React.FC<any> = ({cuveList,getAllCuvesAsync,incidentList,getAllIncidentsAsync,dashboard,getAllDashboardAsync,chantierList,getChantieresAsync,personnelList,getAllPersonnelsAsync,enginList,getAllEnginsAsync}) => {

  console.log('dashboard', dashboard);
  

  useEffect(() => {
    const url:any = getAllDashboardAsync();
    const url1:any = getChantieresAsync();
    const url2:any = getAllPersonnelsAsync();
    const url3:any = getAllEnginsAsync();
    const url4:any = getAllIncidentsAsync();
    const url5:any = getAllCuvesAsync();

    const fetchData = async () => {
        try {
            await fetch(url);
            await fetch(url1);
            await fetch(url2);
            await fetch(url3);
            await fetch(url4);
            await fetch(url5);
        } catch (error) {
            console.log("error", error);
        }
    };
    fetchData();
    
  }, [getAllDashboardAsync,getChantieresAsync,getAllPersonnelsAsync,getAllEnginsAsync,getAllIncidentsAsync,getAllCuvesAsync]);

  const [category, setCategory] = useState<'Chantier' | 'Engin' | 'Personnel' | 'Incidents' | 'Cuves'>('Chantier'); 

  return (
    <>
      <DashboardFilterNew />
      <PerfectScrollbar className="ct-dashboard">
        <Box>
          <Grid container spacing={2} >
              <Grid item xs={12}>
                <div className="main-kpi-container">
                    <KpiMiniCard
                        stackId = "a"
                        title="Chantiers" 
                        value={dashboard?.chantier?.total || 0}
                        icon={chantierIcon}
                        dataToSet = { 
                            [
                              { name: 'terminer', value: (dashboard?.chantier?.terminer || 0) , color:'#008F75' },
                                { name: 'en_cours', value: (dashboard?.chantier?.enCours || 0) , color:'#37474f' },
                                { name: 'a_venir', value: (dashboard?.chantier?.avenir || 0) , color:'#b0bec5' }
                            ] 
                        }/>
                    <KpiMiniCard
                        stackId = "b"
                        title="Engins"
                        value={dashboard?.engin?.total || 0}
                        icon={enginIcon}
                        dataToSet = { 
                            [
                                { name: 'chantier', value: (dashboard?.engin?.chantier || 0) , color:'#008F75' },
                                { name: 'atelier', value: (dashboard?.engin?.attelier || 0) , color:'#26353F' },
                                { name: 'en_panne', value: (dashboard?.engin?.panne || 0) , color:'#ff1744' }
                            ] 
                        } />
                    <KpiMiniCard
                        stackId = "c"
                        title="Personnel"
                        value={dashboard?.personnel?.total || 0}
                        icon={personIcon}
                        dataToSet = { 
                            [
                                { name: 'chantier', value: (dashboard?.personnel?.chantier || 0) , color:'#33691e' },
                                { name: 'atelier', value: (dashboard?.personnel?.atelier || 0) , color:'#4caf50' },
                                { name: 'administration', value: (dashboard?.personnel?.administration || 0) , color:'#8bc34a' },
                                { name: 'absent', value: (dashboard?.personnel?.absent || 0) , color:'#ff1744' },
                            ] 
                        }/>
                    
                    {/* <KpiSimpleCard
                        title="Énergie rappelée" 
                        value1={150035000}
                        value2={38950}/> */}
                </div>
              </Grid>
              
             
              <Grid item xs={12}>
                <DashboardTableauBordCard 
                  chantierList={chantierList} 
                  personnelList={personnelList} 
                  enginList={enginList} 
                  incidentList={incidentList} 
                  cuveList={cuveList} 
                  setCategory={setCategory} 
                  category={category} 
                />
              </Grid>
          </Grid>
        </Box>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps:any = createStructuredSelector({
  // loading: selectLoading,
  chantierList: selectChantierItemList,
  personnelList: selectPersonnelItemList,
  enginList: selectEnginItemList,
  dashboard: selectDashboardItemList,
  incidentList: selectIncidentItemList,
  cuveList: selectCuveItemList
});

const mapDispatchToProps = (dispatch:any) => ({
  getChantieresAsync: () => dispatch(getAllChantiersAsync()),
  getAllPersonnelsAsync: () => dispatch(getAllPersonnelsAsync()),
  getAllEnginsAsync: () => dispatch(getAllEnginsAsync()),
  getAllDashboardAsync: () => dispatch(getAllDashboardAsync()),
  getAllIncidentsAsync: () => dispatch(getAllIncidentsAsync()),
  getAllCuvesAsync: () => dispatch(getAllCuvesAsync()),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CtDashboard = connector(CtDashboardPage);

export default CtDashboard;
