import MiniPiehart from '@/features/admin-iveqi/components/Mini-pie-chart/MiniPieChart';
import { InlineIcon } from '@iconify/react';
import { TuneRounded, TurnedIn, ListAlt, Edit, Delete, ViewArray } from '@mui/icons-material';
import { Badge, Button, Chip, Divider, IconButton, InputAdornment, Skeleton, TextField } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SideModal from '../../components/SideModal/SideModal';
import './Engins.content.style.scss';
import CreateEnginForm from '../../Forms/CreateEnginForm/CreateEnginForm';
import { DataGrid, GridValueGetterParams } from '@mui/x-data-grid';
import ListeItemCardGenerique from '@/features/admin-iveqi/components/ListeItem/ListeItemCardGenerique';
import { selectEnginItemList, selectLoading, selectSyntheseEnginList } from '@/store/reducers/engin/engin.selector';
import { deleteEnginAsync, getAllEnginsAsync, getSyntheseEnginsAsync } from '@/store/reducers/engin/engin.action';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import { Stack } from "@mui/material";
import Swal from 'sweetalert2';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';
import { exportToExcel } from '@/config';
import SyntheseEngin from '../../Forms/SyntheseEngin/SyntheseEngin';
import { selectUserProfil } from '@/store/reducers/profil/profil.selector';


const columns = [
  { field: 'libelle', headerName: 'Libellé', width: 280 },
  { field: 'num_parc', headerName: 'N°Parc', width: 150 },
  {
    field: 'description',
    headerName: 'Description',
    sortable: false,
    width: 200,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.description.slice(0, 20) || '',
  },
  {
    field: 'dernier_compteur',
    headerName: 'Compteur Engin',
    type: 'number',
    width: 150,
  },
  {
    field: 'intervalle_entretien',
    headerName: 'Intervalle entretien',
    type: 'number',
    width: 150,
  },
  {
    field: 'derniere_entretien',
    headerName: 'compteur derniere entretien',
    type: 'number',
    width: 150,
  },
  {
    field: 'prochain_entretien',
    headerName: 'compteur prochain entretien',
    type: 'number',
    width: 150,
  }, {
    field: 'situation',
    headerName: 'Situation',
    type: 'text',
    width: 150,
  },
];




const CtEnginsAdministrationPage: React.FC<any> = ({loading, enginList, getAllEnginsAsync, 
  deleteEnginAsync,
  getSyntheseEnginsAsync,
  syntheseEngin,
   userConnected,
   permissions
  }) => {

  useEffect(() => {
    const url:any = getAllEnginsAsync();

    const fetchData = async () => {
        try {
            await fetch(url);
        } catch (error) {
            console.log("error", error);
        }
    };
    fetchData();
    
  }, [getAllEnginsAsync]);

  const [searchText, setSearchText] = useState('');
  const [isCardMode, setIsCardMode] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {setOpen(false); isCardMode && setSelection(null)};

  const [isAdd, setIsAdd] = useState(false);
  const [selection, setSelection] = React.useState<any>(null);
  const [isSyn, setIsSyn] = useState(false);

  const listItems: any[] = enginList.map((x: any) => {
    return {
      ...x,
      situation: (x.chantierId) ? x.chantier?.nom +' à '+x.chantier?.localisation : 'Non Loué',
      searchText: x.libelle + ' ' + x.description + x.num_parc
    }
  });


  const getListItemsFilteredSearch = () => {
    return listItems.filter((value: any) => {
      return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
    })
  }

  // Appel de la fonction d'exportation lorsque vous souhaitez exporter les données
  const handleExportExcel = () => {
    exportToExcel(listItems, columns, 'engin');
  };

  const selectItemFunc = (idElt: number | any) => {
      const element =  getListItemsFilteredSearch().find((x:any) => x.id === idElt);
      console.log("element", element);
      if (selection?.id === element?.id) {
        isCardMode && setSelection(null);
        return;
      }
      setSelection(element);
      console.log("element?.id", element?.id);
      getSyntheseEnginsAsync(element?.id);
  }


  const isCardActive = (idCard: any) => {
    // if (selection?.id == idCard) return false
    return selection?.id === idCard
  }

  const confirmBeforeRemove =()=> {
    Swal.fire({
      title: "⚠️ Important ",
      text: "Vous êtes sur le point de supprimer une information, desirez-vous poursuivre cette action ?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
      confirmButtonColor: "red",
      cancelButtonColor: "#91949c",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  const deleteItem = () => {
    let datatoDelete = {"ops": (userConnected?.user?.nom +' '+userConnected?.user?.prenoms), "id": selection.id }
    // console.log('go to delete', selection, datatoDelete);
    deleteEnginAsync(datatoDelete);
  }
  

  return (
    <>
      <div className="ct-engins-campagne">
        <div className="ct-engins-campagne__card">

          <div className="header-part">
            <div className="title-part">
                <div className="title-bloc">
                  <span className="title"> Gestion des engins </span>
                  <Chip label={` ${listItems?.length} ${listItems?.length < 2 ? 'engin' : 'engins'}  `}
                    className='total-number-chip' />

                  <Badge color="secondary">
                      <IconButton className='close-icon'
                            size="small" 
                            color='blue'
                            onClick={()=>{
                              handleOpen();
                              setIsAdd(false);
                            }}>
                        <TuneRounded />
                      </IconButton>
                  </Badge>
                  <Badge color="secondary" 
                        variant="dot"
                        >
                      <IconButton 
                        className='close-icon'
                        title={`Mode ${isCardMode ? "liste" : 'carte'}`}
                        size="small" 
                        color='blue'
                        onClick={()=>{
                          setIsCardMode(!isCardMode); setSelection(null);
                        }}>
                        {
                          isCardMode ?
                          <ListAlt /> :
                          <TurnedIn />
                        }
                      </IconButton>
                  </Badge>
                </div>
                <div className="toolbar-part">

                  <div className="actions-part">

                  {
                      selection ?
                      <>
                        <Divider orientation='vertical' />
                        <Button
                          color='blue'
                          className='btn-option'
                          onClick={()=>{
                            handleOpen();
                            setIsAdd(false);
                            setIsSyn(true)
                          }}
                          startIcon={<ViewArray />}>
                          Synthese
                        </Button>
                        <Divider orientation='vertical' />
                        {
                          permissions?.parametre?.find((o: any) => (o.libelle === 'engin' && o.edit === true)) &&
                          <Button
                          color='blue'
                          className='btn-option'
                          onClick={()=>{
                            handleOpen();
                            setIsAdd(true);
                          }}
                          startIcon={<Edit />}>
                          Modifier
                        </Button>
                        }
                        <Divider orientation='vertical' />
                        {
                          permissions?.parametre?.find((o: any) => (o.libelle === 'engin' && o.delete === true)) &&
                          <Button
                          color='blue'
                          className='btn-option'
                          onClick={confirmBeforeRemove}
                          startIcon={<Delete />}>
                          Supprimer
                        </Button>
                        }
                      </>: 
                      <>
                        {
                          permissions?.parametre?.find((o: any) => (o.libelle === 'engin' && o.create === true)) &&
                          <Button
                          color='blue'
                          className='btn-option'
                          onClick={()=>{
                              handleOpen();
                              setIsAdd(true);
                          }}
                          //variant='contained'
                          startIcon={<InlineIcon icon="carbon:document-add" />}>
                          Créer un engin
                        </Button>
                        }
                        <Divider orientation='vertical' />
                        <Button
                          color='blue'
                          className='btn-option'
                          onClick={handleExportExcel}
                          //variant='contained'
                          startIcon={<InlineIcon icon="carbon:document-import" />}>
                          Exporter la liste
                        </Button>
                      </>
                  }
                    
                  </div>

                  <TextField
                    value={searchText}
                    onChange = {
                      (e) => {
                        setSearchText(e.target.value);
                      }
                    }
                    className="search-input"
                    placeholder="Recherche ... "
                    variant="outlined"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InlineIcon icon="bi:search" />
                        </InputAdornment>
                      )
                    }}
                  />

                </div>
            </div>
            <Divider className='custom-divider' />
          </div>

          <PerfectScrollbar id="scrollableDiv" className="content-part">
            <div className="kpi-part">
              <div className={classNames('kpi-card ')}>
                  <span className="value">
                    {listItems?.length}
                  </span>
                  <span className="text">
                    Total Engins
                  </span>
                  <div className="mini-pie">
                    <MiniPiehart
                          value1={15}
                          value2={50}
                          value3={35}
                          value4={0}
                          color1={'#EF7D00'}
                          color2={'#26353F'}
                          color3={'#008F75'}
                          color4={'#ff1744'}
                          height={30} 
                          width={30}
                          interRadius={5}/>
                  </div>
              </div>
            </div>
            <>
                {
                    loading && 
                    <>
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="pulse"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                    </>
                }

                {
                  isCardMode ?
                  <InfiniteScroll
                    className="content-part__infinite-scroll-part"
                    scrollableTarget="scrollableDiv"
                    dataLength={listItems?.length}
                    next={getAllEnginsAsync}
                    hasMore={true}
                    loader={
                      listItems  ?
                      <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                      :
                      <p> Aucune donnée disponible </p>
                    }>

                        {
                              !loading &&
                              listItems.filter((value: any) => {
                                  return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
                                })
                                .map((value: any, index: any) => {

                                  return <ListeItemCardGenerique id={value.id}
                                  key={index} 
                                  setOpen={setOpen}
                                  columns={columns}
                                  data={[value]}
                                  setIsEdit={selectItemFunc}
                                  isActive={isCardActive(value.id)}/>

                                })
                          }
                  </InfiniteScroll> :
                  <div style={{ height: '85%', width: '100%' }}>
                    <DataGrid  components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            Pas de donnees
                          </Stack>
                        ),
                      }}
                      localeText={{
                        MuiTablePagination: {
                          labelDisplayedRows: ({ from, to, count }) =>
                            `${from} - ${to} Total: ${count}`,
                        },
                      }}
                      rows={
                        getListItemsFilteredSearch()
                      }
                      columns={columns}
                       initialState={{
                        pagination: { paginationModel: { pageSize: 7 } },
                      }}
                      onRowSelectionModelChange={(row) => {
                        selectItemFunc(row[0]);
                      }}
                    />
                  </div>
                }
            </>

          </PerfectScrollbar>
        </div>
      </div>

      <SideModal 
          open={open}
          onClose={handleClose}
          closeClick={handleClose}>
            <>
                {
                    isAdd &&
                    <CreateEnginForm closeModal={handleClose} selectedItem={selection} />
                }
                {
                    isSyn &&
                    <SyntheseEngin closeModal={handleClose} selectedItem={syntheseEngin} />
                }
            </>
      </SideModal>
    </>
  );
};



const mapStateToProps: any = createStructuredSelector({
  loading: selectLoading,
  enginList: selectEnginItemList,
  syntheseEngin: selectSyntheseEnginList,
  userConnected: selectAuthUser,
  permissions: selectUserProfil
});

const mapDispatchToProps = (dispatch:any) => ({
  getAllEnginsAsync: () => dispatch(getAllEnginsAsync()),
  getSyntheseEnginsAsync: (id:any) => dispatch(getSyntheseEnginsAsync(id)),
  deleteEnginAsync: (data:any) => dispatch(deleteEnginAsync(data)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CtEnginsAdministration = connector(CtEnginsAdministrationPage);

export default  CtEnginsAdministration;
