import React from "react";
import "./TimelineItem.style.scss";
import { Timeline } from "antd";
import { Chip } from "@mui/material";

const TimelineItem: React.FC<any> = ({ data, isDash, category }) => {
  let content: any = [];
  data?.chantiers?.forEach((o: any) => {
    if (category === "Chantier") {
      content.push({
        label: o?.engin,
        children: (
          <>
            <strong>Date d'affectation:</strong> {o?.date_affectation || "N/A"}{" "}
            <br />
            <strong>Carburant:</strong> {o?.totalCarburant || "N/A"} (L) <br />
            <strong>Travail:</strong> {o?.totalQuantite || "N/A"}  <br />
            <strong>Moyenne :</strong> {(o?.totalCarburant/o?.totalQuantite).toFixed(2) || "N/A"}  <br />
            <strong>Date retour:</strong> {o?.date_retour || "N/A"}
          </>
        ),
      });
    }
    if (category === "Engin") {
      content.push({
        label: o?.chantier,
        children: (
          <>
            <strong>Date d'affectation:</strong> {o?.date_affectation || "N/A"}{" "}
            <br />
            <strong>Carburant:</strong> {o?.totalCarburant || "N/A"} (L) <br />
            <strong>Travail:</strong> {o?.totalQuantite || "N/A"} <br />
            <strong>Moyenne :</strong> {(o?.totalCarburant/o?.totalQuantite).toFixed(2) || "N/A"}  <br />

            <strong>Tache:</strong> {o?.nb_jr_travail || "N/A"} jour(s) <br />

            <strong>Date retour:</strong> {o?.date_retour || "N/A"}
          </>
        ),
      });
    }
    if (category === "Personnel") {
      content.push({
        label: o?.chantier,
        children: (
          <>
            <strong>Date d'affectation:</strong> {o?.date_affectation || "N/A"}{" "}
            <br />
            <strong>Heure d'absence:</strong> {o?.totalHeureAbsences || "N/A"}{" "}
            heure(s) <br />
            <strong>Date retour:</strong> {o?.date_retour || "N/A"}
          </>
        ),
      });
    }
  });

  if (isDash) {
    return (
      <>
        <div className="chip-part">
          {category === "Chantier"  && (
            <>
              <Chip
                label={`Travail effectue: ${data?.totalQuantite}`}
                className="chip"
              />
              <Chip
                label={`Carburant: ${data?.totalCarburant}`}
                className="chip"
              />
            </>
          )}
          {category === "Engin"  && (
            <>
              <Chip
                label={`Travail effectue: ${data?.totalQuantite}`}
                className="chip"
              />
              <Chip
                label={`Carburant: ${data?.totalCarburant}`}
                className="chip"
              />
            </>
          )}
          {category === "Personnel" && (
            <>
              <Chip
                label={`Temps d'absences: ${data?.totalHeureAbsence}`}
                className="chip"
              />
            </>
          )}
        </div>
        <Timeline className="timeline" mode={"left"} items={content} />
      </>
    );
  } else {
    return (
      <>
        <Timeline
          className="timeline"
          mode={"left"}
          items={
            data
              ? data
              : [
                  {
                    label: "2015-09-01",
                    children: "Create a services",
                  },
                  {
                    label: "2015-09-01 09:12:11",
                    children: "Solve initial network problems",
                  },
                  {
                    children: "Technical testing",
                  },
                  {
                    label: "2015-09-01 09:12:11",
                    children: "Network problems being solved",
                  },
                ]
          }
        />
      </>
    );
  }
};

export default TimelineItem;
