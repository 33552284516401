import React from 'react';
import CtEquipementsAdministration from '../pages-content/Equipements-content/Equipements.content';

const Equipements:React.FC = () => {
  return (
    <>
      <CtEquipementsAdministration />
    </>
  )
}

export default Equipements;