import { InlineIcon } from '@iconify/react';
import { Divider, Grid, TextField, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';

import './HistoriqueCuveForm.style.scss';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCuveItemDetail } from '@/store/reducers/cuve/cuve.selector';
import { createCuveDetailAsync } from '@/store/reducers/cuve/cuve.action';
import moment from 'moment';

//const regexImmatriculation = /[0-9]{1,4}[A-Z]{2}01/

const HistoriqueCuveFormPage: React.FC<any> = ({
    selectedItem, 
    getDetail,
    createCuveDetailAsync
}) => {
 
    useEffect(() => {
        const url:any = createCuveDetailAsync(selectedItem?.id);

        const fetchData = async () => {
            try {
                await fetch(url);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
        
      }, [createCuveDetailAsync]);

      const cuveHistoriqueFormArray = getDetail?.utilisation_cuves;

    return (
        <form className='create-list-form'>
 {
                (cuveHistoriqueFormArray?.length > 0 ) ?
                <>
                    <span>Historique de la cuve {selectedItem?.libelle || ''}</span>
                    <Grid container rowSpacing={2} columnSpacing={2} >
                        {cuveHistoriqueFormArray?.map((m: any) => (
                            <Fragment key={m.id}>
                               
                                <Grid item xs={12} sm={12} lg={12} xl={11} >
                                <span className="sub-title-part"> <InlineIcon icon="bx:car" /> {m.type} du {moment(m.date).format('Do MMMM YYYY')} </span>
                                <Divider className='custom-divider' />
                                    <Grid container rowSpacing={1} columnSpacing={2} >
                                        <Grid item xs={12} sm={12} lg={4} xl={4} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m.quantite_cuve_avant}
                                                variant="outlined"
                                                name="quantite"
                                                label="Quantite avant (L)"
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={4} xl={4} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m.quantite}
                                                variant="outlined"
                                                name="quantite"
                                                label="Quantite transferee (L)"
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={4} xl={4} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m.quantite_cuve_apres}
                                                variant="outlined"
                                                name="quantite"
                                                label="Quantite apres (L)"
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            
                            </Fragment>
                        ))}
                    </Grid>
                </>
                :
                <span>Aucun historique</span>
            }
        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    getDetail: selectCuveItemDetail,
});

const mapDispatchToProps = (dispatch:any) => ({
    createCuveDetailAsync: (id:any) => dispatch(createCuveDetailAsync(id)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const HistoriqueCuveForm = connector(HistoriqueCuveFormPage);

export default HistoriqueCuveForm