import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectCategorieEngin = (state:TRootState) => state.categorieEngin;

export const selectLoading = createSelector(
  [selectCategorieEngin],
  (categorieEngin:any) => categorieEngin.loading
);

export const selectCategorieEnginItemList = createSelector(
    [selectCategorieEngin],
    (categorieEngin:any) => categorieEngin.list
);

export const selectCategorieEnginDetailLoading = createSelector(
  [selectCategorieEngin],
  (categorieEngin:any) => categorieEngin.loading
);

export const selectCategorieEnginItemSelected = createSelector(
    [selectCategorieEngin],
    (categorieEngin:any) => categorieEngin.itemSelected
);
