import { InlineIcon } from "@iconify/react";
import { Box, Button, Chip, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import "./EditPersonAffectedForm.style.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { selectAuthUser } from "@/store/reducers/auth/auth.selector";
import { getAllAffectedPersonsByChantierAsync, updatePersonAffectatedAsync } from "@/store/reducers/affectation/affectation.action";

const EditPersonAffectedFormPage: React.FC<any> = ({
  selectedItem,
  userConnected,
  closeModal,
  selectedChantier,
  updatePersonAffectatedAsync,
  getAllAffectedPersonsByChantierAsync
}) => {
  console.log("settingData", selectedItem);
  console.log("selectedChantier", selectedChantier);

  const [date, setDate] = useState(
    selectedItem ? selectedItem.date : moment().format("YYYY-MM-DD HH:m:s")
  );

  const hebergementList: any[] = ["Maison", "Hôtel", "Autre"];
  const [dortoir, setDortoir] = useState<any[]>(
    selectedItem ? selectedItem?.row?.item?.hebergement_chantier?.split(",") : ["Hôtel"]
  );
  const handleDortoirChange = (event: SelectChangeEvent<any>) => {
    setDortoir(event.target.value);
    console.log("SelectChangeEvent", event.target.value);
  };

  const validationSchema = yup.object({
    // hebergement_chantier: yup.string().required(" Le nom du chantier est obligatoire "),
  });

  const formik = useFormik({
    initialValues: {
      id: selectedItem ? selectedItem.id : null,
      date_chantier: date,
      hebergement_chantier: "",
      ops: userConnected?.user?.nom + " " + userConnected?.user?.prenoms,
    },
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      values.hebergement_chantier = dortoir.toString();

      console.log("data", values);

      updatePersonAffectatedAsync(values);
      closeModal();
      
      setTimeout(() => {
        getAllAffectedPersonsByChantierAsync(selectedItem?.row?.item?.chantierId);
      }, 1000);
    },
  });

  return (
    <form className="create-list-form" onSubmit={formik.handleSubmit}>
      <span className="title">
        {" "}
        {"Modification des attributs de " + selectedItem?.row?.matricule}{" "}
      </span>

      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DatePicker
              label="Date affectation"
              value={date}
              onChange={(newValue: any) => {
                setDate(newValue);
                formik.setFieldValue(
                  "date_chantier",
                  newValue.format("YYYY-MM-DD HH:mm:ss")
                );
              }}
              renderInput={(params) => (
                <TextField {...params} className="my-text-field" />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <FormControl fullWidth>
            <InputLabel
              id="id-dortoir"
              htmlFor="dortoir"
              sx={{
                position: "absolute !important",
                top: "-5px !important",
                fontSize: "0.8rem",
              }}
            >
              Herbergement
            </InputLabel>
            <Select
              multiple
              size="small"
              labelId="id-hebergement_chantier"
              name="hebergement_chantier"
              value={dortoir}
              onChange={handleDortoirChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} sx={{ height: "20px" }} />
                  ))}
                </Box>
              )}
              //   MenuProps={MenuProps}
            >
              {hebergementList.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <div className="btn-part">
        <Button
          color="secondary"
          className="btn-option"
          type="submit"
          startIcon={<InlineIcon icon={"iconoir:edit-pencil"} />}
          variant="contained"
        >
          {"Modifier"}
        </Button>

        <Button
          color="light"
          className="btn-option"
          startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
          onClick={() => {
            formik.resetForm();
          }}
          variant="contained"
        >
          {"Réinitialiser"}
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  updatePersonAffectatedAsync: (data: any) =>
    dispatch(updatePersonAffectatedAsync(data)),
  getAllAffectedPersonsByChantierAsync: (data: any) =>
    dispatch(getAllAffectedPersonsByChantierAsync(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const EditPersonAffectedForm = connector(EditPersonAffectedFormPage);

export default EditPersonAffectedForm;
