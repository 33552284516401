import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectAffectation = (state:TRootState) => state.affectation;

export const selectLoading = createSelector(
  [selectAffectation],
  (affectation:any) => affectation.loading
);

export const selectAffectedPersonByChantierItemList = createSelector(
    [selectAffectation],
    (affectation:any) => affectation.affectedPersonByChantierList
);

export const selectPersonAffectationItemSelected = createSelector(
    [selectAffectation],
    (affectation:any) => affectation.affectedPersonByChantierItemSelected
);

export const detailAboutRhAffectation = createSelector(
  [selectAffectation],
  (affectation:any) => affectation.affectedRhDetail
);

export const selectAffectedEnginByChantierItemList = createSelector(
  [selectAffectation],
  (affectation:any) => affectation.affectedEnginByChantierList
);

export const selectEnginAffectationItemSelected = createSelector(
  [selectAffectation],
  (affectation:any) => affectation.affectedEnginByChantierItemSelected
);

export const detailAboutEnginAffectation = createSelector(
  [selectAffectation],
  (affectation:any) => affectation.affectedEnginDetail
);
