import { InlineIcon } from '@iconify/react';
import {   Button,  Divider,  Grid, TextField,  } from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import './CreateNatureActiviteForm.style.scss';
import { createNatureTravauxAsync, updateNatureTravauxAsync } from '@/store/reducers/nature-travaux/natureTravaux.action';
import { connect } from 'react-redux';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';
import { createStructuredSelector } from 'reselect';


const CreateNatureActiviteFormPage: React.FC<any> = ({closeModal, selectedItem, createNatureTravauxAsync, updateNatureTravauxAsync, userConnected}) => {

   
   
    const validationSchema = yup.object({
        libelle: yup.string().required(" Le libellé est obligatoire "),
       // heure: yup.number().required(" L'heure est obligatoire "),
       // prestation: yup.string().required(" La prestation est obligatoire "),
    });

    const formik = useFormik({
        initialValues: {
            id: selectedItem ? selectedItem.id : null,
            libelle: selectedItem ? selectedItem.libelle : '',
            heure: selectedItem ? selectedItem.heure : '',
            prestation: selectedItem ? selectedItem.prestation : '',
            ops: (userConnected?.user?.nom +' '+userConnected?.user?.prenoms)

        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            console.log('Values form', values);
            if (!selectedItem) {
                delete values.id;
                createNatureTravauxAsync(values);
            } else {
                values.id = selectedItem.id;
                updateNatureTravauxAsync(values);
               
            }
            closeModal();
        }
    });


    return (
        <form className='create-list-form' onSubmit={formik.handleSubmit}>
            <span className="title">  {selectedItem ? 'Modification Nature Travaux' : 'Création Nature Travaux'} </span>

            <Grid container rowSpacing={2} columnSpacing={2} >
                <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <TextField placeholder="Libelle de le catégorie"
                        variant="outlined"
                        name="libelle"
                        label="Libellé"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.libelle}
                        error={formik.touched.libelle && Boolean(formik.errors.libelle)}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Heure Nature d'activité"
                        variant="outlined"
                        name="heure"
                        label="Heure"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.heure}
                        error={formik.touched.heure && Boolean(formik.errors.heure)}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Prestation Nature d'activité"
                        variant="outlined"
                        name="prestation"
                        label="Prestation"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.prestation}
                        error={formik.touched.prestation && Boolean(formik.errors.prestation)}
                        type="text" />
                </Grid>

            </Grid>

            <Divider className='custom-divider' />

            <div className="btn-part">
                <Button color='secondary'
                    className='btn-option'
                    type='submit'
                    startIcon={<InlineIcon icon="iconoir:save-floppy-disk" />}
                    variant='contained'>
                    {selectedItem ? 'Modifier' : 'Créer'}
                </Button>

                <Button color='light'
                    className='btn-option'
                    startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
                    onClick={() => {formik.resetForm()}}
                    variant='contained'>
                    Réinitialiser
                </Button>
            </div>

        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch:any) => ({
    createNatureTravauxAsync: (data:any) => dispatch(createNatureTravauxAsync(data)),
    updateNatureTravauxAsync: (data:any) => dispatch(updateNatureTravauxAsync(data)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CreateNatureActiviteForm = connector(CreateNatureActiviteFormPage);

export default CreateNatureActiviteForm