import React from 'react';
import CtIncidentCampaign from '../pages-content/Incidents-content/Incidents.content';

const Incidents:React.FC = () => {
  return (
    <>
      <CtIncidentCampaign/>
    </>
  )
}

export default Incidents;