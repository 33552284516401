import React from 'react';
import CtCategorieEnginsAdministration from '../pages-content/CategorieEngin-content/CategorieEngin.content';

const CategorieEngin:React.FC = () => {
  return (
    <>
      <CtCategorieEnginsAdministration />
    </>
  )
}

export default CategorieEngin;