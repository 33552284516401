import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectIncident = (state:TRootState) => state.incident;

export const selectLoading = createSelector(
  [selectIncident],
  (incident:any) => incident.loading
);

export const selectIncidentItemList = createSelector(
    [selectIncident],
    (incident:any) => incident.list
);

export const selectIncidentItemSelected = createSelector(
    [selectIncident],
    (incident:any) => incident.itemSelected
);
