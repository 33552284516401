import React from 'react';
import CtHistoriqueRh from '../pages-content/Historique-content/Historique.content';

const HistoriqueRh:React.FC = () => {
  return (
    <>
      <CtHistoriqueRh />
    </>
  )
}

export default HistoriqueRh;