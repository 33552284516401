import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectEngin = (state:TRootState) => state.engin;

export const selectLoading = createSelector(
  [selectEngin],
  (engin:any) => engin.loading
);

export const selectEnginItemList = createSelector(
    [selectEngin],
    (engin:any) => engin.list
);

export const selectEnginDetailLoading = createSelector(
  [selectEngin],
  (engin:any) => engin.loading
);

export const selectEnginItemSelected = createSelector(
    [selectEngin],
    (engin:any) => engin.itemSelected
);

export const selectVidangeItemList = createSelector(
  [selectEngin],
  (engin:any) => engin.vidange
);

export const selectVidangeItemSelected = createSelector(
  [selectEngin],
  (engin:any) => engin.itemSelected
);

export const selectSyntheseEnginList = createSelector(
  [selectEngin],
  (engin:any) => engin.getSyntheseList
);