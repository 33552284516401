import { InlineIcon } from '@iconify/react';
import { Button, Divider, Grid, TextField, } from '@mui/material';
import React, {useState, useEffect} from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import './CreateHabilitationForm.style.scss';
import { connect } from 'react-redux';
import { createProfilAsync, updateProfilAsync } from '@/store/reducers/profil/profil.action';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';
import { createStructuredSelector } from 'reselect';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const actions: any[] = ["Créer", "Consulter", "Modifier", "Supprimer"];


const CreateHabilitationFormPage: React.FC<any> = ({
    // userConnected, 
    selectedItem,
    createProfilAsync,
    updateProfilAsync,
    closeModal
}) => {

    let homeItems:any[] = [
        // {
        //     id: 1, 
        //     libelle:'Carte', 
        //     create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Carte' && o.create === true))) ? true : false,
        //     read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Carte' && o.read === true))) ? true : false,
        //     edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Carte' && o.edit === true))) ? true : false,
        //     delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Carte' && o.delete === true))) ? true : false,
        // },
        { 
            id: 2,  
            libelle:'Tableau de bord',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Tableau de bord' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Tableau de bord' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Tableau de bord' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Tableau de bord' && o.delete === true))) ? true : false,
        }
    ];
    
    let chantierItems:any[] = [
        // {
        //     id: 1, 
        //     libelle:'Accueil', 
        //     create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Accueil' && o.object.libelle === 'chantier' && o.create === true))) ? true : false,
        //     read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Accueil' && o.object.libelle === 'chantier' && o.read === true))) ? true : false,
        //     edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Accueil' && o.object.libelle === 'chantier' && o.edit === true))) ? true : false,
        //     delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Accueil' && o.object.libelle === 'chantier' && o.delete === true))) ? true : false,
        // }, 
        {
            id: 2, 
            libelle:'Chantier', 
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Chantier' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Chantier' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Chantier' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Chantier' && o.delete === true))) ? true : false,
        },
        {
            id: 3, 
            libelle:'Incident', 
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Incident' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Incident' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Incident' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Incident' && o.delete === true))) ? true : false,
        },
        {
            id: 4, 
            libelle:'Affectation',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Affectation' && o.object.libelle === 'chantier' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Affectation' && o.object.libelle === 'chantier' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Affectation' && o.object.libelle === 'chantier' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Affectation' && o.object.libelle === 'chantier' && o.delete === true))) ? true : false,
        }, 
        {
            id: 5, 
            libelle:'Historique',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Historique' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Historique' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Historique' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Historique' && o.delete === true))) ? true : false,
        }
    ];
    
    let rhItems:any[] = [
        // {
        //     id: 1, 
        //     libelle:'Accueil',
        //     create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Accueil' && o.object.libelle === 'rh' && o.create === true))) ? true : false,
        //     read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Accueil' && o.object.libelle === 'rh' && o.read === true))) ? true : false,
        //     edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Accueil' && o.object.libelle === 'rh' && o.edit === true))) ? true : false,
        //     delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Accueil' && o.object.libelle === 'rh' && o.delete === true))) ? true : false,
        // }, 
        {
            id: 2, 
            libelle:'Personnel',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Personnel' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Personnel' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Personnel' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Personnel' && o.delete === true))) ? true : false,
        },
        {
            id: 3, 
            libelle:'Affectation',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Affectation'  && o.object.libelle === 'rh' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Affectation'  && o.object.libelle === 'rh' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Affectation'  && o.object.libelle === 'rh' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Affectation'  && o.object.libelle === 'rh' && o.delete === true))) ? true : false,
        }, 
        {
            id: 4, 
            libelle:'Conges',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Conges' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Conges' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Conges' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Conges' && o.delete === true))) ? true : false,
        }, 
        {
            id: 5, 
            libelle:'Absence',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Absence' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Absence' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Absence' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Absence' && o.delete === true))) ? true : false,
        }
    ];
    
    let parametreItems:any[] = [
        // {
        //     id: 1, 
        //     libelle:'Accueil',
        //     create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Accueil' && o.object.libelle === 'parametre' && o.create === true))) ? true : false,
        //     read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Accueil' && o.object.libelle === 'parametre' && o.read === true))) ? true : false,
        //     edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Accueil' && o.object.libelle === 'parametre' && o.edit === true))) ? true : false,
        //     delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Accueil' && o.object.libelle === 'parametre' && o.delete === true))) ? true : false,
        // }, 
        {
            id: 2, 
            libelle:'Habilitation',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Habilitation' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Habilitation' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Habilitation' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Habilitation' && o.delete === true))) ? true : false,
        },
        {
            id: 3, 
            libelle:'Utilisateur',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Utilisateur' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Utilisateur' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Utilisateur' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Utilisateur' && o.delete === true))) ? true : false,
        }, 
        {
            id: 4, 
            libelle:'Client',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Client' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Client' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Client' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Client' && o.delete === true))) ? true : false,
        },
        {
            id: 5, 
            libelle:'Nature travaux',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Nature travaux' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Nature travaux' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Nature travaux' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Nature travaux' && o.delete === true))) ? true : false,
        }, 
        {
            id: 6, 
            libelle:'Categorie equipement',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Categorie equipement' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Categorie equipement' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Categorie equipement' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Categorie equipement' && o.delete === true))) ? true : false,
        },
        {
            id: 7, 
            libelle:'equipement',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'equipement' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'equipement' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'equipement' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'equipement' && o.delete === true))) ? true : false,
        }, 
        {
            id: 8, 
            libelle:'Categorie engin',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Categorie engin' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Categorie engin' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Categorie engin' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Categorie engin' && o.delete === true))) ? true : false,
        },
        {
            id: 9, 
            libelle:'engin',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'engin' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'engin' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'engin' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'engin' && o.delete === true))) ? true : false,
        }, 
        {
            id: 10,
            libelle:'Vidange',
            create: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Vidange' && o.create === true))) ? true : false,
            read: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Vidange' && o.read === true))) ? true : false,
            edit: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Vidange' && o.edit === true))) ? true : false,
            delete: (selectedItem?.permissions && selectedItem?.permissions?.find((o:any) => (o.libelle === 'Vidange' && o.delete === true))) ? true : false,
        }
    ];


    const [home, setHome] = useState(homeItems);
    const [chantier, setChantier] = useState(chantierItems);
    const [rh, setRh] = useState(rhItems);
    const [parametre, setParametre] = useState(parametreItems);
    const [isHomeIndeterminate, setIsHomeIndeterminate] = useState(false);
    const [isChantierIndeterminate, setIsChantierIndeterminate] = useState(false);
    const [isRhIndeterminate, setIsRhIndeterminate] = useState(false);
    const [isParametreIndeterminate, setIsParametreIndeterminate] = useState(false);
    const [homeChecked, setHomeChecked] = useState(false);
    const [chantierChecked, setChantierChecked] = useState(false);
    const [rhChecked, setRhChecked] = useState(false);
    const [parametreChecked, setParametreChecked] = useState(false);

    useEffect(() => {

        //intermediate
        if (home) {
            let elt: number = (
                home.filter((a:any) => ( a.create === true ))?.length +
                home.filter((a:any) => ( a.read === true ))?.length +
                home.filter((a:any) => ( a.edit === true))?.length +
                home.filter((a:any) => ( a.delete === true))?.length 
            );
            if ( elt > 0) {
                setHomeChecked(false);
                setIsHomeIndeterminate(true);
            } 
            if (elt === (home?.length* 4)) {
                setIsHomeIndeterminate(false);
                setHomeChecked(true);
            } 
            if (elt === 0) {
                setIsHomeIndeterminate(false);
                setHomeChecked(false);
            }  
        }
        if (chantier) {
            let elt: number = (
                chantier.filter((a:any) => ( a.create === true ))?.length +
                chantier.filter((a:any) => ( a.read === true ))?.length +
                chantier.filter((a:any) => ( a.edit === true))?.length +
                chantier.filter((a:any) => ( a.delete === true))?.length 
            );
            if ( elt > 0) {
                setChantierChecked(false);
                setIsChantierIndeterminate(true);
            } 
            if (elt === (chantier?.length* 4)) {
                setIsChantierIndeterminate(false);
                setChantierChecked(true);
            } 
            if (elt === 0) {
                setIsChantierIndeterminate(false);
                setChantierChecked(false);
            }  
        }
        if (rh) {
            let elt: number = (
                rh.filter((a:any) => ( a.create === true ))?.length +
                rh.filter((a:any) => ( a.read === true ))?.length +
                rh.filter((a:any) => ( a.edit === true))?.length +
                rh.filter((a:any) => ( a.delete === true))?.length 
            );
            if ( elt > 0) {
                setRhChecked(false);
                setIsRhIndeterminate(true);
            } 
            if (elt === (rh?.length* 4)) {
                setIsRhIndeterminate(false);
                setRhChecked(true);
            } 
            if (elt === 0) {
                setIsRhIndeterminate(false);
                setRhChecked(false);
            }  
        }
        if (parametre) {
            let elt: number = (
                parametre.filter((a:any) => ( a.create === true ))?.length +
                parametre.filter((a:any) => ( a.read === true ))?.length +
                parametre.filter((a:any) => ( a.edit === true))?.length +
                parametre.filter((a:any) => ( a.delete === true))?.length 
            );
            if ( elt > 0) {
                setParametreChecked(false);
                setIsParametreIndeterminate(true);
            } 
            if (elt === (parametre?.length* 4)) {
                setIsParametreIndeterminate(false);
                setParametreChecked(true);
            } 
            if (elt === 0) {
                setIsParametreIndeterminate(false);
                setParametreChecked(false);
            }  
        }

    }, [home, chantier, rh, parametre])
    

    const handleToggleAll = (e:any,items:any, title:string) => {
        console.log('e items',e.target.checked);
        let newInputs: any = [];
        if (e.target.checked === true) {
            items.map((i:any) => {
                i['create'] = true;
                i['read'] = true;
                i['edit'] = true;
                i['delete'] = true;
                newInputs.push(i);
            });
        } else {
            items.map((i:any) => {
                i['create'] = false;
                i['read'] = false;
                i['edit'] = false;
                i['delete'] = false;
                newInputs.push(i);
            });
        }
        if (title==='home') {
            setHomeChecked(e.target.checked);
            setIsHomeIndeterminate(false);
            setHome(newInputs);
        } 
        if (title==='chantier') {
            setChantierChecked(e.target.checked);
            setIsChantierIndeterminate(false);
            setChantier(newInputs);
        }
        if (title==='rh') {
            setRhChecked(e.target.checked);
            setIsRhIndeterminate(false);
            setRh(newInputs);
        }
        if (title==='parametre') {
            setParametreChecked(e.target.checked);
            setIsParametreIndeterminate(false);
            setParametre(newInputs);
        }
        console.log('items',home);
        
    }

    const handleChangeArray = (id:any, e:any, title:string) => {

        if (title === 'home') {
            let newInputs: any = home.map((i:any) => {
                if (id === i.id) {
                    console.log('item',i);
                    
                  i[e.target.name] = e.target.checked;
                }
                return i;
            });
          
            setHome(newInputs);
        }
        if (title === 'chantier') {
            let newInputs: any = chantier.map((i:any) => {
                if (id === i.id) {
                  i[e.target.name] = e.target.checked;
                }
                return i;
            });
          
            setChantier(newInputs);
        }
        if (title === 'rh') {
            let newInputs: any = rh.map((i:any) => {
                if (id === i.id) {
                  i[e.target.name] = e.target.checked;
                }
                return i;
            });
          
            setRh(newInputs);
        }
        if (title === 'parametre') {
            let newInputs: any = parametre.map((i:any) => {
                if (id === i.id) {
                  i[e.target.name] = e.target.checked;
                }
                return i;
            });
          
            setParametre(newInputs);
        }
    };

    const validationSchema = yup.object({
        libelle: yup.string().required(" Le libelle est obligatoire "),
    });

    const formik = useFormik({
        initialValues: {
            id: selectedItem ? selectedItem.id : null,
            libelle: selectedItem ? selectedItem.libelle : '',
            description: selectedItem ? selectedItem.description : '',
            permissions:  {
                accueil: home,
                chantier: chantier,
                rh: rh,
                parametre: parametre,
            },
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            console.log('Values', JSON.stringify(values));
            // values.ops = (userConnected?.user?.nom +' '+userConnected?.user?.prenoms);
            if (!selectedItem) {
                delete values.id;
                createProfilAsync(values);
            } else {
                updateProfilAsync(values);
            }
            closeModal();
        }
    });


    return (
        <form className='create-list-form' onSubmit={formik.handleSubmit}>
            <span className="title">  {selectedItem ? 'Modification d\'une habilitation ' : 'Création d\'une habilitation '} </span>

            <Grid container rowSpacing={2} columnSpacing={2} >
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Libelle"
                        variant="outlined"
                        name="libelle"
                        label="Libelle"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.libelle}
                        error={formik.touched.libelle && Boolean(formik.errors.libelle)}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Description"
                        variant="outlined"
                        name="description"
                        label="Description"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        type="text" />
                </Grid>



            </Grid>

            <Divider className='custom-divider' />

            <Grid container rowSpacing={2} columnSpacing={2} >
                <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Checkbox 
                                    checked={homeChecked}
                                    onClick={(e:any)=>{ handleToggleAll(e,home,'home')}}
                                    indeterminate={isHomeIndeterminate}
                                />
                                <span>Accueil</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: '0 !important' }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ width: '100%' }} aria-label="a dense table" size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            {actions.map((o: string, i: number) => (
                                                <TableCell align="right" key={i} sx={{ fontSize: 11 }}>
                                                    {o}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {home?.map((row:any) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ fontSize: 12 }}>
                                                    {row.libelle}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'home')}}
                                                        checked={row.create || false}
                                                        size='small' 
                                                        name="create" 
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'home')}}
                                                        checked={row.read || false}
                                                        size='small' 
                                                        name="read" 
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'home')}}
                                                        checked={row.edit || false}
                                                        size='small' 
                                                        name="edit" 
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'home')}}
                                                        checked={row.delete || false}
                                                        size='small' 
                                                        name="delete" 
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Checkbox 
                                    checked={chantierChecked}
                                    onClick={(e:any)=>{ handleToggleAll(e,chantier,'chantier')}}
                                    indeterminate={isChantierIndeterminate}
                                />
                                <span>Gestion des chantiers</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: '0 !important' }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ width: '100%' }} aria-label="a dense table" size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            {actions.map((o: string, i: number) => (
                                                <TableCell align="right" key={i} sx={{ fontSize: 11 }}>
                                                    {o}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {chantier?.map((row:any) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ fontSize: 12 }}>
                                                    {row.libelle}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'chantier')}}
                                                        checked={row.create || false}
                                                        size='small' 
                                                        name="create" 
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'chantier')}}
                                                        checked={row.read || false}
                                                        size='small' 
                                                        name="read" 
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'chantier')}}
                                                        checked={row.edit || false}
                                                        size='small' 
                                                        name="edit" 
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'chantier')}}
                                                        checked={row.delete || false}
                                                        size='small' 
                                                        name="delete" 
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Checkbox 
                                    checked={rhChecked}
                                    onClick={(e:any)=>{ handleToggleAll(e,rh,'rh')}}
                                    indeterminate={isRhIndeterminate}
                                />
                                <span>Gestion des Ressources humaines</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: '0 !important' }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ width: '100%' }} aria-label="a dense table" size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            {actions.map((o: string, i: number) => (
                                                <TableCell align="right" key={i} sx={{ fontSize: 11 }}>
                                                    {o}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rh?.map((row:any) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ fontSize: 12 }}>
                                                    {row.libelle}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'rh')}}
                                                        checked={row.create || false}
                                                        size='small' 
                                                        name="create" 
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'rh')}}
                                                        checked={row.read || false}
                                                        size='small' 
                                                        name="read" 
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'rh')}}
                                                        checked={row.edit || false}
                                                        size='small' 
                                                        name="edit" 
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'rh')}}
                                                        checked={row.delete || false}
                                                        size='small' 
                                                        name="delete" 
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Checkbox 
                                    checked={parametreChecked}
                                    onClick={(e:any)=>{ handleToggleAll(e,parametre,'parametre')}}
                                    indeterminate={isParametreIndeterminate}
                                />
                                <span>Parametres</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: '0 !important' }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ width: '100%' }} aria-label="a dense table" size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            {actions.map((o: string, i: number) => (
                                                <TableCell align="right" key={i} sx={{ fontSize: 11 }}>
                                                    {o}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {parametre?.map((row:any) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ fontSize: 12 }}>
                                                    {row.libelle}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'parametre')}}
                                                        checked={row.create || false}
                                                        size='small' 
                                                        name="create" 
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'parametre')}}
                                                        checked={row.read || false}
                                                        size='small' 
                                                        name="read" 
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'parametre')}}
                                                        checked={row.edit || false}
                                                        size='small' 
                                                        name="edit" 
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Checkbox 
                                                        onClick  ={(e:any) =>{ handleChangeArray(row.id, e, 'parametre')}}
                                                        checked={row.delete || false}
                                                        size='small' 
                                                        name="delete" 
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>

                </Grid>

            </Grid>

            <div className="btn-part">
                <Button color='secondary'
                    className='btn-option'
                    onSubmit={formik.submitForm}
                    type='submit'
                    startIcon={<InlineIcon icon="iconoir:save-floppy-disk" />}
                    variant='contained'>
                    {selectedItem ? 'Modifier' : 'Créer'}
                </Button>

                <Button color='light'
                    className='btn-option'
                    startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
                    onClick={() => { formik.resetForm() }}
                    variant='contained'>
                    Réinitialiser
                </Button>
            </div>

        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch: any) => ({
    createProfilAsync: (data: any) => dispatch(createProfilAsync(data)),
    updateProfilAsync: (data: any) => dispatch(updateProfilAsync(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const CreateHabilitationForm = connector(CreateHabilitationFormPage);

export default CreateHabilitationForm

