import { InlineIcon } from '@iconify/react';
import { Delete, Edit, ListAlt, TuneRounded, TurnedIn, ViewArray } from '@mui/icons-material';
import { Badge,Button,Chip, Divider, IconButton, InputAdornment, Skeleton, TextField } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SideModal from '../../components/SideModal/SideModal';
import CreatePersonnelForm from '../../Forms/CreatePersonnelForm/CreatePersonnelForm';
import './Personnel.content.style.scss';
import MiniPiehart from '@/features/admin-iveqi/components/Mini-pie-chart/MiniPieChart';
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { deletePersonnelAsync, getAllPersonnelsAsync, getSynthesePersonnelsAsync } from '@/store/reducers/personnel/personnel.action';
import { selectPersonnelItemList, selectLoading, selectSynthesePersonnelList } from '@/store/reducers/personnel/personnel.selector';
import { createStructuredSelector } from 'reselect';
import ListeItemCardGenerique from '@/features/admin-iveqi/components/ListeItem/ListeItemCardGenerique';
import { Stack } from "@mui/material";
import Swal from 'sweetalert2';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';
import { exportToExcel } from '@/config';
import SynthesePersonnel from '../../Forms/SynthesePersonnel/SynthesePersonnel';
import { selectUserProfil } from '@/store/reducers/profil/profil.selector';


type TListLegend = 'Cadre'|'AM'|'Employé'|'Ouvrier';


const columns = [
  { field: 'matricule', headerName: 'Matricule', width: 80 },
  { field: 'nom', headerName: 'Nom', width: 100 },
  { field: 'prenoms', headerName: 'Prénoms', width: 200 },
  { field: 'email', headerName: 'Email', width: 100 },
  { field: 'telephone', headerName: 'Phone', width: 130 },
  { field: 'fonction', headerName: 'Fonction', width: 100},
  { field: 'statut', headerName: 'statut', width: 100 },
  { field: 'categorie_professionnelle', headerName: 'Catégorie', width: 130 },
  { field: 'etat_matrimoniale', headerName: 'Etat Matrimoniale', width: 130 }
  , {
    field: 'situation',
    headerName: 'Situation',
    type: 'text',
    width: 150,
  },
];

type TPersonnel = {
    id?: number,
    email: string,
    matricule: string,
    nom: string,
    prenoms: string,
    telephone?: string,
    date_naissance?: string,
    lieu_naissance?: string,
    age?: string,
    nationalite?: string,
    fonction?: string,
    date_embauche?: string,
    cnps?: string,
    categorie_professionnelle?: string,
    statut: TListLegend,
    genre?: string,
    photo?: string,
    isactif?: number,

    etat_matrimoniale?: string,
    nombre_jr_acquis?: number,
    ops?: string,
    searchText: string
    situation?: string;

}[]


const CtPersonnelRhPage: React.FC<any> = ({
  loading, 
  personnelList, 
  getAllPersonnelsAsync,
  deletePersonnelAsync,
  userConnected,
  synthesePersonnel,
  getSynthesePersonnelsAsync,
  permissions
}) => {

  useEffect(() => {
    const url:any = getAllPersonnelsAsync();

    const fetchData = async () => {
        try {
            await fetch(url);
        } catch (error) {
            console.log("error", error);
        }
    };
    fetchData();
    
  }, [getAllPersonnelsAsync]);


  const [searchText, setSearchText] = useState('');

  const [legend, setLegend] = useState<TListLegend |''>('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isAdd, setIsAdd] = useState(false);

  const [isCardMode, setIsCardMode] = useState(true);

  const [isSyn, setIsSyn] = useState(false);

  const [selection, setSelection] = React.useState<any>(null);

  const listItems: TPersonnel =  personnelList.map((x:any) => {
    return {
      ...x,
      situation: (x.chantierId) ? x.chantier?.nom +' à '+x.chantier?.localisation : 'Pas sur chantier',
      searchText : x.matricule +' '+ x.nom +' '+x.prenoms + ' '+ x.email + ' '+ x.fonction ,
    }
  });

  const getListItemsFilteredSearch = () => {
    return listItems.filter((value:any) => {
      return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
    })
  }

   // Appel de la fonction d'exportation lorsque vous souhaitez exporter les données
   const handleExportExcel = () => {
    exportToExcel(listItems, columns, 'personnel');
  };

  const selectItemFunc = (idElt: number | any) => {
    const element: any =  getListItemsFilteredSearch().find((x:any) => x.id === idElt);
    console.log("element", element);
    if (selection?.id === element?.id) {
      isCardMode && setSelection(null);
      return;
    }
    setSelection(element);
    getSynthesePersonnelsAsync(element?.id);
  }

  const isCardActive = (idCard: any) => {
    // if (selection?.id == idCard) return false
    return selection?.id === idCard
  }

  const confirmBeforeRemove =()=> {
    Swal.fire({
      title: "⚠️ Important ",
      text: "Vous êtes sur le point de supprimer une information, desirez-vous poursuivre cette action ?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
      confirmButtonColor: "red",
      cancelButtonColor: "#91949c",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  const deleteItem = () => {
    let datatoDelete = {"ops": (userConnected?.user?.nom +' '+userConnected?.user?.prenoms), "id": selection.id }
    deletePersonnelAsync(datatoDelete);
  }
  

  return (
    <>
      <div className="ct-personnels-campagne">
        <div className="ct-personnels-campagne__card">

          <div className="header-part">
            <div className="title-part">
                <div className="title-bloc">
                  <span className="title"> Gestion du personnel </span>
                  <Chip label={` ${listItems?.length} ${listItems?.length < 2 ? 'personne' : 'personnes'} `}
                    className='total-number-chip' />

                  <Badge color="secondary">
                      <IconButton className='close-icon'
                            size="small" 
                            color='blue'
                            onClick={()=>{
                              handleOpen();
                              setIsAdd(false);
                            }}>
                        <TuneRounded />
                      </IconButton>
                  </Badge>

                  <Badge color="secondary" 
                        variant="dot"
                        //  invisible={ valueFromSelect?.length === 0}
                         >
                      <IconButton 
                        className='close-icon'
                        title={`Mode ${isCardMode ? "liste" : 'carte'}`}
                        size="small" 
                        color='blue'
                        onClick={()=>{
                          setIsCardMode(!isCardMode)
                        }}>
                        {
                          isCardMode ?
                          <ListAlt /> :
                          <TurnedIn />
                        }
                      </IconButton>
                  </Badge>
                </div>
                <div className="toolbar-part">

                  <div className="actions-part">
                  {
                      selection ?
                      <>
                        <Divider orientation='vertical' />
                        <Button
                          color='blue'
                          className='btn-option'
                          onClick={()=>{
                            handleOpen();
                            setIsAdd(false);
                            setIsSyn(true)
                          }}
                          startIcon={<ViewArray />}>
                          Synthese
                        </Button>
                        <Divider orientation='vertical' />
                        {
                          permissions?.rh?.find((o: any) => (o.libelle === 'Personnel' && o.edit === true)) &&
                          <Button
                          color='blue'
                          className='btn-option'
                          onClick={()=>{
                            handleOpen();
                            setIsAdd(true);
                          }}
                          startIcon={<Edit />}>
                          Modifier
                        </Button>
                        }
                        <Divider orientation='vertical' />
                        {
                          permissions?.rh?.find((o: any) => (o.libelle === 'Personnel' && o.delete === true)) &&
                          <Button
                          color='blue'
                          className='btn-option'
                          onClick={confirmBeforeRemove}
                          startIcon={<Delete />}>
                          Supprimer
                        </Button>
                        }
                      </>: 
                      <>
                        {
                          permissions?.rh?.find((o: any) => (o.libelle === 'Personnel' && o.create === true)) &&
                          <Button
                          color='blue'
                          className='btn-option'
                          onClick={()=>{
                              handleOpen();
                              setIsAdd(true);
                          }}
                          //variant='contained'
                          startIcon={<InlineIcon icon="carbon:document-add" />}>
                          Créer un employé
                        </Button>
                        }
                        <Divider orientation='vertical' />
                        <Button
                          color='blue'
                          className='btn-option'
                          onClick={handleExportExcel}
                          //variant='contained'
                          startIcon={<InlineIcon icon="carbon:document-import" />}>
                          Exporter la liste
                        </Button>
                      </>
                  }
                  </div>

                  <TextField
                    value={searchText}
                    onChange = {
                      (e) => {
                        setSearchText(e.target.value);
                      }
                    }
                    className="search-input"
                    placeholder="Recherche ... "
                    variant="outlined"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InlineIcon icon="bi:search" />
                        </InputAdornment>
                      )
                    }}
                  />

                </div>
            </div>
            <Divider className='custom-divider' />
          </div>

          <PerfectScrollbar id="scrollableDiv" className="content-part">
            <div className="kpi-part">
              <div className={classNames('kpi-card ')}
                      onClick={()=>{ 
                              setLegend('');
                          }}>
                  <span className="value">
                    {listItems?.length}
                  </span>
                  <span className="text">
                    Total 
                  </span>
                  <div className="mini-pie">
                    <MiniPiehart
                          value1={15}
                          value2={50}
                          value3={35}
                          value4={0}
                          color1={'#EF7D00'}
                          color2={'#26353F'}
                          color3={'#008F75'}
                          color4={'#ff1744'}
                          height={30} 
                          width={30}
                          interRadius={5}/>
                  </div>
              </div>
              <div className={classNames('kpi-card tertiary',{'active': legend === 'Cadre',})}
                     onClick={()=>{ 
                            setLegend('Cadre');
                        }}>
                <span className="percentage"> {Math.round((15 / 100) * 100) | 0}%   </span>
                <span className="value">
                  {listItems.filter(val => val.categorie_professionnelle === 'Cadre')?.length}
                </span>
                <span className="text">
                    Cadre
                </span>
              </div>
              <div className={classNames('kpi-card primary',{'active': legend === 'AM',})}
                     onClick={()=>{ 
                            setLegend('AM');
                        }}>
                          <span className="percentage"> {Math.round((50 / 100) * 100) | 0} %   </span>
                <span className="value">
                  {listItems.filter(val => val.categorie_professionnelle === 'AM')?.length }
                </span>
                <span className="text">
                  AM
                </span>
              </div>
              <div className={classNames('kpi-card secondary',{'active': legend === 'Employé',})}
                     onClick={()=>{ 
                            setLegend('Employé');
                        }}>
                <span className="percentage"> {Math.round((35 / 100) * 100) | 0}%   </span>
                <span className="value">
                {listItems.filter(val => val.categorie_professionnelle === 'Employé')?.length }
                </span>
                <span className="text">
                  Employé
                </span>
              </div>
              <div className={classNames('kpi-card quaternary',{'active': legend === 'Ouvrier',})}
                     onClick={()=>{ 
                            setLegend('Ouvrier');
                        }}>
                <span className="percentage"> {Math.round((35 / 100) * 100) | 0} %   </span>
                <span className="value">
                {listItems.filter(val => val.categorie_professionnelle === 'Ouvrier')?.length }
                </span>
                <span className="text">
                Ouvrier
                </span>
              </div>
            </div>
            <>
                {
                    loading && 
                    <>
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="pulse"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                    </>
                }
                {
                  isCardMode ?
                  <InfiniteScroll
                    className="content-part__infinite-scroll-part"
                    scrollableTarget="scrollableDiv"
                    dataLength={listItems?.length}
                    next={getAllPersonnelsAsync}
                    hasMore={true}
                    loader={
                      listItems ?
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        :
                        <p> Aucune donnée disponible </p>
                    }>

                        {
                              !loading &&
                              listItems.filter((value: any) => {
                                  return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
                                })
                                .map((value: any, index: any) => {

                                return <ListeItemCardGenerique key={index}
                                id={value.id}
                                setOpen={setOpen}
                                columns={columns}
                                data={[value]}
                                setIsEdit={selectItemFunc}
                                isActive={isCardActive(value.id)}
                                />

                                })
                          }
                  </InfiniteScroll> :
                  <div style={{ height: '85%', width: '100%' }}>
                    <DataGrid  components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            Pas de donnees
                          </Stack>
                        ),
                      }}
                      localeText={{
                        MuiTablePagination: {
                          labelDisplayedRows: ({ from, to, count }) =>
                            `${from} - ${to} Total: ${count}`,
                        },
                      }}
                      rows={
                        getListItemsFilteredSearch()
                      }
                      columns={columns}
                       initialState={{
                        pagination: { paginationModel: { pageSize: 7 } },
                      }}
                      onRowSelectionModelChange={(row) => {
                        selectItemFunc(row[0]);
                      }}
                    />
                  </div>
                }     
            
            
            </>

          </PerfectScrollbar>
        </div>
      </div>

      <SideModal 
          open={open}
          onClose={handleClose}
          closeClick={handleClose}>
            <>
                {
                    isAdd &&
                    <CreatePersonnelForm closeModal={handleClose} selectedItem={selection} />
                }
                {
                  isSyn &&
                  <SynthesePersonnel closeModal={handleClose} selectedItem={synthesePersonnel} />
                }
            </>
      </SideModal>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
  personnelList: selectPersonnelItemList,
  userConnected: selectAuthUser,
  synthesePersonnel: selectSynthesePersonnelList,
  permissions: selectUserProfil
});

const mapDispatchToProps = (dispatch:any) => ({
  getAllPersonnelsAsync: () => dispatch(getAllPersonnelsAsync()),
  deletePersonnelAsync: (data:any) => dispatch(deletePersonnelAsync(data)),
  getSynthesePersonnelsAsync: (id:any) => dispatch(getSynthesePersonnelsAsync(id)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CtPersonnelRh = connector(CtPersonnelRhPage);


export default CtPersonnelRh;
