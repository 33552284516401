import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectCarte = (state:TRootState) => state.carte;

export const selectCarteChantierLoading = createSelector(
  [selectCarte],
  (carte:any) => carte.loading_carte_chantier
);

export const selectCarteStatistiqueLoading = createSelector(
  [selectCarte],
  (carte:any) => carte.loading_carte_statistique
);

export const selectCarteEnginLoading = createSelector(
  [selectCarte],
  (carte:any) => carte.loading_carte_engin_by_id_chantier
);

export const selectCartePersonLoading = createSelector(
  [selectCarte],
  (carte:any) => carte.loading_carte_person_by_id_chantier
);

export const selectCarteDataByStatusLoading = createSelector(
  [selectCarte],
  (carte:any) => carte.loading_carte_data_by_status
);

export const selectCarteChantierItemList = createSelector(
  [selectCarte],
  (carte:any) => carte.list_carte_chantier
);

export const selectCarteStatistiqueItemList = createSelector(
  [selectCarte],
  (carte:any) => carte.list_carte_statistique
);

export const selectCarteEnginByIdChantierItemList = createSelector(
  [selectCarte],
  (carte:any) => carte.list_carte_engin_by_id_chantier
);

export const selectCartePersonByIdChantierItemList = createSelector(
  [selectCarte],
  (carte:any) => carte.list_carte_person_by_id_chantier
);

export const selectCarteDataByStatusItemList = createSelector(
  [selectCarte],
  (carte:any) => carte.list_carte_data_by_status
);