import React, { Fragment, useState } from 'react';
import './SyntheseEngin.style.scss';
import { Divider,  Grid, IconButton,  TextField } from '@mui/material';
import { InlineIcon } from '@iconify/react';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';

const SyntheseEngin: React.FC<any> = ({ selectedItem }) => {

    console.log('selectedItem', selectedItem);

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);

    const handleClose = () => {
        setOpen(false);
        setUtilisationEngins([]);
        setIncidentEngins([]);
        setItemSelected([]);
    };

    const handleClose2 = () => {
        setOpen2(false);
        setAbsencePersonnel([]);
        setItemSelected([]);
    };

    const columns = [
        { field: 'date', headerName: 'Date',   valueGetter: (params: any) => {
            // Format the date using moment
            return moment(params.value).format('DD-MM-YYYY');
        },width: 190 },
        { field: 'travail', headerName: 'travail', width: 190 },
        { field: 'compteur_debut', headerName: 'compteur_debut', width: 190 },
        { field: 'compteur_fin', headerName: 'compteur_fin', width: 190 },
        { field: 'quantite', headerName: 'quantite', width: 190 },
        { field: 'carburant', headerName: 'Carburant', width: 190 },
        { field: 'chauffeur', headerName: 'Chauffeur', width: 190 },
        { field: 'nature_travaux', headerName: 'Nature Travaux', width: 190 },
        { field: 'motif', headerName: 'Motif', width: 190 },
        { field: 'observation', headerName: 'observation', width: 190 },
      ];

    const personnelColumns = [
        { field: 'date', headerName: 'Date',   valueGetter: (params: any) => {
            // Format the date using moment
            return moment(params.value).format('DD-MM-YYYY');
        },width: 120 },
        { field: 'heure_debut', headerName: 'Heure Debut', width: 120 },
        { field: 'heure_fin', headerName: 'Heure Fin', width: 120 },
        { field: 'motif', headerName: 'Motif', width: 320 },
        { field: 'observation', headerName: 'observation', width: 190 },
      ];

    let vidangeFormArray = selectedItem?.vidange?.map((x: any) => {
        return {
            ...x,
            libelle: x?.libelle_vidange
        }
    })

    let incidentFormArray = selectedItem?.incident?.map((x: any) => {
        return {
            ...x,
        }
    })

    let chantierFormArray = selectedItem?.chantier;
  //  let caburantFormArray = selectedItem?.caburant;

    const [utilisationEngins, setUtilisationEngins] = useState([]);
    const [incidentEngins, setIncidentEngins] = useState([]);
    const [absencePersonnel, setAbsencePersonnel] = useState([]);


    // const handleSeeMoreEnginFormArray = (id: number) => {
    // //     let values: any[] = [...selectedItem?.engin];
    // //     let data: any = [];
    // //     values.map((value: any) => {
    // //         if (value.id === id) {
    // //             data.push(value);
    // //         }
    // //     });
    // //     console.log('handleSeeMoreEnginFormArray', data.utilisation_engins);
    // //    setItemSelected(data);
    // //     setUtilisationEngins(data.utilisation_engins);
    // //     setIncidentEngins(data.incident_engins || []);
    // //     console.log('itemSelected', itemSelected);
    // //     console.log('utilisationEngins', utilisationEngins);
    // //     setOpen(true);
    //     const enginData = selectedItem?.engin?.find((value: any) => value.id === id);

    //     if (enginData) {
    //         setUtilisationEngins(enginData.utilisation_engins || []);
    //         setIncidentEngins(enginData.incident_engins || []);
    //         setItemSelected(enginData);
    //         setOpen(true);
    //     }
    // };

    const handleSeeMorePersonnelFormArray = (id: number) => {
        const personnelData = selectedItem?.personnel?.find((value: any) => value.id === id);

        if (personnelData) {
            setAbsencePersonnel(personnelData.absences_chantiers || []);
            setItemSelected(personnelData);
            setOpen2(true);
        }
    };

    // const handleSeeMoreCuveFormArray = (id: number) => {
    //     let values: any[] = [...selectedItem?.utilisation_cuve];
    //     let data: any = [];
    //     values.map((value: any) => {
    //         if (value.id === id) {
    //             data.push(value);
    //         }
    //     });
    //     setItemSelected(data);
    //     console.log('itemSelected', itemSelected);
    //     setOpen(true);
    // };

    return (<>
        <form className='create-list-form'>
            <span className="title"> Synthese de {selectedItem?.engin?.libelle} ({selectedItem?.engin?.num_parc}) </span>

            <Grid container rowSpacing={2} columnSpacing={2} >
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="Libelle"
                        className='my-text-field'
                        value={selectedItem?.engin?.num_parc}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="description"
                        className='my-text-field'
                        value={selectedItem?.engin?.description}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="Numéro parc"
                        className='my-text-field'
                        value={selectedItem?.engin?.num_parc}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="Type de compteur"
                        className='my-text-field'
                        value={selectedItem?.engin?.type_compteur_engin}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="intervalle entretien"
                        className='my-text-field'
                        value={selectedItem?.engin?.intervalle_entretien}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="dernier entretien"
                        className='my-text-field'
                        value={selectedItem?.engin.derniere_entretien}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="prochain entretien"
                        className='my-text-field'
                        value={selectedItem?.engin.prochain_entretien}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="num chassis"
                        className='my-text-field'
                        value={selectedItem?.engin?.num_chassis}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="Immatriculation"
                        className='my-text-field'
                        value={selectedItem?.engin?.immatriculation}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="Dernier compteur"
                        className='my-text-field'
                        value={selectedItem?.engin?.dernier_compteur}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="date dernier compteur"
                        className='my-text-field'
                        value={selectedItem?.engin?.date_dernier_compteur}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        name="typeCuve"
                        label="compteur aquisition"
                        className='my-text-field'
                        value={selectedItem?.engin?.compteur_aquisition ? selectedItem?.engin?.compteur_aquisition : "Inexistant"}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        name="date_aquisition"
                        label="Date aquisition"
                        className='my-text-field'
                        value={selectedItem?.engin?.date_aquisition ? selectedItem?.engin?.date_aquisition : "Inexistant"}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        name="annee_fabrication"
                        label="Année fabrication"
                        className='my-text-field'
                        value={selectedItem?.engin?.annee_fabrication}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        name="type_cabine"
                        label="Type cabine"
                        className='my-text-field'
                        value={selectedItem?.engin?.type_cabine}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        name="climatisation"
                        label="Climatisation"
                        className='my-text-field'
                        value={selectedItem?.engin?.climatisation}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        name="protection_cabine"
                        label="Protection cabine"
                        className='my-text-field'
                        value={selectedItem?.engin?.protection_cabine}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        name="poids_a_vide"
                        label="poids_a_vide"
                        className='my-text-field'
                        value={selectedItem?.engin?.poids_a_vide}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        name="puissance_moteur"
                        label="puissance_moteur"
                        className='my-text-field'
                        value={selectedItem?.engin?.puissance_moteur}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        name="capacite_recevoir"
                        label="capacite_recevoir"
                        className='my-text-field'
                        value={selectedItem?.engin?.capacite_recevoir}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        name="transmission"
                        label="transmission"
                        className='my-text-field'
                        value={selectedItem?.engin?.transmission}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        name="capacite_chargement"
                        label="capacite_chargement"
                        className='my-text-field'
                        value={selectedItem?.engin?.capacite_chargement}
                        type="text" />
                </Grid>

              
            </Grid>

            {
                (vidangeFormArray?.length || vidangeFormArray) &&
                <>
                    <span className="sub-title-part"> <InlineIcon icon="bx:user" /> &nbsp;  Vidange </span>
                    <Divider className='custom-divider' />

                    <Grid container rowSpacing={2} columnSpacing={2} >
                        {vidangeFormArray?.map((m: any, i: number) => (
                            <Fragment key={m.id}>
                                <Grid item xs={12} sm={12} lg={12} xl={11} >
                                    <Grid container rowSpacing={1} columnSpacing={2} >
                                        <Grid item xs={12} sm={12} lg={12} xl={4} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m.compteur_engin}
                                                variant="outlined"
                                                name="compteur_engin"
                                                label="Compteur engin"
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12} xl={4} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m.libelle_vidange}
                                                variant="outlined"
                                                name="pngin"
                                                label={`libelle ${i + 1}`}
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12} xl={4} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                variant="outlined"
                                                label="Date"
                                                className='my-text-field'
                                                value={(m.date_vidange  == null ? "Sur chantier": moment(m.date_vidange).format('DD-MM-YYYY') )}
                                                type="text" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <hr />
                               
                            </Fragment>
                        ))}
                    </Grid>
                </>
            }

            {
                (incidentFormArray?.length || incidentFormArray) &&
                <>
                    <span className="sub-title-part"> <InlineIcon icon="bx:user" /> &nbsp;  Incident Engin </span>
                    <Divider className='custom-divider' />

                    <Grid container rowSpacing={2} columnSpacing={2} >
                        {incidentFormArray?.map((m: any ) => (
                            <Fragment key={m.id}>
                                <Grid item xs={12} sm={12} lg={12} xl={11} >
                                    <Grid container rowSpacing={1} columnSpacing={2} >
                                        <Grid item xs={12} sm={12} lg={12} xl={4} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={ moment(m.date_incient).format('DD-MM-YYYY')}
                                                variant="outlined"
                                                name="Date incident"
                                                label={`Date incident`}
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12} xl={4} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m.panne}
                                                variant="outlined"
                                                name="Panne"
                                                label="Panne"
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12} xl={4} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={ moment(m.date_depannage).format('DD-MM-YYYY')}
                                                variant="outlined"
                                                name="Date depannage"
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        ))}
                    </Grid>
                </>
            }

            {
                (chantierFormArray?.length || chantierFormArray) &&
                <>
                    <span className="sub-title-part"> <InlineIcon icon="bx:user" /> &nbsp; Liste des Chantiers </span>
                    <Divider className='custom-divider' />

                    <Grid container rowSpacing={2} columnSpacing={2} >
                        {chantierFormArray?.map((m: any, i: number) => (
                            <Fragment key={m.id}>
                                <Grid item xs={12} sm={12} lg={12} xl={11} >
                                    <Grid container rowSpacing={1} columnSpacing={2} >
                                        <Grid item xs={12} sm={12} lg={12} xl={6} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m.nom}
                                                variant="outlined"
                                                name="nom"
                                                label="nom"
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12} xl={6} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m.numero_chantier}
                                                variant="outlined"
                                                name="numero_chantier"
                                                label="numero_chantier"
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid item xs={12} sm={12} lg={12} xl={1}>
                                    <IconButton
                                        onClick={() => { handleSeeMorePersonnelFormArray(m.id) }}
                                        style={{ transform: 'translateY(5px)' }}
                                        size='small'
                                        color="error"
                                        title='Voir les details'>
                                        <InlineIcon icon="clarity:eye-line" />
                                    </IconButton>
                                </Grid>
                            
                            </Fragment>
                        ))}
                    </Grid>
                </>
            }

        </form>

        <div className="myModal" style={open ? { display: 'block' } : { display: 'none' }}>
            {/* <!-- myModal content --> */}
            <div className="myModal-content">
                <span className="myModal-close" onClick={handleClose}>&times;</span>
                <span className="myModal-title">Detail</span>
                <div style={{ height: '85%', width: '100%' }}>
                    <DataGrid
                        rows={utilisationEngins}
                        columns={columns}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        localeText={{
                            MuiTablePagination: {
                            labelDisplayedRows: ({ from, to, count }) =>
                                `${from} - ${to} Total: ${count}`,
                            },
                        }}
                        />
                </div>
            </div>
        </div>

        <div className="myModal" style={open2 ? { display: 'block' } : { display: 'none' }}>
            {/* <!-- myModal content --> */}
            <div className="myModal-content">
                <span className="myModal-close" onClick={handleClose2}>&times;</span>
                <span className="myModal-title">Detail Personnel</span>
                <div style={{ height: '85%', width: '100%' }}>
                    <DataGrid
                        rows={absencePersonnel}
                        columns={personnelColumns}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        localeText={{
                            MuiTablePagination: {
                            labelDisplayedRows: ({ from, to, count }) =>
                                `${from} - ${to} Total: ${count}`,
                            },
                        }}
                        />
                </div>
            </div>
        </div>
    </>)
}

export default SyntheseEngin