import { InlineIcon } from '@iconify/react';
import { Autocomplete, Box, Button, FormControlLabel, FormGroup, Grid, Switch, TextField, Typography } from '@mui/material';
import React, {useEffect, useState} from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import './CreateUtilisationForm.style.scss';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from 'moment';
import dayjs from "dayjs";
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';
import { getAllPersonnelsAsync } from '@/store/reducers/personnel/personnel.action';
import { selectPersonnelItemList } from '@/store/reducers/personnel/personnel.selector';
import { getAllNatureTravauxAsync } from '@/store/reducers/nature-travaux/natureTravaux.action';
import { selectNatureTravauxItemList } from '@/store/reducers/nature-travaux/natureTravaux.selector';
import { createUseAffectedEnginAsync, updateUseAffectedEnginAsync } from '@/store/reducers/affectation/affectation.action';


const CreateUtilisationFormPage: React.FC<any> = ({
    selectedItem,
    userConnected,
    closeModal,
    settingData,
    getNatureTravauxList,
    getPersonnelList,
    getAllNatureTravauxAsync,
    getPersonnelListAsync,
    createUseAffectedEnginAsync,
    updateUseAffectedEnginAsync,
    isEditAffectedRow
}) => {

    console.log('settingData',settingData);
    console.log('selectedItem',selectedItem);
    console.log('userConnected',userConnected);
    

    useEffect(() => {
        const url2:any = getAllNatureTravauxAsync();
        const url1:any = getPersonnelListAsync();
    
        const fetchData = async () => {
            try {
                await fetch(url1);
                await fetch(url2);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
        
    }, [getPersonnelListAsync,getAllNatureTravauxAsync]);

    let chauffeurList:any[] = [];
    getPersonnelList.forEach((x:any) => {
       
        if (x?.fonction.includes('auffeur')) {
            chauffeurList.push({id: x.id , value: (x.nom + ' '+ x.prenoms)});
        } else { 
            
        }
    }); 
    
    console.log('chauffeurList', chauffeurList);

    const [chauffeur, setChauffeur] = useState<{ id: any, value: string }>(selectedItem ? {id:selectedItem?.utilisateur?.id , value:selectedItem?.utilisateur?.nom} : { id: null, value: '' });
    const handleChauffeurChange = (event: any, value: { id: any, value: string } | null) => {
        console.log(value);
        if (value === null) {
            setChauffeur({ id: null, value: '' });
        } else {
            setChauffeur(value as { id: any, value: string });
        }
    };

    let natureTravauxList:any[] = [];
    getNatureTravauxList.forEach((x:any) => {
            natureTravauxList.push({id: x.libelle , value: x.libelle});
    }); 
    
    console.log('NatureTravauxList', natureTravauxList);

    const [natureTravaux, setNatureTravaux] = useState<{ id: any, value: string }>(selectedItem ? {id:selectedItem?.nature_travaux , value:selectedItem?.nature_travaux} : { id: null, value: '' });
    const handleNatureTravauxChange = (event: any, value: { id: any, value: string } | null) => {
        console.log(value);
        if (value === null) {
            setNatureTravaux({ id: null, value: '' });
        } else {
            setNatureTravaux(value as { id: any, value: string });
        }
    };

    const [dateValue, setDateValue] = useState(selectedItem ? selectedItem?.date : new Date());

    const validationSchema = yup.object({
        // nom: yup.string().required(" Le nom du chantier est obligatoire "),
    });

    const formik = useFormik({
        initialValues: {
            id: selectedItem ? selectedItem.id : null,
            date: moment(dateValue)?.format('YYYY-MM-DD HH:mm:ss'),
            chantierEnginId: settingData ? settingData?.row?.chantierId : '',
            enginId: settingData ? settingData?.row?.item?.enginId : '',
            compteur_debut: settingData ? settingData?.row?.compteur_debut : '',
            compteur_fin: selectedItem ? selectedItem.compteur_fin : '',
            carburant: selectedItem ? selectedItem.carburant : '',
            quantite: selectedItem ? selectedItem.quantite : '',
            nature_travaux: selectedItem ? selectedItem.nature_travaux : '',
            chauffeur: selectedItem ? selectedItem.chauffeur : '',
            utilisateurId: userConnected?.user?.id,
            travail: selectedItem ? selectedItem.travail : false,
            ops: (userConnected?.user?.nom +' '+userConnected?.user?.prenoms)
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values: any) => {

            (values.travail && values.travail[0] === 'on') ? values.travail = 'oui': values.travail = 'non';
            console.log('data', JSON.stringify(values));
            
            if (!isEditAffectedRow) {
                delete values.id;
                createUseAffectedEnginAsync(values);
            } else {
                updateUseAffectedEnginAsync(values);
            }
            closeModal();
        }
    });


    return (
        <form className='create-list-form' onSubmit={formik.handleSubmit}>
            <span className="title"> { !selectedItem.enginId ? 'Création d\'une utilisation' : 'Modification d\'une utilisation'} </span>

            <Grid container rowSpacing={2} columnSpacing={2} >
                <Grid item xs={12} sm={12} lg={12} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                    <DatePicker
                        label="Date de début"
                        value={dateValue}
                        onChange={(newValue:any) => {
                            setDateValue(newValue);
                            // formik.setFieldValue("date", newValue.format('YYYY-MM-DD HH:mm:ss'));
                        }}
                        renderInput={(params) => <TextField {...params} className='my-text-field' />}
                    />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <Autocomplete
                        id="id-chauffeur-select"
                        options={chauffeurList}
                        value={chauffeur}
                        onChange={handleChauffeurChange}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option?.value === value?.value}
                        getOptionLabel={(option) => option?.value}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.value}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='my-text-field'
                                placeholder="Chauffeur"
                                label="Chauffeur"
                                variant="outlined"
                                name="chauffeur"
                                onChange={formik.handleChange}
                                value={formik.values.chauffeur = chauffeur.value}
                                error={formik.touched.chauffeur && Boolean(formik.errors.chauffeur)}
                                // helperText={formik.touched.chauffeurId && formik.errors.chauffeurId}
                            />
                        )} />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Compteur debut"
                        variant="outlined"
                        // name="compteur_debut"
                        label="Compteur debut"
                        className='my-text-field'
                        // onChange={formik.handleChange}
                        value={settingData?.row?.dernier_compteur}
                        // error={formik.touched.compteur_debut && Boolean(formik.errors.compteur_debut)}
                        // helperText={formik.touched.compteur_debut && formik.errors.compteur_debut}
                        inputProps={{
                            readOnly: Boolean(true),
                            // disabled: Boolean(readOnly),
                        }}
                        type="number" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Compteur fin"
                        variant="outlined"
                        name="compteur_fin"
                        label="Compteur fin"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.compteur_fin}
                        error={formik.touched.compteur_fin && Boolean(formik.errors.compteur_fin)}
                        // helperText={formik.touched.compteur_fin && formik.errors.compteur_fin}
                        type="number" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Carburant"
                        variant="outlined"
                        name="carburant"
                        label="Carburant"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.carburant}
                        error={formik.touched.carburant && Boolean(formik.errors.carburant)}
                        // helperText={formik.touched.carburant && formik.errors.carburant}
                        type="number" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Quantite"
                        variant="outlined"
                        name="quantite"
                        label="Quantite"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.quantite}
                        error={formik.touched.quantite && Boolean(formik.errors.quantite)}
                        // helperText={formik.touched.quantite && formik.errors.quantite}
                        type="number" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <Autocomplete
                        id="id-chauffeur-select"
                        options={natureTravauxList}
                        value={natureTravaux}
                        onChange={handleNatureTravauxChange}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option?.value === value?.value}
                        getOptionLabel={(option) => option?.value}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.value}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='my-text-field'
                                placeholder="Nature travaux"
                                label="Nature travaux"
                                variant="outlined"
                                name="natureTravaux"
                                onChange={formik.handleChange}
                                value={formik.values.nature_travaux = natureTravaux.value}
                                error={formik.touched.nature_travaux && Boolean(formik.errors.nature_travaux)}
                                // helperText={formik.touched.natureTravauxId && formik.errors.natureTravauxId}
                            />
                        )} />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <div className="switch-container">
                        <Typography>Travail </Typography>
                        <FormGroup className='my-toggle'>
                            <FormControlLabel
                            label={formik.values.travail ? "Oui" : "Non"}
                            control={<Switch name="travail"
                                checked={formik.values.travail}
                                onChange={formik.handleChange} />}
                            />
                        </FormGroup>
                    </div>
                </Grid>
                
            </Grid>

            
            <div className="btn-part">
                <Button color='secondary'
                    className='btn-option'
                    type='submit'
                    startIcon={<InlineIcon icon={ selectedItem.enginId ? "iconoir:edit-pencil" : "iconoir:save-floppy-disk"} />}
                    variant='contained'>
                    { selectedItem.enginId ? 'Modifier':'Créer'}
                </Button>

                <Button color='light'
                    className='btn-option'
                    startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
                    onClick={()=>{formik.resetForm()}}
                    variant='contained'>
                    {'Réinitialiser'}
                </Button>
            </div>

        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    getNatureTravauxList: selectNatureTravauxItemList,
    getPersonnelList: selectPersonnelItemList,
    userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch:any) => ({
    getAllNatureTravauxAsync: () => dispatch(getAllNatureTravauxAsync()),
    getPersonnelListAsync: () => dispatch(getAllPersonnelsAsync()),
    createUseAffectedEnginAsync: (data:any) => dispatch(createUseAffectedEnginAsync(data)),
    updateUseAffectedEnginAsync: (data:any) => dispatch(updateUseAffectedEnginAsync(data)),
});
  
const connector = connect(mapStateToProps,mapDispatchToProps);
const CreateUtilisationForm = connector(CreateUtilisationFormPage);

export default CreateUtilisationForm