import React from 'react';
import CtClientsAdministration from '../pages-content/Clients-content/Clients.content';

const Clients:React.FC = () => {
  return (
    <>
      <CtClientsAdministration />
    </>
  )
}

export default Clients;