import { ClientService } from "../../../services/client.service";
import { clientTypes } from "./client.types";

export const setLoading = (loading:any) => ({
    type: clientTypes.LOADING,
    payload: loading,
});

export const getAllClients = (list:any) => ({
    type: clientTypes.GET_CLIENT_LIST,
    payload: list,
});

export const selectedItem = (client:any) => ({
  type: clientTypes.SELECTED_CLIENT,
  payload: client,
});

 
export const  getAllClientsAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  ClientService.getClients();
};

export const  createClientAsync =  (client:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  ClientService.createClient(client);
};

export const  updateClientAsync =  (client:any) =>
(dispatch:any) => {
  console.log("client to service", client);
  dispatch(setLoading(true));
  ClientService.updateClient(client);
};

export const  deleteClientAsync =  (data:any) =>
(dispatch:any) => {
  console.log("client to service", data);
  dispatch(setLoading(true));
  ClientService.deleteClient(data);
};



