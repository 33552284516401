import { InlineIcon } from '@iconify/react';
import {  Autocomplete, Box, Button,  Divider,  Grid, TextField, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import './CreateEquipementForm.style.scss';
import { connect } from 'react-redux';
import { getAllCategorieEquipementsAsync } from '@/store/reducers/categorie-equipement/categorieEquipement.action';
import { selectCategorieEquipementItemList } from '@/store/reducers/categorie-equipement/categorieEquipement.selector';
import { createEquipementAsync, updateEquipementAsync } from '@/store/reducers/equipement/equipement.action';
import { createStructuredSelector } from 'reselect';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';


const CreateEquipementFormPage: React.FC<any> = ({selectedItem, userConnected, getAllCategorieEquipements, getCategorieEquipementsAsync, createEquipementAsync, updateEquipementAsync, closeModal}) => {

    useEffect(() => {
        const url:any = getCategorieEquipementsAsync();
    
        const fetchData = async () => {
            try {
                await fetch(url);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
        
      }, [getCategorieEquipementsAsync]);
   
    const categorieEquipementList: any[] = getAllCategorieEquipements.map((y:any) => {
        return {
            ...y,
          id: y.id,
          value: y.libelle
        }
    })


    const [categorieEquipement, setCategorieEquipement] = useState<any>(selectedItem ? {id:selectedItem?.categorie_equipement?.id , value:selectedItem?.categorie_equipement?.libelle} :{ id: '', value: '' });
    const handleCategorieEquipementChange = (event: any, value: { id: string, value: string } | null) => {
        console.log('Change autocomplete');
        if (value == null) {
            setCategorieEquipement({id: "", value: ''});
        } else {
            setCategorieEquipement(value);
        }
        formik.setFieldValue('categorieEquipementId', value?.id);
    };

    const resetForm = () => {
        formik.resetForm(); setCategorieEquipement({id: "", value: ''});
    }

    const validationSchema = yup.object({
        libelle: yup.string().required("Veuillez renseigner le libellé"),
        description: yup.string().required("Veuillez renseigner la description"),
        categorieEquipementId: yup.string().required("Veuillez sélectionner une catégorie")
    });

    const formik = useFormik({
        initialValues: {
            id: selectedItem ? selectedItem.id : null,
            libelle:  selectedItem ? selectedItem.libelle : '',
            description:  selectedItem ? selectedItem.description : '',
            categorieEquipementId: '',
            ops: (userConnected?.user?.nom +' '+userConnected?.user?.prenoms)
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            console.log('Form Data', values);
            if (!selectedItem) {
                delete values.id;
                createEquipementAsync(values);
            } else {
                updateEquipementAsync(values); 
            }
            closeModal();
        }
    });

   
    return (
        <form className='create-list-form' onSubmit={formik.handleSubmit}>
            <span className="title"> Création d'un Equipement </span>

            <Grid container rowSpacing={2} columnSpacing={2} >
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Libelle equipement"
                        variant="outlined"
                        name="libelle"
                        label="Libelle"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.libelle}
                        error={formik.touched.libelle && Boolean(formik.errors.libelle)}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="description"
                        variant="outlined"
                        name="description"
                        label="description"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <Autocomplete
                        id="id-service-select"
                        options={categorieEquipementList}
                        value={categorieEquipement}
                        onChange={handleCategorieEquipementChange}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.value}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.value}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='my-text-field'
                                placeholder="Categorie Equipemennt"
                                label="Catégorie équipement"
                                variant="outlined"
                                name="categorieEquipementId"
                                onChange={formik.handleChange}
                                value={formik.values.categorieEquipementId = categorieEquipement?.id}
                                error={formik.touched.categorieEquipementId && Boolean(formik.errors.categorieEquipementId)}
                            />
                        )} />
                </Grid>
            </Grid>


      <Divider className='custom-divider' />

            <div className="btn-part">
                <Button color='secondary'
                    className='btn-option'
                    type='submit'
                    startIcon={<InlineIcon icon="iconoir:save-floppy-disk" />}
                    onSubmit={formik.submitForm}
                    variant='contained'>
                    {!selectedItem ? 'Créer' : 'Modifier'}
                </Button>

                <Button color='light'
                    className='btn-option'
                    startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
                    onClick={() => resetForm()}
                    variant='contained'>
                    Réinitialiser
                </Button>
            </div>

        </form>
    )
}


const mapStateToProps = createStructuredSelector({
    getAllCategorieEquipements: selectCategorieEquipementItemList,
    userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch:any) => ({
    getCategorieEquipementsAsync: () => dispatch(getAllCategorieEquipementsAsync()),
    createEquipementAsync: (data:any) => dispatch(createEquipementAsync(data)),
    updateEquipementAsync: (data:any) => dispatch(updateEquipementAsync(data)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CreateEquipementForm = connector(CreateEquipementFormPage);

export default CreateEquipementForm