import { axios } from "@/lib/axios";
import { store } from "@/store";
import { isDev } from "@/utils/isDev";
import { ToastGeneric } from "@/utils/toast";
import { getAllVidangeAsync, setLoading, getAllVidanges } from "@/store/reducers/vidange/vidange.action";


export class VidangeService {

  static getVidange() {
    axios.get('/vidange').then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllVidanges(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static createVidange(data:any) {

    axios.post('/vidange',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{
            store.dispatch(getAllVidangeAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message,  'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static updateVidange(data:any) {

    axios.put('/vidange',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{
            store.dispatch(getAllVidangeAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message,  'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
      
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static deleteVidange(data:any) {

    axios.patch('/delete/vidange',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{
            store.dispatch(getAllVidangeAsync());
            console.log("Not error ---> ");
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
      
    }).catch((error: any)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

}