import React from 'react';
import { CtRhRoutes } from './routes/Rh.content.routes';

const CtRh: React.FC = () => {

  return (
    <>
        <CtRhRoutes />
    </>
  );
};

export default CtRh;
