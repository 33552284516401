import React, { useState } from 'react';
import classnames from 'classnames';
import './DashboardChartCard.styles.scss';
import { Button,  Stack } from '@mui/material';
import { DataGrid, frFR } from '@mui/x-data-grid';
import moment from 'moment';
import { InlineIcon } from '@iconify/react';
import { exportToExcel } from '@/config';


  const columns = [
    { field: 'date', headerName: 'Date',   valueGetter: (params: any) => {
        // Format the date using moment
        return moment(params.value).format('DD-MM-YYYY');
    },width: 110 },
    { field: 'personnel', headerName: 'Personnel', width: 150 },
    { field: 'chantier', headerName: 'Chantier', width: 150 },
    { field: 'heure_debut', headerName: 'Heure Debut', width: 100 },
    { field: 'heure_fin', headerName: 'Heure Fin', width: 100 },
    { field: 'motif', headerName: 'Motif', width: 320 },
    { field: 'observation', headerName: 'observation', width: 190 },
  ];






const DashboardRhCard: React.FC<any> = ({ historiqueRhs })=> {
    console.log('DashboardRhCard', historiqueRhs);

    const [loading, setLoading] = useState(false);

    const [category, setCategory] = useState<'Absences Chantiers'>('Absences Chantiers');

    const [filteredValue, setFilteredValue] = React.useState<string[]>([]);

    const [dataList, setDataList] = React.useState<any[]>([]);
    const [columList, setColumList] = useState<any>(columns);

    
    // Appel de la fonction d'exportation lorsque vous souhaitez exporter les données
    const handleExportUtilisationExcel = () => {
        exportToExcel(dataList, columList, category);
    };


    return (
        <div className="dashboard-chart-card">
            <span className="title"> Performances </span>
            <div className="option-part">

                <div className="categories-part">
                    <div className={classnames('category-item', { 'active': category === 'Absences Chantiers' })}
                        onClick={() => {
                            setCategory('Absences Chantiers');
                         
                            setFilteredValue([]);

                            setLoading(true);
                            setTimeout(() => {
                                //setDataList(historiqueChantiers.UtilisationEngin);
                                // setDataForChart(dataCellule);
                                console.log('historiqueChantiers.absences', historiqueRhs.absences);

                                if(historiqueRhs.absences?.length > 0){
                                  let val=  historiqueRhs.absences.map((x: any) =>{
                                        return {
                                            ...x,
                                            personnel: x.personnel.nom +' '+ x.personnel.prenoms,
                                            chantier: x.chantier.nom
                                        }
                                    });
                                    setDataList(val);
                                    setColumList(columns)
                                }
                               // setDataList((!historiqueRhs.absences)?[]:historiqueRhs.absences);
                               // setColumList(columns)
                                setLoading(false);
                            }, 1000)

                        }}>
                        <span> Absences  </span>
                    </div>
                   
                </div>


                {/* <Select
                    labelId="id-services-filtre"
                    className="filter-select-input"
                    placeholder="test"
                    multiple
                    value={filteredValue}
                    onChange={handleFilteredValue}
                    input={<OutlinedInput />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} color="secondary" />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {
                        category === 'Utilisation Engin' &&
                        servicesList.map((elt, index) => (
                            <MenuItem key={index} value={elt}>
                                <Checkbox checked={filteredValue.indexOf(elt) > -1} />
                                <ListItemText primary={elt} />
                            </MenuItem>
                        ))
                    }
                    {
                        category === 'Incidents' &&
                        cellulesList.map((elt, index) => (
                            <MenuItem key={index} value={elt}>
                                <Checkbox checked={filteredValue.indexOf(elt) > -1} />
                                <ListItemText primary={elt} />
                            </MenuItem>
                        ))
                    }
                    {
                        category === 'Cuves' &&
                        equipesList.map((elt, index) => (
                            <MenuItem key={index} value={elt}>
                                <Checkbox checked={filteredValue.indexOf(elt) > -1} />
                                <ListItemText primary={elt} />
                            </MenuItem>
                        ))
                    }
                </Select>



                <Button color='secondary'
                    className='btn-option'
                    startIcon={<InlineIcon icon="ep:filter" />}
                    variant='contained'>
                    Filtrer les {category}
                </Button> */}

            </div>
            <div >
                {
                    loading ?
                        <div className="loading-chart">
                            <img src={require('@/assets/images/gif/chart-load.gif')} alt="load" />
                        </div>
                        :
                        <div style={{ height: '200%', width: '100%' }}>
                            <Button
                                color='blue'
                                className='btn-option'
                                onClick={handleExportUtilisationExcel}
                                variant='contained'
                                startIcon={<InlineIcon icon="carbon:document-export" />}>
                                Exporter
                            </Button>
                            <DataGrid  components={{
                                NoRowsOverlay: () => (
                                  <Stack height="200%" alignItems="center" justifyContent="center">
                                    Pas de donnees
                                  </Stack>
                                ),
                              }}
                              localeText={{
                                ...frFR.components.MuiDataGrid.defaultProps.localeText,
                                MuiTablePagination: {
                                    labelRowsPerPage: "Lignes par page",
                                    labelDisplayedRows: ({ from, to, count }) =>
                                        `${from} - ${to} sur ${count}`,
                                },
                                noRowsLabel: 'Pas de données',
                                noResultsOverlayLabel: 'Aucun résultat trouvé.',
                                toolbarDensity: 'Densité',
                                toolbarDensityLabel: 'Densité',
                                toolbarDensityCompact: 'Compacte',
                                toolbarDensityStandard: 'Standard',
                                toolbarDensityComfortable: 'Confortable',
                                toolbarColumns: 'Colonnes',
                                toolbarColumnsLabel: 'Sélectionner les colonnes',
                                toolbarFilters: 'Filtres',
                                toolbarFiltersLabel: 'Afficher les filtres',
                                toolbarFiltersTooltipHide: 'Masquer les filtres',
                                toolbarFiltersTooltipShow: 'Afficher les filtres',
                                toolbarFiltersTooltipActive: (count) =>
                                    count !== 1 ? `${count} filtres actifs` : `${count} filtre actif`,
                                toolbarExport: 'Exporter',
                                toolbarExportLabel: 'Exporter',
                                toolbarExportCSV: 'Télécharger en CSV',
                                toolbarExportPrint: 'Imprimer',
                                columnsPanelTextFieldLabel: 'Rechercher une colonne',
                                columnsPanelTextFieldPlaceholder: 'Titre de colonne',
                                columnsPanelDragIconLabel: 'Réorganiser la colonne',
                                columnsPanelShowAllButton: 'Tout afficher',
                                columnsPanelHideAllButton: 'Tout masquer',
                                filterPanelAddFilter: 'Ajouter un filtre',
                                filterPanelDeleteIconLabel: 'Supprimer',
                                filterPanelOperatorAnd: 'Et',
                                filterPanelOperatorOr: 'Ou',
                                filterPanelColumns: 'Colonnes',
                                filterPanelInputLabel: 'Valeur',
                                filterPanelInputPlaceholder: 'Filtrer la valeur',
                                filterOperatorContains: 'contient',
                                filterOperatorEquals: 'est égal à',
                                filterOperatorStartsWith: 'commence par',
                                filterOperatorEndsWith: 'finit par',
                                filterOperatorIs: 'est',
                                filterOperatorNot: 'n\'est pas',
                                filterOperatorAfter: 'est après',
                                filterOperatorOnOrAfter: 'est le ou après',
                                filterOperatorBefore: 'est avant',
                                filterOperatorOnOrBefore: 'est le ou avant',
                                filterOperatorIsEmpty: 'est vide',
                                filterOperatorIsNotEmpty: 'n\'est pas vide',
                                filterOperatorIsAnyOf: 'est l\'un des',
                                filterValueAny: 'toutes',
                                filterValueTrue: 'vrai',
                                filterValueFalse: 'faux',
                                columnMenuLabel: 'Menu',
                                columnMenuShowColumns: 'Afficher les colonnes',
                                columnMenuFilter: 'Filtrer',
                                columnMenuHideColumn: 'Masquer',
                                columnMenuUnsort: 'Annuler le tri',
                                columnMenuSortAsc: 'Trier par ordre croissant',
                                columnMenuSortDesc: 'Trier par ordre décroissant',
                                columnHeaderFiltersTooltipActive: (count) =>
                                    count !== 1 ? `${count} filtres actifs` : `${count} filtre actif`,
                                columnHeaderFiltersLabel: 'Afficher les filtres',
                                columnHeaderSortIconLabel: 'Trier',
                                footerRowSelected: (count) =>
                                    count !== 1
                                        ? `${count.toLocaleString()} lignes sélectionnées`
                                        : `${count.toLocaleString()} ligne sélectionnée`,
                                footerTotalRows: 'Lignes totales :',
                                footerTotalVisibleRows: (visibleCount, totalCount) =>
                                    `${visibleCount.toLocaleString()} sur ${totalCount.toLocaleString()}`,
                                checkboxSelectionHeaderName: 'Sélection',
                                booleanCellTrueLabel: 'oui',
                                booleanCellFalseLabel: 'non',
                                actionsCellMore: 'plus',
                            }}
                            rowHeight={50} 
                            rows={
                                dataList
                            }
                            columns={columList}
                            initialState={{
                              pagination: { paginationModel: { pageSize: 10 } },
                            }}
                          />
                        </div>
                }
            </div>
        </div>
    )
}

export default DashboardRhCard