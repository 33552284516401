import { CategorieEnginService } from "../../../services/categorieEngin.service";
import { categorieEnginTypes } from "./categorieEngin.types";

export const setLoading = (loading:any) => ({
    type: categorieEnginTypes.LOADING,
    payload: loading,
});

export const getAllCategorieEngins = (list:any) => ({
    type: categorieEnginTypes.GET_CATEGORIE_ENGIN_LIST,
    payload: list,
});

export const selectedItem = (categorieEngin:any) => ({
  type: categorieEnginTypes.SELECTED_CATEGORIE_ENGIN,
  payload: categorieEngin,
});

 
export const  getAllCategorieEnginsAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  CategorieEnginService.getCategorieEngins();
};

export const  createCategorieEnginAsync =  (categorieEngin:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  CategorieEnginService.createCategorieEngin(categorieEngin);
};

export const  updateCategorieEnginAsync =  (categorieEngin:any) =>
(dispatch:any) => {
  console.log("categorieEngin to service", categorieEngin);
  dispatch(setLoading(true));
  CategorieEnginService.updateCategorieEngin(categorieEngin);
};

export const  deleteCategorieEnginAsync =  (categorieEngin:any) =>
(dispatch:any) => {
  console.log("categorieEngin to service", categorieEngin);
  dispatch(setLoading(true));
  CategorieEnginService.deleteCategorieEngin(categorieEngin);
};



