import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectAbsence = (state:TRootState) => state.absence;

export const selectLoading = createSelector(
  [selectAbsence],
  (absence:any) => absence.loading
);

export const selectAbsenceItemList = createSelector(
    [selectAbsence],
    (absence:any) => absence.list
);

export const selectAbsenceDetailLoading = createSelector(
  [selectAbsence],
  (absence:any) => absence.loading
);

export const selectAbsenceItemSelected = createSelector(
    [selectAbsence],
    (absence:any) => absence.itemSelected
);
