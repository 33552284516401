import { InlineIcon } from '@iconify/react';
import { Delete, Edit, ListAlt, TurnedIn } from '@mui/icons-material';
import { Badge,  Button, Chip, Divider, IconButton, InputAdornment, Skeleton, TextField } from '@mui/material';
import classNames from 'classnames';
import { useReactToPrint } from 'react-to-print';
import React, { useEffect, useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SideModal from '../../components/SideModal/SideModal';
import './Conges.content.style.scss';
import MiniPiehart from '@/features/admin-iveqi/components/Mini-pie-chart/MiniPieChart';
import CreateCongesForm from '../../Forms/CreateCongesForm/CreateCongesForm';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { createStructuredSelector } from "reselect";
import { selectCongesItemList,selectLoading } from '@/store/reducers/conges/conges.selector';
import { connect, useDispatch } from 'react-redux';
import { deleteCongesAsync, getAllCongesAsync, selectedItem } from '@/store/reducers/conges/conges.action';
import PrintCongesForm from '../../Forms/PrintCongesForm/PrintCongesForm';
import ListeItemCardGenerique from '@/features/admin-iveqi/components/ListeItem/ListeItemCardGenerique';
import { Stack } from "@mui/material";
import Swal from 'sweetalert2';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';
import { exportToExcel } from '@/config';
import { selectUserProfil } from '@/store/reducers/profil/profil.selector';



type TPersonnel = {
  id?: number,
  email?: string,
  matricule?: string,
  nom: string,
  prenoms: string,
  telephone?: string,
  date_naissance?: string,
  lieu_naissance?: string,
  age?: string,
  nationalite?: string,
  fonction?: string,
  date_embauche?: string,
  cnps?: string,
  categorie_professionnelle?: string,
  statut: any,
  genre?: string,
  photo?: string,
  isactif?: number,

  etat_matrimoniale?: string,
  nombre_jr_acquis?: number,
  ops?: string,
}

type TConges = {
  id: number,
  date_depart: string,
  date_retour: string,
  date_prochain_conges: string,
  nombre_jours: string,
  personnel: TPersonnel,
  observation: string,
  searchText: string,
} []

const columns = [
  { field: 'date_depart', headerName: 'Date depart', width: 120 },
  { field: 'date_retour', headerName: 'Date retour', width: 120 },
  { field: 'date_prochain_conges', headerName: 'Date prochaine', width: 130 },
  { field: 'nombre_jours', headerName: 'Nombre de jours pris', width: 170 },
  { field: 'searchText', headerName: 'Personnel', width: 230 },
  { field: 'observation', headerName: 'Observation', width: 230 },
];


const CtCongesRhPage: React.FC<any> = ({loading, congesList, getAllCongesAsync, deleteCongesAsync, userConnected, permissions}) => {

  useEffect(() => {
    const url:any = getAllCongesAsync();

    const fetchData = async () => {
        try {
            await fetch(url);
        } catch (error) {
            console.log("error", error);
        }
    };
    fetchData();
    
  }, [getAllCongesAsync]);

  const dispatch = useDispatch();

  let listItems:TConges = congesList.map((x:any) => {
    return {
      ...x,
      date_depart: moment(x.date_depart).format('DD-MM-YYYY'),
      date_retour: moment(x.date_retour).format('DD-MM-YYYY'),
      date_prochain_conges: moment(x.date_prochain_conges).format('DD-MM-YYYY'),
      searchText: x.personnel.matricule +' ' + x.personnel.nom + ' ' +  x.personnel.prenoms
    }
  });

  const [searchText, setSearchText] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isAdd, setIsAdd] = useState(false);

  const [isCardMode, setIsCardMode] = useState(true);

  const [selection, setSelection] = React.useState<any>(null);

  const getListItemsFilteredSearch = () => {
    return listItems.filter((value:any) => {
      return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
    })
  }

   // Appel de la fonction d'exportation lorsque vous souhaitez exporter les données
   const handleExportExcel = () => {
    exportToExcel(listItems, columns, 'conges');
  };

  const selectItemFunc = (idElt: number | any) => {
    const element: any =  getListItemsFilteredSearch().find((x:any) => x.id === idElt);
    console.log("element", element);
    if (selection?.id === element?.id) {
      isCardMode && setSelection(null);
      return;
    }
    setSelection(element);
    if (element) {
      dispatch(selectedItem(element));
    } else {
      dispatch(selectedItem({}));
    }
  }

  const isCardActive = (idCard: any) => {
    // if (selection?.id == idCard) return false
    return selection?.id === idCard
  }

  const confirmBeforeRemove =()=> {
    Swal.fire({
      title: "⚠️ Important ",
      text: "Vous êtes sur le point de supprimer une information, desirez-vous poursuivre cette action ?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
      confirmButtonColor: "red",
      cancelButtonColor: "#91949c",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  const deleteItem = () => {
    let datatoDelete = {"ops": (userConnected?.user?.nom +' '+userConnected?.user?.prenoms), "id": selection.id }
    deleteCongesAsync(datatoDelete);
  }
  
  const componentRef:any = useRef();
  const handlePrint = useReactToPrint({
      content: ()=> componentRef.current
  })

  return (
    <>
      <div className="ct-conge-campagne">
        <div className="ct-conge-campagne__card">

          <div className="header-part">
            <div className="title-part">
                <div className="title-bloc">
                  <span className="title"> Gestion des congés </span>
                  <Chip label={` ${listItems?.length} congés pris `}
                    className='total-number-chip' />

                  <Badge color="secondary" 
                        variant="dot"
                        //  invisible={ valueFromSelect?.length === 0}
                         >
                      <IconButton 
                        className='close-icon'
                        title={`Mode ${isCardMode ? "liste" : 'carte'}`}
                        size="small" 
                        color='blue'
                        onClick={()=>{
                          setIsCardMode(!isCardMode)
                        }}>
                        {
                          isCardMode ?
                          <ListAlt /> :
                          <TurnedIn />
                        }
                      </IconButton>
                  </Badge>
                </div>
                <div className="toolbar-part">

                  <div className="actions-part">

                    {
                      selection ?
                      <>
                      {
                        permissions?.rh?.find((o: any) => (o.libelle === 'Conges' && o.edit === true)) &&
                        <Button
                          color='blue'
                          className='btn-option'
                          onClick={()=>{
                            handleOpen();
                            setIsAdd(true);
                          }}
                          // eslint-disable-next-line react/jsx-no-undef
                          startIcon={<Edit />}>
                          Modifier
                        </Button>
                      }
                       <Divider orientation='vertical' />       
                       {
                          permissions?.rh?.find((o: any) => (o.libelle === 'Conges' && o.delete === true)) &&
                          <Button
                            color='blue'
                            className='btn-option'
                            onClick={confirmBeforeRemove}
                            startIcon={<Delete />}>
                            Supprimer
                          </Button>
                        }
                        <Divider orientation='vertical' />
                        <Button
                          color='blue'
                          className='btn-option'
                          onClick={handleExportExcel}
                          //variant='contained'
                          startIcon={<InlineIcon icon="carbon:document-export" />}>
                          Exporter la liste
                        </Button>
                      </>: 
                      <>
                       {
                        permissions?.rh?.find((o: any) => (o.libelle === 'Conges' && o.create === true)) &&
                        <Button
                          color='blue'
                          className='btn-option'
                          onClick={()=>{
                              handleOpen();
                              setIsAdd(true);
                          }}
                          //variant='contained'
                          startIcon={<InlineIcon icon="carbon:document-add" />}>
                           Ajouter un congés
                        </Button>
                      }
                        
                        <Divider orientation='vertical' />
                        <Button
                          color='blue'
                          className='btn-option'
                          startIcon={<InlineIcon icon="carbon:document-import" />}
                          onClick={handlePrint}
                          >
                          Imprimer
                        </Button>
                        
                      </>
                  }
                   
                  </div>

                  <TextField
                    value={searchText}
                    onChange = {
                      (e) => {
                        setSearchText(e.target.value);
                      }
                    }
                    className="search-input"
                    placeholder="Recherche ... "
                    variant="outlined"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InlineIcon icon="bi:search" />
                        </InputAdornment>
                      )
                    }}
                  />

                </div>
            </div>
            <Divider className='custom-divider' />
          </div>

          <PerfectScrollbar id="scrollableDiv" className="content-part">
            <div className="kpi-part">
              <div className={classNames('kpi-card ')}>
                  <span className="value">
                    {listItems?.length}
                  </span>
                  <span className="text">
                    Total 
                  </span>
                  <div className="mini-pie">
                    <MiniPiehart
                          value1={15}
                          value2={50}
                          value3={35}
                          value4={0}
                          color1={'#EF7D00'}
                          color2={'#26353F'}
                          color3={'#008F75'}
                          color4={'#ff1744'}
                          height={30} 
                          width={30}
                          interRadius={5}/>
                  </div>
              </div>
              
            </div>
            <>
                {
                    loading && 
                    <>
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="pulse"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                    </>
                }
                {
                  isCardMode ?

                  <InfiniteScroll
                      className="content-part__infinite-scroll-part"
                      scrollableTarget="scrollableDiv"
                      dataLength={listItems?.length}
                      next={getAllCongesAsync}
                      hasMore={true}
                      loader={
                        listItems ?
                          <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                          :
                          <p> Aucune donnée disponible </p>
                      }>

                          {
                                !loading &&
                                listItems.filter((value:any) => {
                                    return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
                                  })
                                  .map((value:any, index:number) => {

                                    return  <ListeItemCardGenerique key={index}
                                            id={value.id}
                                            setOpen={setOpen}
                                            columns={columns}
                                            data={[value]}
                                            setIsEdit={selectItemFunc}
                                            isActive={isCardActive(value.id)}
                                            />

                                  })
                            }
                    </InfiniteScroll>  :
                    <div style={{ height: '85%', width: '100%' }}>
                      <DataGrid  components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            Pas de donnees
                          </Stack>
                        ),
                      }}
                      localeText={{
                        MuiTablePagination: {
                          labelDisplayedRows: ({ from, to, count }) =>
                            `${from} - ${to} Total: ${count}`,
                        },
                      }}
                        rows={
                          getListItemsFilteredSearch()
                        }
                        columns={columns}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 7 } },
                        }}
                        onRowSelectionModelChange={(row) => {
                          selectItemFunc(row[0]);
                        }}
                      />
                    </div>
                }  
            
            
            </>


          </PerfectScrollbar>
        </div>
      </div>

      <SideModal 
          open={open}
          onClose={handleClose}
          closeClick={handleClose}>
            <>
                {
                    isAdd &&
                    <CreateCongesForm closeModal={handleClose} selectedItem={selection} />
                }
            </>
      </SideModal>

      <PrintCongesForm ref={componentRef} item={selection} />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
  congesList: selectCongesItemList,
  userConnected: selectAuthUser,
  permissions: selectUserProfil
});

const mapDispatchToProps = (dispatch:any) => ({
  getAllCongesAsync: () => dispatch(getAllCongesAsync()),
  deleteCongesAsync: (data:any) => dispatch(deleteCongesAsync(data)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CtCongesRh = connector(CtCongesRhPage);

export default CtCongesRh;
