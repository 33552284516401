import { ProfilService } from "@/services/profil.service";
import { profilTypes } from "./profil.types";

export const setLoading = (loading:any) => ({
    type: profilTypes.LOADING,
    payload: loading,
});

export const getAllProfils = (list:any) => ({
    type: profilTypes.GET_PROFIL_LIST,
    payload: list,
});

export const selectedItem = (profil:any) => ({
  type: profilTypes.SELECTED_PROFIL,
  payload: profil,
});

export const getUserProfil = (user:any) => ({
  type: profilTypes.GET_PROFIL_USER,
  payload: user,
});

 
export const  getAllProfilsAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  ProfilService.getProfils();
};

export const  createProfilAsync =  (profil:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  ProfilService.createProfil(profil);
};

export const  updateProfilAsync =  (profil:any) =>
(dispatch:any) => {
  console.log("profil to service", profil);
  dispatch(setLoading(true));
  ProfilService.updateProfil(profil);
};

export const deleteProfilAsync =  (id:any) =>
(dispatch:any) => {
  console.log("profil to service", id);
  dispatch(setLoading(true));
  ProfilService.deleteProfil(id);
};

export const userProfilAsync =  (id:any) =>
(dispatch:any) => {
  console.log("profil to service", id);
  dispatch(setLoading(true));
  ProfilService.detailUserProfil(id);
};



