import { axios } from "@/lib/axios";
import { store } from "@/store";
import { isDev } from "@/utils/isDev";
import { ToastGeneric } from "@/utils/toast";
import { setLoading, getAllConges, getAllCongesAsync } from "@/store/reducers/conges/conges.action";


export class CongesService {

  static getConges() {
    axios.get('/conges').then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllConges(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static createConges(data:any) {

    axios.post('/conges',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{
            store.dispatch(getAllCongesAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message,  'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
       // store.dispatch(setLoading(false));
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static updateConges(data:any) {

    axios.put('/conges',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{
            store.dispatch(getAllCongesAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message,  'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static deleteConges(data:any) {

    axios.patch('/delete/conges',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{
            store.dispatch(getAllCongesAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message,  'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
       
    }).catch((error: any)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

}