import React, { useEffect, useState } from 'react';
import { BarChart, Bar, ResponsiveContainer } from "recharts";

type TMiniStackedChart = {
    stackId:string,
    dataToSet: {name:string,value:number,color:string}[],
}
const MiniStackedChart: React.FC<TMiniStackedChart> = ({
    stackId,
    dataToSet,
}) => {


    const [dataCharts, setDataCharts] = useState<any[]>([]);



    useEffect(() => {


        interface IData {
            [key: string]: any;
        }

        let itemTemp:IData = { name: '',};

        dataToSet.forEach((elt)=>{
            itemTemp[elt.name] = elt.value;
        })

        setDataCharts([itemTemp]);

        // eslint-disable-next-line    
    }, []);



    return (
        <>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={dataCharts}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0
                    }}
                >
                    {
                        dataToSet.map((elt,index, array)=> {
                            return <Bar 
                                        key={index+stackId}
                                        dataKey={elt.name} 
                                        radius={    
                                                    (index === 0 && [0,0,5,5]) || 
                                                    (index === (array?.length -1) && [5,5,0,0])  || 
                                                    [0,0,0,0]
                                                }  
                                        stackId={stackId}
                                        fill={elt.color} />
                        })
                    }
                </BarChart>
            </ResponsiveContainer>
        </>
    )
}

export default MiniStackedChart