import React, { useEffect, useState } from 'react';
import { InlineIcon } from '@iconify/react';
import { HighlightOffRounded } from '@mui/icons-material';
import { Badge, Box, Button, Checkbox, Chip, Divider, Fade, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Select, SelectChangeEvent, TextField, useMediaQuery, useTheme } from '@mui/material';
import styled, { css } from 'styled-components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import { getAllTableDonneesAsync } from '@/store/reducers/table-donnee/tableDonnee.action';
import { selectTableDonneeItemList } from '@/store/reducers/table-donnee/tableDonnee.selector';
import { getAllChantiersAsync } from '@/store/reducers/chantier/chantier.action';
import { selectChantierItemList } from '@/store/reducers/chantier/chantier.selector';
import { selectPersonnelItemList } from '@/store/reducers/personnel/personnel.selector';
import { getAllPersonnelsAsync, getHistoriquePersonnelsAsync } from '@/store/reducers/personnel/personnel.action';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const DashboardFilterRhPage:  React.FC<any> = ({
    getAllPersonnelsAsync,
    getAllPersonnel,
    getChantierList,
    getHistoriquePersonnelsAsync,
    getAllChantiersAsync,
}) => {

    
    useEffect(() => {
        const url:any = getAllPersonnelsAsync();
        const url1:any = getAllChantiersAsync();
    
        const fetchData = async () => {
            try {
                await fetch(url);
                await fetch(url1);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
        
      }, [getAllPersonnelsAsync,getAllChantiersAsync]);

        const personnelList:any = getAllPersonnel.map((x:any) => {
            return {id: x.id , value:x.nom +' '+x.prenoms }
        });
        const chantierList:any = getChantierList.map((x:any) => {
            return {id: x.id , value:x.nom}
       })


    const theme = useTheme();
    const matches_down_lg = useMediaQuery(theme.breakpoints.down('lg'));

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [personnel, setPersonnel] = useState<{ id: number, value: string }>( { id: 0, value: '' });
    const handlePersonnel = (event: SelectChangeEvent<any>) => {
        const { target: { value } } = event;
        console.log('value', value);
      //  setEngin(value);
      formik.setFieldValue("personnelId", value.id);
      setPersonnel(value as { id:0, value: string });
    };

    const [chantier, setChantier] = useState<{ id: number, value: string }>( { id: 0, value: '' });
    const handleChantier = (event: SelectChangeEvent<any>) => {
        const { target: { value } } = event;
        //setChantier(value);
        formik.setFieldValue("chantierId", value.id);

        setChantier(value as { id:number, value: string });
    };

      // Calcul des dates initiales
      const getCurrentDate = () => {
        const date = new Date();
        return date.toISOString().split('T')[0];
    };

    const getFirstDateOfMonth = () => {
        const date = new Date();
        date.setDate(1);
        return date.toISOString().split('T')[0];
    };

    const validationSchema = yup.object({
       dateDebut: yup.string().required(" La date depart est obligatoire "),
       dateFin: yup.string().required(" La date de fin est obligatoire "),
    });

    const formik = useFormik({
        initialValues: {
           dateDebut:getFirstDateOfMonth(),
           dateFin:getCurrentDate(),
           chantierId: null,
           personnelId:null
        },
       validationSchema: validationSchema,
        onSubmit: (values: any) => {
           
            console.log('values formik3', values);
          // dispatch(getHistoriquePersonnelsAsync(values));
          getHistoriquePersonnelsAsync(values);
           handleClose();
        }
    });


    return (
        <>
            <FilterRow media_breakpoint_down_lg={matches_down_lg}>
                <span className="title"> Historique </span>
                {
                   ((formik.values.dateDebut) || (formik.values.dateFin)) && !matches_down_lg && (
                    <div className="tag-part">
                        {formik.values.dateDebut  && (
                            <Badge  color="secondary">
                                <Chip label={`Date de début: ${formik.values.dateDebut}`} className="chip" />
                            </Badge>
                        )}
                        {formik.values.dateFin && (
                            <Badge  color="secondary">
                                <Chip label={`Date de fin: ${formik.values.dateFin}`} className="chip" />
                            </Badge>
                        )}
                        {chantier.value && (
                            <Badge color="secondary">
                                <Chip label={`Chantier: ${chantier.value}`} className="chip" />
                            </Badge>
                        )}
                        {personnel.value && (
                            <Badge color="secondary">
                                <Chip label={`Personnel: ${personnel.value}`} className="chip" />
                            </Badge>
                        )}
                        <Divider orientation="vertical" />
                    </div>
                )}

                <Badge color="primary"
                    className="badge-btn-filter"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    variant="dot"
                    invisible={!matches_down_lg} >
                    <Button color="secondary"
                        variant="contained"
                        onClick={handleOpen}
                        startIcon={<InlineIcon icon="ep:filter" />}>
                        Filtre
                    </Button>
                </Badge>
            </FilterRow>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
            >
                <Fade in={open}>
                    <BoxCustom>
                        <IconButton className='close-icon'
                            size="large"
                            color="primary"
                            onClick={handleClose}>
                            <HighlightOffRounded />
                        </IconButton>

                        <Box>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container rowSpacing={4} >
                                <Grid item xs={12} sm={12} lg={12} xl={12} xxl={12}>
                                    <div className='title'> Formulaire de filtre </div>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} xl={12} xxl={12}>
                                    <TextField
                                        label="Date de début"
                                        name="dateDebut"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={formik.handleChange}
                                        value={formik.values.dateDebut}
                                        error={formik.touched.dateDebut && Boolean(formik.errors.dateDebut)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12} xxl={12}>
                                    <TextField
                                        label="Date de fin"
                                        name="dateFin"
                                        type="date"
                                        onChange={formik.handleChange}
                                        value={formik.values.dateFin}
                                        error={formik.touched.dateFin && Boolean(formik.errors.dateFin)}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} xl={12} xxl={12}>
                                    <FormControl>
                                        <InputLabel id="id-services-filtre"  htmlFor="chantierId"> Chantiers </InputLabel>
                                        <Select
                                            labelId="id-services-filtre"
                                            name='chantierId'
                                            value={chantier.value}
                                            onChange={handleChantier}
                                            input={<OutlinedInput label="chantierId" />}
                                            renderValue={(selected: any) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {Array.isArray(selected) ? (
                                                        selected.map((value: any) => (
                                                            <Chip key={value.id} label={value.value} color="primary" />
                                                        ))
                                                    ) : (
                                                        <Chip key={selected} label={selected} color="primary" />
                                                    )}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                             {chantierList.map((elt: any, index: any) => (
                                                <MenuItem key={index} value={elt}>
                                                    <Checkbox checked={chantier.id === elt.id} />
                                                    <ListItemText primary={elt.value} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12} xxl={12}>
                                    <FormControl>
                                        <InputLabel id="id-client-filtre"  htmlFor="personnelId"> Personnel </InputLabel>
                                        <Select
                                            labelId="id-client-filtre"
                                            name='personnelId'
                                            value={personnel.value}
                                            onChange={handlePersonnel}
                                            input={<OutlinedInput label="personnelId" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {Array.isArray(selected) ? (
                                                        selected.map((value: any) => (
                                                            <Chip key={value.id} label={value.libelle} color="primary" />
                                                        ))
                                                    ) : (
                                                        <Chip key={selected} label={selected} color="primary" />
                                                    )}
                                                </Box>
                                            )}
                                            
                                            MenuProps={MenuProps}
                                        >
                                            {personnelList.map((elt: any, index: any) => (
                                                <MenuItem key={index} value={elt}>
                                                    <Checkbox checked={personnel.id === elt.id} />
                                                    <ListItemText primary={elt.value} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} xl={12} xxl={12}>
                                    <Button color="secondary"
                                        variant="contained"
                                        sx={{ mt: 2 }}
                                        type="submit"
                                        startIcon={<InlineIcon icon="ep:filter" />}>
                                        Appliquer le filtre
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        </Box>

                    </BoxCustom>
                </Fade>
            </Modal>


        </>

    )
}


const FilterRow = styled.div<{
    media_breakpoint_down_lg: boolean
}>`
    width: 100%;
    height: 65px;
    padding: 0px 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px ;
    position: relative;

   ${({ media_breakpoint_down_lg }) =>
        media_breakpoint_down_lg && css`
                    padding: 0px 10px;
                `
    }

    .title {
        font-size: var(--title-size) ;
        font-weight: normal;
        color: var(--ui-primary-tint);
    }

    .tag-part {
        position: absolute;
        right: 190px;
        top: 50%;
        transform: translateY(-50%) ;
        height: 40px;
        width: fit-content;
        border-radius: 5px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding: 0px 5px;
        gap:20px ;

        .chip {
            background: var(--ui-secondary-tint) ;
            color: var(--ui-primary-tint) ;
        }

        hr {
            margin-left: 10px;
        }
    }

    .badge-btn-filter{
        margin-left: auto;

        button {
            width: 120px;
            height: 40px ;
            font-weight: bold ;
        }

        ${({ media_breakpoint_down_lg }) =>
            media_breakpoint_down_lg && css`
                        .MuiBadge-dot{
                            background: var(--ui-secondary-tint);
                            border:1px solid var(--ui-light) ;
                            transform: scale(1.8)translateX(-2px)translateY(-2px);
                        }
                    `
        }


    }




`;

const BoxCustom = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    background: var(--ui-light);
    padding: 30px 30px 50px 30px;
    width: 80%;
    max-width: 700px ;
    height: fit-content;

    .close-icon {
        position: absolute;
        top: 0px;
        right: 0px;
    }


    .title {
        width: 100%;
        text-align: center ;
        font-size: var(--title-size) ;
        color: var(--ui-primary);
        margin-bottom: 20px ;
    }

    .MuiFormControl-root {
        width: 100%;
        background: var(--ui-white) ;
        border-radius: 5px ;
    }
`;

const mapStateToProps = createStructuredSelector({
    getChantierList: selectChantierItemList,
    getData: selectTableDonneeItemList,
    getAllPersonnel: selectPersonnelItemList,
});

const mapDispatchToProps = (dispatch:any) => ({
    getAllPersonnelsAsync: () => dispatch(getAllPersonnelsAsync()),
    getAllTableDonneesAsync: () => dispatch(getAllTableDonneesAsync()),
    getAllChantiersAsync: () => dispatch(getAllChantiersAsync()),
    getHistoriquePersonnelsAsync: (data:any) => dispatch(getHistoriquePersonnelsAsync(data)),
});
  
const connector = connect(mapStateToProps,mapDispatchToProps);
const DashboardFilterRh = connector(DashboardFilterRhPage);

export default DashboardFilterRh

