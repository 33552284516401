import { InlineIcon } from '@iconify/react';
import { Button, Grid, TextField } from '@mui/material';
import React, {useState} from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import './EditEnginAffectedForm.style.scss';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from 'moment';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';
import { getAllAffectedEnginsByChantierAsync, updateEnginAffectationAsync } from '@/store/reducers/affectation/affectation.action';


const EditEnginAffectedFormPage: React.FC<any> = ({
    selectedItem,
    userConnected,
    closeModal,
    updateEnginAffectationAsync,
    getAllAffectedEnginsByChantierAsync
}) => {

    console.log('settingData',selectedItem);
    

    const [date, setDate] = useState(selectedItem ? selectedItem.date : moment().format('YYYY-MM-DD HH:m:s'));

    const validationSchema = yup.object({
        // nom: yup.string().required(" Le nom du chantier est obligatoire "),
    });

    const formik = useFormik({
        initialValues: {
            id: selectedItem ? selectedItem.id : null,
            enginId: selectedItem ? selectedItem?.row?.enginId : '',
            date_chantier: date,
            quantite_a_realiser: selectedItem ? selectedItem?.row?.item?.quantite_a_realiser : '',
            quantite_journalier: selectedItem ? selectedItem?.row?.item?.quantite_journalier : '',
            ops: (userConnected?.user?.nom +' '+userConnected?.user?.prenoms)
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            console.log('data', values);
        
            updateEnginAffectationAsync(values);
            
            closeModal();

            setTimeout(() => {
                getAllAffectedEnginsByChantierAsync(selectedItem?.row?.item?.chantierId);
              }, 1000);
        }
    });


    return (
        <form className='create-list-form' onSubmit={formik.handleSubmit}>
            <span className="title"> { 'Modification des attributs de ' + selectedItem?.row?.num_parc} </span>

            <Grid container rowSpacing={2} columnSpacing={2} >
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                    <DatePicker
                        label="Date affectation"
                        value={date}
                        onChange={(newValue:any) => {
                        setDate(newValue);
                        formik.setFieldValue("date_chantier", newValue.format('YYYY-MM-DD HH:mm:ss'));
                        }}
                        renderInput={(params) => <TextField {...params} className='my-text-field' />}
                    />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Quantite realisee"
                        variant="outlined"
                        name="quantite_a_realiser"
                        label="Quantite realisee"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.quantite_a_realiser}
                        error={formik.touched.quantite_a_realiser && Boolean(formik.errors.quantite_a_realiser)}
                        // helperText={formik.touched.quantite_a_realiser && formik.errors.quantite_a_realiser}
                    />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Quantite journaliere"
                        variant="outlined"
                        name="quantite_journalier"
                        label="Quantite journaliere"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.quantite_journalier}
                        error={formik.touched.quantite_journalier && Boolean(formik.errors.quantite_journalier)}
                        // helperText={formik.touched.quantite_journalier && formik.errors.quantite_journalier}
                    />
                </Grid>

            </Grid>

            
            <div className="btn-part">
                <Button color='secondary'
                    className='btn-option'
                    type='submit'
                    startIcon={<InlineIcon icon={ "iconoir:edit-pencil"} />}
                    variant='contained'>
                    {'Modifier'}
                </Button>

                <Button color='light'
                    className='btn-option'
                    startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
                    onClick={()=>{formik.resetForm()}}
                    variant='contained'>
                    {'Réinitialiser'}
                </Button>
            </div>

        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch:any) => ({
    updateEnginAffectationAsync: (data:any) => dispatch(updateEnginAffectationAsync(data)),
    getAllAffectedEnginsByChantierAsync: (data: any) =>
    dispatch(getAllAffectedEnginsByChantierAsync(data)),
});
  
const connector = connect(mapStateToProps,mapDispatchToProps);
const EditEnginAffectedForm = connector(EditEnginAffectedFormPage);

export default EditEnginAffectedForm