import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectCategorieEquipement = (state:TRootState) => state.categorieEquipement;

export const selectLoading = createSelector(
  [selectCategorieEquipement],
  (categorieEquipement:any) => categorieEquipement.loading
);

export const selectCategorieEquipementItemList = createSelector(
    [selectCategorieEquipement],
    (categorieEquipement:any) => categorieEquipement.list
);

export const selectCategorieEquipementDetailLoading = createSelector(
  [selectCategorieEquipement],
  (categorieEquipement:any) => categorieEquipement.loading
);

export const selectCategorieEquipementItemSelected = createSelector(
    [selectCategorieEquipement],
    (categorieEquipement:any) => categorieEquipement.itemSelected
);
