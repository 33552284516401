import { Divider } from '@mui/material';
import React from 'react';
import MiniStackedChart from '../Mini-Stacked-chart/MiniStackedChart';
import './KpiMiniCard.styles.scss';

type TKpiMiniCard = {
    stackId: string,
    title: string,
    value: number,
    icon: any,
    dataToSet: { name: string, value: number, color: string }[],
}
const KpiMiniCard: React.FC<TKpiMiniCard> = ({
    stackId,
    title,
    value,
    icon,
    dataToSet,
}) => {
    return (
        <div className="kpi-mini-card-container">
            <div className="stacked-chart-part">
                <MiniStackedChart
                    stackId={stackId}
                    dataToSet={dataToSet}
                />
            </div>
            <div className="content-part">
                <span className="title">{title}</span>
                <div className="main-value-part">
                    <span className="main-value">{new Intl.NumberFormat('fr-FR').format(value)}</span>
                    {
                        dataToSet.map((elt, index) => {
                            return <span key={index} className="sub-value" style={{ color: elt.color }} > {Math.round((elt.value / value) * 100) | 0}%  </span>
                        })
                    }
                </div>
                <Divider className='my-divider' />
                <div className="sub-content-part">
                    <div className="sub-detail-icon">
                        <img src={icon} alt="" className="kpi-icon" />
                    </div>
                    <div className="sub-detail-part">

                        {
                            dataToSet.map((elt, index) => {
                                return <div key={index} className="sub-detail-item">
                                    <span className="bullet" style={{ background: elt.color }} ></span>
                                    <span className="number" style={{ color: elt.color }} > {new Intl.NumberFormat('fr-FR').format(elt.value)} </span>
                                    <span className="label"> {elt.name.replace('_', ' ')} </span>
                                </div>
                            })
                        }

                    </div>
                </div>

            </div>
        </div>
    )
}

export default KpiMiniCard