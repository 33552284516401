import MiniPiehart from '@/features/admin-iveqi/components/Mini-pie-chart/MiniPieChart';
import { deleteChantierAsync } from '@/store/reducers/chantier/chantier.action';
import {  selectLoading } from '@/store/reducers/chantier/chantier.selector';
import { InlineIcon } from '@iconify/react';
import {  Badge, Button,  Chip, Divider, IconButton, InputAdornment, Skeleton, TextField } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
// import SideModal from '../../components/SideModal/SideModal';
// import CreateChantierForm from '../../Forms/CreateChantierForm/CreateChantierForm';
import './Incidents.content.style.scss';
import ListeItemCardGenerique from '@/features/admin-iveqi/components/ListeItem/ListeItemCardGenerique';
import { getAllClientsAsync } from '@/store/reducers/client/client.action';
import { Edit, ListAlt, TurnedIn } from '@mui/icons-material';
import { DataGrid} from '@mui/x-data-grid';
import { Stack } from "@mui/material";
// import Swal from 'sweetalert2';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';
import { getAllIncidentsAsync } from '@/store/reducers/incident/incident.action';
import { selectIncidentItemList } from '@/store/reducers/incident/incident.selector';
import { exportToExcel } from '@/config';
import moment from 'moment';
import { selectUserProfil } from '@/store/reducers/profil/profil.selector';
import SideModal from '../../components/SideModal/SideModal';
import CreateIncidentForm from '../../Forms/CreateIncindentForm/CreateIncindentForm';


const columns = [
  { field: 'libelle', headerName: 'Libelle', width: 190 },

  { field: 'engin', headerName: 'Engin', width: 190 },
  { field: 'date_incient', headerName: 'Date Incident', width: 190 },
  { field: 'panne', headerName: 'panne', width: 190 },
  { field: 'observation_panne', headerName: 'Observation panne', width: 190 },
  { field: 'date_depannage', headerName: 'Date depannage', width: 190 },
  { field: 'compteur_depannage', headerName: 'Compteur Depannage', width: 190 },
  { field: 'nom_technicien', headerName: 'Nom Technicien', width: 190 },
  { field: 'observation_depannage', headerName: 'Observation depannage', width: 190 },
  { field: 'chantier', headerName: 'Chantier', width: 190 },
  { field: 'chef_chantier', headerName: 'Chef Chantier', width: 190 },
];

const CtIncidentsCampaignPage: React.FC<any> = ({
  loading,
  getIncidents,
  getAllIncidentsAsync,
  permissions,
  // userConnected
}) => {

  console.log('permissions',permissions);
  

  useEffect(() => {
    const url:any = getAllIncidentsAsync();

    const fetchData = async () => {
        try {
            await fetch(url);
        } catch (error) {
            console.log("error", error);
        }
    };
    fetchData();
    
  }, [getAllIncidentsAsync]);

  // const dispatch = useDispatch();
  
  const [searchText, setSearchText] = useState('');
  const [isCardMode, setIsCardMode] = useState(true);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isAdd, setIsAdd] = useState(false);

  const listItems:any[] = getIncidents.map((x:any) => {
    return {
      ...x,
      date_incient: x?.date_incient ? moment(x?.date_incient).format('LL') : '',
      date_depannage: x?.date_depannage ? moment(x?.date_depannage).format('LL') : '',
      chef_chantier: x?.utilisateur?.nom + ' ' +  x?.utilisateur?.prenoms ,
      chantier: x?.chantier?.nom,
      engin: x?.engin?.num_parc,
      libelle: x?.engin?.libelle,
      searchText: x.panne +' '+ x?.chantier?.nom +' '+ x?.observation_panne +' '+ x?.observation_depannage + ' '+ x?.engin?.num_parc, 
    }
  });


  const [selection, setSelection] = React.useState<any>(null);
  
  const getListItemsFilteredSearch = () => {
    return listItems.filter((value: any) => {
      return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
    })
  }

   // Appel de la fonction d'exportation lorsque vous souhaitez exporter les données
   const handleExportExcel = () => {
    exportToExcel(listItems, columns, 'incident');
  };

  const selectItemFunc = (idElt: number | any) => {
    const element: any =  getListItemsFilteredSearch().find((x:any) => x.id === idElt);
    console.log("element", element);
    if (selection?.id === element?.id) {
      isCardMode && setSelection(null);
      return;
    }
    setSelection(element);
  }

  const isCardActive = (idCard: any) => {
    // if (selection?.id == idCard) return false
    return selection?.id === idCard
  }

  // const confirmBeforeRemove =()=> {
  //   Swal.fire({
  //     title: "⚠️ Important ",
  //     text: "Vous êtes sur le point de supprimer une information, desirez-vous poursuivre cette action ?",
  //     icon: 'question',
  //     showCancelButton: true,
  //     confirmButtonText: 'Supprimer',
  //     cancelButtonText: 'Annuler',
  //     confirmButtonColor: "red",
  //     cancelButtonColor: "#91949c",
  //     reverseButtons: true
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       deleteItem();
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //     }
  //   })
  // }

  // const deleteItem = () => {
  //   let datatoDelete = {"ops": (userConnected?.user?.nom +' '+userConnected?.user?.prenoms), "id": selection.id }

  //   deleteClientAsync(datatoDelete);
  // }

  return (
    <>
      <div className="ct-incident-campagne">
        <div className="ct-incident-campagne__card">

          <div className="header-part">
            <div className="title-part">
                <div className="title-bloc">
                  <span className="title"> Gestion des Incidents </span>
                  <Chip label={` ${listItems?.length} ${listItems?.length < 2 ? 'incident' : 'incidents'}  `}
                    className='total-number-chip' />

                    <Badge color="secondary" 
                        variant="dot"
                        //  invisible={ valueFromSelect?.length === 0}
                         >
                      <IconButton 
                        className='close-icon'
                        title={`Mode ${isCardMode ? "liste" : 'carte'}`}
                        size="small" 
                        color='blue'
                        onClick={()=>{
                          setIsCardMode(!isCardMode)
                        }}>
                        {
                          isCardMode ?
                          <ListAlt /> :
                          <TurnedIn />
                        }
                      </IconButton>
                  </Badge>

                  
                </div>
                <div className="toolbar-part">

                <div className="actions-part">
                  {
                      selection ?
                      <>
                        <Divider orientation='vertical' />
                        {
                          permissions?.chantier?.find((o: any) => (o.libelle === 'Incident' && o.edit === true)) &&
                          <Button
                          color='blue'
                          className='btn-option'
                          onClick={()=>{
                            handleOpen();
                            setIsAdd(true);
                          }}
                          startIcon={<Edit />}>
                          Modifier
                        </Button>
                        }
                      </>: 
                      <>
                        <Divider orientation='vertical' />
                        <Button
                          color='blue'
                          className='btn-option'
                          onClick={handleExportExcel}
                          //variant='contained'
                          startIcon={<InlineIcon icon="carbon:document-import" />}>
                          Exporter la liste
                        </Button>
                      </>
                  }
                  </div>

                  <TextField
                    value={searchText}
                    onChange = {
                      (e) => {
                        setSearchText(e.target.value);
                      }
                    }
                    className="search-input"
                    placeholder="Recherche ... "
                    variant="outlined"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InlineIcon icon="bi:search" />
                        </InputAdornment>
                      )
                    }}
                  />

                </div>
            </div>
            <Divider className='custom-divider' />
          </div>

          <PerfectScrollbar id="scrollableDiv" className="content-part">
            <div className="kpi-part">
              <div className={classNames('kpi-card ')}>
                  <span className="value">
                    {listItems?.length}
                  </span>
                  <span className="text">
                    Total
                  </span>
                  <div className="mini-pie">
                    <MiniPiehart
                          value1={15}
                          value2={50}
                          value3={35}
                          value4={0}
                          color1={'#EF7D00'}
                          color2={'#26353F'}
                          color3={'#008F75'}
                          color4={'#ff1744'}
                          height={30} 
                          width={30}
                          interRadius={5}/>
                  </div>
              </div>

              {/* <div className={classNames('kpi-card primary')}>
                          <span className="percentage"> { ( (listItems.filter(val => val.date_depannage !== '')?.length / listItems?.length ) * 100) | 0}%   </span>
                <span className="value">
                  {listItems.filter(val => val.date_depannage !== '')?.length }
                </span>
                <span className="text">
                  Traités
                </span>
              </div> */}
              <div className={classNames('kpi-card tertiary ')}>
                <span className="percentage"> { ( (listItems.filter(val => val.date_depannage === '')?.length / listItems?.length ) * 100) | 0} %   </span>
                <span className="value">
                {listItems.filter(val => val.date_depannage === null || val.date_depannage === '' )?.length }
                </span>
                <span className="text">
                  En cours
                </span>
              </div>
              
            </div>
            <>
                {
                    loading && 
                    <>
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="pulse"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                    </>
                }
                {
                  isCardMode ?

                  <InfiniteScroll
                    className="content-part__infinite-scroll-part"
                    scrollableTarget="scrollableDiv"
                    dataLength={listItems?.length}
                    next={getAllClientsAsync}
                    hasMore={true}
                    loader={
                      listItems ?
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        :
                        <p> Aucune donnée disponible </p>
                    }>

                        {
                              !loading &&
                              listItems.filter((value: any) => {
                                  return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
                                })
                                .map((value: any, index:any) => {

                                  return <ListeItemCardGenerique id={value.id}
                                          key={index}
                                          setOpen={''}
                                          columns={columns}
                                          data={[value]}
                                          setIsEdit={selectItemFunc}
                                          isChantier={false}
                                          isActive={isCardActive(value.id)}/>

                                })
                          }
                  </InfiniteScroll>  :
                  <div style={{ height: '85%', width: '100%' }}>
                    <DataGrid  components={{
                          NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                              Pas de donnees
                            </Stack>
                          ),
                        }}
                        localeText={{
                          MuiTablePagination: {
                            labelDisplayedRows: ({ from, to, count }) =>
                              `${from} - ${to} Total: ${count}`,
                          },
                        }}
                      rows={
                        getListItemsFilteredSearch()
                      }
                      columns={columns}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 7 } },
                      }}
                      onRowSelectionModelChange={(row) => {
                        selectItemFunc(row[0]);
                      }}
                    />
                  </div>
                }
            </>

          </PerfectScrollbar>
        </div>
      </div>

      <SideModal 
          open={open}
          onClose={handleClose}
          closeClick={handleClose}>
            <>
                {
                    isAdd &&
                    <CreateIncidentForm closeModal={handleClose} selectedItem={selection} />
                }
            </>
      </SideModal>
    </>
  );
};

const mapStateToProps:any = createStructuredSelector({
  loading: selectLoading,
  getIncidents: selectIncidentItemList,
  userConnected: selectAuthUser,
  permissions: selectUserProfil
});

const mapDispatchToProps = (dispatch:any) => ({
  getAllIncidentsAsync: () => dispatch(getAllIncidentsAsync()),
  deleteChantierAsync: (data:any) => dispatch(deleteChantierAsync(data)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CtIncidentsCampaign = connector(CtIncidentsCampaignPage);

export default CtIncidentsCampaign;
