import React from 'react';
import CtRolesCampaign from '../pages-content/Roles-content/Roles.content';

const Roles:React.FC = () => {
  return (
    <>
      <CtRolesCampaign />
    </>
  )
}

export default Roles;