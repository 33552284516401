import { CarteService } from "@/services/carte.service";
import { carteTypes } from "./carte.types";

export const setCarteChantierLoading = (loading:any) => ({
    type: carteTypes.LOADING_CHANTIER,
    payload: loading,
});

export const setCarteDataByStatusLoading = (loading:any) => ({
  type: carteTypes.LOADING_DATA_BY_STATUS,
  payload: loading,
});

export const setCarteStatistiqueLoading = (loading:any) => ({
  type: carteTypes.LOADING_STATISTIQUE,
  payload: loading,
});

export const setCarteEnginLoading = (loading:any) => ({
  type: carteTypes.LOADING_ENGIN,
  payload: loading,
});

export const setCartePersonLoading = (loading:any) => ({
  type: carteTypes.LOADING_PERSON,
  payload: loading,
});

export const getAllCarteChantiers = (list:any) => ({
    type: carteTypes.GET_CARTE_CHANTIER_LIST,
    payload: list,
});

export const getAllCarteStatistiques = (list:any) => ({
  type: carteTypes.GET_CARTE_STATISTIQUE_LIST,
  payload: list,
});

export const getAllCarteEnginByIdChantiers = (list:any) => ({
  type: carteTypes.GET_ENGIN_LIST_BY_ID_CHANTIER,
  payload: list,
});

export const getAllCartePersonByIdChantiers = (list:any) => ({
  type: carteTypes.GET_PERSON_LIST_BY_ID_CHANTIER,
  payload: list,
});

export const getCarteDataByStatus = (list:any) => ({
  type: carteTypes.GET_DATA_LIST_BY_STATUS,
  payload: list,
});

 
export const  getAllCarteChantiersAsync =  () =>
(dispatch:any) => {
  dispatch(setCarteChantierLoading(true));
  CarteService.getCarteChantiers();
};

export const  getAllCarteStatistiquesAsync =  () =>
(dispatch:any) => {
  dispatch(setCarteStatistiqueLoading(true));
  CarteService.getCarteStatistiques();
};

export const  getAllCarteEnginByIdChantiersAsync =  (id:any) =>
(dispatch:any) => {
  dispatch(setCarteEnginLoading(true));
  CarteService.getCarteEnginByIdChantier(id);
};

export const  getAllCartePersonByIdChantiersAsync =  (id:any) =>
(dispatch:any) => {
  dispatch(setCartePersonLoading(true));
  CarteService.getCartePersonByIdChantier(id);
};

export const  getCarteDataByStatusAsync =  (id:any, data:any) =>
(dispatch:any) => {
  dispatch(setCarteDataByStatusLoading(true));
  CarteService.getCarteDataByStatus(id, data);
};





