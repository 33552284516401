import React from 'react';
import CtVidangesAdministration from '../pages-content/Vidanges-content/Vidanges.content';

const Vidanges:React.FC = () => {
  return (
    <>
      <CtVidangesAdministration />
    </>
  )
}

export default Vidanges;