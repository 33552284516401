import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectEquipement = (state:TRootState) => state.equipement;

export const selectLoading = createSelector(
  [selectEquipement],
  (equipement:any) => equipement.loading
);

export const selectEquipementItemList = createSelector(
    [selectEquipement],
    (equipement:any) => equipement.list
);

export const selectEquipementDetailLoading = createSelector(
  [selectEquipement],
  (equipement:any) => equipement.loading
);

export const selectEquipementItemSelected = createSelector(
    [selectEquipement],
    (equipement:any) => equipement.itemSelected
);
