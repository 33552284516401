import { routePaths } from "@/config";
import { Routes, Route, Navigate } from "react-router-dom";
import LCampaign from "../layouts/Campaign.content.layout";
import Accueil from "../pages/Accueil.page";
import Affectations from "../pages/Affectations.page";
import Campagnes from "../pages/Incidents.page";
import Listes from "../pages/Listes.page";
import Historique from "../pages/Historique.page";
import Organisation from "../pages/Organisation.page";

export const CtCampaignRoutes:React.FC = () => {
    return (
      <Routes>
          <Route path="" element={<LCampaign />}>
              <Route index element={ <Navigate to={`${routePaths.adminIVEQIChildren.campaignChildren.homeAbsolute}`} /> } /> 
              <Route path={routePaths.adminIVEQIChildren.campaignChildren.home} element={ <Accueil />} />
              <Route path={routePaths.adminIVEQIChildren.campaignChildren.liste} element={ <Listes />} />
              <Route path={routePaths.adminIVEQIChildren.campaignChildren.incident} element={ <Campagnes />} />
              <Route path={routePaths.adminIVEQIChildren.campaignChildren.organisation} element={ <Organisation />} />
              <Route path={routePaths.adminIVEQIChildren.campaignChildren.affectations} element={ <Affectations />} />
              <Route path={routePaths.adminIVEQIChildren.campaignChildren.historique} element={ <Historique />} />
              <Route path="/*" element={ <Navigate to={`${routePaths.adminIVEQIChildren.campaignChildren.homeAbsolute}`} />} />
          </Route>
      </Routes>
    );
};