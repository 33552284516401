import { InlineIcon } from '@iconify/react';
import {  Autocomplete, Box, Button,  Divider,  Grid, TextField, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import './CreateUserForm.style.scss';
import { connect } from 'react-redux';
import { getAllProfilsAsync } from '@/store/reducers/profil/profil.action';
import { selectProfilItemList } from '@/store/reducers/profil/profil.selector';
import { createStructuredSelector } from "reselect";
import { createUserAsync, updateUserAsync } from '@/store/reducers/user/user.action';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';

const CreateUserFormPage: React.FC<any> = ({
    selectedItem, 
    getProfilesAsync, 
    getAllProfiles, 
    createUserAsync,
    updateUserAsync,
    closeModal,
    // userConnected
}) => {

    useEffect(() => {
        const url:any = getProfilesAsync();
    
        const fetchData = async () => {
            try {
                await fetch(url);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, [getProfilesAsync]);


    const profilList:any = getAllProfiles.map((x:any) => {
        return {id: x.id , value:x.libelle}
    })

    const [profil, setProfil] = useState<{ id: string, value: string }>(selectedItem ? {id: selectedItem?.profil?.id, value: selectedItem?.profil?.libelle} : { id: '', value: '' });
    const handleProfilChange = (event: any, value: { id: string, value: string } | null) => {
        console.log(value);
        if (value === null) {
            setProfil({ id: '', value: '' });
        } else {
            setProfil(value as { id: string, value: string });
        }
    };

    const validationSchema = selectedItem ? yup.object({
        nom: yup.string().required(" Le nom est obligatoire "),
        prenoms: yup.string().required(" Le prenoms est obligatoire "),
        email: yup.string().required(" L'email est obligatoire "),
        telephone: yup.string().required(" Le telephone est obligatoire "),
        matricule: yup.string().required(" Le matricule est obligatoire "),
        profilId: yup.string().required(" Le profil est obligatoire "),
        fonction: yup.string().required(" La fonction est obligatoire "),
    }) : yup.object({
        nom: yup.string().required(" Le nom est obligatoire "),
        prenoms: yup.string().required(" Le prenoms est obligatoire "),
        email: yup.string().required(" L'email est obligatoire "),
        telephone: yup.string().required(" Le telephone est obligatoire "),
        matricule: yup.string().required(" Le matricule est obligatoire "),
        profilId: yup.string().required(" Le profil est obligatoire "),
        fonction: yup.string().required(" La fonction est obligatoire "),
        password: yup.string().required('Le mot de passe est obligatoire').min(6, 'Le mot de passe doit comporter au moins 6 caractères'),
        confirmPassword: yup.string().required('La confirmation du mot de passe est obligatoire').oneOf([yup.ref('password'), null], 'Les mots de passe ne correspondent pas')
    });

    const formik = useFormik({
        initialValues: {
            id: selectedItem ? selectedItem.id : null,
            nom: selectedItem ? selectedItem.nom : '',
            prenoms: selectedItem ? selectedItem.prenoms : '',
            email: selectedItem ? selectedItem.email : '',
            telephone: selectedItem ? selectedItem.telephone : '',
            matricule: selectedItem ? selectedItem.matricule : '',
            profilId: '',
            fonction: selectedItem ? selectedItem.fonction : '',
            password: '',
            confirmPassword: '',
            // ops: (userConnected?.user?.nom +' '+userConnected?.user?.prenoms)
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            console.log('data',values);
            
            if (!selectedItem) {
                delete values.id;
                delete values.confirmPassword;
                createUserAsync(values);
            } else {
                delete values.password;
                delete values.confirmPassword;
                updateUserAsync(values);
            }
            closeModal();
        }
    });


    return (
        <form className='create-list-form' onSubmit={formik.handleSubmit}>
            <span className="title"> {selectedItem ? 'Modification d\'un utilisateur' : 'Création d\'un utilisateur'} </span>

            <Grid container rowSpacing={2} columnSpacing={2} >
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Nom"
                        variant="outlined"
                        name="nom"
                        label="Nom"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.nom}
                        error={formik.touched.nom && Boolean(formik.errors.nom)}
                        // helperText={formik.touched.nom && formik.errors.nom}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Prénoms"
                        variant="outlined"
                        name="prenoms"
                        label="Prénoms"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.prenoms}
                        error={formik.touched.prenoms && Boolean(formik.errors.prenoms)}
                        // helperText={formik.touched.prenoms && formik.errors.prenoms}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Email"
                        variant="outlined"
                        name="email"
                        label="Email"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        // helperText={formik.touched.email && formik.errors.email}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Matricule"
                        variant="outlined"
                        name="matricule"
                        label="Matricule"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.matricule}
                        error={formik.touched.matricule && Boolean(formik.errors.matricule)}
                        // helperText={formik.touched.matricule && formik.errors.matricule}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Téléphone"
                        variant="outlined"
                        name="telephone"
                        label="Téléphone"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.telephone}
                        error={formik.touched.telephone && Boolean(formik.errors.telephone)}
                        // helperText={formik.touched.telephone && formik.errors.telephone}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Fonction"
                        variant="outlined"
                        name="fonction"
                        label="Fonction"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.fonction}
                        error={formik.touched.fonction && Boolean(formik.errors.fonction)}
                        // helperText={formik.touched.fonction && formik.errors.fonction}
                        type="text" />
                </Grid>



                <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <Autocomplete
                        id="id-service-select"
                        options={profilList}
                        value={profil}
                        onChange={handleProfilChange}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.value}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.value}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='my-text-field'
                                placeholder="Profil"
                                label="Profil"
                                variant="outlined"
                                name="profilId"
                                onChange={formik.handleChange}
                                value={formik.values.profilId = profil.id}
                                error={formik.touched.profilId && Boolean(formik.errors.profilId)}
                                // helperText={formik.touched.profilId && formik.errors.profilId}
                            />
                        )} />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        placeholder="Mot de passe"
                        variant="outlined"
                        name="password"
                        label="Mot de passe"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        type="password"
                        style={{ display: selectedItem ? 'none' : 'block' }} // Affichage conditionnel

                    />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6}>
                    <TextField
                        placeholder="Confirmer le mot de passe"
                        variant="outlined"
                        name="confirmPassword"
                        label="Confirmer le mot de passe"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.confirmPassword}
                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                        type="password"
                        style={{ display: selectedItem ? 'none' : 'block' }} // Affichage conditionnel
                    />
                </Grid>
            </Grid>


      <Divider className='custom-divider' />

            <div className="btn-part">
                <Button color='secondary'
                    className='btn-option'
                    type='submit'
                    startIcon={<InlineIcon icon={selectedItem? "iconoir:edit-pencil" : "iconoir:save-floppy-disk"} />}
                    variant='contained'>
                    {selectedItem ? 'Modifier':'Créer'}
                </Button>

                <Button color='light'
                    className='btn-option'
                    startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
                    onClick={() => {formik.resetForm()}}
                    variant='contained'>
                    {'Réinitialiser'}
                </Button>
            </div>

        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    getAllProfiles: selectProfilItemList,
    userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch:any) => ({
    getProfilesAsync: () => dispatch(getAllProfilsAsync()),
    createUserAsync: (data:any) => dispatch(createUserAsync(data)),
    updateUserAsync: (data:any) => dispatch(updateUserAsync(data)),
});
  
const connector = connect(mapStateToProps,mapDispatchToProps);
const CreateUserForm = connector(CreateUserFormPage);

export default CreateUserForm

