import React from 'react';
import CtCategorieEquipementAdministration from '../pages-content/CategorieEquipement-content/CategorieEquipement.content';

const CategorieEquipement:React.FC = () => {
  return (
    <>
      <CtCategorieEquipementAdministration />
    </>
  )
}

export default CategorieEquipement;