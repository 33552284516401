import { axios } from "@/lib/axios";
import { store } from "@/store";
import { isDev } from "@/utils/isDev";
import { ToastGeneric } from "@/utils/toast";
import { setLoading, getAllAlertes, getAllNumberOfAlertes } from "@/store/reducers/alerte/alerte.action";


export class AlerteService {

  static getAlertes() {
    axios.get('/alerte/list').then((response:any)=>{
        console.log(response);
        if(response?.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllAlertes(response?.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static getCountAlertes() {
    axios.get('/alerte/count').then((response:any)=>{
        console.log(response);
        if(response?.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllNumberOfAlertes(response?.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

}