import { selectAuthToken, selectAuthUser } from "@/store/reducers/auth/auth.selector";
import { setAuthToken } from "@/utils/setAuthToken";
import { connect } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { privateRoutes } from "./private.routes";
import { publicRoutes } from "./public.routes";
import { routePaths } from "../config";
import { getAllAlertesAsync, getAllCountAlertesAsync } from "@/store/reducers/alerte/alerte.action";
import { useEffect } from "react";
import { userProfilAsync } from "@/store/reducers/profil/profil.action";

const AppRoutes:React.FC<any> = ({ token, user, getAllCountAlertesAsync, getAllAlertesAsync, userProfilAsync }) => {
  // console.log("Auth token", token);
  const auth: boolean = !!token;
  setAuthToken(token); 

  const commonRoutes:any = auth === true ? [{ path: "*", element: <Navigate to={routePaths.adminIVEQI} /> }] : [{ path: "*", element: <Navigate to={routePaths.auth} /> }];

  const routes:any = auth === true ? privateRoutes : publicRoutes;
  const element =useRoutes([...routes, ...commonRoutes]);

  useEffect(() => {

    if (auth) {
      
      const url:any = userProfilAsync(user?.user?.profil?.id);
      const url2:any = getAllCountAlertesAsync();
      const url1:any = getAllAlertesAsync();
      

      const fetchData = async () => {
          try {
              await fetch(url);
              await fetch(url2);
                await fetch(url1);
          } catch (error) {
              console.log("error", error);
          }
      };
      fetchData();
    }
    
  }, [userProfilAsync, user, auth, getAllCountAlertesAsync, getAllAlertesAsync]);

  return <>{element}</>;
};

const mapStateToProps = createStructuredSelector({
  token: selectAuthToken,
  user: selectAuthUser
});

const mapDispatchToProps = (dispatch:any) => ({
  getAllCountAlertesAsync: () => dispatch(getAllCountAlertesAsync()),
  getAllAlertesAsync: () => dispatch(getAllAlertesAsync()),
  userProfilAsync: (id:any) => dispatch(userProfilAsync(id)),
});


const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(AppRoutes);