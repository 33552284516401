export const routePaths = {
  root: "/",
  auth: "/auth",
  authChildren: {
    signIn : "/sign-in",
    signInAbsolute : "/auth/sign-in",
    welcomPortail : "/welcome",
    welcomPortailAbsolute : "/auth/welcome",
    forgottenPassword : "/forgot-password",
    forgottenPasswordAbsolute : "/auth/forgot-password",
    codeOtp : "/code-otp",
    codeOtpAbsolute : "/auth/code-otp",
    resetPassword : "/reset-password",
    resetPasswordAbsolute : "/auth/reset-password",
  },
  adminIVEQI: "/admin-iveqi",
  adminIVEQIChildren: {
    dashboard:"/dashboard",
    dashboardAbsolute: "/admin-iveqi/dashboard",
    map:"/map",
    mapAbsolute: "/admin-iveqi/map",
    campaign:"/campaign",
    campaignAbsolute: "/admin-iveqi/campaign",
    campaignChildren: {
      home: "/home",
      homeAbsolute: "/admin-iveqi/campaign/home",
      liste: "/liste",
      listeAbsolute: "/admin-iveqi/campaign/liste",
      organisation: "/cuve",
      organisationAbsolute: "/admin-iveqi/campaign/cuve",
      incident: "/incident",
      incidentAbsolute: "/admin-iveqi/campaign/incident",
      affectations: "/affectations",
      affectationsAbsolute: "/admin-iveqi/campaign/affectations",
      rapportTerrain: "/rapport-terrain",
      rapportTerrainAbsolute: "/admin-iveqi/campaign/rapport-terrain",
      historique: "/historique-chantier",
      historiqueAbsolute: "/admin-iveqi/campaign/historique-chantier",
    },
    administration:"/administration",
    administrationAbsolute: "/admin-iveqi/administration",
    administrationChildren: {
      home: "/home",
      homeAbsolute: "/admin-iveqi/administration/home",
      roles: "/roles",
      rolesAbsolute: "/admin-iveqi/administration/roles",
      objectifs: "/objectifs",
      objectifsAbsolute: "/admin-iveqi/administration/objectifs",
      utilisateurs: "/utilisateurs",
      utilisateursAbsolute: "/admin-iveqi/administration/utilisateurs",
      equipes: "/equipes",
      equipesAbsolute: "/admin-iveqi/administration/equipes",

      profils: "/profils",
      profilssAbsolute: "/admin-iveqi/administration/profils",
      clients: "/clients",
      clientsAbsolute: "/admin-iveqi/administration/clients",
      natureTravaux: "/nature-travaux",
      natureTravauxAbsolute: "/admin-iveqi/administration/nature-travaux",
      categorieEquipement: "/categorie-equipement",
      categorieEquipementAbsolute: "/admin-iveqi/administration/categorie-equipement",
      equipements: "/equipements",
      equipementsAbsolute: "/admin-iveqi/administration/equipements",
      categorieEngins: "/categorie-engins",
      categorieEnginsAbsolute: "/admin-iveqi/administration/categorie-engins",
      engins: "/engins",
      enginsAbsolute: "/admin-iveqi/administration/engins",
      vidanges: "/vidanges",
      vidangesAbsolute: "/admin-iveqi/administration/vidanges"
    },
    rh:"/rh",
    rhAbsolute: "/admin-iveqi/rh",
    rhChildren: {
      home: "/home",
      homeAbsolute: "/admin-iveqi/rh/home",
      roles: "/roles",
      rolesAbsolute: "/rh-iveqi/rh/roles",

      personnels: "/personnel",
      personnelsAbsolute: "/admin-iveqi/rh/personnel",

      AffectationRH: "/affectation-rh",
      AffectationRHAbsolute: "/admin-iveqi/rh/affectation-rh",

      conges: "/gestion-conges",
      congesAbsolute: "/admin-iveqi/rh/gestion-conges",

      demandePermission: "/demande-permission",
      demandePermissionAbsolute: "/admin-iveqi/rh/demande-permission",

      historique: "/historique-rh",
      historiqueAbsolute: "/admin-iveqi/rh/historique-rh",


      clients: "/clients",
      clientsAbsolute: "/admin-iveqi/administration/clients",
      natureTravaux: "/nature-travaux",
      natureTravauxAbsolute: "/admin-iveqi/administration/nature-travaux",
      categorieEquipement: "/categorie-equipement",
      categorieEquipementAbsolute: "/admin-iveqi/administration/categorie-equipement",
      equipements: "/equipements",
      equipementsAbsolute: "/admin-iveqi/administration/equipements",
      categorieEngins: "/categorie-engins",
      categorieEnginsAbsolute: "/admin-iveqi/administration/categorie-engins",
      engins: "/engins",
      enginsAbsolute: "/admin-iveqi/administration/engins",

    },
  }
};
