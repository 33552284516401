import { InlineIcon } from "@iconify/react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import "./CreateVidangeForm.style.scss";
import { connect } from "react-redux";
// import { getAllProfilsAsync } from '@/store/reducers/profil/profil.action';
import { selectProfilItemList } from "@/store/reducers/profil/profil.selector";
import { createStructuredSelector } from "reselect";
import { selectAuthUser } from "@/store/reducers/auth/auth.selector";
import { selectEnginItemList } from "@/store/reducers/engin/engin.selector";
import {
  createVidangeAsync,
  updateVidangeAsync,
} from "@/store/reducers/vidange/vidange.action";
import { getAllEnginsAsync } from "@/store/reducers/engin/engin.action";
import moment from "moment";

const CreateVidangeFormPage: React.FC<any> = ({
  selectedItem,
  getAllEnginsAsync,
  getAllEngins,
  createVidangeAsync,
  updateVidangeAsync,
  closeModal,
  userConnected,
}) => {
  useEffect(() => {
    const url: any = getAllEnginsAsync();

    const fetchData = async () => {
      try {
        await fetch(url);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, [getAllEnginsAsync]);

  const listVidange: any[] = [
    "Vidange moteur",
    "Vidange BV",
    "Chg. Filtre BV",
    "Vidange Hydraulique",
    "Chg. Filtre Hydraulique",
    "Vidange ponts reducteurs",
  ];
  // const [listV, setListe] = useState<{ id: string, value: string }>({ id: '', value: '' });
  // const handleListeChange = (event: any, value: { id: string, value: string } | null) => {
  //     console.log(value);
  //     if (value == null) {
  //         setListe({ id: '', value: '' });
  //     } else {
  //         setListe(value as { id: string, value: string });
  //     }
  //     formik.setFieldValue('libelle_vidange', value?.value);
  // };

  const [selectedLibelleVidange, setSelectedLibelleVidange] = useState<any[]>(
    selectedItem
      ? selectedItem.libelle_vidange : []
  );
  const handleListeChange = (event: SelectChangeEvent<any>) => {
    setSelectedLibelleVidange(event.target.value);
  };

  const enginList: any = getAllEngins.map((x: any) => {
    return {
      ...x,
      id: x.id,
      value: x.num_parc,
      intervalle_entretien: x.intervalle_entretien,
    };
  });

  console.log("selectedItem", selectedItem);

  const [engin, setEngin] = useState<{
    id: number;
    value: string;
    intervalle_entretien: number;
  }>(
    selectedItem
      ? {
          id: selectedItem?.engin?.id,
          value: selectedItem?.engin?.libelle,
          intervalle_entretien: selectedItem?.engin?.intervalle_entretien,
        }
      : { id: 0, value: "", intervalle_entretien: "" }
  );
  const handleProfilChange = (event: any, value: any | null) => {
    //console.log(value);
    if (value === null) {
      setEngin({ id: 0, value: "", intervalle_entretien: 0 });
    } else {
      if (value) {
        formik.setFieldValue(
          "intervalle_entretien",
          value.intervalle_entretien
        );
        formik.setFieldValue("dernier_compteur", value.dernier_compteur);
        formik.setFieldValue(
          "date_dernier_compteur",
          value.date_dernier_compteur
        );
        formik.setFieldValue("derniere_entretien", value.derniere_entretien);
      }
      setEngin(value as any);
    }
  };

  // const [engin, setEngin] = useState<{ id: number, value: string }>( { id: 0, value: '' });
  // const handleListVidange = (event: SelectChangeEvent<any>) => {
  //     const { target: { value } } = event;
  //     console.log('value', value);
  //   //  setEngin(value);
  //   formik.setFieldValue("libelle_vidange", value.id);
  //     setEngin(value as any);
  // };

  const validationSchema = yup.object({
    enginId: yup.string().required(" Le nom est obligatoire "),
    compteur_engin: yup.string().required(" Le prenoms est obligatoire "),
    date_vidange: yup.string().required("  est obligatoire "),
   // libelle_vidange: yup.array().required(" Le telephone est obligatoire "),
    chantier: yup.string().required(" Le nom est obligatoire "),
    nom_mecanicien: yup.string().required(" Le prenoms est obligatoire "),
    type_vidange: yup.string().required(" type vidange est obligatoire "),

    // email: yup.string().required(" L'email est obligatoire "),
    // telephone: yup.string().required(" Le telephone est obligatoire "),
    // matricule: yup.string().required(" Le matricule est obligatoire "),
    // profilId: yup.string().required(" Le profil est obligatoire "),
    // fonction: yup.string().required(" La fonction est obligatoire "),
  });

  const formik = useFormik({
    initialValues: {
      enginId: selectedItem ? selectedItem.enginId : null,
      compteur_engin: selectedItem ? selectedItem.compteur_engin : "",
      date_vidange: selectedItem ? selectedItem.date_vidange : "",
      libelle_vidange: selectedItem ? selectedItem.libelle_vidange : "",
      chantier: selectedItem ? selectedItem.chantier : "",
      nom_mecanicien: selectedItem ? selectedItem.nom_mecanicien : "",
      type_vidange: selectedItem ? selectedItem.type_vidange : "",

      dernier_compteur: "",
      date_dernier_compteur: "",
      intervalle_entretien: "",
      // prochaine_vidange => derniere_entretien
      derniere_entretien: "",
      // prochaine_vidange => prochain_entretien
      prochain_entretien: "",

      ops: userConnected?.user?.nom + " " + userConnected?.user?.prenoms,
    },
    //validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      console.log("data", values);
      console.log("selectedLibelleVidange", selectedLibelleVidange.join(" - "));
      values.libelle_vidange = selectedLibelleVidange.join(" - ");
      if (!selectedItem) {
        delete values.id;
        createVidangeAsync(values);
      } else {
        updateVidangeAsync(values);
      }
      closeModal();
    },
  });

  return (
    <form className="create-list-form" onSubmit={formik.handleSubmit}>
      <span className="title">
        {" "}
        {selectedItem
          ? "Modification d'une vidange"
          : "Création d'une vidange"}{" "}
      </span>

      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Autocomplete
            id="id-service-select"
            options={enginList}
            value={engin}
            onChange={handleProfilChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(option) => option.value}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder="Engin"
                label="Engin"
                variant="outlined"
                name="enginId"
                onChange={formik.handleChange}
                value={(formik.values.enginId = engin.id)}
                error={formik.touched.enginId && Boolean(formik.errors.enginId)}
                // helperText={formik.touched.profilId && formik.errors.profilId}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Date dernier compteur"
            variant="outlined"
            name="date_dernier_compteur"
            label="Date dernier compteur"
            className="my-text-field"
            onChange={formik.handleChange}
            value={moment(formik.values.date_dernier_compteur).format(
              "DD-MM-YYYY"
            )}
            error={
              formik.touched.date_dernier_compteur &&
              Boolean(formik.errors.date_dernier_compteur)
            }
            disabled
            // helperText={formik.touched.nom && formik.errors.nom}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Dernier compteur"
            variant="outlined"
            name="dernier_compteur"
            label="Dernier compteur"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.dernier_compteur}
            error={
              formik.touched.dernier_compteur &&
              Boolean(formik.errors.dernier_compteur)
            }
            disabled
            // helperText={formik.touched.nom && formik.errors.nom}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Intervalle Entretien"
            variant="outlined"
            name="intervalle_entretien"
            label="Intervalle Entretien"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.intervalle_entretien}
            error={
              formik.touched.intervalle_entretien &&
              Boolean(formik.errors.intervalle_entretien)
            }
            disabled
            // helperText={formik.touched.nom && formik.errors.nom}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Compteur dernier entretien"
            variant="outlined"
            name="derniere_entretien"
            label="Compteur dernier entretien"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.derniere_entretien}
            error={
              formik.touched.derniere_entretien &&
              Boolean(formik.errors.derniere_entretien)
            }
            disabled
            // helperText={formik.touched.nom && formik.errors.nom}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Compteur Vidange"
            variant="outlined"
            name="compteur_engin"
            label="Compteur Vidange"
            className="my-text-field"
            // onChange={formik.handleChange}
            onChange={(e: any) => {
              formik.handleChange(e); // Gère le changement de champ via formik
              // Calcul du prochain entretien
              const intervalle = parseFloat(formik.values.intervalle_entretien);
              const compteur = parseFloat(e.target.value);
              if (!isNaN(intervalle) && !isNaN(compteur)) {
                const prochainEntretien = intervalle + compteur;
                formik.setFieldValue(
                  "prochain_entretien",
                  prochainEntretien.toString()
                );
              }
            }}
            value={formik.values.compteur_engin}
            error={
              formik.touched.compteur_engin &&
              Boolean(formik.errors.compteur_engin)
            }
            // helperText={formik.touched.nom && formik.errors.nom}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Date Vidange"
            variant="outlined"
            name="date_vidange"
            label="Date Vidange"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.date_vidange}
            error={
              formik.touched.date_vidange && Boolean(formik.errors.date_vidange)
            }
            // helperText={formik.touched.prenoms && formik.errors.prenoms}
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <FormControl fullWidth>
            <InputLabel
              id="id-vidange"
              htmlFor="libelle_vidange"
              sx={{
                position: "absolute !important",
                top: "-5px !important",
                fontSize: "0.8rem",
              }}
            >
              Libelle vidange
            </InputLabel>
            <Select
              multiple
              size="small"
              labelId="id-libelle-vidange"
              name="libelle_vidange"
              value={selectedLibelleVidange}
              onChange={handleListeChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} sx={{ height: "20px" }} />
                  ))}
                </Box>
              )}
              //   MenuProps={MenuProps}
            >
              {listVidange.map((value:any) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={12} lg={12} xl={12} xxl={12}>
                    <FormControl>
                        <InputLabel id="id-service-select"  htmlFor="libelle_vidange"> Engin </InputLabel>
                        <Select
                            labelId="id-client-filtre"
                            name='libelle_vidange'
                            value={selectedLibelleVidange}
                            onChange={handleListVidange}
                            input={<OutlinedInput label="libelle_vidange" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {Array.isArray(selected) ? (
                                        selected.map((value: any) => (
                                            <Chip key={value.id} label={value.libelle} color="primary" />
                                        ))
                                    ) : (
                                        <Chip key={selected} label={selected} color="primary" />
                                    )}
                                </Box>
                            )}
                            
                            MenuProps={MenuProps}
                        >
                            {listVidange.map((elt: any, index: any) => (
                                <MenuItem key={index} value={elt}>
                                    <Checkbox checked={selectedLibelleVidange.id === elt.id} />
                                    <ListItemText primary={elt.value} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> */}

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Chantier"
            variant="outlined"
            name="chantier"
            label="Chantier"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.chantier}
            error={formik.touched.chantier && Boolean(formik.errors.chantier)}
            // helperText={formik.touched.matricule && formik.errors.matricule}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Nom mecanicien"
            variant="outlined"
            name="nom_mecanicien"
            label="Nom mecanicien"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.nom_mecanicien}
            error={
              formik.touched.nom_mecanicien &&
              Boolean(formik.errors.nom_mecanicien)
            }
            // helperText={formik.touched.telephone && formik.errors.telephone}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Type Vidange"
            variant="outlined"
            name="type_vidange"
            label="Type Vidange"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.type_vidange}
            error={
              formik.touched.type_vidange && Boolean(formik.errors.type_vidange)
            }
            // helperText={formik.touched.fonction && formik.errors.fonction}
            type="number"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Compteur prochain entretien"
            variant="outlined"
            name="prochain_entretien"
            label="Compteur prochain entretien"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.prochain_entretien}
            error={
              formik.touched.prochain_entretien &&
              Boolean(formik.errors.prochain_entretien)
            }
            disabled
            // helperText={formik.touched.nom && formik.errors.nom}
            type="text"
          />
        </Grid>
      </Grid>

      <Divider className="custom-divider" />

      <div className="btn-part">
        <Button
          color="secondary"
          className="btn-option"
          type="submit"
          startIcon={
            <InlineIcon
              icon={
                selectedItem
                  ? "iconoir:edit-pencil"
                  : "iconoir:save-floppy-disk"
              }
            />
          }
          variant="contained"
        >
          {selectedItem ? "Modifier" : "Créer"}
        </Button>

        <Button
          color="light"
          className="btn-option"
          startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
          onClick={() => {
            formik.resetForm();
          }}
          variant="contained"
        >
          {"Réinitialiser"}
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = createStructuredSelector({
  getAllProfiles: selectProfilItemList,
  getAllEngins: selectEnginItemList,
  userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAllEnginsAsync: () => dispatch(getAllEnginsAsync()),
  createVidangeAsync: (data: any) => dispatch(createVidangeAsync(data)),
  updateVidangeAsync: (data: any) => dispatch(updateVidangeAsync(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const CreateVidangeForm = connector(CreateVidangeFormPage);

export default CreateVidangeForm;
