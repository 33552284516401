import MiniPiehart from '@/features/admin-iveqi/components/Mini-pie-chart/MiniPieChart';
import { deleteChantierAsync, getAllChantiersAsync, getSyntheseChantiersAsync } from '@/store/reducers/chantier/chantier.action';
import { selectChantierItemList, selectLoading, selectSyntheseChantierList } from '@/store/reducers/chantier/chantier.selector';
import { InlineIcon } from '@iconify/react';
import {  Badge, Button,  Chip, Divider, IconButton, InputAdornment, Skeleton, TextField } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import SideModal from '../../components/SideModal/SideModal';
import CreateChantierForm from '../../Forms/CreateChantierForm/CreateChantierForm';
import './Listes.content.style.scss';
import ListeItemCardGenerique from '@/features/admin-iveqi/components/ListeItem/ListeItemCardGenerique';
import {  getAllClientsAsync } from '@/store/reducers/client/client.action';
import { ListAlt, TurnedIn, Edit, Delete, ViewArray } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { Stack } from "@mui/material";
import Swal from 'sweetalert2';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';
import SyntheseChantier from '../../Forms/SyntheseChantier/SyntheseChantier';
import { exportToExcel } from '@/config';
import { selectUserProfil } from '@/store/reducers/profil/profil.selector';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

type TListLegend = 1|2|3;

const columns = [
  { field: 'numero_chantier', headerName: 'Numero', width: 190 },
  { field: 'nom', headerName: 'Nom', width: 190 },
  { field: 'localisation', headerName: 'Localisation', width: 190 },
  { field: 'date_debut', headerName: 'Debut', width: 190 },
  { field: 'date_fin', headerName: 'Fin', width: 190 },
  { field: 'chef_nom', headerName: 'Chef de chantier', width: 190 },
  { field: 'client_nom', headerName: 'Client', width: 190 },
  { field: 'but_location', headerName: 'but_location', width: 190 },
];

const CtListesCampaignPage: React.FC<any> = ({
  loading,
  synthese,
  getChantiers,
  getChantieresAsync,
  deleteChantierAsync,
  getSyntheseChantiersAsync,
  userConnected,
  permissions
}) => {

  useEffect(() => {
    const url:any = getChantieresAsync();

    const fetchData = async () => {
        try {
            await fetch(url);
        } catch (error) {
            console.log("error", error);
        }
    };
    fetchData();
    
  }, [getChantieresAsync]);
  
  const [searchText, setSearchText] = useState('');
  const [isCardMode, setIsCardMode] = useState(true);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isAdd, setIsAdd] = useState(false);

  const [isSyn, setIsSyn] = useState(false);

  const listItems:any[] = getChantiers.map((x:any) => {
    return {
      ...x,
      but_location: x.but_location,
      date_debut: x.date_debut,
      date_fin: x.date_fin,
      client_nom: x?.client?.nom ,
      chef_nom: x?.utilisateur?.nom ,
      searchText: x.nom +' '+ x.localisation
    }
  });

  const [selection, setSelection] = React.useState<any>(null);
  //const [isEdit, setIsEdit] = useState(false);
  const [legend, setLegend] = useState<TListLegend |''>('');
  
  const getListItemsFilteredSearch = () => {
    return listItems.filter((value: any) => {
      return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
    })
  }

   // Appel de la fonction d'exportation lorsque vous souhaitez exporter les données
   const handleExportExcel = () => {
    exportToExcel(listItems, columns, 'chantier');
  };

  const selectItemFunc = (idElt: number | any) => {
    const element: any =  getListItemsFilteredSearch().find((x:any) => x.id === idElt);
    console.log("element", element);
    if (selection?.id === element?.id) {
      isCardMode && setSelection(null);
      return;
    }
    setSelection(element);
    getSyntheseChantiersAsync(element?.id);

  }

  const isCardActive = (idCard: any) => {
    // if (selection?.id == idCard) return false
    return selection?.id === idCard
  }

  const confirmBeforeRemove =()=> {
    Swal.fire({
      title: "⚠️ Important ",
      text: "Vous êtes sur le point de supprimer une information, desirez-vous poursuivre cette action ?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
      confirmButtonColor: "red",
      cancelButtonColor: "#91949c",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  const deleteItem = () => {
    let datatoDelete = {"ops": (userConnected?.user?.nom +' '+userConnected?.user?.prenoms), "id": selection.id }

    deleteChantierAsync(datatoDelete);
  }

  return (
    <>
      <div className="ct-liste-campagne">
        <div className="ct-liste-campagne__card">

          <div className="header-part">
            <div className="title-part">
                <div className="title-bloc">
                  <span className="title"> Gestion des Chantiers </span>
                  <Chip label={` ${listItems?.length} ${listItems?.length < 2 ? 'chantier' : 'chantiers'}  `}
                    className='total-number-chip' />

                    <Badge color="secondary" 
                        variant="dot"
                        //  invisible={ valueFromSelect?.length === 0}
                         >
                      <IconButton 
                        className='close-icon'
                        title={`Mode ${isCardMode ? "liste" : 'carte'}`}
                        size="small" 
                        color='blue'
                        onClick={()=>{
                          setIsCardMode(!isCardMode)
                        }}>
                        {
                          isCardMode ?
                          <ListAlt /> :
                          <TurnedIn />
                        }
                      </IconButton>
                  </Badge>

                  
                </div>
                <div className="toolbar-part">

                  <div className="actions-part">
                  {
                      selection ?
                      <>
                        <Divider orientation='vertical' />
                        <Button
                          color='blue'
                          className='btn-option'
                          onClick={()=>{
                            handleOpen();
                            setIsAdd(false);
                            setIsSyn(true)
                          }}
                          startIcon={<ViewArray />}>
                          Synthese
                        </Button>
                        <Divider orientation='vertical' />
                        {
                          permissions?.chantier?.find((o: any) => (o.libelle === 'Chantier' && o.edit === true)) &&
                          <Button
                          color='blue'
                          className='btn-option'
                          onClick={()=>{
                            handleOpen();
                            setIsAdd(true);
                          }}
                          startIcon={<Edit />}>
                          Modifier
                        </Button>
                        }
                        <Divider orientation='vertical' />
                        {
                          permissions?.chantier?.find((o: any) => (o.libelle === 'Chantier' && o.delete === true)) &&
                          <Button
                          color='blue'
                          className='btn-option'
                          onClick={confirmBeforeRemove}
                          startIcon={<Delete />}>
                          Supprimer
                        </Button>
                        }
                      </>: 
                      <>
                        {
                          permissions?.chantier?.find((o: any) => (o.libelle === 'Chantier' && o.create === true)) &&
                          <Button
                          color='blue'
                          className='btn-option'
                          onClick={()=>{
                              handleOpen();
                              setIsAdd(true);
                          }}
                          //variant='contained'
                          startIcon={<InlineIcon icon="carbon:document-add" />}>
                          Créer un chantier
                        </Button>
                        }
                        <Divider orientation='vertical' />
                        <Button
                          color='blue'
                          className='btn-option'
                          onClick={handleExportExcel}
                          //variant='contained'
                          startIcon={<InlineIcon icon="carbon:document-import" />}>
                          Exporter la liste
                        </Button>
                      </>
                  }
                  </div>

                  <TextField
                    value={searchText}
                    onChange = {
                      (e) => {
                        setSearchText(e.target.value);
                      }
                    }
                    className="search-input"
                    placeholder="Recherche ... "
                    variant="outlined"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InlineIcon icon="bi:search" />
                        </InputAdornment>
                      )
                    }}
                  />

                </div>
            </div>
            <Divider className='custom-divider' />
          </div>

          <PerfectScrollbar id="scrollableDiv" className="content-part">
            <div className="kpi-part">
              <div className={classNames('kpi-card ')}>
                  <span className="value">
                    {listItems?.length}
                  </span>
                  <span className="text">
                    Total 
                  </span>
                  <div className="mini-pie">
                    <MiniPiehart
                          value1={15}
                          value2={50}
                          value3={35}
                          value4={0}
                          color1={'#EF7D00'}
                          color2={'#26353F'}
                          color3={'#008F75'}
                          color4={'#ff1744'}
                          height={30} 
                          width={30}
                          interRadius={5}/>
                  </div>
              </div>

              <div className={classNames('kpi-card secondary',{'active': legend === 3,})}
                     onClick={()=>{ 
                            setLegend(2);
                        }}>
                <span className="percentage"> { ( (listItems.filter(val => val.statut === 2)?.length / listItems?.length ) * 100) | 0}%   </span>
                <span className="value">
                  {listItems.filter(val => val.statut === 2)?.length}
                </span>
                <span className="text">
                    En cours
                </span>
              </div>
              <div className={classNames('kpi-card primary',{'active': legend === 3,})}
                     onClick={()=>{ 
                            setLegend(3);
                        }}>
                          <span className="percentage"> { ( (listItems.filter(val => val.statut === 3)?.length / listItems?.length ) * 100) | 0}%   </span>
                <span className="value">
                  {listItems.filter(val => val.statut === 3)?.length }
                </span>
                <span className="text">
                  Terminé
                </span>
              </div>
              <div className={classNames('kpi-card tertiary ',{'active': legend === 1,})}
                     onClick={()=>{ 
                            setLegend(1);
                        }}>
                <span className="percentage"> { ( (listItems.filter(val => val.statut === 1)?.length / listItems?.length ) * 100) | 0} %   </span>
                <span className="value">
                {listItems.filter(val => val.statut === 1)?.length }
                </span>
                <span className="text">
                  A Venir
                </span>
              </div>
              
            </div>
            <>
                {
                    loading && 
                    <>
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="pulse"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                    </>
                }
                {
                  isCardMode ?

                  <InfiniteScroll
                    className="content-part__infinite-scroll-part"
                    scrollableTarget="scrollableDiv"
                    dataLength={listItems?.length}
                    next={getAllClientsAsync}
                    hasMore={true}
                    loader={
                      listItems ?
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        :
                        <p> Aucune donnée disponible </p>
                    }>

                        {
                              !loading &&
                              listItems.filter((value: any) => {
                                  return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
                                })
                                .map((value: any, index:any) => {

                                  return <ListeItemCardGenerique id={value.id}
                                          key={index}
                                          setOpen={setOpen}
                                          columns={columns}
                                          data={[value]}
                                          setIsEdit={selectItemFunc}
                                          isChantier={true}
                                          isActive={isCardActive(value.id)}/>

                                })
                          }
                  </InfiniteScroll>  :
                  <div style={{ height: '85%', width: '100%' }}>
                    <DataGrid  components={{
                          NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                              Pas de donnees
                            </Stack>
                          ),
                        }}
                        localeText={{
                          MuiTablePagination: {
                            labelDisplayedRows: ({ from, to, count }) =>
                              `${from} - ${to} Total: ${count}`,
                          },
                        }}
                      rows={
                        getListItemsFilteredSearch()
                      }
                      columns={columns}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 7 } },
                      }}
                      onRowSelectionModelChange={(row) => {
                        selectItemFunc(row[0]);
                      }}
                    />
                  </div>
                }
            </>

          </PerfectScrollbar>
        </div>
      </div>

      <SideModal 
          open={open}
          onClose={handleClose}
          closeClick={handleClose}>
            <>
                {
                    isAdd &&
                    <CreateChantierForm closeModal={handleClose} selectedItem={selection} />
                }
                {
                    isSyn &&
                    <SyntheseChantier closeModal={handleClose} selectedItem={synthese} />
                }
            </>
      </SideModal>
    </>
  );
};


const mapStateToProps:any = createStructuredSelector({
  loading: selectLoading,
  synthese: selectSyntheseChantierList,
  getChantiers: selectChantierItemList,
  userConnected: selectAuthUser,
  permissions: selectUserProfil
});

const mapDispatchToProps = (dispatch:any) => ({
  getChantieresAsync: () => dispatch(getAllChantiersAsync()),
  getSyntheseChantiersAsync: (id:any) => dispatch(getSyntheseChantiersAsync(id)),
  deleteChantierAsync: (data:any) => dispatch(deleteChantierAsync(data)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CtListesCampaign = connector(CtListesCampaignPage);

export default CtListesCampaign;
