import { IncidentService } from "@/services/incident.service";
import { incidentTypes } from "./incident.types";

export const setLoading = (loading:any) => ({
    type: incidentTypes.LOADING,
    payload: loading,
});

export const getAllIncidents = (list:any) => ({
    type: incidentTypes.GET_INCIDENT_LIST,
    payload: list,
});

export const selectedItem = (incident:any) => ({
  type: incidentTypes.SELECTED_INCIDENT,
  payload: incident,
});

 
export const  getAllIncidentsAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  IncidentService.getIncidents();
};

export const  updateIncidentAsync =  (incident:any) =>
(dispatch:any) => {
  console.log("Incident to service", incident);
  dispatch(setLoading(true));
  IncidentService.updateIncident(incident);
};

