import React from 'react';
import CtOrganisationCampaign from '../pages-content/Organisation-content/Organisation.content';

const Organisation:React.FC = () => {
  return (
    <>
      <CtOrganisationCampaign />
    </>
  )
}

export default Organisation;