import React from 'react';
import { CtAdministrationRoutes } from './routes/administration.content.routes';

const CtAdministration: React.FC = () => {

  return (
    <>
        <CtAdministrationRoutes />
    </>
  );
};

export default CtAdministration;
