import { axios } from "@/lib/axios";
import { store } from "@/store";
import { isDev } from "@/utils/isDev";
import { ToastGeneric } from "@/utils/toast";
import { getCarteDataByStatus, setCarteDataByStatusLoading, setCarteChantierLoading, getAllCarteChantiers, setCarteStatistiqueLoading, getAllCarteStatistiques, setCarteEnginLoading, getAllCarteEnginByIdChantiers, setCartePersonLoading, getAllCartePersonByIdChantiers} from "@/store/reducers/carte/carte.action";

export class CarteService {

  static getCarteChantiers() {
    axios.get('/carte/chantier').then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllCarteChantiers(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setCarteChantierLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setCarteChantierLoading(false));
    });
    
  }

  static getCarteEnginByIdChantier(id:any) {
    axios.get(`/carte/chantier-engin?chantierId=${id}`).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllCarteEnginByIdChantiers(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setCarteEnginLoading(false));
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setCarteEnginLoading(false));
    });
    
  }

  static getCartePersonByIdChantier(id:any) {
    axios.get(`/carte/chantier-rh?chantierId=${id}`).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllCartePersonByIdChantiers(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setCartePersonLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setCartePersonLoading(false));
    });
    
  }

  static getCarteStatistiques() {
    axios.get('/carte/statistique').then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllCarteStatistiques(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setCarteStatistiqueLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setCarteStatistiqueLoading(false));
    });
    
  }

  static getCarteDataByStatus(id:any , data:any) {
    let stat: any = null ;
    if (id === 1) {
        if (data === 'Terminer') {
            stat = 3;
        } else if (data === 'Encours') {
            stat = 2;
        } else {
            stat = 1;
        }
        axios.get(`/carte/liste-chantier-by-satut?statut=${stat}`).then((response:any)=>{
            console.log(response);
            if(response.error){
    
                console.log("Error ---> ");
                ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();
    
            }else{
    
                console.log("Not error ---> ");
                store.dispatch(getCarteDataByStatus(response.data));
                // ToastGeneric('Valider avec succès', 'success', 1000).fire();
            }
           store.dispatch(setCarteDataByStatusLoading(false));
        }).catch((error)=>{
            if (isDev()) {
                console.error(error);
            }
            ToastGeneric('Erreur serveur!', 'error', 3000).fire();
            store.dispatch(setCarteDataByStatusLoading(false));
        });
    }
   
    if (id === 3) {
        if (data === 'Atelier') {
            stat = 3;
        } else if (data === 'Chantier') {
            stat = 2;
        } else {
            stat = 1;
        }
        axios.get(`/carte/liste-engin-by-satut?statut=${stat}`).then((response:any)=>{
            console.log(response);
            if(response.error){
    
                console.log("Error ---> ");
                ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();
    
            }else{
    
                console.log("Not error ---> ");
                store.dispatch(getCarteDataByStatus(response.data));
                // ToastGeneric('Valider avec succès', 'success', 1000).fire();
            }
           store.dispatch(setCarteDataByStatusLoading(false));
        }).catch((error)=>{
            if (isDev()) {
                console.error(error);
            }
            ToastGeneric('Erreur serveur!', 'error', 3000).fire();
            store.dispatch(setCarteDataByStatusLoading(false));
        });
    }

    if (id === 2) {
        axios.get(`/carte/liste-personnel-by-sitetravail?sitetravail=${data}`).then((response:any)=>{
            console.log(response);
            if(response.error){
    
                console.log("Error ---> ");
                ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();
    
            }else{
    
                console.log("Not error ---> ");
                store.dispatch(getCarteDataByStatus(response.data));
                // ToastGeneric('Valider avec succès', 'success', 1000).fire();
            }
           store.dispatch(setCarteDataByStatusLoading(false));
        }).catch((error)=>{
            if (isDev()) {
                console.error(error);
            }
            ToastGeneric('Erreur serveur!', 'error', 3000).fire();
            store.dispatch(setCarteDataByStatusLoading(false));
        });
    }
  }
 
}