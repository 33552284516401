import React from 'react';
import CtEquipesCampaign from '../pages-content/Equipes-content/Equipes.content';

const Equipes:React.FC = () => {
  return (
    <>
      <CtEquipesCampaign />
    </>
  )
}

export default Equipes;