import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MapCarte from '../../components/Map-carte/MapCarte';
import './Map.content.style.scss';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getAllCarteChantiersAsync } from '@/store/reducers/carte/carte.action';
import { selectCarteChantierItemList, selectCarteChantierLoading, selectCarteStatistiqueLoading } from '@/store/reducers/carte/carte.selector';

const CtMapPage: React.FC<any> = ({getChantiers, getChantiersAsync}) => {

  useEffect(() => {
    const url:any = getChantiersAsync();

    const fetchData = async () => {
        try {
            await fetch(url);
        } catch (error) {
            console.log("error", error);
        }
    };
    fetchData();
  }, [getChantiersAsync]);

  return (
    <>
      <PerfectScrollbar className="ct-map">
        <div className="card">
          <MapCarte list={getChantiers} />
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps:any = createStructuredSelector({
  loadingChantier: selectCarteChantierLoading,
  getChantiers: selectCarteChantierItemList,
  loadingStatistique: selectCarteStatistiqueLoading,
});

const mapDispatchToProps = (dispatch:any) => ({
  getChantiersAsync: () => dispatch(getAllCarteChantiersAsync()),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CtMap = connector(CtMapPage);

export default CtMap;
