import React from 'react';
import CtCongesRh from '../pages-content/Conges-content/Conges.content';

const CongesRh:React.FC = () => {
  return (
    <>
      <CtCongesRh />
    </>
  )
}

export default CongesRh;