import { InlineIcon } from '@iconify/react';
import {  Autocomplete, Box, Button, Divider, FormControlLabel, FormGroup, Grid, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import './CreateEnginForm.style.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { createEnginAsync, updateEnginAsync, updateVidangeAsync } from '@/store/reducers/engin/engin.action';
import { createStructuredSelector } from 'reselect';
import { getAllCategorieEnginsAsync } from '@/store/reducers/categorie-engin/categorieEngin.action';
import { selectCategorieEnginItemList } from '@/store/reducers/categorie-engin/categorieEngin.selector';
import { NumberValidator } from '@/utils/validators';
import { getAllTableDonneesAsync } from '@/store/reducers/table-donnee/tableDonnee.action';
import { selectTableDonneeItemList } from '@/store/reducers/table-donnee/tableDonnee.selector';
import 'dayjs/locale/fr';


//const regexImmatriculation = /[0-9]{1,4}[A-Z]{2}01/

const CreateEnginFormPage: React.FC<any> = ({
    selectedItem, 
    getAllType,
    getAllTableDonneesAsync,
    createEnginAsync, 
    updateEnginAsync, 
    getAllCategorieEngins, 
    getCategorieEnginsAsync,
    updateVidangeAsync,
    closeModal
}) => {
 
    useEffect(() => {
        const url:any = getCategorieEnginsAsync();
        const url1:any = getAllTableDonneesAsync();

        const fetchData = async () => {
            try {
                await fetch(url);
                await fetch(url1);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
        
      }, [getCategorieEnginsAsync,getAllTableDonneesAsync]);

    const typeComteurList: any = [];
    const protectionCabineList: any = [];
    getAllType.forEach((x:any) => {
        if (x.type === 'TYPE COMPTEUR') {
            typeComteurList.push({...x , value:x.libelle})
        }
        if (x.type === 'PROTECTION CABINE' || x.type === 'PROTECTION CABINE ') {
            protectionCabineList.push({...x , value:x.libelle})
        }
    })

    const categorieEnginIdList: any[] = getAllCategorieEngins.map((x:any, i:number) => {
        return {...x, value: x.libelle}
    });

    useEffect(() => { 
        if (selectedItem) {
            console.log('Selected Item set');
            // format selected because patchvalue
            Object.keys(selectedItem).forEach((x:any) => { if (selectedItem[x] == null) selectedItem[x] = ''})
            formik.setValues({
                ...selectedItem,
                climatisation: (selectedItem.climatisation === 1) ? true : false,
            });
            let categorie: any = categorieEnginIdList.find((x:any) => x.id === selectedItem.categorieEnginId);
            setCategorie(categorie);
            let typeCompteur:any = typeComteurList.find((x:any) => x.id === selectedItem.type_compteur_engin);
            setTypeCompteur(typeCompteur);
            let protection:any = protectionCabineList.find((x:any) => x.id === selectedItem.protection_cabine);
            setProtectionCabineList(protection);
            setDate_fin(dayjs(selectedItem.date_aquisition));
            setDate_debut(dayjs(selectedItem.date_dernier_compteur));
        }
    }, [])
  
    const [categorieEngin, setCategorie] = useState<{ id: number, value: string }>({ id: 0, value: '' });
    const handleCategorieChange = (event: any, value: { id: number, value: string } | null) => {
        console.log(value);
        if (value == null) {
            setCategorie({ id: 0, value: '' });
        } else {
            setCategorie(value as { id: number, value: string });
            formik.setFieldValue('categorieEnginId', value?.id);
        }
    };

    const [typeCompteur, setTypeCompteur] = useState<{ id: string, value: string }>({ id: '', value: '' });
    const handleTypeCompteurChange = (event: any, value: { id: string, value: string } | null) => {
        console.log(value);
        if (value == null) {
            setTypeCompteur({ id: '', value: '' });
        } else {
            setTypeCompteur(value as { id: string, value: string });
            formik.setFieldValue('type_compteur_engin', value?.value);
        }
    };

    const [protectionCabine, setProtectionCabineList] = useState<{ id: string, value: string }>({ id: '', value: '' });
    const handleprotectionCabineChange = (event: any, value: { id: string, value: string } | null) => {
        console.log(value);
        if (value == null) {
            setProtectionCabineList({ id: '', value: '' });
        } else {
            setProtectionCabineList(value as { id: string, value: string });
            formik.setFieldValue('protection_cabine', value?.value);
        }
    };

    const [date_debut, setDate_debut] = React.useState<Dayjs | null>(null);
    const [date_fin, setDate_fin] = React.useState<Dayjs | null>(null);


    const validationSchema = yup.object({
        libelle: yup.string().required("Champ obligatoire"),
        num_parc: yup.string().required("Champ obligatoire"),
        description: yup.string().required("Champ obligatoire"),
        categorieEnginId: yup.string().required("Champ obligatoire"),
        type_compteur_engin: yup.string().required("Champ obligatoire"),
        // valeur_compteur_engin: NumberValidator(),
        // dernier_compteur: NumberValidator(),
        // prochaine_vidange: NumberValidator(),
        num_chassis: yup.string().required("Champ obligatoire"),
       immatriculation: yup.string().required("Champ obligatoire"),
        // annee_fabrication: NumberValidator().test(
        //     {
        //         name: 'in-period',
        //         message: "Doit être entre 1980 et " + new Date().getFullYear(),
        //         test: (value) => parseInt(value, 10) <= new Date().getFullYear() && parseInt(value, 10) >= 1980
        //     }
        // ),
        compteur_aquisition: NumberValidator(),
      //  type_cabine: yup.string().required("Champ obligatoire"),
        // climatisation: yup.string().required("Champ obligatoire")
      //  protection_cabine: yup.string().required("Champ obligatoire"),
        // poids_a_vide: NumberValidator(),
        // puissance_moteur: NumberValidator(),
        // capacite_recevoir: NumberValidator(),
        // capacite_chargement: NumberValidator()
    });

    const resetForm = () => {
        formik.resetForm();
        setCategorie({ id: 0, value: "" });
        setTypeCompteur({ id: "", value: "" });
        setProtectionCabineList({ id: "", value: "" });
    }  


    const formik = useFormik({
        initialValues: {
            id: selectedItem ? selectedItem.id : null,
            libelle: selectedItem ? selectedItem.libelle : '',
            num_parc: selectedItem ? selectedItem.num_parc : '',
           
            description: '',
            categorieEnginId: '',
            type_compteur_engin: '',
            intervalle_entretien: '',

            derniere_entretien: selectedItem ? selectedItem.derniere_entretien : '',
            prochain_entretien: selectedItem ? selectedItem.prochain_entretien : '',
          //  prochaine_vidange: '',

             date_dernier_compteur: '',
             dernier_compteur: '',
            // End of geographique

            // Information vehicule

            num_chassis: '',
            immatriculation: '',
            
           
            compteur_aquisition: '',
            date_aquisition: '',
            annee_fabrication: '',
            /* RA  */
            type_cabine: '',
            climatisation: false,
            protection_cabine: '',
            poids_a_vide: '',
            puissance_moteur: '',
            capacite_recevoir: '',
            capacite_chargement: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            values.ops = '4';
            if (selectedItem) {
                values.date_aquisition = dayjs(values.date_aquisition).format('YYYY/MM/DD');
                values.date_dernier_compteur = dayjs(values.date_dernier_compteur).format('YYYY/MM/DD');
                values.transmission = "234";
                delete values.label;
                delete values.legend;
                delete values.hierarchy;
                delete values.categorie_engin;
                delete values.isactif;
                delete values.updatedAt;
                delete values.createdAt;
            }
            console.log('Values form', values);

            if (!selectedItem) {
                delete values.id;
                createEnginAsync(values);
            } else {
                updateEnginAsync(values); 
            }
            closeModal();
        }
    });

    const makeVidange = () => {
        for (let i = 0; i < selectedItem?.vidanges?.length; i++) {
            updateVidangeAsync({
                "id": selectedItem?.id,
                "enginId": selectedItem?.vidanges[i]?.id,
                "compteur_engin":selectedItem?.vidanges[i]?.compteur_engin,
                "date_vidange": selectedItem?.vidanges[i]?.date_vidange,
                "libelle_vidange": selectedItem?.vidanges[i]?.libelle_vidange,
                "chantier": selectedItem?.vidanges[i]?.chantier,
                "nom_mecanicien": selectedItem?.vidanges[i]?.nom_mecanicien,
                "ops": selectedItem?.vidanges[i]?.ops,
                "type_vidange": selectedItem?.vidanges[i]?.type_vidange
            });
        }      
    }

    return (
        <form className='create-list-form' onSubmit={formik.handleSubmit}>
            <span className="title"> {selectedItem ? 'Modification' : 'Création'} </span>

            <Grid container rowSpacing={2} columnSpacing={2} >
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Libellé engin"
                        variant="outlined"
                        name="libelle"
                        label="Libellé Engin"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.libelle}
                        error={formik.touched.libelle && Boolean(formik.errors.libelle)}
                        // helperText={formik.touched.libelle && formik.errors.libelle}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="N° PARC "
                        variant="outlined"
                        name="num_parc"
                        label="N° PARC"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.num_parc}
                        error={formik.touched.num_parc && Boolean(formik.errors.num_parc)}
                        // helperText={formik.touched.num_parc && formik.errors.num_parc}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Description "
                        variant="outlined"
                        name="description"
                        label="Description"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <Autocomplete
                        id="id-service-select"
                        options={categorieEnginIdList}
                        value={categorieEngin || null}
                        onChange={handleCategorieChange}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.value}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.value}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='my-text-field'
                                placeholder="Catégorie Engin"
                                label="Catégorie Engin"
                                variant="outlined"
                                name="categorieEnginId"
                                onChange={formik.handleChange}
                                value={formik.values.categorieEnginId}
                                error={formik.touched.categorieEnginId && Boolean(formik.errors.categorieEnginId)}
                            />
                        )} />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <Autocomplete
                        id="id-service-select"
                        options={typeComteurList}
                        value={typeCompteur || null}
                        onChange={handleTypeCompteurChange}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.value}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.value}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='my-text-field'
                                placeholder="Type compteur"
                                label="Type Compteur"
                                variant="outlined"
                                name="type_compteur_engin"
                                onChange={formik.handleChange}
                                value={formik.values.type_compteur_engin}
                                error={formik.touched.type_compteur_engin && Boolean(formik.errors.type_compteur_engin)}
                            />
                        )} />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="intervalle entretien"
                        variant="outlined"
                        name="intervalle_entretien"
                        label="Intervalle Entretien"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.intervalle_entretien}
                        error={formik.touched.intervalle_entretien && Boolean(formik.errors.intervalle_entretien)}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                    <DatePicker
                        label="Date dernier Compteur"
                        value={date_debut}
                        onChange={(newValue) => {
                        setDate_debut(newValue);
                        formik.setFieldValue("date_dernier_compteur", dayjs(newValue))
                        }}
                        renderInput={(params) => <TextField {...params} className='my-text-field'
                         />}
                    />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Dernier compteur "
                        variant="outlined"
                        name="dernier_compteur"
                        label="Dernier compteur"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.dernier_compteur}
                        error={formik.touched.dernier_compteur && Boolean(formik.errors.dernier_compteur)}
                        type="number" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="dernier entretien"
                        variant="outlined"
                        name="derniere_entretien"
                        label="Compteur dernier entretien"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.derniere_entretien}
                        error={formik.touched.derniere_entretien && Boolean(formik.errors.derniere_entretien)}
                        type="number" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Prochain entretien"
                        variant="outlined"
                        name="prochain_entretien"
                        label="Compteur prochain entretien"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.prochain_entretien}
                        error={formik.touched.prochain_entretien && Boolean(formik.errors.prochain_entretien)}
                        type="number" />
                </Grid>
            </Grid>

            <span className="sub-title-part"> <InlineIcon icon="bx:map-pin" /> &nbsp;  Information Acquisition </span>
            <Divider className='custom-divider' />

            <Grid container rowSpacing={1} columnSpacing={2} >

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="N° chassis "
                        variant="outlined"
                        name="num_chassis"
                        label="N° chassis"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.num_chassis}
                        error={formik.touched.num_chassis && Boolean(formik.errors.num_chassis)}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Immatriculation"
                        variant="outlined"
                        name="immatriculation"
                        label="Immatriculation"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.immatriculation}
                        error={formik.touched.immatriculation && Boolean(formik.errors.immatriculation)}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                    <DatePicker
                        label="Date d'acquisition"
                        value={date_fin}
                        onChange={(newValue) => {
                        setDate_fin(newValue); formik.setFieldValue("date_aquisition", newValue);
                        }}
                        renderInput={(params) => <TextField {...params} className='my-text-field' />}
                    />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Année de fabrication "
                        variant="outlined"
                        name="annee_fabrication"
                        label="Année de fabrication"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.annee_fabrication}
                        error={formik.touched.annee_fabrication && Boolean(formik.errors.annee_fabrication)}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Compteur d'acquisition "
                        variant="outlined"
                        name="compteur_aquisition"
                        label="Compteur d'acquisition"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.compteur_aquisition}
                        error={formik.touched.compteur_aquisition && Boolean(formik.errors.compteur_aquisition)}
                        type="text" />
                </Grid>
                
                
               
            </Grid>

            <span className="sub-title-part"> <InlineIcon icon="bx:user" /> &nbsp;  Autres Informations </span>
            <Divider className='custom-divider' />

            <Grid container rowSpacing={2} columnSpacing={2} >

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Type cabine "
                        variant="outlined"
                        name="type_cabine"
                        label="Type cabine"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.type_cabine}
                        error={formik.touched.type_cabine && Boolean(formik.errors.type_cabine)}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <div className="switch-container">
                        <Typography>Climatisation Cabine ?</Typography>
                        <FormGroup className='my-toggle'>
                            <FormControlLabel
                            label={formik.values.climatisation ? "Oui" : "Non"}
                            control={<Switch name="climatisation"
                                checked={formik.values.climatisation}
                                onChange={formik.handleChange} />}
                            />
                        </FormGroup>
                    </div>
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <Autocomplete
                        id="id-service-select"
                        options={protectionCabineList}
                        value={protectionCabine || null}
                        onChange={handleprotectionCabineChange}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.value}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.value}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='my-text-field'
                                placeholder="Protection cabine"
                                label="Protection cabine"
                                variant="outlined"
                                name="protection_cabine"
                                onChange={formik.handleChange}
                                value={formik.values.protection_cabine}
                                error={formik.touched.protection_cabine && Boolean(formik.errors.protection_cabine)}
                            />
                        )} />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Poids à vide"
                        variant="outlined"
                        name="poids_a_vide"
                        label="Poids à vide"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.poids_a_vide}
                        error={formik.touched.poids_a_vide && Boolean(formik.errors.poids_a_vide)}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Puissance moteur "
                        variant="outlined"
                        name="puissance_moteur"
                        label="Puissance moteur"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.puissance_moteur}
                        error={formik.touched.puissance_moteur && Boolean(formik.errors.puissance_moteur)}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Capacité réservoir à carburant "
                        variant="outlined"
                        name="capacite_recevoir"
                        label="Capacité réservoir à carburant"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.capacite_recevoir}
                        error={formik.touched.capacite_recevoir && Boolean(formik.errors.capacite_recevoir)}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Capacité de chargement"
                        variant="outlined"
                        name="capacite_chargement"
                        label="Capacité de chargement"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.capacite_chargement}
                        error={formik.touched.capacite_chargement && Boolean(formik.errors.capacite_chargement)}
                        type="text" />
                </Grid>  
            </Grid>

            <div className="btn-part">

                <Button color='secondary'
                    className='btn-option'
                    type='submit'
                    startIcon={<InlineIcon icon="iconoir:save-floppy-disk" />}
                    variant='contained'>
                    {!selectedItem ? 'Créer' : 'Modifier'}
                </Button>

                <Button color='light'
                    className='btn-option'
                    startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
                    onClick={() => {resetForm()}}
                    variant='contained'>
                    {'Réinitialiser'}
                </Button>

                {   
                    selectedItem &&
                        <Button color='error'
                            className='btn-option'
                            startIcon={<InlineIcon icon="iconoir:car" />}
                            variant='contained'
                            onClick={makeVidange}>
                            Vidange
                        </Button>
                }

            </div>

        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    getAllCategorieEngins: selectCategorieEnginItemList,
    getAllType: selectTableDonneeItemList,
});

const mapDispatchToProps = (dispatch:any) => ({
    getAllTableDonneesAsync: () => dispatch(getAllTableDonneesAsync()),
    getCategorieEnginsAsync: () => dispatch(getAllCategorieEnginsAsync()),
    createEnginAsync: (data:any) => dispatch(createEnginAsync(data)),
    updateEnginAsync: (data:any) => dispatch(updateEnginAsync(data)),
    updateVidangeAsync: (data:any) => dispatch(updateVidangeAsync(data)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CreateEnginForm = connector(CreateEnginFormPage);

export default CreateEnginForm