import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectVidange = (state:TRootState) => state.vidange;

export const selectLoading = createSelector(
  [selectVidange],
  (vidange:any) => vidange.loading
);

export const selectVidangeItemList = createSelector(
    [selectVidange],
    (vidange:any) => vidange.list
);

export const selectVidangeDetailLoading = createSelector(
  [selectVidange],
  (vidange:any) => vidange.loading
);

export const selectVidangeItemSelected = createSelector(
    [selectVidange],
    (vidange:any) => vidange.itemSelected
);
