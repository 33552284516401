import MiniPiehart from '@/features/admin-iveqi/components/Mini-pie-chart/MiniPieChart';
import { InlineIcon } from '@iconify/react';
import { TuneRounded, TurnedIn, ListAlt, ViewArray } from '@mui/icons-material';
import { Badge, Button, Chip, Divider, IconButton, InputAdornment, Skeleton, TextField } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './Organisation.content.style.scss';
import { DataGrid, GridRenderCellParams, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import ListeItemCardGenerique from '@/features/admin-iveqi/components/ListeItem/ListeItemCardGenerique';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import { Stack } from "@mui/material";
import { exportToExcel } from '@/config';
import { selectUserProfil } from '@/store/reducers/profil/profil.selector';
// import Swal from 'sweetalert2';
import { getAllCuvesAsync } from '@/store/reducers/cuve/cuve.action';
import { selectLoading, selectCuveItemList } from '@/store/reducers/cuve/cuve.selector';
import SideModal from '../../../Administration-content/components/SideModal/SideModal';
import HistoriqueCuveForm from '../../Forms/HistoriqueCuveForm/HistoriqueCuveForm';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


const columns = [
  //{ field: 'libelle', headerName: 'Libellé', width: 280 },
  { field: 'chantier', headerName: 'Chantier', width: 280 },
  { field: 'typeCuve', headerName: 'type', width: 150 },
  {
    field: 'quantite',
    headerName: 'Quantité (L)',
    width: 150,
    renderCell: (params: GridRenderCellParams) => {
      console.log('GridRenderCellParams',params);
      let nbre = ((params?.value * 100)/ params?.row?.capacite);
      if (nbre < 20) {
        return <Chip label={params?.value} color='error' variant="outlined" />;
      } else if (nbre > 70) {
        return <Chip label={params?.value} color='quartiary' variant="outlined" />;
      } else {
        return <Chip label={params?.value} color='warning' variant="outlined" />;
      }
    },
  },
  {
    field: 'capacite',
    headerName: 'Capacité (L)',
    width: 200,
  },
];


const CtOrganisationCampaignPage: React.FC<any> = ({loading, cuveList, getAllCuvesAsync, permissions}) => {

  useEffect(() => {
    const url:any = getAllCuvesAsync();

    const fetchData = async () => {
        try {
            await fetch(url);
        } catch (error) {
            console.log("error", error);
        }
    };
    fetchData();
    
  }, [getAllCuvesAsync]);

  const [searchText, setSearchText] = useState('');
  const [isCardMode, setIsCardMode] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {setOpen(false); isCardMode && setSelection(null)};

  const [isAdd, setIsAdd] = useState(false);

  const [selection, setSelection] = React.useState<any>(null);


  const listItems: any[] = cuveList.map((x: any) => {
    return {
      ...x,
      chantier: x.chantier.nom,
      searchText: x.libelle + ' ' + x.type
    }
  });


  const getListItemsFilteredSearch = () => {
    return listItems.filter((value: any) => {
      return (value?.searchText?.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
    })
  }

   // Appel de la fonction d'exportation lorsque vous souhaitez exporter les données
  const handleExportExcel = () => {
    exportToExcel(listItems, columns, 'vidange');
  };

  const selectItemFunc = (idElt: number | any) => {
      const element =  getListItemsFilteredSearch().find((x:any) => x.id === idElt);
      console.log("element", element);
      if (selection?.id === element?.id) {
        isCardMode && setSelection(null);
        return;
      }
      setSelection(element);
  }

  const isCardActive = (idCard: any) => {
    // if (selection?.id == idCard) return false
    return selection?.id === idCard
  }

  

  return (
    <>
      <div className="ct-cuves-campagne">
        <div className="ct-cuves-campagne__card">

          <div className="header-part">
            <div className="title-part">
                <div className="title-bloc">
                  <span className="title"> Gestion des cuves </span>
                  <Chip label={` ${listItems?.length} ${listItems?.length < 2 ? 'élément' : 'éléments'}  `}
                    className='total-number-chip' />

                  <Badge color="secondary" >
                      <IconButton className='close-icon'
                            size="small" 
                            color='blue'
                            onClick={()=>{
                              // handleOpen();
                              // setIsAdd(false);
                            }}>
                        <TuneRounded />
                      </IconButton>
                  </Badge>
                  <Badge color="secondary" 
                        variant="dot"
                        >
                      <IconButton 
                        className='close-icon'
                        title={`Mode ${isCardMode ? "liste" : 'carte'}`}
                        size="small" 
                        color='blue'
                        onClick={()=>{
                          setIsCardMode(!isCardMode); setSelection(null);
                        }}>
                        {
                          isCardMode ?
                          <ListAlt /> :
                          <TurnedIn />
                        }
                      </IconButton>
                  </Badge>
                </div>
                <div className="toolbar-part">

                  <div className="actions-part">

                  {
                    selection ?
                      <>
                        <Divider orientation='vertical' />
                        {
                          permissions?.parametre?.find((o: any) => (o.libelle === 'Vidange' && o.edit === true)) &&
                          <Button
                            color='blue'
                            className='btn-option'
                            onClick={() => {
                              handleOpen();
                              setIsAdd(true);
                            }}
                            startIcon={<ViewArray />}>
                            Historique
                          </Button>
                        }
                        <Divider orientation='vertical' />
                        {/* {
                          permissions?.parametre?.find((o: any) => (o.libelle === 'Vidange' && o.delete === true)) &&
                          <Button
                            color='blue'
                            className='btn-option'
                            onClick={confirmBeforeRemove}
                            startIcon={<Delete />}>
                            Supprimer
                          </Button>
                        }  */}
                      </> :
                      <>
                        {/* {
                          permissions?.parametre?.find((o: any) => (o.libelle === 'Vidange' && o.create === true)) &&
                          <Button
                            color='blue'
                            className='btn-option'
                            onClick={() => {
                              handleOpen();
                              setIsAdd(true);
                            }}
                            //variant='contained'
                            startIcon={<InlineIcon icon="carbon:document-add" />}>
                            Créer une vidange
                          </Button>
                        } */}
                        <Divider orientation='vertical' />
                        <Button
                          color='blue'
                          className='btn-option'
                          onClick={handleExportExcel}
                          //variant='contained'
                          startIcon={<InlineIcon icon="carbon:document-import" />}>
                          Exporter la liste
                        </Button>
                      </>
                  }
                    
                  </div>

                  <TextField
                    value={searchText}
                    onChange = {
                      (e) => {
                        setSearchText(e.target.value);
                      }
                    }
                    className="search-input"
                    placeholder="Recherche ... "
                    variant="outlined"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InlineIcon icon="bi:search" />
                        </InputAdornment>
                      )
                    }}
                  />

                </div>
            </div>
            <Divider className='custom-divider' />
          </div>

          <PerfectScrollbar id="scrollableDiv" className="content-part">
            <div className="kpi-part">
              <div className={classNames('kpi-card ')}>
                  <span className="value">
                    {listItems?.length}
                  </span>
                  <span className="text">
                    Total 
                  </span>
                  <div className="mini-pie">
                    <MiniPiehart
                          value1={15}
                          value2={50}
                          value3={35}
                          value4={0}
                          color1={'#EF7D00'}
                          color2={'#26353F'}
                          color3={'#008F75'}
                          color4={'#ff1744'}
                          height={30} 
                          width={30}
                          interRadius={5}/>
                  </div>
              </div>
              
            </div>
            <>
                {
                    loading && 
                    <>
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="pulse"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                    </>
                }

                {
                  isCardMode ?
                  <InfiniteScroll
                    className="content-part__infinite-scroll-part"
                    scrollableTarget="scrollableDiv"
                    dataLength={listItems?.length}
                    next={getAllCuvesAsync}
                    hasMore={true}
                    loader={
                      listItems
                      ?
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        :
                        <p> Aucune donnée disponible </p>
                    }>

                        {
                              !loading &&
                              listItems?.filter((value: any) => {
                                  return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
                                }).map((value: any, index: any) => {

                                  return <ListeItemCardGenerique id={value.id}
                                  key={index} 
                                  // setOpen={setOpen}
                                  columns={columns}
                                  data={[value]}
                                  setIsEdit={selectItemFunc}
                                  isActive={isCardActive(value.id)}/>

                                })
                          }
                  </InfiniteScroll> :
                  <div style={{ height: '85%', width: '100%' }}>
                    <DataGrid  components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            Pas de donnees
                          </Stack>
                        ),
                      }}
                      localeText={{
                        MuiTablePagination: {
                          labelDisplayedRows: ({ from, to, count }) =>
                            `${from} - ${to} Total: ${count}`,
                        },
                      }}
                      rows={
                        getListItemsFilteredSearch()
                      }
                      columns={columns}
                       initialState={{
                        pagination: { paginationModel: { pageSize: 7 } },
                      }}
                      onRowSelectionModelChange={(row) => {
                        selectItemFunc(row[0]);
                      }}
                      slots={{
                        toolbar: CustomToolbar,
                      }}
                    />
                  </div>
                }
            </>

          </PerfectScrollbar>
        </div>
      </div>
      <SideModal 
          open={open}
          onClose={handleClose}
          closeClick={handleClose}>
            <>
                {
                    isAdd &&
                    <HistoriqueCuveForm selectedItem={selection} />
                }
            </>
      </SideModal>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
  cuveList: selectCuveItemList,
  permissions: selectUserProfil
});

const mapDispatchToProps = (dispatch:any) => ({
  getAllCuvesAsync: () => dispatch(getAllCuvesAsync()),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CtOrganisationCampaign = connector(CtOrganisationCampaignPage);

export default CtOrganisationCampaign;
