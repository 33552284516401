import MiniPiehart from '@/features/admin-iveqi/components/Mini-pie-chart/MiniPieChart';
import { Chip, Divider, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './Accueil.content.style.scss';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAlerteCountList, selectAlerteItemList } from "@/store/reducers/alerte/alerte.selector";
import InfiniteScroll from 'react-infinite-scroll-component';
import AlertItem from '@/components/Snackbar/AlertItem';
import { getAllAlertesAsync } from '@/store/reducers/alerte/alerte.action';

const CtAccueilRhPage: React.FC<any> = ({alerte, alerteList, getAllAlertesAsync}) => {
  

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    
    setLoading(true);

    const fakeLoading = setTimeout(() => {
        setLoading(false);
    }, 2000);
  
    return () => {
        clearTimeout(fakeLoading);
    }

  }, []);

  return (
    <>
      <div className="ct-accueil-campagne">
        <div className="ct-accueil-campagne__card">

            <div className="title-bloc">
                <span className="title"> Alertes </span>
                {
                      loading ? 
                        <>
                            <Skeleton variant="text" animation="wave"  width={80} height={20} />
                        </>
                        :
                        <Chip label={` ${alerte?.personnel} ${alerte?.personnel < 2 ? 'alerte' : 'alertes'}  `}
                              className='total-number-chip' />
                }
            </div>
            <Divider className='custom-divider' />
            <PerfectScrollbar id="scrollableDiv" className="content-part">
            <div className="kpi-part">

                {
                      loading ? 
                      <>
                          <Skeleton variant="rounded" animation="wave"  width={200} height={90} />
                          <Skeleton variant="rounded" animation="pulse"  width={200} height={90} />
                          <Skeleton variant="rounded" animation="wave"  width={200} height={90} />
                      </> 
                      :   
                      <>
                          <div className="kpi-card">
                              <span className="value">
                                {alerte?.personnel}
                              </span>
                              <span className="text">
                                Total alertes
                              </span>
                              <div className="mini-pie">
                                <MiniPiehart
                                      value1={15}
                                      value2={50}
                                      value3={35}
                                      value4={0}
                                      color1={'#26353F'}
                                      color2={'#EF7D00'}
                                      color3={'#008F75'}
                                      color4={'#ff1744'}
                                      height={30} 
                                      width={30}
                                      interRadius={5}/>
                              </div>
                          </div>
                          {/* <div className="kpi-card primary">
                            <span className="percentage"> {((absentList?.length * 100) / (alerte?.personnel)) | 0}%   </span>
                            <span className="value">
                              {absentList?.length}
                            </span>
                            <span className="text">
                              Absences
                            </span>
                          </div>
                          <div className="kpi-card tertiary">
                                      <span className="percentage"> {((affectedList?.length * 100) / (alerte?.personnel)) | 0}%   </span>
                            <span className="value">
                              {affectedList?.length}
                            </span>
                            <span className="text">
                              Affectations
                            </span>
                          </div> */}
                      </>
                }

            </div>

            <>
                {
                    loading && 
                    <>
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="pulse"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                    </>
                }
      

                  <InfiniteScroll
                    className="content-part__infinite-scroll-part"
                    scrollableTarget="scrollableDiv"
                    dataLength={alerteList?.personnel?.length || []}
                    next={getAllAlertesAsync}
                    hasMore={true}
                    loader={
                      alerteList?.personnel ?
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        :
                        <p> Aucune donnée disponible </p>
                    }>

                        {
                              !loading &&
                              alerteList?.personnel?.map((value: any, index:any) => {

                                  return <AlertItem 
                                            type={'warning'} 
                                            msg={'Alerte absence'} 
                                            desc={('Monsieur / Madame /Mademoiselle '+ value?.nom + " " + value?.prenoms  + " (" + value?.matricule + ') est absent(e) du chantier '+ value?.chantier?.nom + ' pour motif :'+ value?.absences_chantiers[0]?.motif + ' Observattion : ' + value?.absences_chantiers[0]?.observation  ) }
                                            key={index} />
                            
                                })
                          }
                  </InfiniteScroll>  
                  
            </>
            </PerfectScrollbar>
        </div>
      </div>
    </>
  );
};

const mapStateToProps:any = createStructuredSelector({
  alerte: selectAlerteCountList,
  alerteList: selectAlerteItemList,
});

const mapDispatchToProps = (dispatch:any) => ({
  getAllAlertesAsync: () => dispatch(getAllAlertesAsync()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const CtAccueilRh = connector(CtAccueilRhPage);

export default CtAccueilRh;
