import React, { useState } from 'react';
import classnames from 'classnames';
import './DashboardChartCard.styles.scss';
import {  Button, Divider, Stack } from '@mui/material';
import { DataGrid, frFR } from '@mui/x-data-grid';
import moment from 'moment';
import { exportToExcel } from '@/config';
import { InlineIcon } from '@iconify/react';



const columns = [
    { field: 'date', headerName: 'Date',   valueGetter: (params: any) => {
        return moment(params.value).format('DD-MM-YYYY');
    },width: 120 , format: 'date' },
    { field: 'engin', headerName: 'Engin', width: 150 },
    { field: 'travail', headerName: 'travail', width: 100 },
    { field: 'nature_travaux', headerName: 'Nature Travaux', width: 190 },
    { field: 'compteur_debut', headerName: 'Compteur debut', width: 130 , format: 'number'},
    { field: 'compteur_fin', headerName: 'Compteur fin', width: 120, format: 'number' },
    { field: 'quantite', headerName: 'quantite', width: 130, format: 'number' },
    { field: 'carburant', headerName: 'Carburant', width: 120, format: 'number' },
    { field: 'chauffeur', headerName: 'Chauffeur', width: 130 },
    { field: 'motif', headerName: 'Motif', width: 130 },
    { field: 'observation', headerName: 'observation', width: 150 },
    { field: 'utilisateur', headerName: 'Chef Chantier', width: 150 },
  ];

  const columnsIncident = [
    { field: 'engin', headerName: 'Engin', width: 150 },
    { field: 'chantier', headerName: 'Chantier', width: 150 },
    { field: 'date_incient', headerName: 'Date incident',   valueGetter: (params: any) => {
        // Format the date using moment
        return moment(params.value).format('DD-MM-YYYY');
    },width: 150  , format: 'date' },
    { field: 'panne', headerName: 'panne', width: 150 },
    { field: 'observation_panne', headerName: 'Observation panne', width: 150 },
    { field: 'date_depannage', headerName: 'Date depannage',   valueGetter: (params: any) => {
        // Format the date using moment
        return moment(params.value).format('DD-MM-YYYY');
    },width: 150  , format: 'date'  },
    { field: 'compteur_depannage', headerName: 'Compteur Depannage', width: 150, format: 'number' },
    { field: 'nom_technicien', headerName: 'Nom Technicien', width: 150 },
    { field: 'observation_depannage', headerName: 'Observation depannage', width: 150 },
    { field: 'chantier', headerName: 'Chantier', width: 150 },
    { field: 'utilisateur', headerName: 'Chef Chantier', width: 150 },
  ];

  const columnsCuve = [
    { field: 'engin', headerName: 'Engin', width: 150 },
    { field: 'chantier', headerName: 'Chantier', width: 150 },
    { field: 'date', headerName: 'Date',   valueGetter: (params: any) => {
        // Format the date using moment
        return moment(params.value).format('DD-MM-YYYY');
    },width: 130 , format: 'date' },
    { field: 'type', headerName: 'Type', width: 150 },
    // { field: 'enginId', headerName: 'Engin', width: 180 },
    // { field: 'chantierId', headerName: 'Chantier', width: 180 },
    { field: 'quantite', headerName: 'Quantité', width: 150  , format: 'number'},
    { field: 'quantite_cuve_avant', headerName: 'Quantité Avant', width: 150 , format: 'number'},
    { field: 'quantite_cuve_apres', headerName: 'Quantité Apres', width: 150 , format: 'number'},
    { field: 'observation', headerName: 'Observation', width: 150 },
    { field: 'utilisateur', headerName: 'Chef Chantier', width: 150 },
  ];






const DashboardChartCard: React.FC<any> = ({ historiqueChantiers })=> {

    const [loading, setLoading] = useState(false);

    const [category, setCategory] = useState<'Utilisation Engin' | 'Incidents' | 'Cuves'>('Utilisation Engin');

    const [dataList, setDataList] = React.useState<any[]>([]);
    const [columList, setColumList] = useState<any>(columns);

      // Appel de la fonction d'exportation lorsque vous souhaitez exporter les données
    const handleExportUtilisationExcel = () => {
        exportToExcel(dataList, columList, category);
    };

    return (
        <div className="dashboard-chart-card">
            <span className="title"> Performances </span>
            {historiqueChantiers && (
                <div>
                    <span className="title" style={{ color: '#008F75' }}> ⛽ Total Carburant : {historiqueChantiers?.totalCarburant?.toLocaleString()} </span> <br/>
                    <span className="title" style={{ color: '#008F75' }}> 🏗 Total Quantité: {historiqueChantiers?.totalQuantite?.toLocaleString()} </span> <br/>
                    <span className="title" style={{ color: '#008F75' }}> 🏗 Moyen: {(historiqueChantiers?.totalCarburant / historiqueChantiers?.totalQuantite).toFixed(2) } </span>

                </div>
                )
            }

            <div className="option-part">

                <div className="categories-part">
                    <div className={classnames('category-item', { 'active': category === 'Utilisation Engin' })}
                        onClick={() => {
                            setCategory('Utilisation Engin');
                            setLoading(true);
                            setTimeout(() => {
                                //setDataList(historiqueChantiers?.UtilisationEngin);
                               // setDataForChart(dataCellule);

                               
                                console.log('UtilisationEngin', historiqueChantiers?.UtilisationEngin);
                                let val : any[] = [];
                                if(historiqueChantiers?.UtilisationEngin?.length > 0){
                                    val=  historiqueChantiers?.UtilisationEngin.map((x: any) =>{
                                            return {
                                            ...x,
                                            engin: x.engin?.num_parc,
                                            utilisateur: x.utilisateur?.nom + ' ' +  x.utilisateur?.prenoms,
                                        }
                                    });
                                   
                                }
                                setDataList(val);
                                setColumList(columns)
                                setLoading(false);
                            }, 1000)

                        }}>
                        <span> Utilisation  </span>
                    </div>
                    <Divider orientation='vertical' />
                    <div className={classnames('category-item', { 'active': category === 'Incidents' })}
                        onClick={() => {
                            setCategory('Incidents');

                            setLoading(true);
                            setTimeout(() => {
                               // setDataForChart(dataCellule);
                               console.log('incidentEngin', historiqueChantiers?.incidentEngin);
                               let val : any[] = [];
                                if(historiqueChantiers?.incidentEngin?.length > 0){
                                   val=  historiqueChantiers?.incidentEngin.map((x: any) =>{
                                           return {
                                           ...x,
                                           engin: x.engin?.num_parc,
                                           chantier: x.chantier?.nom,
                                           utilisateur: x.utilisateur?.nom + ' ' +  x.utilisateur?.prenoms,
                                       }
                                   });
                                }
                               setDataList(val);
                               setColumList(columnsIncident)
                               
                               //setDataList((!historiqueChantiers?.incidentEngin)?[]:historiqueChantiers?.incidentEngin);
                         
                                setLoading(false);
                            }, 1000)
                           
                        }}>
                        <span> Incidents </span>
                    </div>
                    <Divider orientation='vertical' />
                    <div className={classnames('category-item', { 'active': category === 'Cuves' })}
                        onClick={() => {
                            setCategory('Cuves');

                            setLoading(true);
                            setTimeout(() => {

                                console.log('utilisationCuve', historiqueChantiers?.utilisationCuve);
                                let val : any[] = [];
                                if(historiqueChantiers?.utilisationCuve?.length > 0){
                                    val =  historiqueChantiers?.utilisationCuve.map((x: any) =>{
                                        return {
                                           ...x,
                                           engin: (x.engin) ? x.engin?.num_parc : x.nom_engin,
                                           chantier: x.chantier?.nom,
                                           utilisateur: x.utilisateur?.nom + ' ' +  x.utilisateur?.prenoms,
                                       }
                                   });
                                }
                                setDataList(val);
                                setColumList(columnsCuve)
                                setLoading(false);
                            }, 1000)
                           
                        }}>
                        <span> Cuves </span>
                    </div>
                </div>


                {/* <Select
                    labelId="id-services-filtre"
                    className="filter-select-input"
                    placeholder="test"
                    multiple
                    value={filteredValue}
                    onChange={handleFilteredValue}
                    input={<OutlinedInput />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} color="secondary" />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {
                        category === 'Utilisation Engin' &&
                        servicesList.map((elt, index) => (
                            <MenuItem key={index} value={elt}>
                                <Checkbox checked={filteredValue.indexOf(elt) > -1} />
                                <ListItemText primary={elt} />
                            </MenuItem>
                        ))
                    }
                    {
                        category === 'Incidents' &&
                        cellulesList.map((elt, index) => (
                            <MenuItem key={index} value={elt}>
                                <Checkbox checked={filteredValue.indexOf(elt) > -1} />
                                <ListItemText primary={elt} />
                            </MenuItem>
                        ))
                    }
                    {
                        category === 'Cuves' &&
                        equipesList.map((elt, index) => (
                            <MenuItem key={index} value={elt}>
                                <Checkbox checked={filteredValue.indexOf(elt) > -1} />
                                <ListItemText primary={elt} />
                            </MenuItem>
                        ))
                    }
                </Select>



                <Button color='secondary'
                    className='btn-option'
                    startIcon={<InlineIcon icon="ep:filter" />}
                    variant='contained'>
                    Filtrer les {category}
                </Button> */}

            </div>
            <div className='chart-part'>
                {
                    loading ?
                        <div className="loading-chart">
                            <img src={require('@/assets/images/gif/chart-load.gif')} alt="load" />
                        </div>
                        :
                        <div style={{ height: '350px', width: '100%' }}>
                            <Button
                                color='blue'
                                className='btn-option'
                                onClick={handleExportUtilisationExcel}
                                variant='contained'
                                startIcon={<InlineIcon icon="carbon:document-export" />}>
                                Exporter
                            </Button>
                            <DataGrid  components={{
                                NoRowsOverlay: () => (
                                  <Stack height="100%" alignItems="center" justifyContent="center">
                                    Pas de donnees
                                  </Stack>
                                ),
                              }}
                              localeText={{
                                ...frFR.components.MuiDataGrid.defaultProps.localeText,
                                MuiTablePagination: {
                                    labelRowsPerPage: "Lignes par page",
                                    labelDisplayedRows: ({ from, to, count }) =>
                                        `${from} - ${to} sur ${count}`,
                                },
                                noRowsLabel: 'Pas de données',
                                noResultsOverlayLabel: 'Aucun résultat trouvé.',
                                toolbarDensity: 'Densité',
                                toolbarDensityLabel: 'Densité',
                                toolbarDensityCompact: 'Compacte',
                                toolbarDensityStandard: 'Standard',
                                toolbarDensityComfortable: 'Confortable',
                                toolbarColumns: 'Colonnes',
                                toolbarColumnsLabel: 'Sélectionner les colonnes',
                                toolbarFilters: 'Filtres',
                                toolbarFiltersLabel: 'Afficher les filtres',
                                toolbarFiltersTooltipHide: 'Masquer les filtres',
                                toolbarFiltersTooltipShow: 'Afficher les filtres',
                                toolbarFiltersTooltipActive: (count) =>
                                    count !== 1 ? `${count} filtres actifs` : `${count} filtre actif`,
                                toolbarExport: 'Exporter',
                                toolbarExportLabel: 'Exporter',
                                toolbarExportCSV: 'Télécharger en CSV',
                                toolbarExportPrint: 'Imprimer',
                                columnsPanelTextFieldLabel: 'Rechercher une colonne',
                                columnsPanelTextFieldPlaceholder: 'Titre de colonne',
                                columnsPanelDragIconLabel: 'Réorganiser la colonne',
                                columnsPanelShowAllButton: 'Tout afficher',
                                columnsPanelHideAllButton: 'Tout masquer',
                                filterPanelAddFilter: 'Ajouter un filtre',
                                filterPanelDeleteIconLabel: 'Supprimer',
                                filterPanelOperatorAnd: 'Et',
                                filterPanelOperatorOr: 'Ou',
                                filterPanelColumns: 'Colonnes',
                                filterPanelInputLabel: 'Valeur',
                                filterPanelInputPlaceholder: 'Filtrer la valeur',
                                filterOperatorContains: 'contient',
                                filterOperatorEquals: 'est égal à',
                                filterOperatorStartsWith: 'commence par',
                                filterOperatorEndsWith: 'finit par',
                                filterOperatorIs: 'est',
                                filterOperatorNot: 'n\'est pas',
                                filterOperatorAfter: 'est après',
                                filterOperatorOnOrAfter: 'est le ou après',
                                filterOperatorBefore: 'est avant',
                                filterOperatorOnOrBefore: 'est le ou avant',
                                filterOperatorIsEmpty: 'est vide',
                                filterOperatorIsNotEmpty: 'n\'est pas vide',
                                filterOperatorIsAnyOf: 'est l\'un des',
                                filterValueAny: 'toutes',
                                filterValueTrue: 'vrai',
                                filterValueFalse: 'faux',
                                columnMenuLabel: 'Menu',
                                columnMenuShowColumns: 'Afficher les colonnes',
                                columnMenuFilter: 'Filtrer',
                                columnMenuHideColumn: 'Masquer',
                                columnMenuUnsort: 'Annuler le tri',
                                columnMenuSortAsc: 'Trier par ordre croissant',
                                columnMenuSortDesc: 'Trier par ordre décroissant',
                                columnHeaderFiltersTooltipActive: (count) =>
                                    count !== 1 ? `${count} filtres actifs` : `${count} filtre actif`,
                                columnHeaderFiltersLabel: 'Afficher les filtres',
                                columnHeaderSortIconLabel: 'Trier',
                                footerRowSelected: (count) =>
                                    count !== 1
                                        ? `${count.toLocaleString()} lignes sélectionnées`
                                        : `${count.toLocaleString()} ligne sélectionnée`,
                                footerTotalRows: 'Lignes totales :',
                                footerTotalVisibleRows: (visibleCount, totalCount) =>
                                    `${visibleCount.toLocaleString()} sur ${totalCount.toLocaleString()}`,
                                checkboxSelectionHeaderName: 'Sélection',
                                booleanCellTrueLabel: 'oui',
                                booleanCellFalseLabel: 'non',
                                actionsCellMore: 'plus',
                            }}
                            rowHeight={50} 
                            rows={
                                dataList
                            }
                            columns={columList}
                            initialState={{
                              pagination: { paginationModel: { pageSize: 10 } },
                            }}
                          />
                        </div>
                }
            </div>
        </div>
    )
}

export default DashboardChartCard