import * as yup from 'yup';

const NumberValidator = () => {
    let positive = true;
    let messageFinal = `Veuillez renseigner un nombre ${positive ? 'positif' : ''} `
    return yup.mixed().required("Champ obligatoire").test(
        {
            name: 'is-number',
            message: messageFinal,
            test: (value) => /^-?\d+$/.test(value) && (positive && value> 0 )
        }
    )
}

const PhoneValidator = (isRequired=false) => {
    return yup.mixed().required('Champ requis').test('test-min-length', 'Numéro de téléphone non valide', (value) => {
        if (value) {
            const valueWSpace = value.replaceAll(' ', '')
            if (valueWSpace.indexOf('+225') === 0) {
                const withoutIndicatorPlus = valueWSpace.split('+225')[1];
                return withoutIndicatorPlus.length === 10;
            } else if (valueWSpace.indexOf('00225') === 0) {
                const withoutIndicatorOO = valueWSpace.split('00225')[1];
                return withoutIndicatorOO.length === 10;
            } else {
                return valueWSpace.length === 10;
            }            
        }
        return false;
    })
}

export {
    NumberValidator,
    PhoneValidator
}