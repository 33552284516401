import { InlineIcon } from "@iconify/react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { useFormik } from "formik";
import "./CreateChantierForm.style.scss";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { Dayjs } from 'dayjs';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  createChantierAsync,
  updateChantierAsync,
} from "@/store/reducers/chantier/chantier.action";
import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";
import Swal from "sweetalert2";
import { getAllClientsAsync } from "@/store/reducers/client/client.action";
import { selectClientItemList } from "@/store/reducers/client/client.selector";
import { getAllUsersAsync } from "@/store/reducers/user/user.action";
import { selectUserItemList } from "@/store/reducers/user/user.selector";
import { selectAuthUser } from "@/store/reducers/auth/auth.selector";
import { getAllTableDonneesAsync } from "@/store/reducers/table-donnee/tableDonnee.action";
import { selectTableDonneeItemList } from "@/store/reducers/table-donnee/tableDonnee.selector";
// import * as _ from 'lodash';
import moment from "moment";
import "dayjs/locale/fr";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 0;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: '100% !important',
//     },
//   },
// };

const CreateChantierFormPage: React.FC<any> = ({
  selectedItem,
  createChantierAsync,
  updateChantierAsync,
  getClientListAsync,
  getAllTableDonneesAsync,
  getClientList,
  getUserListAsync,
  getUserList,
  getData,
  userConnected,
  closeModal,
}) => {
  useEffect(() => {
    const url: any = getClientListAsync();
    const url2: any = getAllTableDonneesAsync();
    const url1: any = getUserListAsync();

    const fetchData = async () => {
      try {
        await fetch(url);
        await fetch(url1);
        await fetch(url2);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, [getClientListAsync, getUserListAsync, getAllTableDonneesAsync]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    // p: 4,
  };

  const clientList: any[] = [];
  getClientList.forEach((x: any) => {
    clientList.push({ id: x.id, value: x.nom });
  });

  const [client, setClient] = useState<{ id: any; value: string }>(
    selectedItem
      ? { id: selectedItem?.client?.id, value: selectedItem?.client?.nom }
      : { id: null, value: "" }
  );
  const handleClientChange = (
    event: any,
    value: { id: any; value: string } | null
  ) => {
    if (value === null) {
      setClient({ id: null, value: "" });
    } else {
      setClient(value as { id: any; value: string });
    }
  };

  let chefChantierList: any[] = [];
  getUserList.forEach((x: any) => {
    if (x?.profilId && x?.profilId === 3) {
      chefChantierList.push({ id: x.id, value: x.nom + " " + x.prenoms });
    } else {
    }
  });

  console.log("chefChantierList", chefChantierList);

  const [chefChantier, setChefChantier] = useState<{ id: any; value: string }>(
    selectedItem
      ? {
          id: selectedItem?.utilisateur?.id,
          value: selectedItem?.utilisateur?.nom,
        }
      : { id: null, value: "" }
  );
  const handleChefChantierChange = (
    event: any,
    value: { id: any; value: string } | null
  ) => {
    console.log(value);
    if (value === null) {
      setChefChantier({ id: null, value: "" });
    } else {
      setChefChantier(value as { id: any; value: string });
    }
  };

  const typeLocationList: any[] = [];
  getData.forEach((x: any) => {
    if (x?.type === "TYPE LOCATION") {
      typeLocationList.push({ id: x.id, value: x.libelle });
    } else {
    }
  });

  const [typeLocation, setTypeLocation] = useState<any>(
    selectedItem
      ? { id: selectedItem?.type_location, value: selectedItem?.type_location }
      : { id: null, value: "" }
  );

  const handleTypeLocationChange = (
    event: any,
    value: { id: any; value: string } | null
  ) => {
    console.log(value);
    if (value === null) {
      setTypeLocation({ id: null, value: "" });
    } else {
      setTypeLocation(value as { id: any; value: string });
    }
  };

  const [correspondantFormArray, setCorrespondantFormArray] = useState(selectedItem ? selectedItem.correspondant_chantiers :[
    { id: uuidv4(), nom_prenoms: "", telephone: "", fonction: "" },
  ]);

  const handleChangeFormArray = (id: any, e: any) => {
    console.log("id", id);

    //correspondantFormArray = selectedItem.correspondant_chantiers;

    let newInputs: any = correspondantFormArray.map((i: any) => {
      if (id === i.id) {
        i[e.target.name] = e.target.value;
      }
      return i;
    });

    setCorrespondantFormArray(newInputs);
  };

  const handleAddFormArray = () => {
    setCorrespondantFormArray([
      ...correspondantFormArray,
      { id: uuidv4(), nom_prenoms: "", telephone: "", fonction: "" },
    ]);
  };

  const handleRemoveFormArray = (id: number) => {
    if (correspondantFormArray?.length > 1) {
    }
    let values: any[] = [...correspondantFormArray];
    console.log("form id", id);
    console.log("form array", values);

    values.splice(
      values.findIndex((value: any) => value.id === id),
      1
    );
    setCorrespondantFormArray(values);
  };

  const [lat, setLat] = useState<any>(
    selectedItem?.latitude ? selectedItem.latitude : "5.4119535"
  );
  const [long, setLong] = useState<any>(
    selectedItem?.longitude ? selectedItem.longitude : "-4.1538222"
  );

  const hebergementList: any[] = ["Maison", "Hôtel", "Autre"];

  console.log(
    "selectedItem?.hebergement_chantier",
    selectedItem
  );

  const [ddebut, setDdebut] = useState(
    selectedItem
      ? selectedItem.date_debut
      : moment().format("YYYY-MM-DD HH:m:s")
  );
  const [dfin, setDfin] = useState(
    selectedItem ? selectedItem.date_fin : moment().format("DD-MM-YYYY HH:m:s")
  );

  // const [dortoir, setDortoir] = useState<{ id: any, value: string }>(selectedItem ? {id:selectedItem?.hebergement_chantier , value:selectedItem?.hebergement_chantier} : { id: null, value: '' });;
  // const handleDortoirChange = (event: any, value: { id: any, value: string } | null) => {
  //     console.log(value);
  //     if (value === null) {
  //         setDortoir({ id: null, value: '' });
  //     } else {
  //         setDortoir(value as { id: any, value: string });
  //     }
  // };

  const [dortoir, setDortoir] = useState<any[]>(
    selectedItem
      ? selectedItem.hebergement_chantier.split(",")
      : ['Hôtel']
  );
  const handleDortoirChange = (event: SelectChangeEvent<any>) => {

    setDortoir(event.target.value);
    console.log("SelectChangeEvent", event.target.value);
  };

  const validationSchema = yup.object({
    nom: yup.string().required(" Le nom du chantier est obligatoire "),
    numero_chantier: yup
      .string()
      .required(" Le numero du chantier est obligatoire "),
    date_debut: yup.string().required(" La date de debut est obligatoire "),
    // date_fin: yup.string().required(" La date de fin est obligatoire "),
    type_location: yup
      .string()
      .required(" Le type de location est obligatoire "),
    // hebergement_chantier: yup
    //   .string()
    //   .required(" L'hebergement doit etre renseigner "),
    cuve_caburant: yup.string().required(" Precisez si il y a une cuve ou pas"),
  });

  const formik = useFormik({
    initialValues: {
      id: selectedItem ? selectedItem.id : null,
      nom: selectedItem ? selectedItem.nom : "",
      numero_chantier: selectedItem ? selectedItem.numero_chantier : "",
      date_debut: ddebut,
      date_fin: dfin,
      type_location: "",
      localisation: selectedItem ? selectedItem.localisation : "",
      longitude: selectedItem ? selectedItem.longitude : "-4.1538222,17",
      latitude: selectedItem ? selectedItem.latitude : "5.4119535",
      utilisateurId: chefChantier.id,
      chefChantierId: chefChantier.id,
      id_client:`${client.id}`,
      clientId: `${client.id}`,
      correspondant: selectedItem
        ? selectedItem.correspondant_chantiers
        : correspondantFormArray,
      cuve_caburant: selectedItem ? selectedItem.cuve_caburant : false,
      quantite_caburant: selectedItem ? selectedItem.quantite_caburant : null,
      container: selectedItem ? selectedItem.container : false,
      gardiennage_vehicules: selectedItem
        ? selectedItem.gardiennage_vehicules
        : false,
      hebergement_chantier: '',

      but_location: selectedItem ? selectedItem.but_location : null,
      ops: userConnected?.user?.nom + " " + userConnected?.user?.prenoms,
    },
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      
      values.longitude = values.longitude ? values.longitude : "-4.1538222";
      values.latitude = values.latitude ? values.latitude : "5.4119535";
      values.hebergement_chantier = dortoir.toString();

      console.log("data dortoir", dortoir.toString());
      console.log("data", values);

      if (!selectedItem) {
        delete values.id;
        createChantierAsync(values);
      } else {
        updateChantierAsync(values);
      }
      closeModal();
    },
  });

  let latLong: any;
  const LocationFinderDummy = () => {
    useMapEvents({
      click(e: any) {
        console.log(e);
        if (e.latlng) {
          latLong = e.latlng;
          confirmSetLatLong();
        }
      },
    });
    return null;
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloseMap = () => {
    handleClose();
  };

  const confirmSetLatLong = () => {
    Swal.fire({
      title: "Avis",
      text: "Vous êtes sur le point de paramétrer la lattitude et la longitude, desirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Parametrer ",
      cancelButtonText: "Annuler",
      confirmButtonColor: "#ffa500",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setLat(latLong.lat.toFixed(9));
        setLong(latLong.lng.toFixed(9));

        handleClose();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        handleCloseMap();
      }
    });
  };

  return (
    <form className="create-list-form" onSubmit={formik.handleSubmit}>
      <span className="title">
        {" "}
        {selectedItem
          ? "Modification d'un chantier"
          : "Création d'un chantier"}{" "}
      </span>

      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Autocomplete
            id="id-client-select"
            options={clientList}
            value={client}
            onChange={handleClientChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(option) => option.value}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder="Client"
                label="Liste des clients"
                variant="outlined"
                name="clientId"
                onChange={formik.handleChange}
                value={(formik.values.clientId = client.id)}
                error={
                  formik.touched.clientId && Boolean(formik.errors.clientId)
                }
                // helperText={formik.touched.clientId && formik.errors.clientId}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Nom du chantier"
            variant="outlined"
            name="nom"
            label="Nom du chantier"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.nom}
            error={formik.touched.nom && Boolean(formik.errors.nom)}
            // helperText={formik.touched.nom && formik.errors.nom}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Numéro chantier"
            variant="outlined"
            name="numero_chantier"
            label="Numéro chantier"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.numero_chantier}
            error={
              formik.touched.numero_chantier &&
              Boolean(formik.errors.numero_chantier)
            }
            // helperText={formik.touched.nom && formik.errors.nom}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DatePicker
              label="Date de début"
              value={ddebut}
              onChange={(newValue: any) => {
                setDdebut(newValue);
                formik.setFieldValue(
                  "date_debut",
                  newValue.format("YYYY-MM-DD HH:mm:ss")
                );
              }}
              renderInput={(params) => (
                <TextField {...params} className="my-text-field" />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DatePicker
              label="Date de fin"
              value={dfin}
              onChange={(newValue: any) => {
                setDfin(newValue);

                formik.setFieldValue(
                  "date_fin",
                  newValue.format("YYYY-MM-DD HH:mm:ss")
                );
              }}
              renderInput={(params) => (
                <TextField {...params} className="my-text-field" />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Autocomplete
            id="id-chef-chantier-select"
            options={chefChantierList}
            value={chefChantier}
            onChange={handleChefChantierChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value
            }
            getOptionLabel={(option) => option?.value}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder="Chef Chantier"
                label="Chef Chantier"
                variant="outlined"
                name="utilisateurId"
                onChange={formik.handleChange}
                value={(formik.values.utilisateurId = chefChantier.id)}
                error={
                  formik.touched.utilisateurId &&
                  Boolean(formik.errors.utilisateurId)
                }
                // helperText={formik.touched.chefChantierId && formik.errors.chefChantierId}
              />
            )}
          />
        </Grid>
      </Grid>

      <span className="sub-title-part">
        {" "}
        <InlineIcon icon="bx:map" /> &nbsp; Localisation du Chantier{" "}
      </span>
      <Divider className="custom-divider" />

      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="localisation du chantier"
            variant="outlined"
            name="localisation"
            label="Localisation"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.localisation}
            error={
              formik.touched.localisation && Boolean(formik.errors.localisation)
            }
            // helperText={formik.touched.localisation && formik.errors.localisation}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <Button
            color="light"
            className="add-target-btn"
            style={{ margin: 0 }}
            startIcon={<InlineIcon icon="mdi:map-legend" />}
            onClick={handleOpen}
            variant="contained"
          >
            {" "}
            Geolocalisation
          </Button>
          {/* map start */}

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                component="div"
                id="modal-modal-description"
                sx={{ position: "relative", width: "100%", height: "100%" }}
              >
                <Button
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    width: 20,
                    height: 30,
                    color: "#000",
                    zIndex: 1000,
                    border: "1px solid #000",
                    borderRadius: "3px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleCloseMap}
                >
                  <strong> x </strong>
                </Button>
                <MapContainer
                  style={{ height: "100%" }}
                  center={[5.316667, -4.033333]}
                  zoom={10}
                  minZoom={7}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <LocationFinderDummy />
                </MapContainer>
              </Typography>
            </Box>
          </Modal>

          {/* map end  */}
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Longitude"
            variant="outlined"
            name="longitude"
            label="Longitude"
            className="my-text-field"
            onChange={formik.handleChange}
            value={(formik.values.longitude = long)}
            focused={long || formik.values.longitude ? true : false}
            // error={formik.touched.longitude && Boolean(formik.errors.longitude)}
            // helperText={formik.touched.longitude && formik.errors.longitude}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Latitude"
            variant="outlined"
            name="latitude"
            label="Latitude"
            className="my-text-field"
            onChange={formik.handleChange}
            value={(formik.values.latitude = lat)}
            focused={lat || formik.values.latitude ? true : false}
            // error={formik.touched.latitude && Boolean(formik.errors.latitude)}
            // helperText={formik.touched.latitude && formik.errors.latitude}
            type="text"
          />
        </Grid>
      </Grid>

      <span className="sub-title-part">
        {" "}
        <InlineIcon icon="bx:user" /> &nbsp; Critères chantier{" "}
      </span>
      <Divider className="custom-divider" />

      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <Autocomplete
            id="id-service-select"
            options={typeLocationList}
            value={typeLocation}
            onChange={handleTypeLocationChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value
            }
            getOptionLabel={(option) => option?.value}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder="Activités"
                label="Activités"
                variant="outlined"
                name="type_location"
                onChange={formik.handleChange}
                value={(formik.values.type_location = typeLocation?.value)}
                error={
                  formik.touched.type_location &&
                  Boolean(formik.errors.type_location)
                }
                // helperText={formik.touched.dr && formik.errors.dr}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Objectif location"
            variant="outlined"
            name="but_location"
            label="Objectif"
            className="my-text-field"
            onChange={formik.handleChange}
            value={
              formik.values.but_location ? formik.values.but_location : null
            }
            error={
              formik.touched.but_location && Boolean(formik.errors.but_location)
            }
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <div className="switch-container">
            <Typography>Cuve à carburant </Typography>
            <FormGroup className="my-toggle">
              <FormControlLabel
                label={formik.values.cuve_caburant ? "Oui" : "Non"}
                control={
                  <Switch
                    name="cuve_caburant"
                    checked={formik.values.cuve_caburant}
                    onChange={formik.handleChange}
                  />
                }
              />
            </FormGroup>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Quantité caburant"
            variant="outlined"
            name="quantite_caburant"
            label="Quantité caburant"
            className="my-text-field"
            onChange={formik.handleChange}
            disabled={formik.values.cuve_caburant ? false : true}
            value={
              formik.values.cuve_caburant
                ? formik.values.quantite_caburant
                : null
            }
            error={
              formik.touched.quantite_caburant &&
              Boolean(formik.errors.quantite_caburant)
            }
            // helperText={formik.touched.nombre_gardien && formik.errors.nombre_gardien}
            type="number"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <div className="switch-container">
            <Typography>Container </Typography>
            <FormGroup className="my-toggle">
              <FormControlLabel
                label={formik.values.container ? "Oui" : "Non"}
                control={
                  <Switch
                    name="container"
                    checked={formik.values.container}
                    onChange={formik.handleChange}
                  />
                }
              />
            </FormGroup>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          {/* <Autocomplete
                        id="id-service-select"
                        options={hebergementList}
                        value={dortoir}
                        onChange={handleDortoirChange}
                        isOptionEqualToValue={(option, value) => dortoir.some((item) => item.id === option.id && item.value === option.value)}
                        autoHighlight
                     //   isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.value}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.value}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='my-text-field'
                                placeholder="Hebergement chantier"
                                label="Hebergement chantier"
                                variant="outlined"
                                name="hebergement_chantier"
                                onChange={formik.handleChange}
                                value={formik.values.hebergement_chantier = dortoir}
                                error={formik.touched.hebergement_chantier && Boolean(formik.errors.hebergement_chantier)}
                                // helperText={formik.touched.dortoir && formik.errors.dortoir}
                            />
                        )}
                        multiple /> */}

          <FormControl fullWidth>
            <InputLabel id="id-dortoir" htmlFor="dortoir" sx={{position: "absolute !important", top: '-5px !important', fontSize: '0.8rem'}}>
              Herbergement
            </InputLabel>
            <Select
              multiple
              size="small"
              labelId="id-hebergement_chantier"
              name="hebergement_chantier"
              value={dortoir}
              onChange={handleDortoirChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5}}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} sx={{height: '20px'}} />
                  ))}
                </Box>
              )}
            //   MenuProps={MenuProps}
            >
              {hebergementList.map((value) => (
                <MenuItem
                  key={value}
                  value={value}
                >
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <div className="switch-container">
            <Typography>Gardiennage véhicules </Typography>
            <FormGroup className="my-toggle">
              <FormControlLabel
                label={formik.values.gardiennage_vehicules ? "Oui" : "Non"}
                control={
                  <Switch
                    name="gardiennage_vehicules"
                    checked={formik.values.gardiennage_vehicules}
                    onChange={formik.handleChange}
                  />
                }
              />
            </FormGroup>
          </div>
        </Grid>
      </Grid>

      <span className="sub-title-part">
        {" "}
        <InlineIcon icon="bx:map-pin" /> &nbsp; Correspondant client du chantier
      </span>
      <Divider className="custom-divider" />

      <>
        <Grid container rowSpacing={2} columnSpacing={2}>
          {correspondantFormArray.map((m: any) => (
            <Fragment key={m.id}>
              <Grid item xs={12} sm={12} lg={12} xl={11}>
                <Grid container rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={12} sm={12} lg={12} xl={4}>
                    <TextField
                      placeholder="Nom & prènoms"
                      value={m.nom_prenoms}
                      onChange={(e: any) => {
                        handleChangeFormArray(m.id, e);
                      }}
                      variant="outlined"
                      name="nom_prenoms"
                      label="Nom & prènoms"
                      className="my-text-field"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} xl={4}>
                    <TextField
                      placeholder="Téléphone"
                      value={m.telephone}
                      onChange={(e: any) => {
                        handleChangeFormArray(m.id, e);
                      }}
                      variant="outlined"
                      name="telephone"
                      label="Téléphone"
                      className="my-text-field"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} xl={4}>
                    <TextField
                      placeholder="Fonction"
                      value={m.fonction}
                      onChange={(e: any) => {
                        handleChangeFormArray(m.id, e);
                      }}
                      variant="outlined"
                      name="fonction"
                      label="Fonction"
                      className="my-text-field"
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} xl={1}>
                <IconButton
                  onClick={() => {
                    handleRemoveFormArray(m.id);
                  }}
                  style={{ transform: "translateY(5px)" }}
                  disabled={correspondantFormArray?.length > 1 ? false : true}
                  size="small"
                  color="error"
                  aria-label="delete"
                >
                  <InlineIcon icon="clarity:remove-line" />
                </IconButton>
              </Grid>
            </Fragment>
          ))}

          <Button
            onClick={handleAddFormArray}
            className="add-target-btn"
            startIcon={<InlineIcon icon="carbon:add" />}
            color="secondary"
          >
            Ajouter un correspondant
          </Button>
        </Grid>
      </>

      <div className="btn-part">
        <Button
          color="secondary"
          className="btn-option"
          type="submit"
          startIcon={
            <InlineIcon
              icon={
                selectedItem
                  ? "iconoir:edit-pencil"
                  : "iconoir:save-floppy-disk"
              }
            />
          }
          variant="contained"
        >
          {selectedItem ? "Modifier" : "Créer"}
        </Button>

        <Button
          color="light"
          className="btn-option"
          startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
          onClick={() => {
            formik.resetForm();
          }}
          variant="contained"
        >
          {"Réinitialiser"}
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = createStructuredSelector({
  getClientList: selectClientItemList,
  getData: selectTableDonneeItemList,
  getUserList: selectUserItemList,
  userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  getClientListAsync: () => dispatch(getAllClientsAsync()),
  getAllTableDonneesAsync: () => dispatch(getAllTableDonneesAsync()),
  getUserListAsync: () => dispatch(getAllUsersAsync()),
  createChantierAsync: (data: any) => dispatch(createChantierAsync(data)),
  updateChantierAsync: (data: any) => dispatch(updateChantierAsync(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const CreateChantierForm = connector(CreateChantierFormPage);

export default CreateChantierForm;
