import React from 'react';
import CtAdministration from '../pages-content/Administration-content/Administration.content';

const Administration:React.FC = () => {
  return (
    <>
      <CtAdministration />
    </>
  )
}

export default Administration;