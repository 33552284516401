import React from 'react';
import CtAffectationsCampaign from '../pages-content/Affectations-content/Affectations.content';

const Affectations:React.FC = () => {
  return (
    <>
      <CtAffectationsCampaign />
    </>
  )
}

export default Affectations;