import React, { useState } from "react";
import "./Header.container.styles.scss";
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import { routePaths } from "@/config";
import logo from "@/assets/images/png/logo.png";
import { InlineIcon } from "@iconify/react";

//redux part
import { logout } from "@/store/reducers/auth/auth.action";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import CenteredModal from "../../pages-content/Administration-content/components/CenteredModal/CenteredModal";
import EditProfilForm from "../../pages-content/Administration-content/Forms/EditProfilForm/EditProfilForm";
import { selectAuthUser } from "@/store/reducers/auth/auth.selector";
import ChangePasswordForm from "../../pages-content/Administration-content/Forms/ChangePasswordForm/ChangePasswordForm";
import { selectAlerteCountList } from "@/store/reducers/alerte/alerte.selector";
import { selectUserProfil } from "@/store/reducers/profil/profil.selector";

const HeaderCIEPage: React.FC<any> = ({ userConnected, alerte, permissions }) => {

  const [openLogout, setOpenLogout] = useState(false);
  const handleClickOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);

  const theme = useTheme();
  const matches_down_lg = useMediaQuery(theme.breakpoints.down("lg"));
  const matches_down_md = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  const location = useLocation();

  const getOut = () => {

    dispatch(logout());
    window.location.reload()

  };

  const [isPasswordEdit, setIsPasswordEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      <div className="cie-header">
        <div className="top-part">
          <img src={logo} alt="logo" className="logo" />
          <div className={classNames("infos-user")} onClick={handleClick}>
            {!matches_down_md && (
              <>
                <div className="text">
                  <span className="fullname"> {userConnected?.user?.nom + ' ' + userConnected?.user?.prenoms} </span>
                  <span className="role"> {userConnected?.user?.profil?.libelle} </span>
                </div>
              </>
            )}
            <div className="avatar-part">
              <InlineIcon icon="carbon:user-avatar-filled-alt" />
            </div>
            {!matches_down_lg ? (
              <div className="icon-end">
                <InlineIcon icon="akar-icons:chevron-down" />
              </div>
            ) : (
              <div className="icon-end" style={{ transform: "scale(2)" }}>
                <InlineIcon icon="heroicons-solid:menu-alt-3" />
              </div>
            )}
          </div>
        </div>
        {!matches_down_lg && (
          <>
            <div className="nav-part">
            <NavLink
                    to={routePaths.adminIVEQIChildren.mapAbsolute}
                    className={
                      "nav-link" +
                      (location.pathname.indexOf(
                        routePaths.adminIVEQIChildren.mapAbsolute
                      ) !== -1
                        ? " selected"
                        : "")
                    }
                  >
                    <InlineIcon icon="bi:pin-map" />
                    Carte
                  </NavLink>
              {
                (permissions?.accueil?.filter((a: any) => (a.create || a.read || a.edit || a.delete))?.length > 0) &&
                <>
                  {/* {false && ( */}
                    <NavLink
                      to={routePaths.adminIVEQIChildren.dashboardAbsolute}
                      className={
                        "nav-link" +
                        (location.pathname.indexOf(
                          routePaths.adminIVEQIChildren.dashboardAbsolute
                        ) !== -1
                          ? " selected"
                          : "")
                      }
                    >
                      <InlineIcon icon="akar-icons:dashboard" />
                      Tableau de bord
                    </NavLink>
                  {/* )} */}
                </>
              }
                  

                  {/* <NavLink
                    to={routePaths.adminIVEQIChildren.dashboardAbsolute}
                    className={
                      "nav-link" +
                      (location.pathname.indexOf(
                        routePaths.adminIVEQIChildren.dashboardAbsolute
                      ) !== -1
                        ? " selected"
                        : "")
                    }
                  >
                    <InlineIcon icon="bi:pin-map" />
                    Tableau board
                  </NavLink> */}
                
              

              {
                (permissions?.chantier?.filter((a: any) => (a.create || a.read || a.edit || a.delete))?.length > 0) &&
                <>
                  <NavLink
                    to={routePaths.adminIVEQIChildren.campaignAbsolute}
                    className={
                      "nav-link" +
                      (location.pathname.indexOf(
                        routePaths.adminIVEQIChildren.campaignAbsolute
                      ) !== -1
                        ? " selected"
                        : "")
                    }
                  >
                    <InlineIcon icon="bi:tools" />
                    <Badge badgeContent={(alerte?.fin_chantier + alerte?.incident) || 0} color="warning">
                      Gestion des chantiers
                    </Badge>
                  </NavLink>
                </>
              }

              {
                (permissions?.rh?.filter((a: any) => (a.create || a.read || a.edit || a.delete))?.length > 0) &&
                <>
                  <NavLink
                    to={routePaths.adminIVEQIChildren.rhAbsolute}
                    className={
                      "nav-link" +
                      (location.pathname.indexOf(
                        routePaths.adminIVEQIChildren.rhAbsolute
                      ) !== -1
                        ? " selected"
                        : "")
                    }
                  >
                    <InlineIcon icon="bi:people" />
                    <Badge badgeContent={(alerte?.personnel) || 0} color="warning">
                      Gestion des Rh
                    </Badge>
                  </NavLink>
                </>
              }

              {
                (permissions?.parametre?.filter((a: any) => (a.create || a.read || a.edit || a.delete))?.length > 0) &&
                <>
                  <NavLink
                    to={routePaths.adminIVEQIChildren.administrationAbsolute}
                    className={
                      "nav-link" +
                      (location.pathname.indexOf(
                        routePaths.adminIVEQIChildren.administrationAbsolute
                      ) !== -1
                        ? " selected"
                        : "")
                    }
                  >
                    <InlineIcon icon="icon-park-solid:database-setting" />
                    <Badge badgeContent={(alerte?.vidange + alerte?.cuve) || 0} color="warning">
                      Paramètres
                    </Badge>
                  </NavLink>
                </>
              }

            </div>
          </>
        )}
      </div>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {matches_down_md && (
          <MenuItem>
            <div className="menu-text">
              <span className="fullname"> {userConnected?.user?.nom + ' ' + userConnected?.user?.prenoms} </span>
              <span className="role"> {userConnected?.user?.profil?.libelle}</span>
            </div>
          </MenuItem>
        )}
        {matches_down_md && <Divider />}

        {
          (permissions?.accueil?.filter((a: any) => (a.create || a.read || a.edit || a.delete))?.length > 0) &&
          <div>
            {matches_down_lg && (
              <MenuItem>
                <NavLink
                  to={routePaths.adminIVEQIChildren.dashboardAbsolute}
                  className={
                    "menu-cie-nav-link" +
                    (location.pathname.indexOf(
                      routePaths.adminIVEQIChildren.dashboardAbsolute
                    ) !== -1
                      ? " selected"
                      : "")
                  }
                >
                  <InlineIcon icon="akar-icons:dashboard" />
                  Tableau de bord
                </NavLink>
              </MenuItem>
            )}
          </div>
        }
            {matches_down_lg && (
              <MenuItem>
                <NavLink
                  to={routePaths.adminIVEQIChildren.mapAbsolute}
                  className={
                    "menu-cie-nav-link" +
                    (location.pathname.indexOf(
                      routePaths.adminIVEQIChildren.mapAbsolute
                    ) !== -1
                      ? " selected"
                      : "")
                  }
                >
                  <InlineIcon icon="bi:pin-map" />
                  Carte
                </NavLink>
              </MenuItem>
            )}
          

        {
          (permissions?.chantier?.filter((a: any) => (a.create || a.read || a.edit || a.delete))?.length > 0) &&
          <>
            {matches_down_lg && (
              <MenuItem>
                <NavLink
                  to={routePaths.adminIVEQIChildren.campaignAbsolute}
                  className={
                    "menu-cie-nav-link" +
                    (location.pathname.indexOf(
                      routePaths.adminIVEQIChildren.campaignAbsolute
                    ) !== -1
                      ? " selected"
                      : "")
                  }
                >
                  <InlineIcon icon="bi:tools" />
                  Gestion des chantiers
                </NavLink>
              </MenuItem>
            )}
          </>
        }

        {
          (permissions?.rh?.filter((a: any) => (a.create || a.read || a.edit || a.delete))?.length > 0) &&
          <div>
            {matches_down_lg && (
              <MenuItem>
                <NavLink
                  to={routePaths.adminIVEQIChildren.rhAbsolute}
                  className={
                    "menu-cie-nav-link" +
                    (location.pathname.indexOf(
                      routePaths.adminIVEQIChildren.rhAbsolute
                    ) !== -1
                      ? " selected"
                      : "")
                  }
                >
                  <InlineIcon icon="bi:people" />
                  Gestion des Rh
                </NavLink>
              </MenuItem>
            )}
          </div>
        }

        {
          (permissions?.parametre?.filter((a: any) => (a.create || a.read || a.edit || a.delete))?.length > 0) &&
          <div>
            {matches_down_lg && (
              <MenuItem>
                <NavLink
                  to={routePaths.adminIVEQIChildren.administrationAbsolute}
                  className={
                    "menu-cie-nav-link" +
                    (location.pathname.indexOf(
                      routePaths.adminIVEQIChildren.administrationAbsolute
                    ) !== -1
                      ? " selected"
                      : "")
                  }
                >
                  <InlineIcon icon="icon-park-solid:database-setting" />
                  Paramètres
                </NavLink>
              </MenuItem>
            )}
          </div>
        }

        {matches_down_lg && <Divider />}
        <MenuItem onClick={() => {
          handleOpenModal();
          setIsPasswordEdit(false);
        }}>
          <ListItemIcon>
            <InlineIcon icon="fluent:window-multiple-swap-20-filled" className="from-menu" />
          </ListItemIcon>
          Profil
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => {
          handleOpenModal();
          setIsPasswordEdit(true);
        }}>
          <ListItemIcon>
            <InlineIcon icon="material-symbols:keyboard" className="from-menu" />
          </ListItemIcon>
          Changer de mot de passe
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClickOpenLogout}>
          <ListItemIcon>
            <InlineIcon icon="charm:power" className="from-menu" />
          </ListItemIcon>
          Déconnexion
        </MenuItem>
      </Menu>

      <Dialog
        open={openLogout}
        onClose={handleCloseLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Déconnexion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point de vous déconnecter. Êtes-vous sûr(e) de
            vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="tertiary" onClick={handleCloseLogout}>
            Annuler
          </Button>
          <Button color="tertiary" onClick={getOut} autoFocus>
            Déconnecter
          </Button>
        </DialogActions>
      </Dialog>


      <CenteredModal
        open={openModal}
        onClose={handleCloseModal}
        closeClick={handleCloseModal}>
        <>
          {
            isPasswordEdit ?
              <ChangePasswordForm />
              :
              <EditProfilForm data={userConnected?.user || {}} />
          }
        </>
      </CenteredModal>
    </>
  );
};

const mapStateToProps: any = createStructuredSelector({
  userConnected: selectAuthUser,
  alerte: selectAlerteCountList,
  permissions: selectUserProfil
});

const connector = connect(mapStateToProps);
const HeaderCIE = connector(HeaderCIEPage);

export default HeaderCIE;
