import { CategorieEquipementService } from "../../../services/categorieEquipement.service";
import { categorieEquipementTypes } from "./categorieEquipement.types";

export const setLoading = (loading:any) => ({
    type: categorieEquipementTypes.LOADING,
    payload: loading,
});

export const getAllCategorieEquipements = (list:any) => ({
    type: categorieEquipementTypes.GET_CATEGORIE_EQUIPEMENT_LIST,
    payload: list,
});

export const selectedItem = (categorieEquipement:any) => ({
  type: categorieEquipementTypes.SELECTED_CATEGORIE_EQUIPEMENT,
  payload: categorieEquipement,
});

 
export const  getAllCategorieEquipementsAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  CategorieEquipementService.getCategorieEquipements();
};

export const  createCategorieEquipementAsync =  (categorieEquipement:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  CategorieEquipementService.createCategorieEquipement(categorieEquipement);
};

export const  updateCategorieEquipementAsync =  (categorieEquipement:any) =>
(dispatch:any) => {
  console.log("categorieEquipement to service", categorieEquipement);
  dispatch(setLoading(true));
  CategorieEquipementService.updateCategorieEquipement(categorieEquipement);
};

export const  deleteCategorieEquipementAsync =  (categorieEquipement:any) =>
(dispatch:any) => {
  console.log("categorieEquipement to service", categorieEquipement);
  dispatch(setLoading(true));
  CategorieEquipementService.deleteCategorieEquipement(categorieEquipement);
};



