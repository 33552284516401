import { routePaths } from "@/config";
import { Routes, Route, Navigate } from "react-router-dom";
import LRh from "../layouts/Rh.content.layout";
import AccueilRh from "../pages/Accueil.page";
import PersonnelRh from "../pages/Personnel.page";
import AffectationRH from "../pages/AffectationRH.page";
import DemandePermissionRh from "../pages/DemandePermission.page";
import CongesRh from "../pages/Conges.page";
import HistoriqueRh from "../pages/Historique.page";

export const CtRhRoutes:React.FC = () => {
    return (
      <Routes>
          <Route path="" element={<LRh />}>
          <Route index element={ <Navigate to={`${routePaths.adminIVEQIChildren.rhChildren.homeAbsolute}`} /> } />
              <Route path={routePaths.adminIVEQIChildren.rhChildren.home} element={ <AccueilRh />} />
              <Route path={routePaths.adminIVEQIChildren.rhChildren.personnels} element={ <PersonnelRh />} />
              <Route path={routePaths.adminIVEQIChildren.rhChildren.AffectationRH} element={ <AffectationRH />} />
              <Route path={routePaths.adminIVEQIChildren.rhChildren.conges} element={ <CongesRh />} />
              <Route path={routePaths.adminIVEQIChildren.rhChildren.demandePermission} element={ <DemandePermissionRh />} />
              <Route path={routePaths.adminIVEQIChildren.rhChildren.historique} element={ <HistoriqueRh />} />
              <Route path="/*" element={ <Navigate to={`${routePaths.adminIVEQIChildren.rhChildren.homeAbsolute}`} /> } />
          </Route>
      </Routes>
    );
};