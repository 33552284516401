import { InlineIcon } from '@iconify/react';
import { AddCircleOutline, Build, NoAccounts } from '@mui/icons-material';
import { Autocomplete, Stack, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SideModal from '../../components/SideModal/SideModal';
import './Affectations.content.style.scss';
import enginIcon from '@/assets/images/png/Airpod.png';
import { selectChantierItemList, selectEnginChantierList, selectLoading } from '@/store/reducers/chantier/chantier.selector';
import { createStructuredSelector } from 'reselect';
import { getAllChantiersAsync } from '@/store/reducers/chantier/chantier.action';
import { connect } from 'react-redux';
import { DataGrid, GridValueGetterParams } from '@mui/x-data-grid';
import { DialogComp } from '@/components/Dialog/Dialog';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
// import { NumberValidator } from '@/utils/validators';
import { getAllAffectedEnginsByChantierAsync, createEnginAffectationAsync, callbackEnginAffectatedAsync, getDetailAboutEnginAffectatedAsync } from '@/store/reducers/affectation/affectation.action';
import { detailAboutEnginAffectation, selectAffectedEnginByChantierItemList } from '@/store/reducers/affectation/affectation.selector';
import { getAllEnginsAsync } from '@/store/reducers/engin/engin.action';
import { selectEnginDetailLoading, selectEnginItemList } from '@/store/reducers/engin/engin.selector';
import { format } from 'date-fns';
import moment from 'moment';
import { getAllTableDonneesAsync } from '@/store/reducers/table-donnee/tableDonnee.action';
import { selectTableDonneeItemList } from '@/store/reducers/table-donnee/tableDonnee.selector';
import { selectUserProfil } from '@/store/reducers/profil/profil.selector';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { fr } from "date-fns/locale";
import CreateUtilisationForm from '../../Forms/CreateUtilisationForm/CreateUtilisationForm';
import EditEnginAffectedForm from '../../Forms/EditEnginAffectedForm/EditEnginAffectedForm';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';

moment.locale('fr');


const CtAffectationsCampaignPage: React.FC<any> = ({
  loading,
  loadingEngin,
  chantierList,
  getAllChantiersAsync,
  getAllAffectedEnginsByChantierAsync,
  affectedEnginOnSelectedChantierList,
  getAllEnginsAsync,
  enginList,
  getAllTableDonneesAsync,
  getData,
  createEnginAffectationAsync,
  callbackEnginAffectatedAsync,
  getDetailAboutEnginAffectatedAsync,
  enginDetail,
  permissions,
  userConnected
}) => {


  const [dateAffect, setDateAffect] = useState(new Date().toISOString().split('T')[0]);

  const handleDate = (newValue:any) => {
    setDateAffect(newValue);
  };

  const [selection, setSelection] = useState<any>({});
  const [settingData, setSettingData] = useState<any>({});
  const [rowSelected, setRowSelected] = useState<any>({});
  const [isEditAffectedRow, setIsEditAffectedRow] = useState<any>(false);


  const [openMotif, setOpenMotif] = React.useState(false);
  const [selectedRowData, setSelectedRowData] =  useState<any>(null);

  const [openUse, setOpenUse] = useState(false);
  const handleOpenUse = () => {setOpenUse(true);}
  const handleCloseUse = () => {setOpenUse(false); setIsEditAffectedRow(false)}


  const handleClickOpenMotif = (rowParams?: any) => {
    console.log('handleClickOpenMotif', rowParams);
    setSelectedRowData(rowParams.row); // Stockez les données de la ligne sélectionnée
    setOpenMotif(true);
  };

  const handleCloseMotif = () => {
    setDialogData({
      motif_retour: '',
      date_retour: dateAffect,
      observation: '',
      date_compteur_retour: "",
      compteur_retour: ""
    });
    setOpenMotif(false);
    setSelectedRowData(null); // Réinitialisez l'état

  };

  // État local pour gérer les informations du dialogue
  const [dialogData, setDialogData] = useState({
    motif_retour: '',
    date_retour: new Date().toISOString().split('T')[0],
    observation: '',
    date_compteur_retour:"",
    compteur_retour:""
  });

  // Fonction pour mettre à jour l'état du dialogue lors de la saisie
  const handleChangeDialogData = (event: any) => {
    const { name, value } = event.target;
    setDialogData((prevData) => ({ ...prevData, [name]: value }));
  };

  const columns:any[] = [
    {
      field: 'libelle',
      headerName: 'Libellé',
      width: 270,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.libelle + ' ' + params.row.num_parc
    },
    { field: 'quantite_a_realiser', headerName: 'Q à realiser',
      sortable: false,
      width: 100,
      valueGetter: (params: GridValueGetterParams) =>
      params?.row?.item?.quantite_a_realiser
    },
    { field: 'quantite_journalier', headerName: 'Q Journalier',
      sortable: false,
      width: 100,
      valueGetter: (params: GridValueGetterParams) =>
      params?.row?.item?.quantite_journalier
    },
    { field: 'date_chantier', headerName: 'Date affect',
      sortable: false,
      width: 100,
      valueGetter: (params: GridValueGetterParams) =>
      moment(params?.row?.item?.date_chantier).format('L')
    },
    { field: "date_retour",
    headerName: "Date retour",
    sortable: false,
    renderCell: (params:any) => {
      const onClick = (e:any) => {
        e.stopPropagation(); // don't select this row after clicking
        console.log('params',params?.row);
      };

      return <>
      {
        !params?.row?.item?.motif_retour ?
        <button className='badge' style={{backgroundColor: 'lightblue', color:'black', marginLeft: 3}} onClick={onClick}>Sur chantier</button>
        :
        <small style={{ marginLeft: 3}}> {moment(params?.row?.item?.date_retour).format('L')} </small>
      }
      </>;
    }, width: 150 
  },
    { field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params:any) => {
        const handleCallback = (e:any) => {

          if (selectedRowData) {
            // Utilisez ici selectedRowData pour vos traitements
            // Par exemple : console.log(selectedRowData.id);

            e.stopPropagation(); // don't select this row after clicking
            console.log('selectedRowData',selectedRowData);
            console.log('params',params);
            console.log('params',dialogData);
            let data = {
              "id":selectedRowData.id,
              "motif_retour": dialogData.motif_retour,
              "date_retour":new Date().toISOString().split('T')[0],
              "observation":dialogData.observation,
              "date_compteur_retour":dialogData.date_compteur_retour,
              "compteur_retour":dialogData.compteur_retour,
              "ops":params?.row?.item?.ops
            }
            console.log('handleCallback',data);
            callbackEnginAffectatedAsync(data);
            getAllAffectedEnginsByChantierAsync(selectedChantier.id);
               // Fermer le dialogue
            setOpenMotif(false);
            setSelectedRowData(null); // Réinitialisez l'état
            setDialogData({
              motif_retour: '',
              date_retour: dateAffect,
              observation: '',
              date_compteur_retour: "",
              compteur_retour: ""
            });
          }

         
          
        };

        const openModal = (e: any) => {
          console.log("params", params);
          e.stopPropagation(); // don't select this row after clicking
          let data = {
            chantierEnginId: params?.row?.item?.id,
            enginId: params?.row?.item?.enginId,
            //"enginId": params?.row?.item?.enginId,
          };
          setSettingData(data);
          getDetailAboutEnginAffectatedAsync(data);
        };


        
        return <>
        <button className='badge' style={{backgroundColor: '#aaa', marginRight: 3}} onClick={()=>{setIsEditAffectedRow(true); setOpenUse(true)}}>Modifier</button>
        {
          (!params?.row?.item?.date_retour && permissions?.chantier?.find((o: any) => (o.libelle === 'Affectation' && o.edit === true))) && <button className='badge' onClick={() => handleClickOpenMotif(params)}>Rappeler</button>

        }
        <button className='badge' style={{backgroundColor: '#111', marginLeft: 3}} onClick={openModal}>Historique</button>

        <Dialog open={openMotif} onClose={handleCloseMotif}>
          <DialogTitle>Motif de retour</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Les Observations concernant le retour de l'engin
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="motif"
              name="motif_retour"
              label="Motif"
              fullWidth
              variant="standard"
              value={dialogData.motif_retour}
              onChange={handleChangeDialogData}
            />
            <TextField
              autoFocus
              margin="dense"
              id="observation"
              name="observation"
              label="Observation"
              fullWidth
              variant="standard"
              value={dialogData.observation}
              onChange={handleChangeDialogData}
            />
            <TextField
              autoFocus
              margin="dense"
              id="date_compteur_retour"
              name="date_compteur_retour"
              label="Date Compteur Retour"
              fullWidth
              variant="standard"
              type="date"
              value={dialogData.date_compteur_retour}
              onChange={handleChangeDialogData}
            />
             <TextField
              autoFocus
              margin="dense"
              id="compteur_retour"
              name='compteur_retour'
              label="Compteur Retour"
              fullWidth
              variant="standard"
              type="number"
              value={dialogData.compteur_retour}
            onChange={handleChangeDialogData}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMotif}>Annuler</Button>
            <Button onClick={handleCallback}>Rappeler</Button>
          </DialogActions>
        </Dialog>
        </>;
      }, width: 250 
    },
  ];
  
  useEffect(() => {
    const url:any = getAllChantiersAsync();
    const url2:any = getAllTableDonneesAsync();

    const fetchData = async () => {
        try {
            await fetch(url);
            // await fetch(url1);
            await fetch(url2);
        } catch (error) {
            console.log("error", error);
        }
    };
    fetchData();
    
  }, [getAllChantiersAsync,getAllTableDonneesAsync]);

  const [searchText, setSearchText] = useState('');

  const [selectedChantier, setSelectedChantier] = useState<any>({ id: '', value: '' });
  const listEnginChantierSelect: any[] = (selectedChantier.id) ?  affectedEnginOnSelectedChantierList.map((o:any) => {
    return {...o.engin, item: o,id: o.id}
  }) : [];

  const listEngins:any[] = [];
  enginList.forEach((x:any) => {
    if (!x.chantierId || x.chantierId === null || x.chantierId === 0) {
      listEngins.push({
        ...x,
        chantierEnginId: x.id,
        numero_serie : x?.description,
      //  searchText: `${x.libelle }#${x.categorie_engin.libelle}#${x.numero_serie}`, 
        searchText: x.libelle + ' '+ x.categorie_engin.libelle + ' '+ x.num_parc
      })
    }
  });
  const [selectedEnginId, setSelectedEnginId] = useState<number | null>();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const [filterChipID, setFilterChipID] = useState<number>(1);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const validationSchema = yup.object({
    // quantite_a_realiser: NumberValidator(),
    // quantite_journalier: NumberValidator(),
    // unite: yup.string().required(" L'unité de la location est obligatoire "),
  });

  const formik = useFormik  ({
    initialValues: {
      quantite_a_realiser: 0,
      quantite_journalier: 0,
      date_chantier: dateAffect,
      unite: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
        console.log('Values form', values);
        if (formik.isValid) {
          const formValues : any = {
            ...formik.values,
            date_chantier: dayjs(formik.values.date_chantier).format('YYYY/MM/DD'),
            chantierId: selectedChantier.id,
            enginId: selectedEnginId,
            ops: userConnected?.user?.nom + " " + userConnected?.user?.prenoms,
          };
          console.log('formvalues', formValues);
          createEnginAffectationAsync(formValues)
          setSelectedEnginId(null);
          setIsDialogOpen(false);
          getAllAffectedEnginsByChantierAsync(selectedChantier.id);
          setOpen(false);
        }
    }
  });

  const ListAllChantiers = chantierList.map((x:any) => {
    return {
      value: x.nom,
      ...x
    }
  })

  function handleChantierChange(event: any, value: { id: string; value: string; } | null) {
    console.log(value);
    if (value === null) {
      setSelectedChantier({ id: '', value: '' });
    } else {
      setSelectedChantier(value as { id: string; value: string; });
    }
  }

  const getChantierInfos = async () => {
    console.log("chantier selected", selectedChantier);
    if (!selectedChantier.id) {
      return;
    } else {
      getAllAffectedEnginsByChantierAsync(selectedChantier.id);
    }
    
  }

  const typeLocationList: any[] = [];
  getData.forEach((x:any) => {
      if (x?.type === "TYPE LOCATION") {
          typeLocationList.push({id: x.id , value: x.libelle});
      } else { 
          
      }
  }); 
  
  const uniteList: { id: any, value: string }[] = [
    { id: 'Heure', value: 'Heure' },
    { id: 'Ml', value: 'Ml' },
    { id: 'Hectare', value: 'Hectare' },
    { id: 'Tone', value: 'Tonne' },
  ];
  const [unite, setUnite] = useState<{ id: any, value: string }>(  { id: null, value: '' });
  const handleUniteChange = (event: any, value: { id: any, value: string } | null) => {
      console.log(value);
      if (value === null) {
        setUnite({ id: null, value: '' });
      } else {
        setUnite(value as { id: any, value: string });
      }
  };

 

  // const getNumSerie = (str: string) => {
  //   const getFirstPartSerie = str?.split(":")[1];
  //   if(getFirstPartSerie === "?" || !getFirstPartSerie) {
  //     return "NA"
  //   } else {
  //     return getFirstPartSerie.split("\r")[0];
  //   }
  // }



  const handleListItemClick = (
    // event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedEnginId(index);
  };

  const openModalAffectation = () => {
    console.log('List selected', selectedEnginId);
    setIsDialogOpen(true);
  }

  const closeModalAffectation = () => {
    formik.resetForm();
    setIsDialogOpen(false);
  }

  const filterList = (mode: number) => {
    console.log('filter ID', mode);
    setFilterChipID(mode);
  }

  const FormAffectation = () => {
    return (<>
    {/* <span className="title-form"> Informations d'affectation </span> */}
    <form className='form-affectation' onSubmit={formik.handleSubmit}>
      <Grid container rowSpacing={2} columnSpacing={2} >
          <Grid item xs={12} sm={12} lg={12} xl={6} >
              <TextField placeholder="Quantité à réaliser"
                  variant="outlined"
                  name="quantite_a_realiser"
                  label="Quantité à réaliser"
                  className='my-text-field'
                  onChange={formik.handleChange}
                  value={formik.values.quantite_a_realiser}
                  error={formik.touched.quantite_a_realiser && Boolean(formik.errors.quantite_a_realiser)}
                  type="text" />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={6} >
            <TextField placeholder="Quantité journalière"
                variant="outlined"
                name="quantite_journalier"
                label="Quantité journalière"
                className='my-text-field'
                onChange={formik.handleChange}
                value={formik.values.quantite_journalier}
                error={formik.touched.quantite_journalier && Boolean(formik.errors.quantite_journalier)}
                type="number" />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={6} >
            <Autocomplete
                id="id-service-select"
                options={uniteList}
                value={unite}
                onChange={handleUniteChange}
                autoHighlight
                isOptionEqualToValue={(option, value) => option.value === value.value}
                getOptionLabel={(option) => option.value}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        {option.value}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className='my-text-field'
                        placeholder="Unité"
                        label="Unité"
                        variant="outlined"
                        name="unite"
                        onChange={formik.handleChange}
                        value={formik.values.unite = unite.value }
                        error={formik.touched.unite && Boolean(formik.errors.unite)}
                        // helperText={formik.touched.dortoir && formik.errors.dortoir}
                    />
                )} />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={6} >
          <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
                        <DatePicker
                        label="Filter par date"
                        value={dateAffect}
                        inputFormat="dd-MM-yyyy"
                        onChange={handleDate}
                        renderInput={(params) => (
                            <TextField {...params} fullWidth className='my-text-field' />
                        )}
                        />
                    </LocalizationProvider>
                    </Grid>
      </Grid>
    </form>
    
    </>)
  }



  return (
    <>
      <div className="ct-affectations-campagne">
        <div className="ct-affectations-campagne__card">
          <div className="header-part">
            <div className="title-part">
              <div className="title-bloc">
                <span className="title"> Affectations engins </span>
                <Chip
                  label={` Chantier actif : ${
                    selectedChantier?.value || "Aucun"
                  } `}
                  className="total-number-chip"
                />
              </div>
              <div className="toolbar-part">
                <div className="actions-part">
                  {permissions?.chantier?.find(
                    (o: any) => o.libelle === "Affectation" && o.create === true
                  ) && (
                    <Button
                      color="blue"
                      className="btn-option"
                      disabled={!selectedChantier.id}
                      onClick={() => {
                        handleOpen();
                        getAllEnginsAsync();
                      }}
                      startIcon={<InlineIcon icon="carbon:document-add" />}
                    >
                      Ajouter un engin
                    </Button>
                  )}
                </div>

                <TextField
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  className="search-input"
                  placeholder="Recherche ... "
                  variant="outlined"
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InlineIcon icon="bi:search" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="searchable-part">
            <Autocomplete
              id="id-service-select"
              className="search-autocomplete"
              options={ListAllChantiers}
              value={selectedChantier.id ? selectedChantier : null}
              onChange={handleChantierChange}
              autoHighlight
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              getOptionLabel={(option) => option.value}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.value}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="my-text-field"
                  placeholder="Chantier"
                  label="Chantier"
                  variant="outlined"
                  name="type_compteur"
                  // onChange={formik.handleChange}
                  // value={formik.values.type_compteur}
                  // error={formik.touched.type_compteur && Boolean(formik.errors.type_compteur)}
                />
              )}
            />
            <Button
              color="secondary"
              className="btn-option"
              // startIcon={<InlineIcon icon="ep:filter" />}
              onClick={() => {
                getChantierInfos();
              }}
              variant="contained"
            >
              Valider
            </Button>
          </div>

          <PerfectScrollbar id="scrollableDiv" className="content-part">
            {loading ? (
              <>
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  width={"40%"}
                  height={"60%"}
                />
                <Skeleton
                  variant="rounded"
                  animation="pulse"
                  width={"40%"}
                  height={"60%"}
                />
              </>
            ) : (
              <div className="data-content-part">
                <>
                  <div className="table-content-part">
                    <DataGrid
                      rows={listEnginChantierSelect}
                      columns={columns}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 7 } },
                      }}
                      components={{
                        NoRowsOverlay: () => (
                          <Stack
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            Pas de donnees
                          </Stack>
                        ),
                      }}
                      localeText={{
                        MuiTablePagination: {
                          labelDisplayedRows: ({ from, to, count }) =>
                            `${from} - ${to} Total: ${count}`,
                        },
                      }}
                      onRowClick={(e: any) => {
                        console.log("change colonne", e);
                        setRowSelected(e);
                      }}
                    />
                  </div>
                  <div className="detail-person">
                    
                      <div className="filter-header">
                      {(enginDetail?.incident ||
                      enginDetail?.utilisation_engin) && (
                        <>
                        <Chip
                          size="small"
                          color={filterChipID === 1 ? "tertiary" : "default"}
                          className="chip-filter"
                          label="Utilisation"
                          onClick={() => filterList(1)}
                        />
                        <Chip
                          size="small"
                          color={filterChipID === 2 ? "tertiary" : "default"}
                          className="chip-filter"
                          label="Incident"
                          onClick={() => filterList(2)}
                        />
                        </>
                         )}
                        {/* <Chip size="small" color={filterChipID === 0 ? "tertiary" : "default"} className="chip-filter" label="Tous" onClick={() => filterList(0)}/> */}
                        {(rowSelected.id && filterChipID === 1) && (
                          <AddCircleOutline onClick={()=> {handleOpenUse();setSelection({})}}
                            sx={{ position: "absolute", right: "10px" }}
                          />
                        )}
                      </div>
                   

                    <List
                      component="nav"
                      aria-label="main mailbox folders"
                      sx={{
                        position: "relative",
                        overflow: "auto",
                      }}
                    >
                      {enginDetail?.utilisation_engin &&
                        filterChipID === 1 &&
                        enginDetail?.utilisation_engin?.map(
                          (x: any, id: number) => {
                            const formattedDate = format(
                              new Date(x.date),
                              "dd/MM/yyyy"
                            ); // Formater la date au format 'jour/mois/année'

                            return (
                              <>
                                {x.id !== 1 && (
                                  <Divider variant="inset" component="li" />
                                )}
                                <ListItem key={id} onClick={()=> {handleOpenUse();setSelection(x)}}>
                                  <ListItemIcon>
                                    <Build />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={x.nature_travaux}
                                    // secondary={x.date}
                                    secondary={`
                                        Date:${formattedDate}\n,\n
                                        \nCompteur Debut: ${x.compteur_debut},
                                        Compteur Fin: ${x.compteur_fin},
                                        Quantite: ${x.quantite},
                                        Carburant: ${x.carburant},\n
                                        chauffeur: ${x.chauffeur},
                                        observation: ${x.observation},
                                        Chef Chantier: ${x?.utilisateur?.nom}`}
                                  />
                                  {x.isactif === 2 && (
                                    <small
                                      style={{
                                        position: "absolute",
                                        right: "5px",
                                        bottom: "1px",
                                        fontSize: "0.6rem",
                                      }}
                                    >
                                      <i>modifier</i>
                                    </small>
                                  )}
                                </ListItem>
                              </>
                            );
                          }
                        )}
                      {enginDetail?.incident &&
                        filterChipID === 2 &&
                        enginDetail?.incident?.map((x: any, id: number) => {
                          const formattedDate = format(
                            new Date(x.date_incient),
                            "dd/MM/yyyy"
                          ); // Formater la date au format 'jour/mois/année'

                          return (
                            <>
                              {x.id !== 1 && (
                                <Divider variant="inset" component="li" />
                              )}
                              <ListItem key={id}>
                                <ListItemIcon>
                                  <NoAccounts />
                                </ListItemIcon>
                                <ListItemText
                                  primary={x.panne}
                                  //  secondary={x.date}
                                  secondary={`
                                        Date Incident:${formattedDate}\n,
                                        Observation incident: ${x.observation_panne},
                                        Date depannage: ${x.date_depannage},
                                        Compteur depannage: ${x.compteur_depannage},
                                        Nom technicien: ${x.nom_technicien},\n
                                        Nom Technicien: ${x.nom_technicien},
                                        Chef Chantier: ${x?.utilisateur?.nom}`}
                                />
                              </ListItem>
                            </>
                          );
                        })}
                    </List>
                  </div>
                </>
              </div>
            )}

            <DialogComp
              isOpen={isDialogOpen}
              handleClose={() => {
                closeModalAffectation();
              }}
              titleDialog="Informations d'affectation"
              children={<FormAffectation />}
              functionBtnYes={() => {
                formik.submitForm();
              }}
            />
          </PerfectScrollbar>
        </div>
      </div>

      {!loadingEngin && (
        <SideModal open={open} onClose={handleClose} closeClick={handleClose}>
          <div className="filter-container">
            <span className="title"> Sélectionner l'engin à affecter </span>
            <TextField
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              className="search-input"
              placeholder="Recherche ... "
              variant="outlined"
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InlineIcon icon="bi:search" />
                  </InputAdornment>
                ),
              }}
            />

            <List
              component="nav"
              aria-label="main mailbox folders"
              sx={{
                position: "relative",
                overflow: "auto",
              }}
            >
              {listEngins
                .filter((value: any) => {
                  return (
                    value.searchText
                      .toLocaleLowerCase()
                      .indexOf(searchText.toLocaleLowerCase() || "") !== -1
                  );
                })
                .map((x: any, id: number) => {
                  return (
                    <>
                      <ListItemButton
                        key={x.id}
                        // selected={ selectedEnginId.includes(x.id) }
                        selected={selectedEnginId === x.id}
                        onClick={(e: any) => handleListItemClick(x.id)}
                        color="secondary"
                        divider={true}
                        sx={{
                          "&.Mui-selected": {
                            // backgroundColor: "#96D1C6"
                            borderColor: "#96D1C6",
                            borderWidth: 2,
                          },
                          "&.Mui-focusVisible": {
                            backgroundColor: "#2e8b57",
                          },
                        }}
                      >
                        <ListItemIcon>
                          {" "}
                          <img src={enginIcon} alt="" height="70px" />{" "}
                        </ListItemIcon>
                        <ListItemText
                          primary={x.libelle}
                          secondary={
                            x.categorie_engin.libelle +
                            " - N° parc : " +
                            x.num_parc
                          }
                        />
                      </ListItemButton>
                    </>
                  );
                })}
            </List>

            <div className="btn-part">
              <Button
                color="secondary"
                className="btn-option"
                disabled={selectedEnginId ? false : true}
                startIcon={<InlineIcon icon="system-uicons:check" />}
                onClick={() => {
                  openModalAffectation();
                }}
                variant="contained"
              >
                Valider la sélection
              </Button>
            </div>
          </div>
        </SideModal>
      )}

        <SideModal 
          open={openUse}
          onClose={handleCloseUse}
          closeClick={handleCloseUse}>
            <>
            { isEditAffectedRow ?
              <EditEnginAffectedForm closeModal={handleCloseUse} selectedItem={rowSelected} isEditAffectedRow={isEditAffectedRow} />
              :
              <CreateUtilisationForm closeModal={handleCloseUse} selectedItem={selection} settingData={rowSelected} isEditAffectedRow={isEditAffectedRow} />
            }
            </>
        </SideModal>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
  loadingEngin: selectEnginDetailLoading,
  chantierList: selectChantierItemList,
  listEnginChantier: selectEnginChantierList,
  affectedEnginOnSelectedChantierList: selectAffectedEnginByChantierItemList,
  enginList: selectEnginItemList,
  enginDetail: detailAboutEnginAffectation,
  getData: selectTableDonneeItemList,
  permissions: selectUserProfil,
  userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAllEnginsAsync: () => dispatch(getAllEnginsAsync()),
  getAllChantiersAsync: () => dispatch(getAllChantiersAsync()),
  callbackEnginAffectatedAsync: (data: any) =>
    dispatch(callbackEnginAffectatedAsync(data)),
  getAllAffectedEnginsByChantierAsync: (id: any) =>
    dispatch(getAllAffectedEnginsByChantierAsync(id)),
  createEnginAffectationAsync: (data: any) =>
    dispatch(createEnginAffectationAsync(data)),
  getDetailAboutEnginAffectatedAsync: (data: any) =>
    dispatch(getDetailAboutEnginAffectatedAsync(data)),
  getAllTableDonneesAsync: () => dispatch(getAllTableDonneesAsync()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const CtAffectationsCampaign = connector(CtAffectationsCampaignPage);

export default CtAffectationsCampaign;
