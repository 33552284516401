import React, { Fragment, useState } from 'react';
import './SyntheseChantier.style.scss';
import { Button, Divider, FormControlLabel, FormGroup, Grid, IconButton, Switch, TextField, Typography } from '@mui/material';
import { InlineIcon } from '@iconify/react';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { exportToExcel } from '@/config';

const SyntheseChantier: React.FC<any> = ({ selectedItem }) => {

    console.log('selectedItem', selectedItem);

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);

    const handleClose = () => {
        setOpen(false);
        setUtilisationEngins([]);
        setIncidentEngins([]);
        setItemSelected([]);
    };

    const handleClose2 = () => {
        setOpen2(false);
        setAbsencePersonnel([]);
        setItemSelected([]);
    };

    const columns = [
        { field: 'date', headerName: 'Date',   valueGetter: (params: any) => {
            // Format the date using moment
            return moment(params.value).format('DD-MM-YYYY');
        },width: 190 },
        { field: 'travail', headerName: 'travail', width: 190 },
        { field: 'compteur_debut', headerName: 'compteur_debut', width: 190 },
        { field: 'compteur_fin', headerName: 'compteur_fin', width: 190 },
        { field: 'quantite', headerName: 'quantite', width: 190 },
        { field: 'carburant', headerName: 'Carburant', width: 190 },
        { field: 'chauffeur', headerName: 'Chauffeur', width: 190 },
        { field: 'nature_travaux', headerName: 'Nature Travaux', width: 190 },
        { field: 'motif', headerName: 'Motif', width: 190 },
        { field: 'observation', headerName: 'observation', width: 190 },
      ];

    const personnelColumns = [
        { field: 'date', headerName: 'Date',   valueGetter: (params: any) => {
            // Format the date using moment
            return moment(params.value).format('DD-MM-YYYY');
        },width: 120 },
        { field: 'heure_debut', headerName: 'Heure Debut', width: 120 },
        { field: 'heure_fin', headerName: 'Heure Fin', width: 120 },
        { field: 'motif', headerName: 'Motif', width: 320 },
        { field: 'observation', headerName: 'observation', width: 190 },
      ];

    let enginFormArray = selectedItem?.engin?.map((x: any) => {
        return {
            ...x,
            libelle: x?.engin?.num_parc
        }
    })

    let personnelFormArray = selectedItem?.personnel?.map((x: any) => {
        return {
            ...x,
            libelle: (x?.personnel?.nom + " " + x?.personnel?.prenoms)
        }
    })

    let cuveFormArray = selectedItem?.utilisation_cuve;

    const [utilisationEngins, setUtilisationEngins] = useState([]);
    const [incidentEngins, setIncidentEngins] = useState([]);
    const [absencePersonnel, setAbsencePersonnel] = useState([]);


    const handleSeeMoreEnginFormArray = (id: number) => {
    //     let values: any[] = [...selectedItem?.engin];
    //     let data: any = [];
    //     values.map((value: any) => {
    //         if (value.id === id) {
    //             data.push(value);
    //         }
    //     });
    //     console.log('handleSeeMoreEnginFormArray', data.utilisation_engins);
    //    setItemSelected(data);
    //     setUtilisationEngins(data.utilisation_engins);
    //     setIncidentEngins(data.incident_engins || []);
    //     console.log('itemSelected', itemSelected);
    //     console.log('utilisationEngins', utilisationEngins);
    //     setOpen(true);
        const enginData = selectedItem?.engin?.find((value: any) => value.id === id);

        if (enginData) {
            setUtilisationEngins(enginData.utilisation_engins || []);
            setIncidentEngins(enginData.incident_engins || []);
            setItemSelected(enginData);
            setOpen(true);
        }
    };

    const handleSeeMorePersonnelFormArray = (id: number) => {
        const personnelData = selectedItem?.personnel?.find((value: any) => value.id === id);

        if (personnelData) {
            setAbsencePersonnel(personnelData.absences_chantiers || []);
            setItemSelected(personnelData);
            setOpen2(true);
        }
    };

    // Appel de la fonction d'exportation lorsque vous souhaitez exporter les données
    const handleExportUtilisationExcel = () => {
        exportToExcel(utilisationEngins, columns, 'utilisationEngin');
    };

  const handleExportPersonnelExcel = () => {
    exportToExcel(absencePersonnel, personnelColumns, 'utilisationPersonnel');
  };


    // const handleSeeMoreCuveFormArray = (id: number) => {
    //     let values: any[] = [...selectedItem?.utilisation_cuve];
    //     let data: any = [];
    //     values.map((value: any) => {
    //         if (value.id === id) {
    //             data.push(value);
    //         }
    //     });
    //     setItemSelected(data);
    //     console.log('itemSelected', itemSelected);
    //     setOpen(true);
    // };

    return (<>
        <form className='create-list-form'>
            <span className="title"> Synthese de {selectedItem?.chantier[0]?.nom} ({selectedItem?.chantier[0]?.localisation}) </span>

            <Grid container rowSpacing={2} columnSpacing={2} >
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="Client"
                        className='my-text-field'
                        value={selectedItem?.chantier[0]?.client?.nom}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="Telephone"
                        className='my-text-field'
                        value={selectedItem?.chantier[0]?.client?.telephone}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="Chef de chantier"
                        className='my-text-field'
                        value={selectedItem?.chantier[0]?.utilisateur?.nom + ' ' + selectedItem?.chantier[0]?.utilisateur?.prenoms}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="Date de cloture"
                        className='my-text-field'
                        value={selectedItem?.chantier[0]?.date_cloture}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="Nom"
                        className='my-text-field'
                        value={selectedItem?.chantier[0]?.nom}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="Numero"
                        className='my-text-field'
                        value={selectedItem?.chantier[0]?.numero_chantier}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="Date debut"
                        className='my-text-field'
                        value={selectedItem?.chantier[0]?.date_debut}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="Date fin"
                        className='my-text-field'
                        value={selectedItem?.chantier[0]?.date_fin}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="Localisation"
                        className='my-text-field'
                        value={selectedItem?.chantier[0]?.localisation}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        label="Type location"
                        className='my-text-field'
                        value={selectedItem?.chantier[0]?.type_location}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <div className="switch-container">
                        <Typography>Cuve à carburant </Typography>
                        <FormGroup className='my-toggle'>
                            <FormControlLabel
                                label={selectedItem?.chantier[0]?.cuve_caburant ? "Oui" : "Non"}
                                control={<Switch name="cuve_caburant"
                                    checked={selectedItem?.chantier[0]?.cuve_caburant}
                                    disabled={true}
                                />}
                            />
                        </FormGroup>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        name="typeCuve"
                        label="Type caburant"
                        className='my-text-field'
                        value={selectedItem?.chantier[0] ? selectedItem?.chantier[0]?.cuves[0]?.typeCuve : "Inexistant"}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        name="quantite_caburant"
                        label="Quantité caburant"
                        className='my-text-field'
                        value={selectedItem?.chantier[0] ? selectedItem?.chantier[0]?.cuves[0]?.quantite : "Inexistant"}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <div className="switch-container">
                        <Typography>Container </Typography>
                        <FormGroup className='my-toggle'>
                            <FormControlLabel
                                label={selectedItem?.chantier[0]?.container ? "Oui" : "Non"}
                                control={<Switch name="container"
                                    checked={selectedItem?.chantier[0]?.container ? true : false}
                                    disabled={true}
                                />}
                            />
                        </FormGroup>
                    </div>
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        inputProps={{
                            readOnly: Boolean(true),
                            disabled: Boolean(true),
                        }}
                        variant="outlined"
                        name="hebergement_chantier"
                        label="Hebergement"
                        className='my-text-field'
                        value={selectedItem?.chantier[0]?.hebergement_chantier}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <div className="switch-container">
                        <Typography>Gardiennage</Typography>
                        <FormGroup className='my-toggle'>
                            <FormControlLabel
                                label={selectedItem?.chantier[0]?.gardiennage_vehicules ? "Oui" : "Non"}
                                control={<Switch name="gardiennage_vehicules"
                                    checked={selectedItem?.chantier[0]?.gardiennage_vehicules ? true : false}
                                    disabled={true} />}
                            />
                        </FormGroup>
                    </div>
                </Grid>
            </Grid>

            {
                (enginFormArray?.length || enginFormArray) &&
                <>
                    <span className="sub-title-part"> <InlineIcon icon="bx:user" /> &nbsp;  Engins du chantier </span>
                    <Divider className='custom-divider' />

                    <Grid container rowSpacing={2} columnSpacing={2} >
                        {enginFormArray.map((m: any, i: number) => (
                            <Fragment key={m.id}>
                                <Grid item xs={12} sm={12} lg={12} xl={11} >
                                    <Grid container rowSpacing={1} columnSpacing={2} >
                                        <Grid item xs={12} sm={12} lg={12} xl={7} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m.libelle}
                                                variant="outlined"
                                                name="pngin"
                                                label={`Engin ${i + 1}`}
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12} xl={5} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                variant="outlined"
                                                label={ m.date_retour  === null ? "Date Retour":"Date Retour"}
                                                className='my-text-field'
                                                value={(m.date_retour  === null ? "Sur chantier": moment(m.date_retour).format('DD-MM-YYYY') )}
                                                type="text" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                
                                <Grid item xs={12} sm={12} lg={12} xl={1}>
                                    <IconButton
                                        onClick={() => { handleSeeMoreEnginFormArray(m.id) }}
                                        style={{ transform: 'translateY(5px)' }}
                                        size='small'
                                        color="error"
                                        title='Voir les details'>
                                        <InlineIcon icon="clarity:eye-line" />
                                    </IconButton>
                                </Grid>
                            </Fragment>
                        ))}
                    </Grid>
                </>
            }

            {
                (cuveFormArray?.length || cuveFormArray) &&
                <>
                    <span className="sub-title-part"> <InlineIcon icon="bx:user" /> &nbsp;  Cuve du chantier </span>
                    <Divider className='custom-divider' />

                    <Grid container rowSpacing={2} columnSpacing={2} >
                        {cuveFormArray.map((m: any ) => (
                            <Fragment key={m.id}>
                                <Grid item xs={12} sm={12} lg={12} xl={11} >
                                    <Grid container rowSpacing={1} columnSpacing={2} >
                                        <Grid item xs={12} sm={12} lg={12} xl={4} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m?.date}
                                                variant="outlined"
                                                name="date"
                                                label={`Date`}
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12} xl={4} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m?.type}
                                                variant="outlined"
                                                name="action"
                                                label="Action"
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12} xl={4} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m.quantite}
                                                variant="outlined"
                                                name="quantite"
                                                label="Quantite (L)"
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12} xl={8} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m.engin?.libelle}
                                                variant="outlined"
                                                name="engin"
                                                label="Engin"
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        ))}
                    </Grid>
                </>
            }

            {
                (personnelFormArray?.length || personnelFormArray) &&
                <>
                    <span className="sub-title-part"> <InlineIcon icon="bx:user" /> &nbsp;  Personnel du chantier </span>
                    <Divider className='custom-divider' />

                    <Grid container rowSpacing={2} columnSpacing={2} >
                        {personnelFormArray.map((m: any, i: number) => (
                            <Fragment key={m.id}>
                                <Grid item xs={12} sm={12} lg={12} xl={11} >
                                    <Grid container rowSpacing={1} columnSpacing={2} >
                                        <Grid item xs={12} sm={12} lg={12} xl={7} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m.libelle}
                                                variant="outlined"
                                                name="personne"
                                                label={`Personne ${i + 1}`}
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12} xl={5} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                variant="outlined"
                                                label="Date Retour"
                                                className='my-text-field'
                                                value={(m.date_retour  == null ? "Sur chantier": moment(m.date_retour).format('DD-MM-YYYY') )}
                                                type="text" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={1}>
                                    <IconButton
                                        onClick={() => { handleSeeMorePersonnelFormArray(m.id) }}
                                        style={{ transform: 'translateY(5px)' }}
                                        size='small'
                                        color="error"
                                        title='Voir les details'>
                                        <InlineIcon icon="clarity:eye-line" />
                                    </IconButton>
                                </Grid>
                            </Fragment>
                        ))}
                    </Grid>
                </>
            }

        </form>

        <div className="myModal" style={open ? { display: 'block' } : { display: 'none' }}>
            {/* <!-- myModal content --> */}
            <div className="myModal-content">
                <span className="myModal-close" onClick={handleClose}>&times;</span>
                <span className="myModal-title">Detail</span>
                <Button
                    color='blue'
                    className='btn-option'
                    onClick={handleExportUtilisationExcel}
                    //variant='contained'
                    startIcon={<InlineIcon icon="carbon:document-export" />}>
                    Exporter
                </Button>
                <div style={{ height: '85%', width: '100%' }}>
                    <DataGrid
                        rows={utilisationEngins}
                        columns={columns}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        localeText={{
                            MuiTablePagination: {
                            labelDisplayedRows: ({ from, to, count }) =>
                                `${from} - ${to} Total: ${count}`,
                            },
                        }}
                        />
                </div>
            </div>
        </div>

        <div className="myModal" style={open2 ? { display: 'block' } : { display: 'none' }}>
            {/* <!-- myModal content --> */}
            <div className="myModal-content">
                <span className="myModal-close" onClick={handleClose2}>&times;</span>
                <span className="myModal-title">Detail Personnel    </span>
                <Button
                    color='blue'
                    className='btn-option'
                    onClick={handleExportPersonnelExcel}
                    //variant='contained'
                    startIcon={<InlineIcon icon="carbon:document-export" />}>
                    Exporter
                </Button>
                <div style={{ height: '85%', width: '100%' }}>
                    <DataGrid
                        rows={absencePersonnel}
                        columns={personnelColumns}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        localeText={{
                            MuiTablePagination: {
                            labelDisplayedRows: ({ from, to, count }) =>
                                `${from} - ${to} Total: ${count}`,
                            },
                        }}
                        />
                </div>
            </div>
        </div>
    </>)
}

export default SyntheseChantier