import React from 'react';
import './PrintCongesForm.style.scss';
import logo from "@/assets/images/png/logo.png";

type MyProps = {
  // using `interface` is also ok
  item: any;
};

class PrintCongesForm extends React.Component<MyProps> {
  render (){
    console.log(this.props.item);
    
    return (
      <div className='print'>
        <div className="main-print">
          <div className='print-form'>
            <table className='print-form-top row'>
              <div className='col-3 text-center'> <img src={logo} alt="logo" style={{ width: '140px' , height: '120px' }}/> </div>
              <div className='col-5 text-center' style={{ borderLeft: '0.5px solid #000' }}>
                <span className='print-title'>ATTESTATION DE CONGES</span>
              </div>
              <div className='last col-4 text-center p-0 m-0' style={{ borderLeft: '0.5px solid #000', position: 'relative' }}>
                <div className='col-12' style={{ borderBottom: '0.5px solid #000' }}>
                  <strong>REF: </strong>
                </div>
                <div className='col-12' style={{ borderBottom: '0.2px solid #000' }}>
                  <strong>Date d’application: </strong> <span>03/01/2022</span>
                </div>
                <div className='col-12' style={{ borderBottom: '0.2px solid #000' }}>
                  <span>Accessibilité: </span> <strong>Restreinte</strong>
                </div>
                <div className='_last col-12'>
                  <div className='col-6'>
                    <span>Révision : 01</span>
                  </div>
                  <div className='col-6' style={{ borderLeft: '0.2px solid #000' }}>
                    <span>Page : 1/1</span>
                  </div>
                </div>
              </div>
            </table>
            <br /><br />
            <br /><br />
            <div className='print-form-body'>
              <span>
                Nous soussignés, Ivoire Equipements, 01 BP 5120 Abidjan 01, PK 23 autoroute du
                nord, sortie Allokoi, attestons que <strong>{ this.props.item?.personnel?.nom + ' ' + this.props.item?.personnel?.prenoms } </strong>, employé en qualité de
                { this.props.item?.personnel?.fonction } est en congés annuels pour la
                période du <strong> { this.props.item?.date_depart } au { this.props.item?.date_retour } inclus.</strong>
                La reprise du travail est prévue le <strong>03 mai 2023 à 07h30.</strong>
                En foi de quoi, la présente attestation lui est délivrée pour servir et valoir ce que
                de droi
              </span>
            </div>
            <div className='print-form-bottom'>
              <span>
                Fait à Abidjan, { this.props.item?.createdAt } <br /><br /><br />
                <u style={{ fontSize: '1.2rem' }}>Le Responsable des Ressources Humaines</u>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PrintCongesForm