import enginTypes from "./engin.types";

interface IEnginReducerState {
  list: any,
  vidange: any,
  itemSelected: any,
  loading: boolean,
  error: string,
  getSyntheseList: any,
}

const INITIAL_STATE = {
  list: [],
  vidange: [],
  itemSelected: {},
  loading: false,
  error: "",
  getSyntheseList: {}
};

const enginReducer = (
  state: IEnginReducerState = INITIAL_STATE,
  action: any
): IEnginReducerState => {
  switch (action.type) {
    case enginTypes.GET_ENGIN_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case enginTypes.GET_VIDANGE_LIST:
      return {
        ...state,
        vidange: action.payload,
        error: ""
      };

    case enginTypes.SELECTED_ENGIN:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case enginTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    case enginTypes.GET_SYNTHESE_ENGIN:
      return {
        ...state,
        getSyntheseList: action.payload,
        error: ""
      };

    default:
      return state;
  }
};

export default enginReducer;
