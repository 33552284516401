import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectClient = (state:TRootState) => state.client;

export const selectLoading = createSelector(
  [selectClient],
  (client:any) => client.loading
);

export const selectClientItemList = createSelector(
    [selectClient],
    (client:any) => client.list
);

export const selectClientDetailLoading = createSelector(
  [selectClient],
  (client:any) => client.loading
);

export const selectClientItemSelected = createSelector(
    [selectClient],
    (client:any) => client.itemSelected
);
