import React from 'react';
import CtListesCampaign from '../pages-content/Listes-content/Listes.content';

const Listes:React.FC = () => {
  return (
    <>
      <CtListesCampaign />
    </>
  )
}

export default Listes;