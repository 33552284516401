import { InlineIcon } from "@iconify/react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "./CreatePersonnelForm.style.scss";
import {
  createPersonnelAsync,
  updatePersonnelAsync,
} from "@/store/reducers/personnel/personnel.action";
import { store } from "@/store";
import "dayjs/locale/fr";

const CreatePersonnelForm: React.FC<any> = ({ selectedItem, closeModal }) => {
  const genreList: { id: string; value: string }[] = [
    { id: "Homme", value: "Homme" },
    { id: "Femme", value: "Femme" },
  ];

  const statutList: { id: string; value: string }[] = [
    { id: "CDI", value: "CDI" },
    { id: "CDD à terme imprécis", value: "CDD à terme imprécis" },
    { id: "CDD à terme précis", value: "CDD à terme précis" },
    { id: "Essai", value: "Essai" },
    { id: "Stagiaire", value: "Stagiaire" },
  ];

  const siteTravailList: { id: string; value: string }[] = [
    { id: "Administration", value: "Administration" },
    { id: "Chantier", value: "Chantier" },
    { id: "Atelier", value: "Atelier" },
  ];

  const etatMatrimonialeList: { id: string; value: string }[] = [
    { id: "Marié", value: "Marié" },
    { id: "Célibataire", value: "Célibataire" },
    { id: "Veuf", value: "Veuf" },
  ];

  const categorieList: { id: string; value: string }[] = [
    { id: "Cadre", value: "Cadre" },
    { id: "AM", value: "AM" },
    { id: "Employé", value: "Employé" },
    { id: "Ouvrier", value: "Ouvrier" },
  ];

  const fonctionList: { id: string; value: string }[] = [
    { id: "Responsable de chantier", value: "Responsable de chantier" },
    { id: "Conducteur de bulldozer", value: "Conducteur de bulldozer" },
    { id: "Mécanicien", value: "Mécanicien" },
    { id: "Conducteur de grader", value: "Conducteur de grader" },
    { id: "Conducteur de pelle", value: "Conducteur de pelle" },
    { id: "Conducteur de chargeuse", value: "Conducteur de chargeuse" },
    {
      id: "Soudeur, chef d'équipe soudure",
      value: "Soudeur, chef d'équipe soudure",
    },
    { id: "Conducteur de tracteur", value: "Conducteur de tracteur" },

    { id: "Chauffeur de benne", value: "Chauffeur de benne" },
    { id: "Conducteur de chargeuse", value: "Conducteur de chargeuse" },
    { id: "Conducteur de Tombereau", value: "Conducteur de Tombereau" },
    { id: "Chauffeur de porte-engin", value: "Chauffeur de porte-engin" },
    { id: "Commis", value: "Commis" },
    {
      id: "Mécanicien, chef d'équipe V.Lourd",
      value: "Mécanicien, chef d'équipe V.Lourd",
    },
    { id: "Soudeur", value: "Soudeur" },
    { id: "Electricien", value: "Electricien" },
    { id: "Manœuvre", value: "Manœuvre" },
    { id: "Opérateur broyeuse", value: "Opérateur broyeuse" },
    {
      id: "Mécanicien, chef d'équipe moteur",
      value: "Mécanicien, chef d'équipe moteur",
    },
    { id: "Scieur", value: "Scieur" },

    {
      id: "Conducteur de compacteur/Chargeuse",
      value: "Conducteur de compacteur/Chargeuse",
    },
    { id: "Magasinier", value: "Magasinier" },
    { id: "Aide mécanicien", value: "Aide mécanicien" },
    {
      id: "Conducteur de grader (Compacteur)",
      value: "Conducteur de grader (Compacteur)",
    },
    { id: "Aide-conducteur", value: "Aide-conducteur" },
    {
      id: "Mécanicien, chef d'équipe organes",
      value: "Mécanicien, chef d'équipe organes",
    },
    { id: "Agent d'entretien", value: "Agent d'entretien" },
    {
      id: "Electricien,chef d'équipe, électricité",
      value: "Electricien,chef d'équipe, électricité",
    },
    { id: "Conducteur de compacteur", value: "Conducteur de compacteur" },
    {
      id: "Mécanicien, chef d'équipe V.Léger",
      value: "Mécanicien, chef d'équipe V.Léger",
    },
    {
      id: "Mécanicien, chef d'équipe moteur",
      value: "Mécanicien, chef d'équipe moteur",
    },
    { id: "Chef d'équipe bulldozer", value: "Chef d'équipe bulldozer" },
    { id: "INFORMATICIEN", value: "INFORMATICIEN" },
  ];

  const [fonction, setFonction] = useState<{ id: string; value: string }>({
    id: "",
    value: "",
  });
  const handleFonctionChange = (
    event: any,
    value: { id: string; value: string } | null
  ) => {
    console.log(value);
    if (value == null) {
      setFonction({ id: "", value: "" });
    } else {
      setFonction(value as { id: string; value: string });
    }
    formik.setFieldValue("fonction", value?.value);
  };

  const [genre, setGenre] = useState<{ id: string; value: string }>({
    id: "",
    value: "",
  });
  const handleGenreChange = (
    event: any,
    value: { id: string; value: string } | null
  ) => {
    console.log(value);
    if (value == null) {
      setGenre({ id: "", value: "" });
    } else {
      setGenre(value as { id: string; value: string });
    }
    formik.setFieldValue("genre", value?.value);
  };

  const [statut, setStatut] = useState<{ id: string; value: string }>({
    id: "",
    value: "",
  });
  const handleStatutChange = (
    event: any,
    value: { id: string; value: string } | null
  ) => {
    console.log(value);
    if (value == null) {
      setStatut({ id: "", value: "" });
    } else {
      setStatut(value as { id: string; value: string });
    }
    formik.setFieldValue("statut", value?.value);
  };

  const [etatMatrimoniale, setEtatMatrimoniale] = useState<{
    id: string;
    value: string;
  }>({ id: "", value: "" });
  const handleEtatMatrimonialeChange = (
    event: any,
    value: { id: string; value: string } | null
  ) => {
    console.log(value);
    if (value == null) {
      setEtatMatrimoniale({ id: "", value: "" });
    } else {
      setEtatMatrimoniale(value as { id: string; value: string });
    }
    formik.setFieldValue("etat_matrimoniale", value?.value);
  };

  const [categorie, setCategorie] = useState<{ id: string; value: string }>({
    id: "",
    value: "",
  });
  const handleCategorieChange = (
    event: any,
    value: { id: string; value: string } | null
  ) => {
    console.log(value);
    if (value == null) {
      setCategorie({ id: "", value: "" });
    } else {
      setCategorie(value as { id: string; value: string });
    }
    formik.setFieldValue("categorie_professionnelle", value?.value);
  };

  // const [photo, setPhoto] = useState('');
  // const [preview, setPreview] = useState('');

  // const handlePhotoChange = (e: any) => {
  //     const selectedFile = e.target.files[0];
  //     if (selectedFile) {
  //       setPhoto(selectedFile);
  //       setPreview(URL.createObjectURL(selectedFile));
  //       formik.setFieldValue("photo", selectedFile);

  //     }
  // };

  const [siteTravail, setSiteTravail] = useState<{ id: string; value: string }>(
    { id: "", value: "" }
  );
  const handleSiteTravailChange = (
    event: any,
    value: { id: string; value: string } | null
  ) => {
    if (value == null) {
      setSiteTravail({ id: "", value: "" });
    } else {
      setSiteTravail(value as { id: string; value: string });
    }
    formik.setFieldValue("site_travail", value?.value);
  };

  const [date_naissance, setDate_naissannce] = useState(
    selectedItem ? selectedItem.date_naissance : new Date()
  );
  const [date_embauche, setDate_dembauche] = useState(
    selectedItem ? selectedItem.date_embauche : new Date()
  );

  const calculateAge = (date: any) => {
    if (date) {
      const now = dayjs();
      const birthdate = dayjs(date);
      const age = now.diff(birthdate, "year");
      return age;
    }
    return "0";
  };

  const calculateAnciennete = (date: any) => {
    if (date) {
      const now = dayjs();
      const dateEmbauche = dayjs(date);
      const anciennete = now.diff(dateEmbauche, "year");
      return anciennete;
    }
    return "0";
  };

  const validationSchema = yup.object({
    nom: yup.string().required(" Le nom de la liste est obligatoire "),
    prenoms: yup.string().required(" Le nom de la liste est obligatoire "),
    matricule: yup.string().required("ce champ est obligatoire "),
  });

  const formik = useFormik({
    initialValues: {
      matricule: selectedItem ? selectedItem.matricule : "",
      nom: selectedItem ? selectedItem.nom : "",
      prenoms: selectedItem ? selectedItem.prenoms : "",
      lieu_naissance: selectedItem ? selectedItem.lieu_naissance : "",
      telephone: selectedItem ? selectedItem.telephone : "",
      email: selectedItem ? selectedItem.email : "",

      date_naissance: selectedItem ? selectedItem.date_naissance : "",
      age: selectedItem ? selectedItem.age : "",

      nationalite: selectedItem ? selectedItem.nationalite : "",
      etat_matrimoniale: selectedItem ? selectedItem.etat_matrimoniale : "",

      date_embauche: selectedItem ? selectedItem.date_embauche : "",
      anciennete: selectedItem ? selectedItem.anciennete : "",
      cnps: selectedItem ? selectedItem.cnps : "",
      categorie_professionnelle: selectedItem
        ? selectedItem.categorie_professionnelle
        : "",
      statut: selectedItem ? selectedItem.statut : "",
      genre: selectedItem ? selectedItem.genre : "",
      fonction: selectedItem ? selectedItem.fonction : "",
      nombre_jr_acquis: selectedItem ? selectedItem.nombre_jr_acquis : "",
      // photo:'',

      site_travail: selectedItem ? selectedItem.site_travail : "",
      statut_chantier:
        (selectedItem && selectedItem.statut_chantier=== -1) ? false : true,
      // End of profil client
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      values.ops = "ANO RAYMOND";
      values.age = calculateAge(date_naissance).toString();
      values.anciennete = calculateAnciennete(date_embauche).toString();

      delete values.nombre_jr_acquis;
      values.statut_chantier === true
        ? (values.statut_chantier = 1)
        : (values.statut_chantier = -1);
      //  delete values.photo;

      console.log('values.statut_chantier',values.statut_chantier);
      

      if (selectedItem) {
        values.id = selectedItem.id;
      }
      console.log("values", values);
      if (selectedItem) {
        console.log("selectedItem", selectedItem);
        console.log("updatePersonnelAsync values", values);

        store.dispatch(updatePersonnelAsync(values));
      } else {
        console.log("createPersonnelAsync", values);
        store.dispatch(createPersonnelAsync(values));
      }
      closeModal();
    },
  });

  return (
    <form
      encType="multipart/form-data"
      className="create-list-form"
      onSubmit={formik.handleSubmit}
    >
      <span className="title">
        {" "}
        {selectedItem ? "Modification employé" : "Ajout d'un employé"}{" "}
      </span>

      <Grid container rowSpacing={2} columnSpacing={2}>
        {/* <Grid item xs={12} sm={12} lg={12} xl={12}>
                    {preview && (
                    <img src={preview} alt="Photo de l'employé" style={{ maxWidth: '200px' }} />
                    )}
                    <TextField placeholder="Photo"
                        variant="outlined"
                        name="photo"
                        label="Photo"
                        id="photo"
                        className='my-text-field'
                        onChange={handlePhotoChange}
                        value={photo}
                        error={formik.touched.photo && Boolean(formik.errors.photo)}
                        type="file" />
                </Grid> */}

        {/* <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Nom"
            variant="outlined"
            name="nom"
            label="Nom"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.nom}
            error={formik.touched.nom && Boolean(formik.errors.nom)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Prénoms"
            variant="outlined"
            name="prenoms"
            label="Prénoms"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.prenoms}
            error={formik.touched.prenoms && Boolean(formik.errors.prenoms)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Email"
            variant="outlined"
            name="email"
            label="Email"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Matricule"
            variant="outlined"
            name="matricule"
            label="Matricule"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.matricule}
            error={formik.touched.matricule && Boolean(formik.errors.matricule)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Phone"
            variant="outlined"
            name="telephone"
            label="Phone"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.telephone}
            error={formik.touched.telephone && Boolean(formik.errors.telephone)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <Autocomplete
            id="id-service-select"
            options={genreList}
            value={
              selectedItem
                ? { id: selectedItem.genre, value: selectedItem.genre }
                : genre
            }
            onChange={handleGenreChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(option) => option.value}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder="Sexe"
                label="Sexe"
                variant="outlined"
                name="genre"
                onChange={formik.handleChange}
                value={formik.values.genre}
                error={formik.touched.genre && Boolean(formik.errors.genre)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DatePicker
              label="Date de naissance"
              value={date_naissance}
              onChange={(newValue: any) => {
                setDate_naissannce(newValue);
                console.log("newValue", newValue.format("YYYY-MM-DD HH:mm:ss"));
                formik.setFieldValue(
                  "date_naissance",
                  newValue.format("YYYY-MM-DD HH:mm:ss")
                );
              }}
              renderInput={(params) => (
                <TextField {...params} className="my-text-field" />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Age"
            variant="outlined"
            name="age"
            label="Age"
            disabled
            className="my-text-field"
            onChange={formik.handleChange}
            value={calculateAge(date_naissance)}
            error={formik.touched.age && Boolean(formik.errors.age)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Lieu de naissance"
            variant="outlined"
            name="lieu_naissance"
            label="Lieu de naissance"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.lieu_naissance}
            error={
              formik.touched.lieu_naissance &&
              Boolean(formik.errors.lieu_naissance)
            }
            type="text"
          />
        </Grid> */}

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Nom"
                        variant="outlined"
                        name="nom"
                        label="Nom"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.nom}
                        error={formik.touched.nom && Boolean(formik.errors.nom)}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Prénoms"
                        variant="outlined"
                        name="prenoms"
                        label="Prénoms"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.prenoms}
                        error={formik.touched.prenoms && Boolean(formik.errors.prenoms)}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Email"
                        variant="outlined"
                        name="email"
                        label="Email"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Matricule"
                        variant="outlined"
                        name="matricule"
                        label="Matricule"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.matricule}
                        error={formik.touched.matricule && Boolean(formik.errors.matricule)}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Phone"
                        variant="outlined"
                        name="telephone"
                        label="Téléphone"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.telephone}
                        error={formik.touched.telephone && Boolean(formik.errors.telephone)}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <Autocomplete
                        id="id-service-select"
                        options={genreList}
                        value={selectedItem ? {id:selectedItem.genre , value:selectedItem.genre} :genre}
                        onChange={handleGenreChange}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.value}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.value}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='my-text-field'
                                placeholder="Sexe"
                                label="Sexe"
                                variant="outlined"
                                name="genre"
                                onChange={formik.handleChange}
                                value={formik.values.genre}
                                error={formik.touched.genre && Boolean(formik.errors.genre)}
                            />
                        )} />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" >
                    <DatePicker
                        label="Date de naissance"
                        value={date_naissance}
                        onChange={(newValue: any) => {
                        setDate_naissannce(newValue);
                        console.log('newValue', newValue.format('YYYY-MM-DD HH:mm:ss'));
                        formik.setFieldValue("date_naissance", newValue.format('YYYY-MM-DD HH:mm:ss'));
                        }}
                        renderInput={(params) => <TextField {...params} className='my-text-field' />}
                    />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Age"
                        variant="outlined"
                        name="age"
                        label="Age"
                        disabled
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={calculateAge(date_naissance)}
                        error={formik.touched.age && Boolean(formik.errors.age)}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Lieu de naissance"
                        variant="outlined"
                        name="lieu_naissance"
                        label="Lieu de naissance"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.lieu_naissance}
                        error={formik.touched.lieu_naissance && Boolean(formik.errors.lieu_naissance)}
                        type="text" />
                </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Nationalité"
            variant="outlined"
            name="nationalite"
            label="Nationalité"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.nationalite}
            error={
              formik.touched.nationalite && Boolean(formik.errors.nationalite)
            }
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <Autocomplete
            id="id-service-select"
            options={etatMatrimonialeList}
            value={
              selectedItem
                ? {
                    id: selectedItem.etat_matrimoniale,
                    value: selectedItem.etat_matrimoniale,
                  }
                : etatMatrimoniale
            }
            onChange={handleEtatMatrimonialeChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(option) => option.value}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder="Etat matrimonial"
                label="Etat matrimonial"
                variant="outlined"
                name="etat_matrimoniale"
                onChange={formik.handleChange}
                value={formik.values.etat_matrimoniale}
                error={
                  formik.touched.etat_matrimoniale &&
                  Boolean(formik.errors.etat_matrimoniale)
                }
              />
            )}
          />
        </Grid>
      </Grid>

      <span className="sub-title-part">
        {" "}
        <InlineIcon icon="bx:map-pin" /> &nbsp; Information sur l 'embauche{" "}
      </span>
      <Divider className="custom-divider" />

      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DatePicker
              label="Date d'embauche"
              value={date_embauche}
              onChange={(newValue: any) => {
                setDate_dembauche(newValue);
                formik.setFieldValue(
                  "date_embauche",
                  newValue.format("YYYY-MM-DD HH:mm:ss")
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="my-text-field"
                  name="date_embauche"
                  value={date_embauche}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Ancienneté"
            variant="outlined"
            name="anciennete"
            label="Ancienneté"
            className="my-text-field"
            onChange={formik.handleChange}
            value={calculateAnciennete(date_embauche)}
            error={
              formik.touched.anciennete && Boolean(formik.errors.anciennete)
            }
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="N° CNPS"
            variant="outlined"
            name="cnps"
            label="N° CNPS "
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.cnps}
            error={formik.touched.cnps && Boolean(formik.errors.cnps)}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <Autocomplete
            id="id-service-select"
            options={siteTravailList}
            value={
              selectedItem
                ? {
                    id: selectedItem.site_travail,
                    value: selectedItem.site_travail,
                  }
                : siteTravail
            }
            onChange={handleSiteTravailChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(option) => option.value}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder="Site de travail"
                label="Site de travail"
                variant="outlined"
                name="site_travail"
                onChange={formik.handleChange}
                value={formik.values.site_travail}
                error={
                  formik.touched.site_travail &&
                  Boolean(formik.errors.site_travail)
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <Autocomplete
            id="id-service-select"
            options={statutList}
            value={
              selectedItem
                ? { id: selectedItem.statut, value: selectedItem.statut }
                : statut
            }
            onChange={handleStatutChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(option) => option.value}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder=" "
                label="Statut"
                variant="outlined"
                name="statut"
                onChange={formik.handleChange}
                value={formik.values.statut}
                error={formik.touched.statut && Boolean(formik.errors.statut)}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <Autocomplete
            id="id-service-select"
            options={categorieList}
            value={
              selectedItem
                ? {
                    id: selectedItem.categorie_professionnelle,
                    value: selectedItem.categorie_professionnelle,
                  }
                : categorie
            }
            onChange={handleCategorieChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(option) => option.value}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder="Catégorie professionnelle"
                label="Catégorie professionnelle"
                variant="outlined"
                name="categorie_professionnelle"
                onChange={formik.handleChange}
                value={formik.values.categorie_professionnelle}
                error={
                  formik.touched.categorie_professionnelle &&
                  Boolean(formik.errors.categorie_professionnelle)
                }
              />
            )}
          />
        </Grid>

        {/* <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Fonction"
                        variant="outlined"
                        name="fonction"
                        label="Fonction"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.fonction}
                        error={formik.touched.fonction && Boolean(formik.errors.fonction)}
                        type="text" />
                </Grid> */}

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <Autocomplete
            id="id-service-select"
            options={fonctionList}
            value={
              selectedItem
                ? { id: selectedItem.fonction, value: selectedItem.fonction }
                : fonction
            }
            onChange={handleFonctionChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(option) => option.value}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder="Fonction"
                label="fonction"
                variant="outlined"
                name="fonction"
                onChange={formik.handleChange}
                value={formik.values.fonction}
                error={
                  formik.touched.fonction && Boolean(formik.errors.fonction)
                }
              />
            )}
          />
        </Grid>
      </Grid>

      <span className="sub-title-part">
        {" "}
        <InlineIcon icon="bx:user" /> &nbsp; Autres{" "}
      </span>
      <Divider className="custom-divider" />

      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Jours de congés acquis"
            variant="outlined"
            name="nombre_jr_acquis"
            label="Jours de congés acquis"
            disabled
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.nombre_jr_acquis}
            error={
              formik.touched.nombre_jr_acquis &&
              Boolean(formik.errors.nombre_jr_acquis)
            }
            type="text"
          />
        </Grid>

        {selectedItem && (
          <Grid item xs={12} sm={12} lg={12} xl={6}>
            <div className="switch-container">
              <Typography>Présent au travail</Typography>
              <FormGroup className="my-toggle">
                <FormControlLabel
                  label={formik.values.statut_chantier}
                  control={
                    <Switch
                      name="statut_chantier"
                      checked={formik.values.statut_chantier}
                      onChange={formik.handleChange}
                    />
                  }
                />
              </FormGroup>
            </div>
          </Grid>
        )}
      </Grid>

      <div className="btn-part">
        <Button
          color="secondary"
          className="btn-option"
          type="submit"
          startIcon={<InlineIcon icon="iconoir:save-floppy-disk" />}
          variant="contained"
        >
          {selectedItem ? "Modifier" : "Créer"}
        </Button>

        <Button
          color="light"
          className="btn-option"
          startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
          onClick={() => {}}
          variant="contained"
        >
          Réinitialiser
        </Button>
      </div>
    </form>
  );
};

export default CreatePersonnelForm;
