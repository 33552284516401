import { AffectationService } from "@/services/affectation.service";
import { affectationTypes } from "./affectation.types";

export const setLoading = (loading:any) => ({
    type: affectationTypes.LOADING,
    payload: loading,
});

export const getAffectedPersonsByChantierList = (list:any) => ({
    type: affectationTypes.GET_AFFECTED_PERSON_BY_CHANTIER_LIST,
    payload: list,
});

export const selectedAffectedPersonsByChantierItem = (affectation:any) => ({
  type: affectationTypes.SELECTED_AFFECTED_PERSON_BY_CHANTIER,
  payload: affectation,
});

export const getAffectedEnginsByChantierList = (list:any) => ({
  type: affectationTypes.GET_AFFECTED_ENGIN_BY_CHANTIER_LIST,
  payload: list,
});

export const selectedAffectedEnginsByChantierItem = (affectation:any) => ({
  type: affectationTypes.SELECTED_AFFECTED_ENGIN_BY_CHANTIER,
  payload: affectation,
});

export const getDetailAboutEnginAffected = (detail:any) => ({
  type: affectationTypes.GET_DETAIL_ABOUT_ENGIN_AFFECTED,
  payload: detail,
});

export const getDetailAboutRhAffected = (detail:any) => ({
  type: affectationTypes.GET_DETAIL_ABOUT_RH_AFFECTED,
  payload: detail,
});

 // affectation person on chantier
export const  getAllAffectedPersonsByChantierAsync =  (id_chantier:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  AffectationService.getAffectedPersonsByChantier(id_chantier);
};

export const  createPersonAffectationAsync =  (affectation:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  AffectationService.createPersonAffectation(affectation);
};

export const  updatePersonAffectationAsync =  (id_chantier:any, affectation:any) =>
(dispatch:any) => {
  console.log("Affectation to service", affectation);
  dispatch(setLoading(true));
  AffectationService.updatePersonAffectation(id_chantier, affectation);
};

export const  updatePersonAffectatedAsync =  (affectation:any) =>
(dispatch:any) => {
  console.log("Affectation to service", affectation);
  dispatch(setLoading(true));
  AffectationService.updatePersonAffectated(affectation);
};

export const  deletePersonAffectationAsync =  (id_chantier:any, affectation:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  AffectationService.deletePersonAffectation(id_chantier, affectation);
};

export const  getDetailAboutRhAffectatedAsync =  (data:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  AffectationService.detailAboutRhAffectated(data);
};



// affection engin on chantier
export const  getAllAffectedEnginsByChantierAsync =  (id_chantier:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  AffectationService.getAffectedEnginsByChantier(id_chantier);
};

export const  createEnginAffectationAsync =  (affectation:any) =>
(dispatch:any) => {
  console.log("Affectation new", affectation);
  AffectationService.createEnginAffectation(affectation);
  dispatch(setLoading(true));

};

export const  updateEnginAffectationAsync =  (affectation:any) =>
(dispatch:any) => {
  console.log("Affectation to service", affectation);
  dispatch(setLoading(true));
  AffectationService.updateEnginAffectation(affectation);
};

export const  deleteEnginAffectationAsync =  (id_chantier:any, affectation:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  AffectationService.deleteEnginAffectation(id_chantier, affectation);
};

export const  callbackPersonAffectatedAsync =  (data:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  AffectationService.callbackPersonAffectated(data);
};

export const  callbackEnginAffectatedAsync =  (data:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  AffectationService.callbackEnginAffectated(data);
};

export const  getDetailAboutEnginAffectatedAsync =  (data:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  AffectationService.detailAboutEnginAffectated(data);
};


export const  createUseAffectedEnginAsync =  (data:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  AffectationService.createUseAffectedEngin(data);
};

export const  updateUseAffectedEnginAsync =  (data:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  AffectationService.updateUseAffectedEngin(data);
};

