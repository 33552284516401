import { AbsenceService } from "../../../services/absence.service";
import { absenceTypes } from "./absence.types";

export const setLoading = (loading:any) => ({
    type: absenceTypes.LOADING,
    payload: loading,
});

export const getAllAbsences = (list:any) => ({
    type: absenceTypes.GET_ABSENCE_LIST,
    payload: list,
});

export const selectedItem = (absence:any) => ({
  type: absenceTypes.SELECTED_ABSENCE,
  payload: absence,
});

 
export const  getAllAbsencesAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  AbsenceService.getAbsences();
};

export const  createAbsenceAsync =  (absence:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  AbsenceService.createAbsence(absence);
};

export const  updateAbsenceAsync =  (absence:any) =>
(dispatch:any) => {
  console.log("absence to service", absence);
  dispatch(setLoading(true));
  AbsenceService.updateAbsence(absence);
};

export const  deleteAbsenceAsync =  (absence:any) =>
(dispatch:any) => {
  console.log("absence to service", absence);
  dispatch(setLoading(true));
  AbsenceService.deleteAbsence(absence);
};



