import { InlineIcon } from '@iconify/react';
import {  Autocomplete, Box, Button, Divider, FormControlLabel, FormGroup, Grid, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr';
import './CreateAbsencesForm.style.scss';
import { createStructuredSelector } from "reselect";
import { connect } from 'react-redux';
import { createAbsenceAsync, updateAbsenceAsync } from '@/store/reducers/absence/absence.action';
import { getAllPersonnelsAsync } from '@/store/reducers/personnel/personnel.action';
import { selectPersonnelItemList } from '@/store/reducers/personnel/personnel.selector';


const CreateAbsencesFormPage:  React.FC<any> = ({ selectedItem, getAllPersonnelsAsync, getAllPersonnels, closeModal})=> {

    useEffect(() => {
        const url:any = getAllPersonnelsAsync();
    
        const fetchData = async () => {
            try {
                await fetch(url);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, [getAllPersonnelsAsync]);
 
    const motifList: { id: string, value: string }[] = [
        { id: 'Exceptionnelle', value: 'Exceptionnelle' },
        { id: 'Personnelle (a déduire du congé annuel)', value: 'Personnelle (a déduire du congé annuel)' },
        { id: 'Arrêt maladie', value: 'Arrêt maladie' },
        { id: 'Arrêt de travail pour  accident de travail', value: 'Arrêt de travail pour  accident de travail' },
        { id: 'Absence Injustifiée', value: 'Absence Injustifiée'}
    ];


    const listpersonnel:any[] = [] 
    getAllPersonnels.forEach((y:any) => {
        listpersonnel.push({
          id: y.id,
          nom: y.nom + ' ' + y.prenoms,
          nombre_jr_acquis: y.nombre_jr_acquis
        }) 
    });

    const [personnel, setPersonnel] = useState<{ id: string, nom: string, nombre_jr_acquis: string  }>({ id: '', nom: '', nombre_jr_acquis:'' });
    const handlePersonnelChange = (event: any, value:{ id: string, nom: string, nombre_jr_acquis: string  } | null) => {
        console.log(value);
        if (value == null) {
            setPersonnel({ id: '', nom: '', nombre_jr_acquis: '' });
        } else {
            setPersonnel(value as { id: string, nom: string, nombre_jr_acquis: string  });
        }
        formik.setFieldValue('personnelId', value?.id);
    };

    const [motif, setMotif] = useState<{ id: string, value: string }>({ id: '', value: '' });
    const handleMotifChange = (event: any, value: { id: string, value: string } | null) => {
        console.log(value);
        if (value == null) {
            setMotif({ id: '', value: '' });
        } else {
            setMotif(value as { id: string, value: string });
        }
        formik.setFieldValue('motif', value?.value);
    };

    const [date_depart, setDate_depart] =  useState(selectedItem ? selectedItem.date_depart: new Date());
    const [date_retour, setDate_retour] =  useState(selectedItem ? selectedItem.date_retour: new Date());

    // const [date_prochain, setDate_prochain] = React.useState<Dayjs | null>(null);


    const validationSchema = yup.object({
        date_depart: yup.string().required(" La date de depart est obligatoire "),
        date_retour: yup.string().required(" La date de retour est obligatoire "),
        motif: yup.string().required(" Le motif est obligatoire "),
    });

    const formik = useFormik({
        initialValues: {
            date_depart:  selectedItem ? selectedItem.date_depart : '',
            date_retour:  selectedItem ? selectedItem.date_retour : '',
            nombre_jr_absent: selectedItem ? selectedItem.nombre_jr_absent : '',
            personnelId:  selectedItem ? selectedItem.personnel.id : '',
            motif: selectedItem ? selectedItem.motif : '',
            observation:  selectedItem ? selectedItem.observation : '',
            deduire_conge:  selectedItem ? selectedItem.deduire_conge : false,


            // End of profil client
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {

            values.ops = 'ANO RAYMOND';
            values.deduire_conge = (values.deduire_conge === 'oui')? true: false;
            // values.date_depart = dayjs(values.date_depart).format('YYYY/MM/DD');
            // values.date_retour = dayjs(values.date_retour).format('YYYY/MM/DD');
            // values.date_prochain_conges = dayjs(values.date_prochain_conges).format('YYYY/MM/DD');
            console.log('Values form', values);
            // return;
            if (!selectedItem) {
               createAbsenceAsync(values);
              //  store.dispatch(createAbsenceAsync(values));
            } else {
               // updateAbsenceAsync(values);
               values.id = selectedItem.id;
                updateAbsenceAsync(values);
               // store.dispatch(updateAbsenceAsync(values));
            }
            closeModal();
        }
    });

    const resetForm = () => {
        formik.resetForm();
        setPersonnel({ id: '', nom: '', nombre_jr_acquis: '' });
    } 


    return (
        <form className='create-list-form' onSubmit={formik.handleSubmit}>
            <span className="title">    {selectedItem ? 'Modificationn d\'une Demande de permission' : 'Demande de permission'} </span>

            <Grid container rowSpacing={2} columnSpacing={2} >

                <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <Autocomplete
                        id="id-service-select"
                        options={listpersonnel}
                        value={selectedItem ? {id:selectedItem.personnel.id , nom:selectedItem.personnel.nom + ' ' + selectedItem.personnel.prenoms, nombre_jr_acquis: selectedItem.personnel.nombre_jr_acquis } :personnel}
                        onChange={handlePersonnelChange}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option.nom === value.nom}
                        getOptionLabel={(option) => option.nom}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.nom}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='my-text-field'
                                placeholder="Personnel"
                                label="Personnel"
                                variant="outlined"
                                name="personnelId"
                                onChange={formik.handleChange}
                                value={formik.values.personnelId}
                                error={formik.touched.personnelId && Boolean(formik.errors.personnelId)}
                            />
                        )} />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" >
                    <DatePicker
                        label="Date de départ"
                        value={date_depart}
                        onChange={(newValue: any) => {
                        setDate_depart(newValue);
                        formik.setFieldValue("date_depart", newValue.format('YYYY-MM-DD HH:mm:ss'));
                        }}
                        renderInput={(params) => <TextField {...params} 
                        className='my-text-field'
                        name="date_depart"
                        onChange={formik.handleChange}
                        value={formik.values.date_depart}
                        error={formik.touched.date_depart && Boolean(formik.errors.date_depart)}
                         />}
                    />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                    <DatePicker
                        label="Date de reprise"
                        value={date_retour}
                        onChange={(newValue: any) => {
                        setDate_retour(newValue);
                        formik.setFieldValue("date_retour", newValue.format('YYYY-MM-DD HH:mm:ss'));
                        }}
                        renderInput={(params) => <TextField {...params} 
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.date_retour}
                        error={formik.touched.date_retour && Boolean(formik.errors.date_retour)}
                         />}
                    />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <TextField placeholder="Nombre de jour d'absence"
                        variant="outlined"
                        name="nombre_jr_absent"
                        label="Nombre de jour d'absence"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.nombre_jr_absent}
                        error={formik.touched.nombre_jr_absent && Boolean(formik.errors.nombre_jr_absent)}
                        type="number" />
                </Grid>
                {/* <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                    <DatePicker
                        label="Date congé suivant"
                        value={date_prochain}
                        onChange={(newValue) => {
                        setDate_prochain(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} className='my-text-field' name='date_prochain_conges' />}
                    />
                    </LocalizationProvider>
                </Grid> */}

                <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <div className="switch-container">
                        <Typography>Déduire du congé annuel ?</Typography>
                        <FormGroup className='my-toggle'>
                            <FormControlLabel
                            label={formik.values.deduire_conge ? "Oui" : "Non"}
                            control={<Switch name="deduire_conge"
                                checked={formik.values.deduire_conge}
                                onChange={formik.handleChange} />}
                            />
                        </FormGroup>
                    </div>
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <Autocomplete
                        id="id-service-select"
                        options={motifList}
                        value={selectedItem ? {id:selectedItem.motif , value:selectedItem.motif} :motif}
                        onChange={handleMotifChange}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.value}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.value}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='my-text-field'
                                placeholder="Motif"
                                label="Motif"
                                variant="outlined"
                                name="motif"
                                onChange={formik.handleChange}
                                value={formik.values.motif}
                                error={formik.touched.motif && Boolean(formik.errors.motif)}
                            />
                        )} />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <TextField placeholder="Observation"
                        variant="outlined"
                        name="observation"
                        label="Observation"
                        multiline
                        className='my-text-field-large'
                        onChange={formik.handleChange}
                        value={formik.values.observation}
                        error={formik.touched.observation && Boolean(formik.errors.observation)}
                        type="text" />
                </Grid>
            </Grid>


            <Divider className='custom-divider' />

            <div className="btn-part">
                <Button color='secondary'
                    className='btn-option'
                    type='submit'
                    startIcon={<InlineIcon icon="iconoir:save-floppy-disk" />}
                    variant='contained'>
                      {selectedItem ? 'Modifier' : 'Créer'}
                </Button>

                <Button color='light'
                    className='btn-option'
                    startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
                    onClick={() => { resetForm() }}
                    variant='contained'>
                    Réinitialiser
                </Button>
            </div>

        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    getAllPersonnels: selectPersonnelItemList,
});

const mapDispatchToProps = (dispatch:any) => ({
    getAllPersonnelsAsync: () => dispatch(getAllPersonnelsAsync()),
    createAbsenceAsync: (data:any) => dispatch(createAbsenceAsync(data)),
    updateAbsenceAsync: (data:any) => dispatch(updateAbsenceAsync(data)),
});
  
const connector = connect(mapStateToProps,mapDispatchToProps);
const CreateAbsencesForm = connector(CreateAbsencesFormPage);

export default CreateAbsencesForm;