import { DashboardService } from "@/services/dashboard.service";
import { dashboardTypes } from "./dashboard.types";

export const setLoading = (loading:any) => ({
    type: dashboardTypes.LOADING,
    payload: loading,
});

export const getAllDashboard = (list:any) => ({
    type: dashboardTypes.GET_DASHBOARD_LIST,
    payload: list,
});

export const getTypedashboard = (type:any) => ({
  type: dashboardTypes.GET_DASHBOARD_USER,
  payload: type,
});

export const setDashboardTableData = (data:any) => ({
  type: dashboardTypes.SET_DASHBOARD_TABLE_DATA,
  payload: data,
});

export const setDashboardHistoriqueData = (data:any) => ({
  type: dashboardTypes.SET_DASHBOARD_HISTORIQUE_DATA,
  payload: data,
});

 
export const  getAllDashboardAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  DashboardService.getDashboard();
};

export const  dashboardByTypeAsync =  (dashboard:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  DashboardService.dashboardByType(dashboard);
};

export const  dashboardHistoriqueChantierAsync =  (id:number) =>
  (dispatch:any) => {
    dispatch(setLoading(true));
    DashboardService.dashboardHistoriqueChantier(id);
  };

export const  dashboardHistoriqueEnginAsync =  (id:number) =>
  (dispatch:any) => {
    dispatch(setLoading(true));
    DashboardService.dashboardHistoriqueEngin(id);
  };

export const  dashboardHistoriquePersonnelAsync =  (id:number) =>
  (dispatch:any) => {
    dispatch(setLoading(true));
    DashboardService.dashboardHistoriquePersonnel(id);
  };

  