import React, { Fragment, useState } from 'react';
import './SynthesePersonnel.style.scss';
import { Button, Divider,  Grid, IconButton, TextField, } from '@mui/material';
import { InlineIcon } from '@iconify/react';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { exportToExcel } from '@/config';

const SynthesePersonnel: React.FC<any> = ({ selectedItem }) => {

    console.log('selectedItem', selectedItem);

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);

    const handleClose = () => {
        setOpen(false);
        setUtilisationEngins([]);
        setItemSelected([]);
    };

    const handleClose2 = () => {
        setOpen2(false);
        setAbsencePersonnel([]);
        setItemSelected([]);
    };

    const columns = [
        { field: 'date', headerName: 'Date',   valueGetter: (params: any) => {
            // Format the date using moment
            return moment(params.value).format('DD-MM-YYYY');
        },width: 190 },
        { field: 'travail', headerName: 'travail', width: 190 },
        { field: 'compteur_debut', headerName: 'compteur_debut', width: 190 },
        { field: 'compteur_fin', headerName: 'compteur_fin', width: 190 },
        { field: 'quantite', headerName: 'quantite', width: 190 },
        { field: 'carburant', headerName: 'Carburant', width: 190 },
        { field: 'chauffeur', headerName: 'Chauffeur', width: 190 },
        { field: 'nature_travaux', headerName: 'Nature Travaux', width: 190 },
        { field: 'motif', headerName: 'Motif', width: 190 },
        { field: 'observation', headerName: 'observation', width: 190 },
      ];

    const personnelColumns = [
        { field: 'date', headerName: 'Date',   valueGetter: (params: any) => {
            // Format the date using moment
            return moment(params.value).format('DD-MM-YYYY');
        },width: 120 },
        { field: 'heure_debut', headerName: 'Heure Debut', width: 120 },
        { field: 'heure_fin', headerName: 'Heure Fin', width: 120 },
        { field: 'motif', headerName: 'Motif', width: 320 },
        { field: 'observation', headerName: 'observation', width: 190 },
      ];


    let chantierFormArray = selectedItem?.chantier;

    const [utilisationEngins, setUtilisationEngins] = useState([]);
    const [absencePersonnel, setAbsencePersonnel] = useState([]);



    const handleSeeMorePersonnelFormArray = (absences_chantiers: any) => {
      //  const personnelData = selectedItem?.chantier?.find((value: any) => value.id === id);

        if (absences_chantiers) {
            setAbsencePersonnel(absences_chantiers || []);
         //   setItemSelected(personnelData);
            setOpen2(true);
        }
    };

       // Appel de la fonction d'exportation lorsque vous souhaitez exporter les données
    const handleExportUtilisationExcel = () => {
        exportToExcel(utilisationEngins, columns, 'utilisationEngin');
    };

    const handleExportPersonnelExcel = () => {
        exportToExcel(absencePersonnel, personnelColumns, 'utilisationPersonnel');
    };


    return (<>
        <form className='create-list-form'>
            <span className="title"> Synthese {selectedItem?.personnel.nom} ({selectedItem?.personnel.prenoms}) </span>

            <Grid container rowSpacing={2} columnSpacing={2} >

            <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Nom"
                        variant="outlined"
                        name="nom"
                        label="Nom"
                        className='my-text-field'
                        value={selectedItem?.personnel?.nom}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Prénoms"
                        variant="outlined"
                        name="prenoms"
                        label="Prénoms"
                        className='my-text-field'
                        value={selectedItem?.personnel?.prenoms}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Email"
                        variant="outlined"
                        name="email"
                        label="Email"
                        className='my-text-field'
                        value={selectedItem?.personnel?.email}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Matricule"
                        variant="outlined"
                        name="matricule"
                        label="Matricule"
                        className='my-text-field'
                        value={selectedItem?.personnel?.matricule}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Phone"
                        variant="outlined"
                        name="telephone"
                        label="Phone"
                        className='my-text-field'
                        value={selectedItem?.personnel?.telephone}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Sexe"
                        variant="outlined"
                        name="sexe"
                        label="Sexe"
                        className='my-text-field'
                        value={selectedItem?.personnel?.genre}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Date de naissance"
                        variant="outlined"
                        name="Date de naissance"
                        label="Date de naissance"
                        className='my-text-field'
                        value={selectedItem?.personnel?.date_naissance}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Age"
                        variant="outlined"
                        name="Age"
                        label="Age"
                        className='my-text-field'
                        value={selectedItem?.personnel?.age}
                        type="text" />
                </Grid>
               
               
               
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Lieu de naissance"
                        variant="outlined"
                        name="lieu_naissance"
                        label="Lieu de naissance"
                        className='my-text-field'
                        value={selectedItem?.personnel?.lieu_naissance}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Nationalité"
                        variant="outlined"
                        name="nationalite"
                        label="Nationalité"
                        className='my-text-field'
                        value={selectedItem?.personnel?.nationalite}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        variant="outlined"
                        placeholder="Etat matrimonial"
                        label="Etat matrimonial"
                        className='my-text-field'
                        value={selectedItem?.personnel?.etat_matrimoniale}
                        type="text" />
                </Grid>
                <span className="sub-title-part"> <InlineIcon icon="bx:map-pin" /> &nbsp;  Information sur l 'embauche </span>
                    <Divider className='custom-divider' />

                    <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField  label="Date d'embauche"
                        variant="outlined"
                        name="date"
                        className='my-text-field'
                        value={selectedItem?.personnel?.date_embauche}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField
                        variant="outlined"
                        placeholder="Ancienneté"
                        label="Ancienneté"
                        className='my-text-field'
                        value={selectedItem?.personnel?.date_embauche}
                        type="text" />
                </Grid>
      
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="N° CNPS"
                        variant="outlined"
                        name="cnps"
                        label="N° CNPS "
                        className='my-text-field'
                        value={selectedItem?.personnel?.cnps}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField 
                        placeholder="Site de travail"
                        label="Site de travail"
                        variant="outlined"
                        name="site_travail"
                        className='my-text-field'
                        value={selectedItem?.personnel?.site_travail}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField 
                         placeholder="Fonction"
                         label="fonction"
                         variant="outlined"
                         name="fonction"
                        className='my-text-field'
                        value={selectedItem?.personnel?.fonction}
                        type="text" />
                </Grid>


                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField 
                        placeholder="Catégorie professionnelle"
                        label="Catégorie professionnelle"
                        variant="outlined"
                        name="categorie_professionnelle"
                        className='my-text-field'
                        value={selectedItem?.personnel?.categorie_professionnelle}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField 
                         placeholder="Statut"
                         label="Statut"
                         variant="outlined"
                         name="statut"
                        className='my-text-field'
                        value={selectedItem?.personnel?.statut}
                        type="text" />
                </Grid>




                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField 
                        placeholder="Jours de congés acquis"
                        variant="outlined"
                        name="nombre_jr_acquis"
                        label="Jours de congés acquis"
                        className='my-text-field'
                        value={selectedItem?.personnel?.nombre_jr_acquis}
                        type="text" />
                </Grid>
              
            </Grid>

         

            {
                (chantierFormArray?.length || chantierFormArray) &&
                <>
                    <span className="sub-title-part"> <InlineIcon icon="bx:user" /> &nbsp; Liste des Chantiers </span>
                    <Divider className='custom-divider' />

                    <Grid container rowSpacing={2} columnSpacing={2} >
                        {chantierFormArray?.map((m: any, i: number) => (
                            <Fragment key={m.id}>
                                <Grid item xs={12} sm={12} lg={12} xl={11} >
                                    <Grid container rowSpacing={1} columnSpacing={2} >
                                        <Grid item xs={12} sm={12} lg={12} xl={6} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m?.chantier?.nom}
                                                variant="outlined"
                                                name="nom"
                                                label="nom"
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12} xl={6} >
                                            <TextField
                                                inputProps={{
                                                    readOnly: Boolean(true),
                                                    disabled: Boolean(true),
                                                }}
                                                value={m?.chantier?.numero_chantier}
                                                variant="outlined"
                                                name="numero_chantier"
                                                label="numero_chantier"
                                                className='my-text-field'
                                                type="text" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid item xs={12} sm={12} lg={12} xl={1}>
                                    <IconButton
                                        onClick={() => { handleSeeMorePersonnelFormArray(m?.absences_chantiers) }}
                                        style={{ transform: 'translateY(5px)' }}
                                        size='small'
                                        color="error"
                                        title='Voir les details'>
                                        <InlineIcon icon="clarity:eye-line" />
                                    </IconButton>
                                </Grid>
                            
                            </Fragment>
                        ))}
                    </Grid>
                </>
            }


        </form>

        <div className="myModal" style={open ? { display: 'block' } : { display: 'none' }}>
            {/* <!-- myModal content --> */}
            <div className="myModal-content">
                <span className="myModal-close" onClick={handleClose}>&times;</span>
                <span className="myModal-title">Detail</span>
                <Button
                    color='blue'
                    className='btn-option'
                    onClick={handleExportUtilisationExcel}
                    //variant='contained'
                    startIcon={<InlineIcon icon="carbon:document-export" />}>
                    Exporter
                </Button>
                <div style={{ height: '85%', width: '100%' }}>
                    <DataGrid
                        rows={utilisationEngins}
                        columns={columns}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        localeText={{
                            MuiTablePagination: {
                            labelDisplayedRows: ({ from, to, count }) =>
                                `${from} - ${to} Total: ${count}`,
                            },
                        }}
                        />
                </div>
            </div>
        </div>

        <div className="myModal" style={open2 ? { display: 'block' } : { display: 'none' }}>
            {/* <!-- myModal content --> */}
            <div className="myModal-content">
                <span className="myModal-close" onClick={handleClose2}>&times;</span>
                <span className="myModal-title">Detail Personnel    </span>
                <Button
                    color='blue'
                    className='btn-option'
                    onClick={handleExportPersonnelExcel}
                    //variant='contained'
                    startIcon={<InlineIcon icon="carbon:document-export" />}>
                    Exporter
                </Button>
                <div style={{ height: '85%', width: '100%' }}>
                    <DataGrid
                        rows={absencePersonnel}
                        columns={personnelColumns}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        localeText={{
                            MuiTablePagination: {
                            labelDisplayedRows: ({ from, to, count }) =>
                                `${from} - ${to} Total: ${count}`,
                            },
                        }}
                        />
                </div>
            </div>
        </div>
    </>)
}

export default SynthesePersonnel