import React from 'react';
import CtAffectationRH from '../pages-content/AffectationRH-content/AffectationRH.content';

const AffectationRH:React.FC = () => {
  return (
    <>
      <CtAffectationRH />
    </>
  )
}

export default AffectationRH;