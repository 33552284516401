import personnelTypes from "./personnel.types";

interface IPersonnelReducerState {
  list: any,
  itemSelected: any,
  loading: boolean,
  error: string,
  getSyntheseList: any,
  getHistoriqueList: any,
}

const INITIAL_STATE = {
  list: [],
  itemSelected: {},
  loading: false,
  error: "",
  getSyntheseList: [],
  getHistoriqueList: {},
};

const personnelReducer = (
  state: IPersonnelReducerState = INITIAL_STATE,
  action: any
): IPersonnelReducerState => {
  switch (action.type) {
    case personnelTypes.GET_PERSONNEL_LIST:
      return {
        ...state,
        list: action.payload,
        error: ""
      };

    case personnelTypes.SELECTED_PERSONNEL:
      return {
        ...state,
        itemSelected: action.payload,
        error: ""
      };
    
    case personnelTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: ""
      };

    case personnelTypes.GET_SYNTHESE_PERSONNEL:
      return {
        ...state,
        getSyntheseList: action.payload,
        error: ""
      };

    case personnelTypes.GET_HISTORIQUE_PERSONNEL:
      return {
        ...state,
        getHistoriqueList: action.payload,
        error: ""
      };
    default:
      return state;
  }
};

export default personnelReducer;
