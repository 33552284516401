import { InlineIcon } from '@iconify/react';
import { Autocomplete, Box, Button,  Divider,  Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import './CreateCategorieEnginForm.style.scss';
import { connect } from 'react-redux';
import { createCategorieEnginAsync, updateCategorieEnginAsync } from '@/store/reducers/categorie-engin/categorieEngin.action';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';
import { createStructuredSelector } from 'reselect';
import { getAllTableDonneesAsync } from '@/store/reducers/table-donnee/tableDonnee.action';
import { selectTableDonneeItemList } from '@/store/reducers/table-donnee/tableDonnee.selector';

const CreateCategorieEnginFormPage: React.FC<any> = ({
    userConnected, 
    selectedItem,  
    getAllType,
    getAllTableDonneesAsync,
    createCategorieEnginAsync, 
    updateCategorieEnginAsync,
    closeModal
}) => {

    useEffect(() => {
        const url:any = getAllTableDonneesAsync();
    
        const fetchData = async () => {
            try {
                await fetch(url);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, []);

    const typeCompteurList: any = [];
    const typeVehiculeList: any = [];
    getAllType.forEach((x:any) => {
        if (x.type === 'TYPE COMPTEUR') {
            typeCompteurList.push({...x , value:x.libelle})
        }
        if (x.type === 'TYPE VEHICULE') {
            typeVehiculeList.push({...x , value:x.libelle})
        }
    })

    useEffect(() => {
        if (selectedItem) {
            // console.log('Selected Item set');
            // // format selected because patchvalue
            // Object.keys(selectedItem).forEach(x => { if (selectedItem[x] == null) selectedItem[x] = ''})
            // formik.setValues(selectedItem);
            let typeCompt: any = typeCompteurList.find((x:any) => x.value === selectedItem.type_compteur);
            setTypeCompteur(typeCompt);
            let typeV: any = typeVehiculeList.find((x:any) => x.value === selectedItem.type_vehicule);
            setTypeVehicule(typeV);
        }
    }, [selectedItem])

    const [typeCompteur, setTypeCompteur] = useState<{ id: string, value: string }>({ id: '', value: '' });
    const [typeVehicule, setTypeVehicule] = useState<{ id: string, value: string }>({ id: '', value: '' });

    const handleTypeCompteurChange = (event: any, value: { id: string, value: string } | null) => {
        console.log(value);
        if (value == null) {
            setTypeCompteur({ id: '', value: '' });
        } else {
            setTypeCompteur(value as { id: string, value: string });
        }
        formik.setFieldValue('type_compteur', value?.id);
    };

    const handleTypeVehiculeChange = (event: any, value: { id: string, value: string } | null) => {
        console.log(value);
        if (value == null) {
            setTypeVehicule({ id: '', value: '' });
        } else {
            setTypeVehicule(value as { id: string, value: string });
        }
        formik.setFieldValue('type_vehicule', value?.id.toString());
    };

    const resetForm = () => {
        setTypeCompteur({ id: '', value: '' });
        setTypeVehicule({ id: '', value: '' });
        formik.resetForm();
    }

    const validationSchema = yup.object({
        libelle: yup.string().required("Veuillez renseigner le libellé "),
        description: yup.string().required("Veuillez renseigner la description"),
        valeur_compteur: yup.number().required("Veuillez renseigner la description"),
        type_compteur: yup.string().required("Veuillez renseigner le type compteur"),
        type_vehicule: yup.string().required("Veuillez renseigner le type véhicule")
    });

    const formik = useFormik({
        initialValues: {
            id: selectedItem ? selectedItem.id : null,
            libelle: selectedItem ? selectedItem.libelle : '',
            description: selectedItem ? selectedItem.description : '',
            type_compteur: '',
            valeur_compteur: selectedItem ? selectedItem.valeur_compteur : '',
            type_vehicule: '',
            ops: (userConnected?.user?.nom +' '+userConnected?.user?.prenoms),
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            console.log("Values", values);

            if (!selectedItem) {
                delete values.id;
                createCategorieEnginAsync(values);
            } else {
                updateCategorieEnginAsync(values);
            }
            closeModal();
        }
    });


    return (
        <form className='create-list-form' onSubmit={formik.handleSubmit}>
            <span className="title"> {selectedItem ? 'Modification' : 'Création'} </span>

            <Grid container rowSpacing={2} columnSpacing={2} >
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Libelle de le catégorie"
                        variant="outlined"
                        name="libelle"
                        label="libelle"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.libelle}
                        error={formik.touched.libelle && Boolean(formik.errors.libelle)}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Description de la catégorie"
                        variant="outlined"
                        name="description"
                        label="description"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        type="text" />
                </Grid>

            </Grid>

            <span className="sub-title-part"> <InlineIcon icon="bx:map-pin" /> &nbsp; Information comptable </span>
            <Divider className='custom-divider' />

            <Grid container rowSpacing={1} columnSpacing={2} >
                
            <Grid item xs={12} sm={12} lg={12} xl={6} >
                <Autocomplete
                    id="id-service-select"
                    options={typeCompteurList}
                    value={typeCompteur || null}
                    onChange={handleTypeCompteurChange}
                    autoHighlight
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => option.value}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            {option.value}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className='my-text-field'
                            placeholder="Type compteur"
                            label="Type compteur"
                            variant="outlined"
                            name="type_compteur"
                            onChange={formik.handleChange}
                            value={formik.values.type_compteur = typeCompteur?.value}
                            error={formik.touched.type_compteur && Boolean(formik.errors.type_compteur)}
                        />
                    )} />
            </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Valeur Compteur"
                        variant="outlined"
                        name="valeur_compteur"
                        label="Valeur compteur"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.valeur_compteur}
                        error={formik.touched.valeur_compteur && Boolean(formik.errors.valeur_compteur)}
                        type="number" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <Autocomplete
                        id="id-service-select"
                        options={typeVehiculeList}
                        value={typeVehicule  || null}
                        onChange={handleTypeVehiculeChange}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.value}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.value}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='my-text-field'
                                placeholder="type véhicule"
                                label="Type véhicule"
                                variant="outlined"
                                name="type_compteur"
                                onChange={formik.handleChange}
                                value={formik.values.type_vehicule = typeVehicule?.value}
                                error={formik.touched.type_vehicule && Boolean(formik.errors.type_vehicule)}
                            />
                        )} />
                </Grid>
                
            </Grid>

            <Divider className='custom-divider' />

            <div className="btn-part">
                <Button color='secondary'
                    className='btn-option'
                    type='submit'
                    startIcon={<InlineIcon icon="iconoir:save-floppy-disk" />}
                    variant='contained'>
                    {!selectedItem ? 'Créer' : 'Modifier'}
                </Button>

                <Button color='light'
                    className='btn-option'
                    startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
                    onClick={() => {
                        resetForm()
                    }}
                    variant='contained'>
                    Réinitialiser
                </Button>
            </div>

        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    userConnected: selectAuthUser,
    getAllType: selectTableDonneeItemList,
});

const mapDispatchToProps = (dispatch:any) => ({
    getAllTableDonneesAsync: () => dispatch(getAllTableDonneesAsync()),
    createCategorieEnginAsync: (data:any) => dispatch(createCategorieEnginAsync(data)),
    updateCategorieEnginAsync: (data:any) => dispatch(updateCategorieEnginAsync(data)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CreateCategorieEnginForm = connector(CreateCategorieEnginFormPage);

export default CreateCategorieEnginForm;