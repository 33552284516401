import { routePaths } from '@/config/routePaths';
import { InlineIcon } from '@iconify/react';
import { Badge, Tab, Tabs, tabsClasses, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { notification } from 'antd';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import './Administration.content.layout.styles.scss';
import { selectAlerteCountList, selectAlerteItemList } from "@/store/reducers/alerte/alerte.selector";
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import Snackbar from '@/components/Snackbar/Snackbar';
import { selectUserProfil } from '@/store/reducers/profil/profil.selector';


const LAdministrationPage: React.FC<any> = ({ alerte, alerteList, permissions }) => {

  console.log('permission',permissions);
  

  const theme = useTheme();
  const matches_down_xl = useMediaQuery(theme.breakpoints.down('xl'));
  const matches_down_lg = useMediaQuery(theme.breakpoints.down('lg'));

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(routePaths.adminIVEQIChildren.administrationChildren.homeAbsolute, {replace:true});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const [api, contextHolder] = notification.useNotification();
  
  const openNotification = (placement: NotificationPlacement, topic:string) => {
    console.log('alerteList',alerteList);
    
    if (topic === 'vidange') {
      for (let i = 0; i < alerteList?.vidange?.length; i++) {
        api.warning({
          message: `Notification ${topic}`,
          description: `Rappel de vidange de l'engin N° ${alerteList?.vidange[i]?.num_parc} qui a un intervalle de ${alerteList?.vidange[i]?.intervalle_entretien} dont le compteur actuel est : ${alerteList?.vidange[i]?.dernier_compteur} et la derniere vidange était de ${alerteList?.vidange[i]?.derniere_entretien}`,
          placement,
        });
      }
    }
    if (topic === 'cuve') {
      for (let i = 0; i < alerteList?.cuve?.length; i++) {
        api.warning({
          message: `Notification ${topic}`,
          description: `Le niveau de la cuve ${alerteList?.cuve[i]?.libelle} est critique`,
          placement,
        });
      }
    }
  };

  return (
    <>
      {alerteList && <Snackbar content={contextHolder} />}
      <div className="l-administration-content">
        <div className="l-administration-content__sidebar">
          {/* {
            permissions?.parametre?.find((o:any) => (o.libelle === 'Accueil' && o.read === true)) && */}
            <NavLink to={routePaths.adminIVEQIChildren.administrationChildren.homeAbsolute}
            className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.administrationChildren.homeAbsolute) !== -1) ? " selected" : "")}>
            <InlineIcon icon="bx:home-alt" />
            Accueil
          </NavLink>
          {/* } */}
          {
            permissions?.parametre?.find((o:any) => (o.libelle === 'Habilitation' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.administrationChildren.rolesAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.administrationChildren.rolesAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="carbon:user-role" />
              Habilitation
            </NavLink>
          } 
          {
            permissions?.parametre?.find((o:any) => (o.libelle === 'Utilisateur' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.administrationChildren.utilisateursAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.administrationChildren.utilisateursAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="teenyicons:users-outline" />
              Utilisateur
            </NavLink>
          }  
          {
            permissions?.parametre?.find((o:any) => (o.libelle === 'Client' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.administrationChildren.clientsAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.administrationChildren.clientsAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="bi:briefcase-fill" />
              Client
            </NavLink>
          }
          {
            permissions?.parametre?.find((o:any) => (o.libelle === 'Nature travaux' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.administrationChildren.natureTravauxAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.administrationChildren.natureTravauxAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="bi:list-task" />
              Nature Travaux
            </NavLink>
          }  
          {
            permissions?.parametre?.find((o:any) => (o.libelle === 'Categorie equipement' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.administrationChildren.categorieEquipementAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.administrationChildren.categorieEquipementAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="fluent:window-dev-tools-16-filled" />
              Catégorie Equipement
            </NavLink>
          }  
          {
            permissions?.parametre?.find((o:any) => (o.libelle === 'equipement' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.administrationChildren.equipementsAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.administrationChildren.equipementsAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="carbon:tools" />
              Equipements
            </NavLink>
          } 
          {
            permissions?.parametre?.find((o:any) => (o.libelle === 'Categorie engin' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.administrationChildren.categorieEnginsAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.administrationChildren.categorieEnginsAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="bi:bullseye" />
              Catégorie Engin
            </NavLink>
          }  
          {
            permissions?.parametre?.find((o:any) => (o.libelle === 'engin' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.administrationChildren.enginsAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.administrationChildren.enginsAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="bi:truck" />
              {/* <Badge badgeContent={alerte?.cuve || 0} color="warning" onClick={() => openNotification('bottomRight', 'cuve')}>
              
              </Badge> */}
              Engin
            </NavLink>
          }  
          {
            permissions?.parametre?.find((o:any) => (o.libelle === 'equipement' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.administrationChildren.vidangesAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.administrationChildren.vidangesAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="bi:bullseye" />
              <Badge badgeContent={alerte?.vidange || 0} color="warning" onClick={() => openNotification('bottomRight', 'vidange')}>
              Vidange
              </Badge>
            </NavLink>
          }  
            
            
            
            {/* <NavLink to={routePaths.adminIVEQIChildren.administrationChildren.objectifsAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.administrationChildren.objectifsAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="carbon:chart-column-target" />
              Objectifs
            </NavLink>*/}
            
            {/* <NavLink to={routePaths.adminIVEQIChildren.administrationChildren.equipesAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.administrationChildren.equipesAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="fa-solid:people-carry" />
              Équipes
            </NavLink>  */}
        </div>
        <div className="l-administration-content__content">

          {
            (matches_down_xl || matches_down_lg) &&
            <>
              <div className="l-administration-content__content__tabs-container">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    variant="scrollable"
                    className="custom-tabs"
                    scrollButtons
                    aria-label="visible arrows tabs example"
                    sx={{
                      [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                      },
                    }}
                  >
                    {
                      permissions?.parametre?.find((o:any) => (o.libelle === 'Accueil' && o.read === true)) &&
                      <Tab  label="Accueil" 
                          icon={<InlineIcon icon="bx:home-alt"/>} 
                          onClick={()=>{
                              navigate(routePaths.adminIVEQIChildren.administrationChildren.homeAbsolute, {replace:true});
                          }}
                          iconPosition="start" />
                    }
                    {
                      permissions?.parametre?.find((o:any) => (o.libelle === 'Habilitation' && o.read === true)) &&
                      <Tab  label="Rôles" 
                          icon={<InlineIcon icon="carbon:user-role"/>}
                          onClick={()=>{
                              navigate(routePaths.adminIVEQIChildren.administrationChildren.rolesAbsolute, {replace:true});
                          }}
                          iconPosition="start" />
                    }
                    {
                      permissions?.parametre?.find((o:any) => (o.libelle === 'Utilisateur' && o.read === true)) &&
                      <Tab  label="Utilisateur" 
                          icon={<InlineIcon icon="teenyicons:users-outline"/>}
                          onClick={()=>{
                              navigate(routePaths.adminIVEQIChildren.administrationChildren.utilisateursAbsolute, {replace:true});
                          }}
                          iconPosition="start" />
                    }
                    {
                      permissions?.parametre?.find((o:any) => (o.libelle === 'Client' && o.read === true)) &&
                      <Tab  label="Client" 
                          icon={<InlineIcon icon="fa-solid:people-carry"/>}
                          onClick={()=>{
                              navigate(routePaths.adminIVEQIChildren.administrationChildren.clientsAbsolute, {replace:true});
                          }}
                          iconPosition="start" /> 
                    }
                    {
                      permissions?.parametre?.find((o:any) => (o.libelle === 'Nature Travaux' && o.read === true)) &&
                      <Tab  label="Nature Travaux" 
                          icon={<InlineIcon icon="fa-solid:people-carry"/>}
                          onClick={()=>{
                              navigate(routePaths.adminIVEQIChildren.administrationChildren.natureTravauxAbsolute, {replace:true});
                          }}
                          iconPosition="start" />
                    }
                    {
                      permissions?.parametre?.find((o:any) => (o.libelle === 'Categorie equipement' && o.read === true)) &&
                      <Tab  label="Catégorie Equipement" 
                          icon={<InlineIcon icon="fa-solid:people-carry"/>}
                          onClick={()=>{
                              navigate(routePaths.adminIVEQIChildren.administrationChildren.categorieEquipementAbsolute, {replace:true});
                          }}
                          iconPosition="start" /> 
                    }
                    {
                      permissions?.parametre?.find((o:any) => (o.libelle === 'equipement' && o.read === true)) &&
                      <Tab  label="Equipements" 
                          icon={<InlineIcon icon="fa-solid:people-carry"/>}
                          onClick={()=>{
                              navigate(routePaths.adminIVEQIChildren.administrationChildren.equipementsAbsolute, {replace:true});
                          }}
                          iconPosition="start" /> 
                    }
                    {
                      permissions?.parametre?.find((o:any) => (o.libelle === 'Categorie engin' && o.read === true)) &&
                      <Tab  label="Catégorie Engin" 
                          icon={<InlineIcon icon="fa-solid:people-carry"/>}
                          onClick={()=>{
                              navigate(routePaths.adminIVEQIChildren.administrationChildren.categorieEnginsAbsolute, {replace:true});
                          }}
                          iconPosition="start" />
                    }
                    {
                      permissions?.parametre?.find((o:any) => (o.libelle === 'engin' && o.read === true)) &&
                      <Tab  label="Engins" 
                          icon={<InlineIcon icon="fa-solid:people-carry"/>}
                          onClick={()=>{
                              navigate(routePaths.adminIVEQIChildren.administrationChildren.enginsAbsolute, {replace:true});
                          }}
                          iconPosition="start" /> 
                    }
                    {
                      permissions?.parametre?.find((o:any) => (o.libelle === 'Vidange' && o.read === true)) &&
                      <Tab  label="Vidange" 
                          icon={<InlineIcon icon="fa-solid:people-carry"/>}
                          onClick={()=>{
                              navigate(routePaths.adminIVEQIChildren.administrationChildren.vidangesAbsolute, {replace:true});
                          }}
                          iconPosition="start" />
                    }
                    
                  </Tabs>
              </div>
            </>
          }

          <Outlet />
        </div>
      </div>
    </>
  )
}

const mapStateToProps:any = createStructuredSelector({
  alerte: selectAlerteCountList,
  alerteList: selectAlerteItemList,
  permissions: selectUserProfil
});

const connector = connect(mapStateToProps);
const LAdministration = connector(LAdministrationPage);

export default LAdministration;