import React from 'react';
import CtAccueilAdministration from '../pages-content/Accueil-content/Accueil.content';

const AccueilAdmin:React.FC = () => {
  return (
    <>
      <CtAccueilAdministration />
    </>
  )
}

export default AccueilAdmin;