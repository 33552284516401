import { axios } from "@/lib/axios";
import { store } from "@/store";
import { isDev } from "@/utils/isDev";
import { ToastGeneric } from "@/utils/toast";
import { setLoading, getAllChantiers, getAllChantiersAsync, selectListPersonnelChantier, selectListEnginChantier, selectSyntheseChantier, selectHistoriqueChantier, selectHistoriqueChantierListEngin } from "@/store/reducers/chantier/chantier.action";
import { setDashboardTableData } from "@/store/reducers/dashboard/dashboard.action";
import { getAllEngins } from "@/store/reducers/engin/engin.action";


export class ChantierService {

  static getChantiers() {
    axios.get('/chantier').then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllChantiers(response.data));
            store.dispatch(setDashboardTableData(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static createChantier(data:any) {

    axios.post('/chantier',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{
            store.dispatch(getAllChantiersAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message, 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static updateChantier(data:any) {

    axios.put('/chantier',data).then((response:any)=>{
        console.log(response);
        if(response.error){
            if (typeof response.message === 'object' && response.message.message) {
                // Si response.message est un objet avec une propriété message
                ToastGeneric(response.message.message, 'error', 3000).fire();
            } else {
                // Sinon, affichez directement response.message
                ToastGeneric(response.message, 'error', 3000).fire();
            }

        }else{
            store.dispatch(getAllChantiersAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message,  'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static deleteChantier(id:any) {

    axios.patch('/delete/chantier',id).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message, 'error', 3000).fire();

        }else{
            
            console.log("Not error ---> ");
            ToastGeneric(response.message, 'success', 1000).fire();
            store.dispatch(getAllChantiersAsync());
            store.dispatch(setLoading(false));
        }
     
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static getPersonnelChantier(id:any) {
  
      axios.get('/affectation-rh',{ params: { chantierId: id } } ).then((response:any)=>{
          console.log(response);
          if(response.error){
            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();
  
          }else{
              console.log("Not error ---> ");
              store.dispatch(selectListPersonnelChantier(response.data));
              // ToastGeneric('Valider avec succès', 'success', 1000).fire();
          }
         store.dispatch(setLoading(false));
      }).catch((error)=>{
          if (isDev()) {
              console.error(error);
          }
          ToastGeneric('Erreur serveur!', 'error', 3000).fire();
          store.dispatch(setLoading(false));
      });
  
  }

  static getEnginChantier(id:any) {
  
    axios.get('/affectation-engin',{ params: { chantierId: id } } ).then((response:any)=>{
        console.log(response);
        if(response.error){
          console.log("Error ---> ");
          ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            console.log("Not error ---> ");
            store.dispatch(selectListEnginChantier(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });

}

static getSyntheseChantier(id:any) {
  
    axios.get('chantier/detail',{ params: { chantierId: id } } ).then((response:any)=>{
        console.log(response);
        if(response.error){
          console.log("Error ---> ");
          ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            console.log("Not error ---> ");
            store.dispatch(selectSyntheseChantier(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });

}

static getHistoriqueChantier(data: any) {
    console.log("Error> ", data);
    axios.put('/historique/chantier',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            store.dispatch(selectHistoriqueChantier(response.data));
            console.log("Not error ---> ", response.data);
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
}


static getHistoriqueChantierListEngin(data: any) {
    console.log("Error> ", data);
    axios.put('/historique-list/engin',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            store.dispatch(selectHistoriqueChantierListEngin(response.data));
            console.log("HistoriqueChantierListEngin ---> ", response.data);
            return response.data

            ToastGeneric('Valider avec succès', 'success', 1000).fire();

            store.dispatch(setLoading(false));
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
}


static async getHistoriqueChantierListEngin2(data: any) {
    try {
        const response = await axios.put('/historique-list/engin', data);
        return response.data;
    } catch (error) {
        if (isDev()) {
            console.error(error);
        }
        throw error;
    }
}


}
