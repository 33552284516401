import vidangeTypes from "./vidange.types";
import { VidangeService } from "@/services/vidange.service";

export const setLoading = (loading:any) => ({
    type: vidangeTypes.LOADING,
    payload: loading,
});

export const getAllVidanges = (list:any) => ({
    type: vidangeTypes.GET_VIDANGE_LIST,
    payload: list,
});

export const selectedItem = (vidange:any) => ({
  type: vidangeTypes.SELECTED_VIDANGE,
  payload: vidange,
});

 
export const  getAllVidangeAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  VidangeService.getVidange();
};

export const  createVidangeAsync =  (vidange:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  VidangeService.createVidange(vidange);
};

export const  updateVidangeAsync =  (vidange:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  VidangeService.updateVidange(vidange);
};

export const  deleteVidangeAsync =  (data:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  VidangeService.deleteVidange(data);
};







