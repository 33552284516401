import React from 'react';
import './ListeItemCard.styles.scss'


type TListItem = {
    label:string,
    legend:string,
    hierarchy?:string,
    setIsEdit?: any,
    id?:any,
    isActive?:boolean
}
const ListeItemCard:React.FC<TListItem> = ({
    label,
    legend,
    setIsEdit,
    id,
    isActive
}) => {
  const handleClick = () => {
    setIsEdit && setIsEdit(id);
  }

  return (
    <div className={`list-item-card-container ${ isActive ? 'active' : ''}`} onClick={handleClick}>
        <p> Label : <b>{ label } </b></p>
        <p> Legend : <b>{ legend } </b></p>
        {/* <p> Hierarchie : <b>{ hierarchy } </b></p> */}
    </div>
  )
}

export default ListeItemCard