import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectConges = (state:TRootState) => state.conges;

export const selectLoading = createSelector(
  [selectConges],
  (conges:any) => conges.loading
);

export const selectCongesItemList = createSelector(
    [selectConges],
    (conges:any) => conges.list
);

export const selectCongesDetailLoading = createSelector(
  [selectConges],
  (conges:any) => conges.loading
);

export const selectCongesItemSelected = createSelector(
    [selectConges],
    (conges:any) => conges.itemSelected
);
