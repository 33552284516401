import React, { useState } from "react";
import classnames from "classnames";
import "./DashboardTableauBordCard.styles.scss";
import { Button, Divider, Stack } from "@mui/material";
import { DataGrid, GridValueGetterParams, frFR } from "@mui/x-data-grid";
import moment from "moment";
import { exportToExcel } from "@/config";
import { InlineIcon } from "@iconify/react";
import TimelineItem from "../Timeline/TimelineItem";
// import { dashboardByTypeAsync } from '@/store/reducers/dashboard/dashboard.action';
import {
  selectHistoriqueDataDashboard,
  selectLoading,
  selectTableDatadashboard,
  selectTypedashboard,
} from "@/store/reducers/dashboard/dashboard.selector";
import { selectLoading as selectChantierLoading } from "@/store/reducers/chantier/chantier.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { store } from "@/store";
import {
  dashboardHistoriqueChantierAsync,
  dashboardHistoriqueEnginAsync,
  dashboardHistoriquePersonnelAsync,
  setDashboardTableData,
} from "@/store/reducers/dashboard/dashboard.action";

const columnChantiers = [
  { field: "numero_chantier", headerName: "Numero", width: 190 },
  { field: "nom", headerName: "Nom", width: 190 },
  { field: "localisation", headerName: "Localisation", width: 190 },
  { field: "date_debut", headerName: "Debut", width: 190, format: "date" },
  { field: "date_fin", headerName: "Fin", width: 190, format: "date" },
  {
    field: "chef_nom",
    headerName: "Chef de chantier",
    width: 190,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.utilisateur.nom + " " + params.row.utilisateur.prenoms || "",
  },
  {
    field: "client_nom",
    headerName: "Client",
    width: 190,
    valueGetter: (params: GridValueGetterParams) => params.row.client.nom || "",
  },
  // { field: 'but_location', headerName: 'but_location', width: 190 },
];

const columnEngins = [
  { field: "libelle", headerName: "Libellé", width: 100 },
  { field: "num_parc", headerName: "N°Parc", width: 150 },
  {
    field: "description",
    headerName: "Description",
    sortable: false,
    width: 200,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.description.slice(0, 20) || "",
  },
  {
    field: "dernier_compteur",
    headerName: "Compteur Engin",
    type: "number",
    width: 150,
    format: "number",
  },
  {
    field: "intervalle_entretien",
    headerName: "Intervalle entretien",
    type: "number",
    width: 150,
  },
  {
    field: "derniere_entretien",
    headerName: "derniere entretien",
    type: "number",
    width: 150,
  },
  {
    field: "prochain_entretien",
    headerName: "prochain entretien",
    type: "number",
    width: 150,
  },
  {
    field: "situation",
    headerName: "Situation",
    type: "text",
    width: 150,
  },
];

const columnPersonnels = [
  { field: "matricule", headerName: "Matricule", width: 80 },
  { field: "nom", headerName: "Nom", width: 100 },
  { field: "prenoms", headerName: "Prénoms", width: 200 },
  { field: "email", headerName: "Email", width: 100 },
  { field: "telephone", headerName: "Phone", width: 130 },
  { field: "fonction", headerName: "Fonction", width: 100 },
  { field: "statut", headerName: "statut", width: 100 },
  { field: "categorie_professionnelle", headerName: "Catégorie", width: 130 },
  { field: "etat_matrimoniale", headerName: "Etat Matrimoniale", width: 130 },
  {
    field: "situation",
    headerName: "Situation",
    type: "text",
    width: 150,
  },
];

// const columns = [
//     { field: 'date', headerName: 'Date',   valueGetter: (params: any) => {
//         return moment(params.value).format('DD-MM-YYYY');
//     },width: 120 },
//     { field: 'engin', headerName: 'Engin', width: 150 },
//     { field: 'travail', headerName: 'travail', width: 100 },
//     { field: 'nature_travaux', headerName: 'Nature Travaux', width: 190 },
//     { field: 'compteur_debut', headerName: 'Compteur debut', width: 130 },
//     { field: 'compteur_fin', headerName: 'Compteur fin', width: 120 },
//     { field: 'quantite', headerName: 'quantite', width: 130 },
//     { field: 'carburant', headerName: 'Carburant', width: 120 },
//     { field: 'chauffeur', headerName: 'Chauffeur', width: 130 },
//     { field: 'motif', headerName: 'Motif', width: 130 },
//     { field: 'observation', headerName: 'observation', width: 150 },
//     { field: 'utilisateur', headerName: 'Chef Chantier', width: 150 },
//   ];

const columnsIncident = [
  { field: "engin", headerName: "Engin", width: 190 },
  {
    field: "date_incient",
    headerName: "Date Incident",
    width: 190,
    format: "date",
  },
  { field: "panne", headerName: "panne", width: 190 },
  { field: "observation_panne", headerName: "Observation panne", width: 190 },
  {
    field: "date_depannage",
    headerName: "Date depannage",
    width: 190,
    format: "date",
  },
  {
    field: "compteur_depannage",
    headerName: "Compteur Depannage",
    width: 190,
    format: "number",
  },
  { field: "nom_technicien", headerName: "Nom Technicien", width: 190 },
  {
    field: "observation_depannage",
    headerName: "Observation depannage",
    width: 190,
  },
  { field: "chantier", headerName: "Chantier", width: 190 },
  { field: "chef_chantier", headerName: "Chef Chantier", width: 190 },
];

const columnsCuve = [
  { field: "engin", headerName: "Engin", width: 150 },
  { field: "chantier", headerName: "Chantier", width: 150 },
  {
    field: "date",
    headerName: "Date",
    valueGetter: (params: any) => {
      // Format the date using moment
      return moment(params.value).format("DD-MM-YYYY");
    },
    width: 130,
    format: "date",
  },
  { field: "type", headerName: "Type", width: 150 },
  { field: "enginId", headerName: "Engin", width: 180 },
  { field: "chantierId", headerName: "Chantier", width: 180 },
  { field: "quantite", headerName: "Quantité", width: 150, format: "number" },
  {
    field: "quantite_cuve_avant",
    headerName: "Quantité Avant",
    width: 150,
    format: "number",
  },
  {
    field: "quantite_cuve_apres",
    headerName: "Quantité Apres",
    width: 150,
    format: "number",
  },
  { field: "observation", headerName: "Observation", width: 150 },
  { field: "utilisateur", headerName: "Chef Chantier", width: 150 },
];

const DashboardTableauBordCardPage: React.FC<any> = ({
  loadingData,
  chantierList,
  personnelList,
  enginList,
  incidentList,
  cuveList,
  setCategory,
  category,
  dataList,
  dashboardHistoriqueChantierAsync,
  dashboardHistoriquePersonnelAsync,
  dashboardHistoriqueEnginAsync,
  dataHistorique,
}) => {
  console.log("dataHistorique", chantierList);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 40, fontWeight: 1000 }} spin />
  );

  const [loading, setLoading] = useState(false);
  const [eltSelected, setEltSelected] = useState<any>({});
  const [columList, setColumList] = useState<any>(columnChantiers);

  const handleEltSelected = (item: any) => {
    console.log("item", item);
    console.log("eltSelected", eltSelected);
    if (eltSelected?.id === item?.id) {
      console.log("no");
      setEltSelected({});
    } else {
      if (category === "Chantier") {
        dashboardHistoriqueChantierAsync(item?.id);
      }
      if (category === "Engin") {
        dashboardHistoriqueEnginAsync(item?.id);
      }
      if (category === "Personnel") {
        dashboardHistoriquePersonnelAsync(item?.id);
      }

      console.log("yes");
      console.log("item", item);
      setEltSelected(item);
    }
  };

  // Appel de la fonction d'exportation lorsque vous souhaitez exporter les données
  const handleExportUtilisationExcel = () => {
    exportToExcel(dataList, columList, category);
  };

  return (
    <div className="dashboard-tableauBord-card">
      <span className="title"> Etat recapitulatif </span>
      <div className="option-part">
        <div className="categories-part">
          <div
            className={classnames("category-item", {
              active: category === "Chantier",
            })}
            onClick={() => {
              setEltSelected({});
              setCategory("Chantier");
              setLoading(true);
              setTimeout(() => {
                //setDataList(historiqueChantiers.UtilisationEngin);
                // setDataForChart(dataCellule);

                console.log("UtilisationEngin", chantierList);
                let val: any[] = [];
                if (chantierList?.length > 0) {
                  val = chantierList.map((x: any) => {
                    return {
                      ...x,
                      but_location: x.but_location,
                      date_debut: moment(x.date_debut).format("DD-MM-YYYY"),
                      date_fin: moment(x.date_fin).format("DD-MM-YYYY"),
                      client_nom: x?.client?.nom,
                      chef_nom: x?.utilisateur?.nom,
                      searchText: x.nom + " " + x.localisation,
                    };
                  });
                }
                store.dispatch(setDashboardTableData(val));
                setColumList(columnChantiers);
                setLoading(false);
              }, 1000);
            }}
          >
            <span> Chantier </span>
          </div>
          <Divider orientation="vertical" />
          <div
            className={classnames("category-item", {
              active: category === "Engin",
            })}
            onClick={() => {
              setEltSelected({});
              setCategory("Engin");
              setLoading(true);
              setTimeout(() => {
                //setDataList(historiqueChantiers.UtilisationEngin);
                // setDataForChart(dataCellule);

                console.log("UtilisationEngin", enginList);
                let val: any[] = [];
                if (enginList?.length > 0) {
                  val = enginList.map((x: any) => {
                    return {
                      ...x,
                      situation: x.chantierId
                        ? x.chantier?.numero_chantier +
                          " à " +
                          x.chantier?.localisation
                        : "Non Loué",
                      searchText: x.libelle + " " + x.description,
                    };
                  });
                }
                store.dispatch(setDashboardTableData(val));
                setColumList(columnEngins);
                setLoading(false);
              }, 1000);
            }}
          >
            <span> Engin </span>
          </div>
          <Divider orientation="vertical" />
          <div
            className={classnames("category-item", {
              active: category === "Personnel",
            })}
            onClick={() => {
              setEltSelected({});
              setCategory("Personnel");
              setLoading(true);
              setTimeout(() => {
                //setDataList(historiqueChantiers.UtilisationEngin);
                // setDataForChart(dataCellule);

                console.log("personnelList", personnelList);
                let val: any[] = [];
                if (personnelList?.length > 0) {
                  val = personnelList.map((x: any) => {
                    return {
                      ...x,
                      situation: x.chantierId
                        ? x.chantier?.numero_chantier +
                          " à " +
                          x.chantier?.localisation
                        : "Pas sur chantier",
                      searchText:
                        x.matricule +
                        " " +
                        x.nom +
                        " " +
                        x.prenoms +
                        " " +
                        x.email +
                        " " +
                        x.fonction,
                    };
                  });
                }
                store.dispatch(setDashboardTableData(val));
                setColumList(columnPersonnels);
                setLoading(false);
              }, 1000);
            }}
          >
            <span> Personnel </span>
          </div>
          <Divider orientation="vertical" />
          <div
            className={classnames("category-item", {
              active: category === "Incidents",
            })}
            onClick={() => {
              setEltSelected({});
              setCategory("Incidents");

              setLoading(true);
              setTimeout(() => {
                // setDataForChart(dataCellule);
                console.log("incidentList", incidentList);
                let val: any[] = [];
                if (incidentList?.length > 0) {
                  val = incidentList.map((x: any) => {
                    return {
                      ...x,
                      date_incient: moment(x.date_incient).format("DD-MM-YYYY"),
                      date_depannage: moment(x.date_depannage).format(
                        "DD-MM-YYYY"
                      ),
                      chef_chantier:
                        x?.utilisateur?.nom + " " + x?.utilisateur?.prenoms,
                      chantier: x?.chantier?.nom,
                      engin: x?.engin?.num_parc,
                      searchText:
                        x.panne +
                        " " +
                        x?.chantier?.nom +
                        " " +
                        x?.observation_panne +
                        " " +
                        x?.observation_depannage,
                    };
                  });
                }
                store.dispatch(setDashboardTableData(val));
                setColumList(columnsIncident);

                //setDataList((!historiqueChantiers.incidentEngin)?[]:historiqueChantiers.incidentEngin);

                setLoading(false);
              }, 1000);
            }}
          >
            <span> Incidents </span>
          </div>
          <Divider orientation="vertical" />
          <div
            className={classnames("category-item", {
              active: category === "Cuves",
            })}
            onClick={() => {
              setEltSelected({});
              setCategory("Cuves");

              setLoading(true);
              setTimeout(() => {
                console.log("utilisationCuve", cuveList);
                let val: any[] = [];
                if (cuveList?.length > 0) {
                  val = cuveList.map((x: any) => {
                    return {
                      ...x,
                      engin: x.engin?.num_parc,
                      chantier: x.chantier?.nom,
                      //    utilisateur: x.utilisateur?.nom + ' ' +  x.utilisateur?.prenoms,
                    };
                  });
                }
                store.dispatch(setDashboardTableData(val));
                setColumList(columnsCuve);
                setLoading(false);
              }, 1000);
            }}
          >
            <span> Cuves </span>
          </div>
        </div>
      </div>
      <div className="chart-part">
        <div
          className={`row-detail ${
            eltSelected?.id &&
            (category === "Chantier" ||
              category === "Engin" ||
              category === "Personnel")
              ? "show"
              : ""
          }`}
        >
          {!loadingData ? (
            <>
              {category === "Chantier" && (
                <div className="timeline-title">
                  <h6>
                    {" "}
                    {eltSelected?.row?.numero_chantier} -{" "}
                    {eltSelected?.row?.nom}{" "}
                  </h6>
                </div>
              )}
              {category === "Engin" && (
                <div className="timeline-title">
                  <h6>
                    {" "}
                    {eltSelected?.row?.id} - {eltSelected?.row?.num_parc}{" "}
                  </h6>
                </div>
              )}
              {category === "Personnel" && (
                <div className="timeline-title">
                  <h6>
                    {" "}
                    {eltSelected?.row?.id} -{" "}
                    {eltSelected?.row?.nom + " " + eltSelected?.row?.prenoms}{" "}
                  </h6>
                </div>
              )}
              <TimelineItem
                data={dataHistorique}
                isDash={true}
                category={category}
              />
            </>
          ) : (
            <Spin
              className="detail-loader"
              tip="Chargement..."
              indicator={antIcon}
            ></Spin>
          )}
        </div>
        <div
          className={`main-table ${
            eltSelected?.id && (category === "Chantier" ||
                category === "Engin" ||
                category === "Personnel") ? "divide" : ""
          }`}
        >
          {loading ? (
            <div className="loading-chart">
              <img
                className="loader-img"
                src={require("@/assets/images/gif/chart-load.gif")}
                alt="load"
              />
            </div>
          ) : (
            <div style={{ height: "350px", width: "100%" }}>
              <Button
                color="blue"
                className="btn-option"
                onClick={handleExportUtilisationExcel}
                variant="contained"
                startIcon={<InlineIcon icon="carbon:document-export" />}
              >
                Exporter
              </Button>

              <DataGrid
                components={{
                  NoRowsOverlay: () => (
                    <Stack
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      Pas de données
                    </Stack>
                  ),
                }}
                localeText={{
                  ...frFR.components.MuiDataGrid.defaultProps.localeText,
                  MuiTablePagination: {
                    labelRowsPerPage: "Lignes par page",
                    labelDisplayedRows: ({ from, to, count }) =>
                      `${from} - ${to} sur ${count}`,
                  },
                  noRowsLabel: "Pas de données",
                  noResultsOverlayLabel: "Aucun résultat trouvé.",
                  toolbarDensity: "Densité",
                  toolbarDensityLabel: "Densité",
                  toolbarDensityCompact: "Compacte",
                  toolbarDensityStandard: "Standard",
                  toolbarDensityComfortable: "Confortable",
                  toolbarColumns: "Colonnes",
                  toolbarColumnsLabel: "Sélectionner les colonnes",
                  toolbarFilters: "Filtres",
                  toolbarFiltersLabel: "Afficher les filtres",
                  toolbarFiltersTooltipHide: "Masquer les filtres",
                  toolbarFiltersTooltipShow: "Afficher les filtres",
                  toolbarFiltersTooltipActive: (count) =>
                    count !== 1
                      ? `${count} filtres actifs`
                      : `${count} filtre actif`,
                  toolbarExport: "Exporter",
                  toolbarExportLabel: "Exporter",
                  toolbarExportCSV: "Télécharger en CSV",
                  toolbarExportPrint: "Imprimer",
                  columnsPanelTextFieldLabel: "Rechercher une colonne",
                  columnsPanelTextFieldPlaceholder: "Titre de colonne",
                  columnsPanelDragIconLabel: "Réorganiser la colonne",
                  columnsPanelShowAllButton: "Tout afficher",
                  columnsPanelHideAllButton: "Tout masquer",
                  filterPanelAddFilter: "Ajouter un filtre",
                  filterPanelDeleteIconLabel: "Supprimer",
                  filterPanelOperatorAnd: "Et",
                  filterPanelOperatorOr: "Ou",
                  filterPanelColumns: "Colonnes",
                  filterPanelInputLabel: "Valeur",
                  filterPanelInputPlaceholder: "Filtrer la valeur",
                  filterOperatorContains: "contient",
                  filterOperatorEquals: "est égal à",
                  filterOperatorStartsWith: "commence par",
                  filterOperatorEndsWith: "finit par",
                  filterOperatorIs: "est",
                  filterOperatorNot: "n'est pas",
                  filterOperatorAfter: "est après",
                  filterOperatorOnOrAfter: "est le ou après",
                  filterOperatorBefore: "est avant",
                  filterOperatorOnOrBefore: "est le ou avant",
                  filterOperatorIsEmpty: "est vide",
                  filterOperatorIsNotEmpty: "n'est pas vide",
                  filterOperatorIsAnyOf: "est l'un des",
                  filterValueAny: "toutes",
                  filterValueTrue: "vrai",
                  filterValueFalse: "faux",
                  columnMenuLabel: "Menu",
                  columnMenuShowColumns: "Afficher les colonnes",
                  columnMenuFilter: "Filtrer",
                  columnMenuHideColumn: "Masquer",
                  columnMenuUnsort: "Annuler le tri",
                  columnMenuSortAsc: "Trier par ordre croissant",
                  columnMenuSortDesc: "Trier par ordre décroissant",
                  columnHeaderFiltersTooltipActive: (count) =>
                    count !== 1
                      ? `${count} filtres actifs`
                      : `${count} filtre actif`,
                  columnHeaderFiltersLabel: "Afficher les filtres",
                  columnHeaderSortIconLabel: "Trier",
                  footerRowSelected: (count) =>
                    count !== 1
                      ? `${count.toLocaleString()} lignes sélectionnées`
                      : `${count.toLocaleString()} ligne sélectionnée`,
                  footerTotalRows: "Lignes totales :",
                  footerTotalVisibleRows: (visibleCount, totalCount) =>
                    `${visibleCount.toLocaleString()} sur ${totalCount.toLocaleString()}`,
                  checkboxSelectionHeaderName: "Sélection",
                  booleanCellTrueLabel: "oui",
                  booleanCellFalseLabel: "non",
                  actionsCellMore: "plus",
                }}
                rowHeight={50}
                rows={dataList}
                columns={columList}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                onRowClick={(item: any) => handleEltSelected(item)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps: any = createStructuredSelector({
  loadingChantier: selectChantierLoading,
  loadingData: selectLoading,
  detailInfo: selectTypedashboard,
  dataList: selectTableDatadashboard,
  dataHistorique: selectHistoriqueDataDashboard,
});

const mapDispatchToProps:any = (dispatch: any) => ({
  dashboardHistoriqueChantierAsync: (id: number) =>
    dispatch(dashboardHistoriqueChantierAsync(id)),
  dashboardHistoriqueEnginAsync: (id: number) =>
    dispatch(dashboardHistoriqueEnginAsync(id)),
  dashboardHistoriquePersonnelAsync: (id: number) =>
    dispatch(dashboardHistoriquePersonnelAsync(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const DashboardTableauBordCard = connector(DashboardTableauBordCardPage);

export default DashboardTableauBordCard;
