import { axios } from "@/lib/axios";
import { store } from "@/store";
import { isDev } from "@/utils/isDev";
import { ToastGeneric } from "@/utils/toast";
import { setLoading, getAllEngins, getAllEnginsAsync, getAllVidanges, getAllVidangesAsync, selectSyntheseEngin } from "@/store/reducers/engin/engin.action";


export class EnginService {

  static getEngins() {
    axios.get('/engin').then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllEngins(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static createEngin(data:any) {

    axios.post('/engin',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            store.dispatch(getAllEnginsAsync());
            console.log("Not error ---> ");
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
      
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static updateEngin(data:any) {

    axios.put('/engin',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            store.dispatch(getAllEnginsAsync());
            console.log("Not error ---> ");
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
     
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static deleteEngin(data:any) {

    axios.patch('/delete/engin',data).then((response:any)=>{
        console.log('/delete/engin', response.message);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message, 'error', 3000).fire();
            store.dispatch(getAllEnginsAsync());
            store.dispatch(setLoading(false));
        }else{
            
            console.log("Not error ---> ");
            ToastGeneric(response.message, 'success', 1000).fire();
            store.dispatch(getAllEnginsAsync());
            store.dispatch(setLoading(false));
        }
     
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  //vidange 
  static getVidanges() {
    axios.get('/vidange').then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllVidanges(response.data));
            store.dispatch(setLoading(false));
            ToastGeneric(response.message,  'success', 1000).fire();
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static createVidange(data:any) {

    axios.post('/vidange',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{
            store.dispatch(getAllVidangesAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message,  'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static updateVidange(data:any) {

    axios.put('/vidange',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message,  'error', 3000).fire();

        }else{
            store.dispatch(getAllVidangesAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message, 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
      
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static getSyntheseEngin(id:any) {
  
    axios.get('synthese/engin',{ params: { enginId: id } } ).then((response:any)=>{
        console.log(response);
        if(response.error){
          console.log("Error ---> ");
          ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            console.log("Not error ---> ", response.data);
            store.dispatch(selectSyntheseEngin(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });

}
}