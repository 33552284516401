import { InlineIcon } from '@iconify/react';
import { PersonRemove } from '@mui/icons-material';
import { Autocomplete, Stack, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, TextField, Grid, SelectChangeEvent, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SideModal from '../../components/SideModal/SideModal';
import './AffectationRH.content.style.scss';
import personIcon from '@/assets/images/png/Deliver.png';
import { DataGrid, GridValueGetterParams } from '@mui/x-data-grid';
import { getAllChantiersAsync } from '@/store/reducers/chantier/chantier.action';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectChantierDetailLoading, selectChantierItemList, selectLoading, selectPersonnelChantierList } from '@/store/reducers/chantier/chantier.selector';
import dayjs from 'dayjs';
import { DialogComp } from '@/components/Dialog/Dialog';
import { callbackPersonAffectatedAsync, createPersonAffectationAsync, getAllAffectedPersonsByChantierAsync, getDetailAboutRhAffectatedAsync } from '@/store/reducers/affectation/affectation.action';
import { detailAboutRhAffectation, selectAffectedPersonByChantierItemList } from '@/store/reducers/affectation/affectation.selector';
import { getAllPersonnelsAsync } from '@/store/reducers/personnel/personnel.action';
import { selectPersonnelItemList } from '@/store/reducers/personnel/personnel.selector';
import moment from 'moment';
import { selectUserProfil } from '@/store/reducers/profil/profil.selector';
import EditPersonAffectedForm from '../../Forms/EditPersonAffectedForm/EditPersonAffectedForm';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';
moment.locale('fr');

const CtAffectationRHItem: React.FC<any> = ({
  loading,
  loadingPerson,
  chantierList,
  getAllChantiersAsync,
  getAllAffectedPersonsByChantierAsync,
  affectedPersonnelOnSelectedChantierList,
  getAllPersonnelsAsync,
  personnelList,
  createPersonAffectationAsync,
  callbackPersonAffectatedAsync,
  getDetailAboutRhAffectatedAsync,
  rhDetail,
  permissions,
  userConnected
}) => {

  const [openMotif, setOpenMotif] = React.useState(false);
  const [selectedRowData, setSelectedRowData] =  useState<any>(null);
  const [rowSelected, setRowSelected] = useState<any>({});
  // const handleClickOpenMotif = () => {
  //   setOpenMotif(true);
  // };

  const [openUse, setOpenUse] = useState(false);
  const handleOpenUse = () => {setOpenUse(true);}
  const handleCloseUse = () => {setOpenUse(false);}

  const handleClickOpenMotif = (rowParams?: any) => {
    console.log('handleClickOpenMotif', rowParams);
    setSelectedRowData(rowParams.row); // Stockez les données de la ligne sélectionnée
    setOpenMotif(true);
  };

  const handleCloseMotif = () => {
    setDialogData({
      motif_retour: ''
    });
    setOpenMotif(false);
    setSelectedRowData(null);
  };

    // État local pour gérer les informations du dialogue
    const [dialogData, setDialogData] = useState({
      motif_retour: '',
    });
  
    // Fonction pour mettre à jour l'état du dialogue lors de la saisie
    const handleChangeDialogData = (event: any) => {
      const { name, value } = event.target;
      setDialogData((prevData) => ({ ...prevData, [name]: value }));
    };

  const columns:any[] = [
    { field: 'matricule', headerName: 'Matricule', width: 100 },
    {
      field: 'nom',
      headerName: 'Nom complet',
      sortable: false,
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        params?.row?.nom + ' ' + params?.row?.prenoms,
    },
   
    { field: 'fonction', headerName: 'Fonction', width: 180 },
    { field: 'hebergement_chantier', headerName: 'Hebergement', width: 150,
    valueGetter: (params: GridValueGetterParams) =>
        
      params?.row?.item?.hebergement_chantier
  
    },

    { field: 'date_chantier', headerName: 'Date affectation', width: 100,
    valueGetter: (params: GridValueGetterParams) =>
        
        moment(params?.row?.item?.date_chantier).format('L')
  
    },

    { field: "date_retour",
      headerName: "Date retour",
      sortable: false,
      renderCell: (params:any) => {
  
        return <>
        {
          !params?.row?.item?.motif_retour ?
          <button className='badge' style={{backgroundColor: 'lightblue', color:'black', marginLeft: 3}}>Sur chantier</button>
          :
          <small style={{ marginLeft: 3}}> {moment(params?.row?.item?.date_retour).format('L')} </small>
        }
        </>;
      }, width: 120 
    },
    { field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params:any) => {
        const handleCallback = (e:any) => {
          if(selectedRowData){

            e.stopPropagation(); // don't select this row after clicking
          console.log('params',params?.row);
          let data = {
            "id":selectedRowData.id,
            "motif_retour":dialogData.motif_retour,
            "date_retour": new Date().toISOString().split('T')[0],
            "ops":params?.row?.item?.ops
          }
          console.log('data item retour',data);
          
          callbackPersonAffectatedAsync(data);
          getAllAffectedPersonsByChantierAsync(selectedChantier.id);
          setSelectedRowData(null); // Réinitialisez l'état
          setOpenMotif(false);
          setDialogData({
            motif_retour: ''
          });


          }
          
        };
  
        const openModal = (e:any) => {
          console.log('params',params);
          e.stopPropagation(); // don't select this row after clicking
          let data = {
            "chantierPersonnelId": params?.row?.item?.id,
            "personnelId": params?.row?.item?.personnelId,
          }
          getDetailAboutRhAffectatedAsync(data);
        };
  
        return <>
        <button className='badge' style={{backgroundColor: '#aaa', marginRight: 3}} onClick={handleOpenUse}>Modifier</button>

        {
          (!params?.row?.item?.motif_retour && permissions?.rh?.find((o: any) => (o.libelle === 'Affectation' && o.edit === true))) && <button className='badge' onClick={() => handleClickOpenMotif(params)}>Rappeler</button>

        }
        
        <button className='badge' style={{backgroundColor: '#111', marginLeft: 3}} onClick={openModal}>Detail</button>
        <Dialog open={openMotif} onClose={handleCloseMotif}>
          <DialogTitle>Motif</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Saisir le motif du rappele du personnel
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="motif"
              label="Motif"
              fullWidth
              variant="standard"
              name='motif_retour'
              value={dialogData.motif_retour}
              onChange={handleChangeDialogData}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMotif}>Annuler</Button>
            <Button onClick={handleCallback}>Rappeler</Button>
          </DialogActions>
        </Dialog>
        </>;
      }, width: 250 
    },
  ];

  useEffect(() => {
    const url:any = getAllChantiersAsync();
    // const url1:any = getAllPersonnelsAsync();

    const fetchData = async () => {
        try {
            await fetch(url);
            // await fetch(url1);
        } catch (error) {
            console.log("error", error);
        }
    };
    fetchData();
    
  }, [getAllChantiersAsync]);

  const [searchText, setSearchText] = useState('');
  const [selectedChantier, setSelectedChantier] = useState<any>({ id: '', value: '' });
  const listPersonnelChantierSelect: any[] = (selectedChantier.id) ?  affectedPersonnelOnSelectedChantierList.map((o:any) => {
    return {...o.personnel, item: o,id: o.id}
  }) : [];
  
  const [selectedPersonId, setSelectedPersonId] = useState<number | null>();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const listPersonnel:any[] = [];
  personnelList.forEach((x:any) => {
    if (!x.chantierId || x.chantierId === null || x.chantierId === 0) {
      listPersonnel.push({
        ...x,
        searchText: x.nom + ' ' + x.prenoms + ' ' + x.fonction + ' ' + x.categorie_professionnelle
      })
    }
  });
  const ListAllChantiers = chantierList.map((x:any) => {
    return {
      value: x.nom,
      ...x
    }
  })

  function handleChantierChange(event: any, value: { id: string; value: string; } | null) {
    console.log(value);
    if (value === null) {
      setSelectedChantier({ id: '', value: '' });
    } else {
      setSelectedChantier(value as { id: string; value: string; });
    }
  }

  const getChantierInfos = async () => {
    console.log("chantier selected", selectedChantier);
    if (!selectedChantier.id) {
      return;
    } else {
      getAllAffectedPersonsByChantierAsync(selectedChantier.id);
    }
  }

  const hebergementList: any[] = ["Maison", "Hôtel", "Autre"];
  const [dortoir, setDortoir] = useState<any[]>(
    selectedChantier?.hebergement_chantier ? selectedChantier?.hebergement_chantier.split(",") : ["Hôtel"]
  );
  const handleDortoirChange = (event: SelectChangeEvent<any>) => {
    setDortoir(event.target.value);
    console.log("SelectChangeEvent", event.target.value);
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedPersonId(index);
  };

  const openModalAffectation = () => {
    console.log('List selected', selectedPersonId);
    setIsDialogOpen(true);
  }

  const validationSchema = yup.object({
    // hebergement_chantier: yup.string().required(" L'unité de la location est obligatoire "),
  });

  const formik = useFormik  ({
    initialValues: {
      date_chantier: dayjs().format('YYYY/MM/DD'),
      chantierId: selectedChantier?.id,
      personnelId: selectedPersonId,
      hebergement_chantier: '',
      ops: userConnected?.user?.nom + " " + userConnected?.user?.prenoms,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      values.chantierId= selectedChantier?.id;
      values.personnelId= selectedPersonId;
      values.hebergement_chantier = dortoir.toString();
      console.log('formvalues selectedChantier', selectedChantier);
      console.log('formvalues', JSON.stringify(values));
      createPersonAffectationAsync(values)
      setSelectedPersonId(null);
      setIsDialogOpen(false);
      getAllAffectedPersonsByChantierAsync(values.chantierId);
      setOpen(false);
    }
  });

  const closeModalAffectation = () => {
    formik.resetForm();
    setIsDialogOpen(false);
  }

  const FormAffectation = () => {
    return (<>
      {/* <span className="title-form"> Informations d'affectation </span> */}
      <form className='form-affectation' onSubmit={formik.handleSubmit}>
        <Grid container rowSpacing={2} columnSpacing={2} >
            <Grid item xs={12} sm={12} lg={12} xl={12} >
            <FormControl fullWidth>
            <InputLabel
              id="id-dortoir"
              htmlFor="dortoir"
              sx={{
                position: "absolute !important",
                top: "-5px !important",
                fontSize: "0.8rem",
              }}
            >
              Herbergement
            </InputLabel>
            <Select
              multiple
              size="small"
              labelId="id-hebergement_chantier"
              name="hebergement_chantier"
              value={dortoir}
              onChange={handleDortoirChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} sx={{ height: "20px" }} />
                  ))}
                </Box>
              )}
              //   MenuProps={MenuProps}
            >
              {hebergementList.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            </Grid>
        </Grid>
      </form>
      
      </>)
  }

  return (
    <>
      <div className="ct-listes-campagne">
        <div className="ct-listes-campagne__card">

          <div className="header-part">
            <div className="title-part">
                <div className="title-bloc">
                  <span className="title"> Affectations personnel </span>
                  <Chip label={` Chantier actif : ${selectedChantier?.value || 'Aucun'} `}
                    className='total-number-chip' />
                </div>
                <div className="toolbar-part">

                  <div className="actions-part">
                  {
                          permissions?.rh?.find((o: any) => (o.libelle === 'Affectation' && o.create === true)) &&
                          <Button
                      color='blue'
                      className='btn-option'
                      disabled={!selectedChantier?.id}
                      onClick={()=>{
                          handleOpen();
                          getAllPersonnelsAsync();
                      }}
                      //variant='contained'
                      startIcon={<InlineIcon icon="carbon:document-add" />}>
                      Ajouter un employé
                    </Button>
                        }
                    
                  </div>

                  <TextField
                    value={searchText}
                    onChange = {
                      (e) => {
                        setSearchText(e.target.value);
                      }
                    }
                    className="search-input"
                    placeholder="Recherche ... "
                    variant="outlined"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InlineIcon icon="bi:search" />
                        </InputAdornment>
                      )
                    }}
                  />

                </div>
            </div>
          </div>

          <div className="searchable-part">
              <Autocomplete
                      id="id-service-select"
                      className='search-autocomplete'
                      options={ListAllChantiers}
                      value={selectedChantier.id ? selectedChantier : null}
                      onChange={handleChantierChange}
                      autoHighlight
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      getOptionLabel={(option) => option.value}
                      renderOption={(props, option) => (
                          <Box component="li" {...props}>
                              {option.value}
                          </Box>
                      )}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              className='my-text-field'
                              placeholder="Chantier"
                              label="Chantier"
                              variant="outlined"
                              name="type_compteur"
                              // onChange={formik.handleChange}
                              // value={formik.values.type_compteur}
                              // error={formik.touched.type_compteur && Boolean(formik.errors.type_compteur)}
                          />
                      )}
              />
              <Button color='secondary'
                  className='btn-option'
                  // startIcon={<InlineIcon icon="ep:filter" />}
                  onClick={()=>{
                    getChantierInfos()
                  }}
                  variant='contained'>
                  Valider
              </Button>

          </div>

          <PerfectScrollbar id="scrollableDiv" className="content-part">
            {
              loading ?
              <>
                  <Skeleton variant="rounded" animation="wave"  width={'40%'} height={'60%'} />
                  <Skeleton variant="rounded" animation="pulse"  width={'40%'} height={'60%'} />
              </> : 
              <div className="data-content-part">
                    <>
                      <div className="table-content-part">
                        <DataGrid
                          rows={listPersonnelChantierSelect}
                          columns={columns}
                          pageSizeOptions={[5, 10, 15]}
                          initialState={{
                            pagination: { paginationModel: { pageSize: 7 } },
                          }}
                          components={{
                            NoRowsOverlay: () => (
                              <Stack height="100%" alignItems="center" justifyContent="center">
                                Pas de donnees
                              </Stack>
                            ),
                          }}
                          localeText={{
                            MuiTablePagination: {
                              labelDisplayedRows: ({ from, to, count }) =>
                                `${from} - ${to} Total: ${count}`,
                            },
                          }}
                          onRowClick={(e: any) => {
                            console.log("change colonne", e);
                            setRowSelected(e);
                          }}
                        />
                      </div>
                      <div className='detail-person'>
                        {
                          rhDetail?.absences &&
                          <div className='filter-header'>
                            <Chip size="small" color={"tertiary"} className="chip-filter" label="Absences"/>
                          </div>
                        }
                          <List component="nav" aria-label="main mailbox folders"
                            sx={{
                                  position: 'relative',
                                  overflow: 'auto',
                                }}
                          >
                             { 
                              rhDetail?.absences?.map((x:any, id:number) => {
                            
                                return (
                                  <>
                                    {
                                      x.id !== 1 && <Divider variant="inset" component="li" />
                                    }
                                    <ListItem key={id} >
                                      <ListItemIcon>
                                        <PersonRemove />
                                      </ListItemIcon>
                                      <ListItemText 
                                        primary={x.motif} 
                                     //   secondary={x.date + ' ' + x.heure_debut}
                                        secondary={ `
                                        Date :${x.date}\n,
                                        Heure debut: ${x.heure_debut},
                                        heure fin: ${x.heure_fin},
                                        Observation: ${x.observation},
                                        Chef Chantier: ${x?.utilisateur?.nom}`   }
                                      />
                                    </ListItem>
                                  </>
                                )
                              })
                             }
                          </List>
                      </div>
                    </>
              </div>
            }

            <DialogComp 
                isOpen={isDialogOpen}
                handleClose={() => {
                  closeModalAffectation();
                }}
                titleDialog="Informations d'affectation"
                children={<FormAffectation />}
                functionBtnYes={() => {
                  formik.submitForm();
                }}
      
            />
          </PerfectScrollbar>
        </div>
      </div>

{
  !loadingPerson && 
  <SideModal 
          open={open}
          onClose={handleClose}
          closeClick={handleClose}>
              <div className="filter-container">
              <span className="title"> Sélectionner les ressources à affecter </span>
                  <TextField
                    value={searchText}
                    onChange = {
                      (e) => {
                        setSearchText(e.target.value);
                      }
                    }
                    className="search-input"
                    placeholder="Recherche ... "
                    variant="outlined"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InlineIcon icon="bi:search" />
                        </InputAdornment>
                      )
                    }}
                  />

                  <List component="nav" aria-label="main mailbox folders"
                    sx={{
                          position: 'relative',
                          overflow: 'auto',
                        }}
                  >
                      {
                      listPersonnel.filter((value: any) => {
                        return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
                      }).map((x:any) => {
                          return (
                            <>
                              <ListItemButton key={x?.id} 
                              selected={selectedPersonId === x?.id}
                              onClick={(event) => handleListItemClick(event, x?.id)}
                              color='secondary'
                              divider={true}
                              sx={{
                                "&.Mui-selected": {
                                  borderColor: "#96D1C6",
                                  borderWidth: 2
                                },
                                "&.Mui-focusVisible": {
                                  backgroundColor: "#2e8b57"
                                }
                              }}
                              >
                                <ListItemIcon> <img src={personIcon} alt="" height="70px" /> </ListItemIcon>
                                <ListItemText 
                                  primary={x?.nom + ' ' + x?.prenoms} 
                                  secondary={x?.fonction}
                                />
                              </ListItemButton>
                            </>
                          )
                        })
                      }
                  </List>

                  <div className="btn-part">
                        <Button color='secondary'
                            className='btn-option'
                            disabled={selectedPersonId ? false : true}
                            startIcon={<InlineIcon icon="system-uicons:check" />}
                            onClick={()=>{
                              openModalAffectation()
                            }}
                            variant='contained'>
                            Valider la sélection
                        </Button>
                  </div>
              </div>
      </SideModal>
}
      
<SideModal 
          open={openUse}
          onClose={handleCloseUse}
          closeClick={handleCloseUse}>
            <>
        
              <EditPersonAffectedForm closeModal={handleCloseUse} selectedItem={rowSelected} selectedChantier={selectedChantier} />
            
            </>
        </SideModal>
      
    </>
  );
};

const mapStateToProps:any = createStructuredSelector({
  loadingPerson: selectLoading,
  loading: selectChantierDetailLoading,
  chantierList: selectChantierItemList,
  listPersonnelChantier: selectPersonnelChantierList,
  affectedPersonnelOnSelectedChantierList: selectAffectedPersonByChantierItemList,
  personnelList: selectPersonnelItemList,
  rhDetail: detailAboutRhAffectation,
  permissions: selectUserProfil,
  userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch:any) => ({
  getAllPersonnelsAsync: () => dispatch(getAllPersonnelsAsync()),
  getAllChantiersAsync: () => dispatch(getAllChantiersAsync()),
  callbackPersonAffectatedAsync: (id:any) => dispatch(callbackPersonAffectatedAsync(id)),
  getAllAffectedPersonsByChantierAsync: (id: any) => dispatch(getAllAffectedPersonsByChantierAsync(id)),
  createPersonAffectationAsync: (data:any) => dispatch(createPersonAffectationAsync(data)),
  getDetailAboutRhAffectatedAsync: (data:any) => dispatch(getDetailAboutRhAffectatedAsync(data)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CtAffectationRH = connector(CtAffectationRHItem);

export default CtAffectationRH;
