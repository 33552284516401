import { routePaths } from '@/config/routePaths';
import { InlineIcon } from '@iconify/react';
import { Badge, Tab, Tabs, tabsClasses, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import './Rh.content.layout.styles.scss';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import { selectUserProfil } from '@/store/reducers/profil/profil.selector';
import { selectAlerteCountList, selectAlerteItemList } from '@/store/reducers/alerte/alerte.selector';
import { notification } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';
import Snackbar from '@/components/Snackbar/Snackbar';

const LRhPage: React.FC<any> = ({ alerte, alerteList, permissions }) => {

  const theme = useTheme();
  const matches_down_xl = useMediaQuery(theme.breakpoints.down('xl'));
  const matches_down_lg = useMediaQuery(theme.breakpoints.down('lg'));

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(routePaths.adminIVEQIChildren.rhChildren.homeAbsolute, { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement: NotificationPlacement, topic: string) => {
    console.log('alerteList', alerteList!);

    if (topic === 'chantier') {
      const today: any = new Date(); // Obtenez la date actuelle

      for (let i = 0; i < alerteList?.fin_chantier.length; i++) {

        const dateFinChantier: any = new Date(alerteList?.fin_chantier[i]?.date_fin);
        const differenceInDays = Math.floor((dateFinChantier - today) / (1000 * 60 * 60 * 24)); // Calculer la différence en jours
        const daysDiff = -differenceInDays;
        if (differenceInDays < -1) {
          
          //description = `Rappel de fin de chantier ${alerteList?.fin_chantier[i]?.nom} localisé à ${alerteList?.fin_chantier[i]?.localisation} est dépassé de plus de ${daysDiff} jour(s)`;
          api.error({
            message: `Notification ${topic}`,
            description: `Rappel de fin de chantier ${alerteList?.fin_chantier[i]?.nom} localisé à ${alerteList?.fin_chantier[i]?.localisation} est dépassé de plus de ${daysDiff} jour(s)`,
            placement,
          });
        }else{
          api.success({
            message: `Notification ${topic}`,
            description: `Le chantier ${alerteList?.fin_chantier[i]?.nom} localisé à ${alerteList?.fin_chantier[i]?.localisation} sera achevé dans moins de ${daysDiff} jour(s)`,
            placement,
          });

        }
       
      }
    }
    if (topic === 'personnel') {
      for (let i = 0; i < alerteList?.personnel.length; i++) {
        api.error({
          message: `Absences ${alerteList?.personnel[i]?.nom} ${alerteList?.personnel[i]?.prenoms}`,
          description: `Monsieur / Madame /Mademoiselle ${alerteList?.personnel[i]?.nom} ${alerteList?.personnel[i]?.prenoms} est absent(e) du chantier  ${alerteList?.personnel[i]?.chantier?.nom} pour motif ${alerteList?.personnel[i]?.absences_chantiers[0]?.motif} `,
          placement,
        });
      }
    }
  };

  return (
    <>
      {alerteList && <Snackbar content={contextHolder} />}
      <div className="l-rh-content">
        <div className="l-rh-content__sidebar">
          <NavLink to={routePaths.adminIVEQIChildren.rhChildren.homeAbsolute}
            className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.rhChildren.homeAbsolute) !== -1) ? " selected" : "")}>
            <InlineIcon icon="bx:home-alt" />
            Accueil
          </NavLink>
          {
            permissions?.rh?.find((o: any) => (o.libelle === 'Personnel' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.rhChildren.personnelsAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.rhChildren.personnelsAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="mdi:people-group-outline" />
              <Badge badgeContent={alerte?.personnel || 0} color="warning" onClick={() => openNotification('bottomRight', 'personnel')}>
                Personnel
              </Badge>
            </NavLink>
          }
          {
            permissions?.rh?.find((o: any) => (o.libelle === 'Affectation' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.rhChildren.AffectationRHAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.rhChildren.AffectationRHAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="bi:stopwatch" />
              Affectation
            </NavLink>
          }
          {
            permissions?.rh?.find((o: any) => (o.libelle === 'Conges' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.rhChildren.congesAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.rhChildren.congesAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="bi:calendar-event" />
              Gestion des congés
            </NavLink>
          }
          {
            permissions?.rh?.find((o: any) => (o.libelle === 'Absence' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.rhChildren.demandePermissionAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.rhChildren.demandePermissionAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="tabler:calendar-event" />
              Gestion des absences
            </NavLink>
          }
          {
           permissions?.rh?.find((o: any) => (o.libelle === 'Absence' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.rhChildren.historiqueAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.rhChildren.historiqueAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="tabler:calendar-event" />
              Rapport
            </NavLink>
          }

        </div>
        <div className="l-rh-content__content">

          {
            (matches_down_xl || matches_down_lg) &&
            <>
              <div className="l-rh-content__content__tabs-container">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  variant="scrollable"
                  className="custom-tabs"
                  scrollButtons
                  aria-label="visible arrows tabs example"
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      '&.Mui-disabled': { opacity: 0.3 },
                    },
                  }}
                >
                  <Tab label="Accueil"
                    icon={<InlineIcon icon="bx:home-alt" />}
                    onClick={() => {
                      navigate(routePaths.adminIVEQIChildren.rhChildren.homeAbsolute, { replace: true });
                    }}
                    iconPosition="start" />
                  {
                    permissions?.rh?.find((o: any) => (o.libelle === 'Personnel' && o.read === true)) &&
                    <Tab label="Personnel"
                      icon={<InlineIcon icon="carbon:chart-column-target" />}
                      onClick={() => {
                        navigate(routePaths.adminIVEQIChildren.rhChildren.personnelsAbsolute, { replace: true });
                      }}
                      iconPosition="start" />
                  }
                  {
                    permissions?.rh?.find((o: any) => (o.libelle === 'Affectation' && o.read === true)) &&
                    <Tab label="Affectation"
                      icon={<InlineIcon icon="carbon:chart-column-target" />}
                      onClick={() => {
                        navigate(routePaths.adminIVEQIChildren.rhChildren.AffectationRHAbsolute, { replace: true });
                      }}
                      iconPosition="start" />
                  }
                  {
                    permissions?.rh?.find((o: any) => (o.libelle === 'Conges' && o.read === true)) &&
                    <Tab label=" Gestion des congés"
                      icon={<InlineIcon icon="ps:organisation" />}
                      onClick={() => {
                        navigate(routePaths.adminIVEQIChildren.rhChildren.congesAbsolute, { replace: true });
                      }}
                      iconPosition="start" />
                  }
                  {
                    permissions?.rh?.find((o: any) => (o.libelle === 'Absence' && o.read === true)) &&
                    <Tab label="Gestion des absences"
                      icon={<InlineIcon icon="fa-solid:people-carry" />}
                      onClick={() => {
                        navigate(routePaths.adminIVEQIChildren.rhChildren.demandePermissionAbsolute, { replace: true });
                      }}
                      iconPosition="start" />
                  }
                  {
                    permissions?.rh?.find((o: any) => (o.libelle === 'Historique' && o.read === true)) &&
                    <Tab label="Rapport"
                      icon={<InlineIcon icon="fa-solid:people-carry" />}
                      onClick={() => {
                        navigate(routePaths.adminIVEQIChildren.rhChildren.historiqueAbsolute, { replace: true });
                      }}
                      iconPosition="start" />
                  }
                  
                </Tabs>
              </div>
            </>
          }

          <Outlet />
        </div>
      </div>
    </>
  )
}

const mapStateToProps: any = createStructuredSelector({
  alerte: selectAlerteCountList,
  alerteList: selectAlerteItemList,
  permissions: selectUserProfil
});

const connector = connect(mapStateToProps);
const LRh = connector(LRhPage);

export default LRh;