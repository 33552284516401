import { EnginService } from "../../../services/engin.service";
import { enginTypes } from "./engin.types";

export const setLoading = (loading:any) => ({
    type: enginTypes.LOADING,
    payload: loading,
});

export const getAllEngins = (list:any) => ({
    type: enginTypes.GET_ENGIN_LIST,
    payload: list,
});

export const getAllVidanges = (list:any) => ({
  type: enginTypes.GET_VIDANGE_LIST,
  payload: list,
});

export const selectedItem = (engin:any) => ({
  type: enginTypes.SELECTED_ENGIN,
  payload: engin,
});

export const selectSyntheseEngin = (syntheseEngin:any) => ({
  type: enginTypes.GET_SYNTHESE_ENGIN,
  payload: syntheseEngin,
});


 
export const  getAllEnginsAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  EnginService.getEngins();
};

export const  createEnginAsync =  (engin:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  EnginService.createEngin(engin);
};

export const  updateEnginAsync =  (engin:any) =>
(dispatch:any) => {
  console.log("engin to service", engin);
  dispatch(setLoading(true));
  EnginService.updateEngin(engin);
};

export const  deleteEnginAsync =  (engin:any) =>
(dispatch:any) => {
  console.log("engin to deleteEnginAsync service", engin);
  
  EnginService.deleteEngin(engin);
  dispatch(setLoading(true));
};

export const  getAllVidangesAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  EnginService.getVidanges();
};

export const  createVidangeAsync =  (vidange:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  EnginService.createVidange(vidange);
};

export const  updateVidangeAsync =  (vidange:any) =>
(dispatch:any) => {
  console.log("Vidange to service", vidange);
  dispatch(setLoading(true));
  EnginService.updateVidange(vidange);
};

export const  getSyntheseEnginsAsync =  (id: any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  EnginService.getSyntheseEngin(id);
};


