import React from 'react';
import './ListeItemCardGenerique.styles.scss'
// import engineer from '@/assets/images/png/engineer.png';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

const API_IMAGE = process.env.REACT_APP_API_URL_IMAGE as string;

const ListeItemCardGenerique: React.FC<any> = ({
    id,
    columns,
    data,
    setIsEdit,
    isActive,
    imageFieldName,
    imageAltText,
}) => {

    const handleClick = () => {
        setIsEdit && setIsEdit(id);
    }
    
    let nbre = ((data[0]?.quantite * 100) / data[0]?.capacite);
    let couleur:any;
    if (nbre < 20) {
        couleur='#FF5500';
    } else if (nbre > 70) {
        couleur='#00A75F';
    } else {
        couleur='#F5B544';
    }

    return (
        <>
            {
                data.map((item: any, index: any) => (
                    <div className={`generic-item-card ${isActive ? 'active' : ''}`} key={index} onClick={() => handleClick()}>

                        {imageFieldName &&
                            <img src={API_IMAGE + imageFieldName} alt={"image de " + imageAltText} className='imageStyle' />
                        }

                        {/* <img src={engineer} alt={item[imageFieldName]} className='imageStyle' /> */}
                        <div className={imageFieldName && "with-image"}>
                            {columns.map((label: any, i: any) => (

                                <p key={i}>
                                    {label.headerName}: <b style={{ color: `${label.headerName === 'Quantité (L)' ? couleur : '#000'}` }}>{(label.headerName === 'Debut' || label.headerName === 'Fin') ? moment(item[label.field]).format('LL') : (label.headerName === 'Exonéré de TVA' ? (item[label.field] ? 'Oui' : 'Non') : item[label.field])}</b>
                                </p>
                            ))}
                        </div>
                    </div>
                ))
            }
        </>
    )
}


export default ListeItemCardGenerique;