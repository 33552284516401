import React from 'react';
import CtMap from '../pages-content/Map-content/Map.content';

const Map:React.FC = () => {
  return (
    <>
      <CtMap />
    </>
  )
}

export default Map;