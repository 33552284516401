import React from 'react';
import CtDashboard from '../pages-content/Dashboard-content/Dashboard.content';

const Dashboard:React.FC = () => {
  return (
    <>
      <CtDashboard />
    </>
  )
}

export default Dashboard;