import { InlineIcon } from '@iconify/react';
import { Delete, Edit, ListAlt, TurnedIn } from '@mui/icons-material';
import { Badge, Button, Chip, Divider, IconButton, InputAdornment,  Skeleton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SideModal from '../../components/SideModal/SideModal';
import './DemandePermission.content.style.scss';
import CreateAbsencesForm from '../../Forms/CreateAbsencesForm/CreateAbsencesForm';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { deleteAbsenceAsync, getAllAbsencesAsync } from '@/store/reducers/absence/absence.action';
import { selectLoading, selectAbsenceItemList } from '@/store/reducers/absence/absence.selector';
import { createStructuredSelector } from 'reselect';
import ListeItemCardGenerique from '@/features/admin-iveqi/components/ListeItem/ListeItemCardGenerique';
import { Stack } from "@mui/material";
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';
import Swal from 'sweetalert2';
import { exportToExcel } from '@/config';
import { selectUserProfil } from '@/store/reducers/profil/profil.selector';


type TPersonnel = {
  id?: number,
  email?: string,
  matricule?: string,
  nom: string,
  prenoms: string,
  telephone?: string,
  date_naissance?: string,
  lieu_naissance?: string,
  age?: string,
  nationalite?: string,
  fonction?: string,
  date_embauche?: string,
  cnps?: string,
  categorie_professionnelle?: string,
  statut: any,
  genre?: string,
  photo?: string,
  isactif?: number,

  etat_matrimoniale?: string,
  nombre_jr_acquis?: number,
  ops?: string,
}

const columns = [
  { field: 'date_depart', headerName: 'Date depart', width: 120 },
  { field: 'date_retour', headerName: 'Date retour', width: 120 },
  { field: 'nombre_jr_absent', headerName: 'Nombre de jours d\'absence', width: 180 },
  { field: 'deduire_conge', headerName: 'Deduire des congés', width: 200 },
  { field: 'searchText', headerName: 'Personnel', width: 230 },
  { field: 'motif', headerName: 'Motif', width: 130 },
  { field: 'observation', headerName: 'Observation', width: 150 },
];


type TAbsences = {
  id?: number;
  date_depart: string,
  date_retour: string,
  personnel: TPersonnel,
  nombre_jr_absent: number,
  motif?: string,
  observation?: string,
  deduire_conge?: boolean,
  searchText: string,
} []



const CtDemandePermissionRhPage: React.FC<any> = ({loading, absenceList, getAllAbsencesAsync, deleteAbsenceAsync, userConnected, permissions}) => {

  useEffect(() => {
    const url:any = getAllAbsencesAsync();

    const fetchData = async () => {
        try {
            await fetch(url);
        } catch (error) {
            console.log("error", error);
        }
    };
    fetchData();
    
  }, [getAllAbsencesAsync]);

  let listItems:TAbsences = absenceList.map((x:any) => {
    return {
      ...x,
      date_depart: moment(x.date_depart).format('DD-MM-YYYY'),
      date_retour: moment(x.date_retour).format('DD-MM-YYYY'),
      deduire_conge: (x.deduire_conge) ? 'Non' : 'Oui', 
      date_prochain_conges: moment(x.date_prochain_conges).format('DD-MM-YYYY'),
      searchText: x.personnel.matricule +' ' + x.personnel.nom + ' ' +  x.personnel.prenoms
    }
  });

  const [searchText, setSearchText] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isAdd, setIsAdd] = useState(false);

  const [isCardMode, setIsCardMode] = useState(true);

  const [selection, setSelection] = React.useState<any>(null);


  const getListItemsFilteredSearch = () => {
    return listItems.filter((value) => {
      return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
    })
  }

   // Appel de la fonction d'exportation lorsque vous souhaitez exporter les données
   const handleExportExcel = () => {
    exportToExcel(listItems, columns, 'demande_permission');
  };

  const selectItemFunc = (idElt: number | any) => {
    const element: any =  getListItemsFilteredSearch().find((x:any) => x.id === idElt);
    console.log("element", element);
    if (selection?.id === element?.id) {
      isCardMode && setSelection(null);
      return;
    }
    setSelection(element);
  }

  const isCardActive = (idCard: any) => {
    // if (selection?.id == idCard) return false
    return selection?.id === idCard
  }

  const confirmBeforeRemove =()=> {
    Swal.fire({
      title: "⚠️ Important ",
      text: "Vous êtes sur le point de supprimer une information, desirez-vous poursuivre cette action ?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
      confirmButtonColor: "red",
      cancelButtonColor: "#91949c",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  const deleteItem = () => {
    let datatoDelete = {"ops": (userConnected?.user?.nom +' '+userConnected?.user?.prenoms), "id": selection.id }
    console.log('go to delete', selection, datatoDelete);
    deleteAbsenceAsync(datatoDelete);
  }
  

  return (
    <>
      <div className="ct-demande-campagne">
        <div className="ct-demande-campagne__card">

          <div className="header-part">
            <div className="title-part">
                <div className="title-bloc">
                  <span className="title"> Gestion des absences </span>
                  <Chip label={` ${listItems?.length} ${listItems?.length < 2 ? 'absence' : 'absences'} `}
                    className='total-number-chip' />

                    <Badge color="secondary" 
                        variant="dot"
                        //  invisible={ valueFromSelect?.length === 0}
                         >
                      <IconButton 
                        className='close-icon'
                        title={`Mode ${isCardMode ? "liste" : 'carte'}`}
                        size="small" 
                        color='blue'
                        onClick={()=>{
                          setIsCardMode(!isCardMode)
                        }}>
                        {
                          isCardMode ?
                          <ListAlt /> :
                          <TurnedIn />
                        }
                      </IconButton>
                  </Badge>
                </div>
                <div className="toolbar-part">

                  <div className="actions-part">
                    
                  {
                    selection ?
                      <>
                        <Divider orientation='vertical' />
                        {
                          permissions?.rh?.find((o: any) => (o.libelle === 'Absence' && o.edit === true)) &&
                          <Button
                            color='blue'
                            className='btn-option'
                            onClick={() => {
                              handleOpen();
                              setIsAdd(true);
                            }}
                            startIcon={<Edit />}>
                            Modifier
                          </Button>
                        }
                        <Divider orientation='vertical' />
                        {
                          permissions?.rh?.find((o: any) => (o.libelle === 'Absence' && o.delete === true)) &&
                          <Button
                            color='blue'
                            className='btn-option'
                            onClick={confirmBeforeRemove}
                            startIcon={<Delete />}>
                            Supprimer
                          </Button>
                        }
                      </> :
                      <>
                        {
                          permissions?.rh?.find((o: any) => (o.libelle === 'Absence' && o.create === true)) &&
                          <Button
                            color='blue'
                            className='btn-option'
                            onClick={() => {
                              handleOpen();
                              setIsAdd(true);
                            }}
                            //variant='contained'
                            startIcon={<InlineIcon icon="carbon:document-add" />}>
                            Créer une permission
                          </Button>
                        }
                        <Divider orientation='vertical' />
                        <Button
                          color='blue'
                          className='btn-option'
                          onClick={handleExportExcel}
                          //variant='contained'
                          startIcon={<InlineIcon icon="carbon:document-import" />}>
                          Exporter la liste
                        </Button>
                      </>
                  }
                  </div>

                  <TextField
                    value={searchText}
                    onChange = {
                      (e) => {
                        setSearchText(e.target.value);
                      }
                    }
                    className="search-input"
                    placeholder="Recherche ... "
                    variant="outlined"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InlineIcon icon="bi:search" />
                        </InputAdornment>
                      )
                    }}
                  />

                </div>
            </div>
            <Divider className='custom-divider' />
          </div>

          <PerfectScrollbar id="scrollableDiv" className="content-part">
           
            <>
                {
                    loading && 
                    <>
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="pulse"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                    </>
                }

                {
                  isCardMode ?

                  <InfiniteScroll
                      className="content-part__infinite-scroll-part"
                      scrollableTarget="scrollableDiv"
                      dataLength={listItems?.length}
                      next={getAllAbsencesAsync}
                      hasMore={true}
                      loader={
                        listItems ?
                          <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                          :
                          <p> Aucune donnée disponible </p>
                      }>

                          {
                                !loading &&
                                listItems.filter((value) => {
                                    return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
                                  })
                                  .map((value) => {

                                    return <ListeItemCardGenerique
                                    id={value.id}
                                    setOpen={setOpen}
                                    columns={columns}
                                    data={[value]}
                                    setIsEdit={selectItemFunc}
                                    isActive={isCardActive(value.id)}
                                    />

                                  })
                            }
                    </InfiniteScroll>  :
                    <div style={{ height: '85%', width: '100%' }}>
                      <DataGrid  components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            Pas de donnees
                          </Stack>
                        ),
                      }}
                      localeText={{
                        MuiTablePagination: {
                          labelDisplayedRows: ({ from, to, count }) =>
                            `${from} - ${to} Total: ${count}`,
                        },
                      }}
                        rows={
                          getListItemsFilteredSearch()
                        }
                        columns={columns} 
                        initialState={{
                          pagination: { paginationModel: { pageSize: 7 } },
                        }}
                        onRowSelectionModelChange={(row) => {
                          selectItemFunc(row[0]);
                        }}
                      />
                    </div>
                }        
            
            
            </>
     
          </PerfectScrollbar>
        </div>
      </div>

      <SideModal 
          open={open}
          onClose={handleClose}
          closeClick={handleClose}>
            <>
                {
                    isAdd &&
                    <CreateAbsencesForm closeModal={handleClose} selectedItem={selection}  />
                }
            </>
      </SideModal>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
  absenceList: selectAbsenceItemList,
  userConnected: selectAuthUser,
  permissions: selectUserProfil
});

const mapDispatchToProps = (dispatch:any) => ({
  getAllAbsencesAsync: () => dispatch(getAllAbsencesAsync()),
  deleteAbsenceAsync: (data:any) => dispatch(deleteAbsenceAsync(data)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CtDemandePermissionRh = connector(CtDemandePermissionRhPage);

export default CtDemandePermissionRh;
