import React from 'react';
import CtUtilisateursCampaign from '../pages-content/Utilisateurs-content/Utilisateurs.content';

const Utilisateurs:React.FC = () => {
  return (
    <>
      <CtUtilisateursCampaign />
    </>
  )
}

export default Utilisateurs;