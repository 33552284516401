import { EquipementService } from "../../../services/equipement.service";
import { equipementTypes } from "./equipement.types";

export const setLoading = (loading:any) => ({
    type: equipementTypes.LOADING,
    payload: loading,
});

export const getAllEquipements = (list:any) => ({
    type: equipementTypes.GET_EQUIPEMENT_LIST,
    payload: list,
});

export const selectedItem = (equipement:any) => ({
  type: equipementTypes.SELECTED_EQUIPEMENT,
  payload: equipement,
});

 
export const  getAllEquipementsAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  EquipementService.getEquipements();
};

export const  createEquipementAsync =  (equipement:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  EquipementService.createEquipement(equipement);
};

export const  updateEquipementAsync =  (equipement:any) =>
(dispatch:any) => {
  console.log("equipement to service", equipement);
  dispatch(setLoading(true));
  EquipementService.updateEquipement(equipement);
};

export const  deleteEquipementAsync =  (data:any) =>
(dispatch:any) => {
  console.log("equipement to service", data);
  dispatch(setLoading(true));
  EquipementService.deleteEquipement(data);
};



