import React, { useEffect, useRef, useState } from "react";
import marker1 from "@/assets/images/png/loger1.png";
import marker2 from "@/assets/images/png/loger2.png";
import marker3 from "@/assets/images/png/loger3.png";
import worker from "@/assets/images/png/worker.png";
import gasoil from "@/assets/images/png/gasoil.png";
import truck from "@/assets/images/png/truck.png";
import reload from "@/assets/svgs/reload.svg";
import allProvincesOfIvoryCoast from '@/constantes/ci-all-region-json.json'
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import * as L from "leaflet";
import "leaflet.markercluster";
import "./MapCarte.style.scss";
import { getAllCarteEnginByIdChantiersAsync, getAllCartePersonByIdChantiersAsync, getCarteDataByStatus } from "@/store/reducers/carte/carte.action";
import { selectCarteDataByStatusItemList, selectCarteEnginByIdChantierItemList, selectCartePersonByIdChantierItemList } from "@/store/reducers/carte/carte.selector";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Box, Stack } from "@mui/material";
import Tile from "../Tile/Tile";

const getColor = (value: number) => {
  if (value !== null) {
    return 'green';
  } else {
    return 'orange';
  }
};

const chantierColumns = [
  { field: 'numero_chantier', headerName: 'Numero', width: 80 },
  { field: 'nom', headerName: 'Nom', width: 200 },
  { field: 'localisation', headerName: 'Localisation', width: 100 },
  { field: 'date_debut', headerName: 'Debut', width: 130 },
  { field: 'date_fin', headerName: 'Fin', width: 100},
];

const personColumns = [
  { field: 'matricule', headerName: 'Matricule', width: 80 },
  { field: 'nom', headerName: 'Nom', width: 100 },
  { field: 'prenoms', headerName: 'Prénoms', width: 200 },
  { field: 'telephone', headerName: 'Telephone', width: 130 },
  { field: 'fonction', headerName: 'Fonction', width: 100},
];

const enginColumns = [
  { field: 'libelle', headerName: 'Libellé', width: 150 },
  { field: 'num_parc', headerName: 'N°Parc', width: 150 },
  {
    field: 'totalCarburant',
    headerName: 'Carburant (L)',
    type: 'number',
    width: 130,
  },
  {
    field: 'totalQuantite',
    headerName: 'Travail effectue',
    type: 'number',
    width: 130,
  },
  {
    field: 'moyenne',
    headerName: 'Moyenne',
    type: 'string',
    width: 130,
  },
  { 
    field: 'statut', 
    headerName: 'Statut', 
    width: 150,
    renderCell: (params: GridRenderCellParams) => {
      const color = getColor(params?.value);
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            backgroundColor: color,
            width: "90%",
            height: "15px",
            borderRadius: "6px"
          }}
        >
          {params?.value === null ? <span>encours</span> : <span>terminer</span>}
        </Box>
      );
    }
  },
];

const MapCartePage: React.FC<any> = ({ list, enginList, personList, getAllCarteEnginByIdChantiersAsync, getAllCartePersonByIdChantiersAsync,modalStatData}) => {

  const [isStatModalOpen, setIsStatModalOpen] = useState<any>(false);
  const [statModalTile, setStatModalTile] = useState<any>([]);
  let statColumns:any[] =[];
  if (statModalTile[0]?.id === 1) {
    statColumns = chantierColumns;
  } else if (statModalTile[0]?.id === 2) {
      statColumns = personColumns;
  } else {
      statColumns = enginColumns;
  }

  const dispatch = useDispatch();


  let listEngin: any[] = [];
  enginList.forEach((o:any) => {
    listEngin.push({
      ...o,
      moyenne: (o?.totalCarburant / o?.totalQuantite).toFixed(2),
      num_parc: o?.engin?.num_parc,
      libelle: o?.engin?.libelle
    })
  });

  let listPersonnel: any[] = [];
  personList?.forEach((o:any) => {
    listPersonnel.push(o.personnel)
  });

  const myIcon1: any = L.icon({
    iconUrl: marker1,
    iconRetinaUrl: marker1,
    iconSize: [50, 50],
    iconAnchor: [12.5, 41],
    popupAnchor: [10, -41],
  });

  const myIcon2: any = L.icon({
    iconUrl: marker2,
    iconRetinaUrl: marker2,
    iconSize: [50, 50],
    iconAnchor: [12.5, 41],
    popupAnchor: [10, -41],
  });

  const myIcon3: any = L.icon({
    iconUrl: marker3,
    iconRetinaUrl: marker3,
    iconSize: [50, 50],
    iconAnchor: [12.5, 41],
    popupAnchor: [10, -41],
  });
  
  const mapRef: any = useRef(null);
  const tileRef: any = useRef(null);
  const geoJsonRef: any = useRef(null);
  const layerRef: any = useRef(null);
  const [getEltId, setGetEltId] = useState<any>(null);
  let contentPerson:any = document.getElementById('personModal');
  let contentEngin:any = document.getElementById('enginModal');
  let contentStat:any = document.getElementById('statModal');

  let myClusterGroup: any = L.markerClusterGroup({
    iconCreateFunction(cluster: any) {
      console.log('iconcluster',cluster);
      
      return L.divIcon({
        html: ` <div class='cluster-container'>
                <div class='cluster-badge'> ${cluster.getChildCount()}  </div>
              </div>`,
        className: "clusterClass",
      });
    },
  });

  useEffect(() => {
    if (getEltId) {
      const url:any = getAllCarteEnginByIdChantiersAsync(getEltId);
      const url1:any = getAllCartePersonByIdChantiersAsync(getEltId);
  
      const fetchData = async () => {
        try {
            await fetch(url);
            await fetch(url1);
        } catch (error) {
            console.log("error", error);
        }
      };
      fetchData();
      setGetEltId(null);
    }
  }, [getEltId, getAllCarteEnginByIdChantiersAsync, getAllCartePersonByIdChantiersAsync]);
  

  const handleCloseModal = () => {
    contentPerson.style.display='none';
    contentEngin.style.display='none';
    contentStat.style.display='none';
    setIsStatModalOpen(false);
    dispatch(getCarteDataByStatus([]));
  }
  
   useEffect( () => {
    if (mapRef.current) {
      mapRef.current.off();
      mapRef.current.remove();
    }
    mapRef.current = new L.Map('map');


    tileRef.current = L.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        maxZoom: 18,
        minZoom: 6,
      }
    );

    mapRef.current.setView(new L.LatLng(7.539989, -5.547080), 1);

    tileRef.current.addTo(mapRef.current);

    mapRef.current.flyTo([7.539989, -5.547080], 7, {
      animate: true,
      duration: 2 // in seconds
    });
  }, []);

  useEffect(() => {
    const ciGeoJson: any = allProvincesOfIvoryCoast;
    geoJsonRef.current = L.geoJSON(ciGeoJson, {
      style: () => {
        let colorZone = {
          color: '#f58220',
          fillOpacity: 0.05,
          fillColor: '#f58220',
          weight: 0.1,
        };
        return colorZone;
      },
    });

    geoJsonRef.current.addTo(mapRef.current);
  }, []);


  useEffect(() => {
    layerRef.current = L.layerGroup().addTo(mapRef.current);

  }, []);

  useEffect(() => {

    // onclick="document.getElementById('id01').style.display='block'"

    layerRef.current.clearLayers();


    list?.forEach((marker: any) => {

      let iconSelected: any;
  
          if (marker?.alerte < 1) {
            iconSelected = myIcon1;
          } else if (marker?.alerte < 3) {
            iconSelected = myIcon2;
          } else {
            iconSelected = myIcon3;
          }
   
      
        const markerList = L.marker([marker?.latitude, marker?.longitude], {
          title: marker?.nom,
          icon: iconSelected,
        });

        const markerPopupHtml = `

          <div class="container-popup">
            <div class="main-popup" onclick="console.log('okay')">
              <div class="form-popup">
                <div class="popup-form-header">
                  <strong>${marker?.nom}</strong>
                  <span class="id">${marker?.numero_chantier ? marker?.numero_chantier : 'N/A'}</span>
                </div>
                <div class="content-popup">
                  <div class="content-popup-zone">
                    <div class="content-popup-col" onclick="document.getElementById('personModal').style.display='block'">
                      <span class="content-title-popup">Total personnes</span>
                      <img src=${worker} alt="" />
                      <span>${marker?.personnel ? marker?.personnel : 'N/A'}</span>
                    </div>
                    <div class="limit-popup"></div>
                    <div class="content-popup-col" class="content-popup-col" onclick="document.getElementById('enginModal').style.display='block'">
                      <span class="content-title-popup">Total engins</span>
                      <img src=${truck} alt="" />
                      <span>${marker?.engin ? marker?.engin : 'N/A'} </span>
                    </div>
                    <div class="limit-popup"></div>
                    <div class="content-popup-col">
                      <span class="content-title-popup">Quantite carburant</span>
                      <img src=${gasoil} alt="" />
                      <span>${marker?.quantite_caburant ? (marker?.quantite_caburant > 1 ? `${marker?.quantite_caburant} litres` : `${marker?.quantite_caburant} litre`) : 'N/A'}</span>
                    </div>
                  </div>
                </div>
              <div class="desc-popup">
                <span><strong>Duree:</strong> ${'Du ' + marker?.date_debut +' au '+ marker?.date_fin}</span>
                <span><strong>Carburant:</strong> ${marker?.carburant} L</span>
                <span><strong>Travail effectue:</strong> ${marker?.quantite}</span>
                <span><strong>Moyenne:</strong> ${(marker?.quantite !== 0 ? (marker?.carburant / marker?.quantite).toFixed(2) : 'N/A')}</span>

                <span><strong>Situation geographique:</strong> ${marker?.localisation}</span>
              </div>
              
            </div>
          </div>
        </div>
          
          
          `;
        const markerPopupOptions = { className: "markerPopupClass" };
        markerList.bindPopup(markerPopupHtml, markerPopupOptions);

        // Mouseover marker
        markerList.on("mouseover", () => {
          markerList.openPopup();
          setGetEltId(marker?.id);

        });

             

        myClusterGroup.addLayer(markerList);
      
    });

    layerRef.current.addLayer(myClusterGroup);
  }, [myClusterGroup, myIcon1, myIcon2, myIcon3, list]);

  function handleInitMap() {
    mapRef.current.flyTo([7.539989, -5.547080], 7);
  }

  return (
    <div className="lmap">
      <div id="map">
        <div className="init-map">
          <button className="btn" onClick={handleInitMap}>
            <img src={reload} alt="" height="30px" />
          </button>
        </div>
        <div className="stat-zone">
          <Tile setIsStatModalOpen={setIsStatModalOpen} setStatModalTile={setStatModalTile}  />
        </div>
      </div>

      
      {/* modal engin */}
      <div id="enginModal" className="myModal">

        {/* <!-- myModal content --> */}
        <div className="myModal-content">
          <span className="myModal-close" onClick={handleCloseModal}>&times;</span>
          <span className="myModal-title">Liste des engins</span>
          <div style={{ height: '85%', width: '100%' }}>
            <DataGrid  
                      rows={listEngin || []}
                      columns={enginColumns}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            Pas de donnees
                          </Stack>
                        ),
                      }}
                      localeText={{
                        MuiTablePagination: {
                          labelDisplayedRows: ({ from, to, count }) =>
                            `${from} - ${to} Total: ${count}`,
                        },
                      }}
                    />
          </div>
        </div>

      </div>

      {/* modal personnel */}
      <div id="personModal" className="myModal">

        {/* <!-- myModal content --> */}
        <div className="myModal-content">
          <span className="myModal-close" onClick={handleCloseModal}>&times;</span>
          <span className="myModal-title">Liste du personnel</span>
          <div style={{ height: '85%', width: '100%' }}>
            <DataGrid
                      rows={listPersonnel}
                      columns={personColumns}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            Pas de donnees
                          </Stack>
                        ),
                      }}
                      localeText={{
                        MuiTablePagination: {
                          labelDisplayedRows: ({ from, to, count }) =>
                            `${from} - ${to} Total: ${count}`,
                        },
                      }}
                    />
          </div>
        </div>

      </div>

      {/* modal stat */}
      <div id="statModal" className="myModal" style={isStatModalOpen ? {display: 'block'} : {display: 'none'}}>

        {/* <!-- myModal content --> */}
        <div className="myModal-content">
          <span className="myModal-close" onClick={handleCloseModal}>&times;</span>
          <span className="myModal-title">{ `${statModalTile[0]?.title || ''} / ${statModalTile[1]?.libelle || ''} (${statModalTile[1]?.value || ''})`}</span>
          <div style={{ height: '85%', width: '100%' }}>
            <DataGrid
                      rows={modalStatData}
                      columns={statColumns}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            Pas de donnees
                          </Stack>
                        ),
                      }}
                      localeText={{
                        MuiTablePagination: {
                          labelDisplayedRows: ({ from, to, count }) =>
                            `${from} - ${to} Total: ${count}`,
                        },
                      }}
                    />
          </div>
        </div>

      </div>
      
    </div>
  );
};

const mapStateToProps:any = createStructuredSelector({
  enginList: selectCarteEnginByIdChantierItemList,
  personList: selectCartePersonByIdChantierItemList,
  modalStatData: selectCarteDataByStatusItemList,
});

const mapDispatchToProps = (dispatch:any) => ({
  getAllCarteEnginByIdChantiersAsync: (id:any) => dispatch(getAllCarteEnginByIdChantiersAsync(id)),
  getAllCartePersonByIdChantiersAsync: (id:any) => dispatch(getAllCartePersonByIdChantiersAsync(id)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const MapCarte = connector(MapCartePage);

export default MapCarte;
