import { routePaths } from '@/config/routePaths';
import { InlineIcon } from '@iconify/react';
import { Badge, Tab, Tabs, tabsClasses, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { notification } from 'antd';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import './Campaign.content.layout.styles.scss';
import { selectAlerteCountList, selectAlerteItemList } from "@/store/reducers/alerte/alerte.selector";
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import Snackbar from '@/components/Snackbar/Snackbar';
import { selectUserProfil } from '@/store/reducers/profil/profil.selector';


const LCampaignPage: React.FC<any> = ({ alerte, alerteList, permissions }) => {

  const theme = useTheme();
  const matches_down_xl = useMediaQuery(theme.breakpoints.down('xl'));
  const matches_down_lg = useMediaQuery(theme.breakpoints.down('lg'));

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    false && navigate(routePaths.adminIVEQIChildren.campaignChildren.homeAbsolute, { replace: true });

    location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.homeAbsolute) !== -1 && setValue(0);
    location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.listeAbsolute) !== -1 && setValue(1);
    location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.incidentAbsolute) !== -1 && setValue(2);
    location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.affectationsAbsolute) !== -1 && setValue(3);
    location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.rapportTerrainAbsolute) !== -1 && setValue(4);
    location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.historiqueAbsolute) !== -1 && setValue(4);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement: NotificationPlacement, topic: string) => {
    console.log('alerteList', alerteList);

    if (topic === 'chantier') {
      const today: any = new Date(); // Obtenez la date actuelle
     
      for (let i = 0; i < alerteList?.fin_chantier?.length; i++) {

        const dateFinChantier: any = new Date(alerteList?.fin_chantier[i]?.date_fin);
        const differenceInDays = Math.floor((dateFinChantier - today) / (1000 * 60 * 60 * 24)); // Calculer la différence en jours
        const daysDiff = -differenceInDays;
        if (differenceInDays < -1) {
          
          //description = `Rappel de fin de chantier ${alerteList?.fin_chantier[i]?.nom} localisé à ${alerteList?.fin_chantier[i]?.localisation} est dépassé de plus de ${daysDiff} jour(s)`;
          api.error({
            message: `Notification ${topic}`,
            description: `Rappel de fin de chantier ${alerteList?.fin_chantier[i]?.nom} localisé à ${alerteList?.fin_chantier[i]?.localisation} est dépassé de plus de ${daysDiff} jour(s)`,
            placement,
          });
        }else{
          api.success({
            message: `Notification ${topic}`,
            description: `Le chantier ${alerteList?.fin_chantier[i]?.nom} localisé à ${alerteList?.fin_chantier[i]?.localisation} sera achevé dans moins de ${daysDiff} jour(s)`,
            placement,
          });

        }
       
      }
    }
    if (topic === 'incident') {
      for (let i = 0; i < alerteList?.incident?.length; i++) {

        const incident = alerteList.incident[i];
        const description = [
          `Date incident: ${new Date(incident.date_incient).toLocaleDateString()}`,
          `Type de panne: ${incident.panne}`,
          `Observation sur la panne: ${incident.observation_panne.trim()}`,
          `Chef Chantier: ${incident.utilisateur.prenoms}`,
          `Chantier : ${incident.chantier.nom}`,
          `N° Parc Engin : ${incident.engin.num_parc}`
        ].join('\n'); 
        // Utiliser '\n' pour le retour à la ligne
        api.error({
          message: `Notification ${topic} N°P: ${alerteList?.incident[i]?.engin.num_parc}`,
          description: `Date incident: ${new Date(alerteList?.incident[i]?.date_incient).toLocaleDateString()} \n Type de panne: ${alerteList?.incident[i]?.panne} \n Observation sur la panne: ${alerteList?.incident[i]?.observation_panne.trim()} \n  Chef Chantier: ${alerteList?.incident[i]?.utilisateur.prenoms} 
          \n Chantier : ${alerteList?.incident[i]?.chantier.nom} \n N° Parc Engin : ${alerteList?.incident[i]?.engin.num_parc} `,
          placement,
        });
      }
    }
    if (topic === 'cuve') {
      for (let i = 0; i < alerteList?.cuve?.length; i++) {
        api.error({
          message: `Notification ${topic}`,
          description: `Le niveau de la cuve ${alerteList?.cuve[i]?.libelle} est critique`,
          placement,
        });
      }
    }
  };

  return (
    <>
      {alerteList && <Snackbar content={contextHolder} />}
      <div className="l-campaign-content">
        <div className="l-campaign-content__sidebar">
          <NavLink to={routePaths.adminIVEQIChildren.campaignChildren.homeAbsolute}
            className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.homeAbsolute) !== -1) ? " selected" : "")}>
            <InlineIcon icon="bx:home-alt" />
            Accueil
          </NavLink>
          {
            permissions?.chantier?.find((o: any) => (o.libelle === 'Chantier' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.campaignChildren.listeAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.listeAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="clarity:list-line" />
              <Badge badgeContent={alerte?.fin_chantier || 0} color="warning" onClick={() => openNotification('bottomRight', 'chantier')}>
                Chantier
              </Badge>
            </NavLink>
          }
          { <NavLink to={routePaths.adminIVEQIChildren.campaignChildren.organisationAbsolute}
            className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.organisationAbsolute) !== -1) ? " selected" : "")}>
            <InlineIcon icon="ps:organisation" />
            <Badge badgeContent={alerte?.cuve || 0} color="warning" onClick={() => openNotification('bottomRight', 'cuve')}>
                Cuve
              </Badge>
          </NavLink> }
          {
            permissions?.chantier?.find((o: any) => (o.libelle === 'Incident' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.campaignChildren.incidentAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.incidentAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="clarity:clipboard-outline-badged" />
              <Badge badgeContent={alerte?.incident || 0} color="warning" onClick={() => openNotification('bottomRight', 'incident')}>
                Incident
              </Badge>
            </NavLink>
          }
          {
            permissions?.chantier?.find((o: any) => (o.libelle === 'Affectation' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.campaignChildren.affectationsAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.affectationsAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="fa-solid:people-arrows" />
              Affectation
            </NavLink>
          }
          {
            permissions?.chantier?.find((o: any) => (o.libelle === 'Historique' && o.read === true)) &&
            <NavLink to={routePaths.adminIVEQIChildren.campaignChildren.historiqueAbsolute}
              className={"campaign-nav-link" + ((location.pathname.indexOf(routePaths.adminIVEQIChildren.campaignChildren.historiqueAbsolute) !== -1) ? " selected" : "")}>
              <InlineIcon icon="fa6-solid:people-group" />
              Rapport
            </NavLink>
          }


        </div>
        <div className="l-campaign-content__content">

          {
            (matches_down_xl || matches_down_lg) &&
            <>
              <div className="l-campaign-content__content__tabs-container">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  variant="scrollable"
                  className="custom-tabs"
                  scrollButtons
                  aria-label="visible arrows tabs example"
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      '&.Mui-disabled': { opacity: 0.3 },
                    },
                  }}
                >
                  <Tab label="Accueil"
                    icon={<InlineIcon icon="bx:home-alt" />}
                    onClick={() => {
                      navigate(routePaths.adminIVEQIChildren.campaignChildren.homeAbsolute, { replace: true });
                    }}
                    iconPosition="start" />
                  {
                    permissions?.chantier?.find((o: any) => (o.libelle === 'Chantier' && o.read === true)) &&
                    <Tab label="Chantier"
                      icon={<InlineIcon icon="clarity:list-line" />}
                      onClick={() => {
                        navigate(routePaths.adminIVEQIChildren.campaignChildren.listeAbsolute, { replace: true });
                      }}
                      iconPosition="start" />
                  }
                  <Tab label="Chantier"
                      icon={<InlineIcon icon="clarity:list-line" />}
                      onClick={() => {
                        navigate(routePaths.adminIVEQIChildren.campaignChildren.organisationAbsolute, { replace: true });
                      }}
                      iconPosition="start" />
                  {
                    permissions?.chantier?.find((o: any) => (o.libelle === 'Incident' && o.read === true)) &&
                    <Tab label="Incident"
                      icon={<InlineIcon icon="clarity:clipboard-outline-badged" />}
                      onClick={() => {
                        navigate(routePaths.adminIVEQIChildren.campaignChildren.incidentAbsolute, { replace: true });
                      }}
                      iconPosition="start" />
                  }
                  {
                    permissions?.chantier?.find((o: any) => (o.libelle === 'Affectation' && o.read === true)) &&
                    <Tab label="Affectation"
                      icon={<InlineIcon icon="fa-solid:people-arrows" />}
                      onClick={() => {
                        navigate(routePaths.adminIVEQIChildren.campaignChildren.affectationsAbsolute, { replace: true });
                      }}
                      iconPosition="start" />
                  }

                  {
                    permissions?.chantier?.find((o: any) => (o.libelle === 'Historique' && o.read === true)) &&
                    <Tab label="Rapport"
                      icon={<InlineIcon icon="fa6-solid:people-group" />}
                      onClick={() => {
                        navigate(routePaths.adminIVEQIChildren.campaignChildren.historiqueAbsolute, { replace: true });
                      }}
                      iconPosition="start" />
                  }

                </Tabs>
              </div>
            </>
          }

          <Outlet />
        </div>
      </div>
    </>
  )
}

const mapStateToProps: any = createStructuredSelector({
  alerte: selectAlerteCountList,
  alerteList: selectAlerteItemList,
  permissions: selectUserProfil
});

const connector = connect(mapStateToProps);
const LCampaign = connector(LCampaignPage);

export default LCampaign;