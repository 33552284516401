import React from 'react';
import CtAccueilRh from '../pages-content/Accueil-content/Accueil.content';

const AccueilRh:React.FC = () => {
  return (
    <>
      <CtAccueilRh />
    </>
  )
}

export default AccueilRh;