import React, { useEffect } from 'react';
import './Tile.style.scss';
import * as _ from 'lodash';
import { getAllCarteStatistiquesAsync, getCarteDataByStatusAsync } from '@/store/reducers/carte/carte.action';
import { selectCarteStatistiqueLoading, selectCarteStatistiqueItemList } from '@/store/reducers/carte/carte.selector';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

const TilePage: React.FC<any> = ({
  setIsStatModalOpen, 
  setStatModalTile, 
  getStatistiques, 
  getStatistiquesAsync,
  getCarteDataByStatusAsync,
}) => {

  useEffect(() => {
    const url1:any = getStatistiquesAsync();

    const fetchData = async () => {
        try {
            await fetch(url1);
        } catch (error) {
            console.log("error", error);
        }
    };
    fetchData();
  }, [getStatistiquesAsync]);
  
  let data:any[] = [
    {
      id: 1,
      title: 'Chantier', 
      total: getStatistiques?.chantier?.total , 
      items: [
        {
          libelle: 'Terminer',
          couleur: 'bulle vert',
          value: getStatistiques?.chantier?.terminer
        },
        {
          libelle: 'Encours',
          couleur: 'bulle orange',
          value: getStatistiques?.chantier?.enCours
        },
        {
          libelle: 'Avenir',
          couleur: 'bulle rouge',
          value: getStatistiques?.chantier?.avenir
        },
      ]
    },
    {
      id: 2,
      title: 'Personnel', 
      total: getStatistiques?.personnel?.total , 
      items: [
        {
          libelle: 'Administration',
          couleur: 'bulle vert',
          value: getStatistiques?.personnel?.administration
        },
        {
          libelle: 'Chantier',
          couleur: 'bulle orange',
          value: getStatistiques?.personnel?.chantier
        },
        {
          libelle: 'Atelier',
          couleur: 'bulle rouge',
          value: getStatistiques?.personnel?.atelier
        },
      ]
    },
    {
      id: 3,
      title: 'Engin', 
      total: getStatistiques?.engin?.total , 
      items: [
        {
          libelle: 'Atelier',
          couleur: 'bulle vert',
          value: getStatistiques?.engin?.attelier 
        },
        {
          libelle: 'Chantier',
          couleur: 'bulle orange',
          value: getStatistiques?.engin?.chantier
        },
        {
          libelle: 'Panne',
          couleur: 'bulle rouge',
          value: getStatistiques?.engin?.panne
        },
      ]
    },
  ] 

  const handleClick = (item: any, subItem:any) => {
    console.log('item', item);
    console.log('libelle', subItem);
    setIsStatModalOpen(true)
    setStatModalTile([item,subItem])
    getCarteDataByStatusAsync(item.id, subItem.libelle);
  } 

  return (
    <div className='box'>
      {
        _.map(data, (o:any, i:number) => 
          <div className='tiles-container' key={i}>
            <div className="tile-title">{o.title} ({o.total || 0})</div>
            <div className="tile-body">
              <div className="tile-content">
              {
                _.map(o.items,(x:any, y:number) => 
                  <div className="tile-content-ligne" key={y} onClick={()=>{handleClick(o, x)}}>
                    <span className={`${x.couleur}`}></span>
                    <span className="libelle">{x.libelle} ({x.value || 0})</span>
                  </div>
                )
              }
              </div>
            </div>
          </div>
        )
      }
    </div>
  ) 
}

const mapStateToProps:any = createStructuredSelector({
  loadingStatistique: selectCarteStatistiqueLoading,
  getStatistiques: selectCarteStatistiqueItemList,
});

const mapDispatchToProps = (dispatch:any) => ({
  getStatistiquesAsync: () => dispatch(getAllCarteStatistiquesAsync()),
  getCarteDataByStatusAsync: (id:any, data:any) => dispatch(getCarteDataByStatusAsync(id,data)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const Tile = connector(TilePage);


export default Tile;