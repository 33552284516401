import React from 'react';
import CtNatureTravauxAdministration from '../pages-content/NatureTravaux-content/NatureTravaux.content';

const NatureTravaux:React.FC = () => {
  return (
    <>
      <CtNatureTravauxAdministration />
    </>
  )
}

export default NatureTravaux;