import { routePaths } from "@/config";
import { Routes, Route, Navigate } from "react-router-dom";
import LAdministration from "../layouts/Administration.content.layout";
import AccueilAdmin from "../pages/Accueil.page";
import Equipes from "../pages/Equipes.page";
import Objectifs from "../pages/Objectifs.page";
import Roles from "../pages/Roles.page";
import Utilisateurs from "../pages/Utilisateurs.page";
import Clients from "../pages/Clients.page";
import CategorieEquipement from "../pages/CategorieEquipement.page";
import Equipements from "../pages/Equipements.page";
import CategorieEngin from "../pages/CategorieEngin.page";
import Engins from "../pages/Engins.page";
import NatureTravaux from "../pages/NatureTravaux.page";
import Vidanges from "../pages/Vidanges.page";

export const CtAdministrationRoutes:React.FC = () => {
    return (
      <Routes>
          <Route path="" element={<LAdministration />}>
              <Route index element={ <Navigate to={`${routePaths.adminIVEQIChildren.administrationChildren.homeAbsolute}`} /> } />
              <Route path={routePaths.adminIVEQIChildren.administrationChildren.home} element={ <AccueilAdmin />} />
              <Route path={routePaths.adminIVEQIChildren.administrationChildren.roles} element={ <Roles />} />
              <Route path={routePaths.adminIVEQIChildren.administrationChildren.utilisateurs} element={ <Utilisateurs />} />

              <Route path={routePaths.adminIVEQIChildren.administrationChildren.clients} element={ < Clients />} />
              <Route path={routePaths.adminIVEQIChildren.administrationChildren.natureTravaux} element={ <NatureTravaux />} />
              <Route path={routePaths.adminIVEQIChildren.administrationChildren.categorieEquipement} element={ <CategorieEquipement />} />
              <Route path={routePaths.adminIVEQIChildren.administrationChildren.equipements} element={ < Equipements />} />
              <Route path={routePaths.adminIVEQIChildren.administrationChildren.categorieEngins} element={ < CategorieEngin />} />
              <Route path={routePaths.adminIVEQIChildren.administrationChildren.engins} element={ <Engins />} />
              <Route path={routePaths.adminIVEQIChildren.administrationChildren.vidanges} element={ <Vidanges />} />

              <Route path={routePaths.adminIVEQIChildren.administrationChildren.equipes} element={ <Equipes />} />
              <Route path={routePaths.adminIVEQIChildren.administrationChildren.objectifs} element={ <Objectifs />} />

              <Route path="/*" element={ <Navigate to={`${routePaths.adminIVEQIChildren.administrationChildren.homeAbsolute}`} /> } />
          </Route>
      </Routes>
    );
};