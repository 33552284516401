import React from 'react';
import CtHistoriqueCampaign from '../pages-content/Historique-content/Historique.content';

const Historique:React.FC = () => {
  return (
    <>
      <CtHistoriqueCampaign />
    </>
  )
}

export default Historique;