import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Grow from '@mui/material/Grow';
import { IconButton } from '@mui/material';
import { HighlightOffRounded } from '@mui/icons-material';
import './Modal.styles.scss';


const CModal:React.FC<any> = ({
    title,
    open,
    setOpen,
    children
}) => {

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Grow in={open}>
          <div className='c-modal '>
            <div className="c-modal-header">
              <span className='c-modal-title'>{title}</span>
              <IconButton className='close-icon'
                          size="large" 
                          color='inherit'
                          onClick={handleClose}>
                  <HighlightOffRounded />
              </IconButton>
            </div>

            <div className="c-modal-content">
              {children}
            </div>

          </div>
        </Grow>
      </Modal>
  )
}

export default CModal;