import React from 'react';
import CtDemandePermissionRh from '../pages-content/DemandePermission-content/DemandePermission.content';


const DemandePermissionRh:React.FC = () => {
  return (
    <>
      <CtDemandePermissionRh />
    </>
  )
}

export default DemandePermissionRh;