import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectDashboard = (state:TRootState) => state.dashboard;

export const selectLoading = createSelector(
  [selectDashboard],
  (dashboard:any) => dashboard.loading
);

export const selectDashboardItemList = createSelector(
    [selectDashboard],
    (dashboard:any) => dashboard.list
);

export const selectDashboardDetailLoading = createSelector(
  [selectDashboard],
  (dashboard:any) => dashboard.loading
);

export const selectTypedashboard = createSelector(
  [selectDashboard],
  (dashboard:any) => dashboard.user
);

export const selectTableDatadashboard = createSelector(
  [selectDashboard],
  (dashboard:any) => dashboard.tableData
);

export const selectHistoriqueDataDashboard = createSelector(
  [selectDashboard],
  (dashboard:any) => dashboard.historiqueData
);