import React, { useMemo } from 'react';
  
  const Context = React.createContext({ name: 'Default' });
  
  const Snackbar: React.FC<any> = ({content}) => {
  
    const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);
  
    return (
      <Context.Provider value={contextValue}>
        {content}
      </Context.Provider>
    );
  };
  
  export default Snackbar;