import MiniPiehart from '@/features/admin-iveqi/components/Mini-pie-chart/MiniPieChart';
import { InlineIcon } from '@iconify/react';
import { TuneRounded } from '@mui/icons-material';
import { Badge, Box, Button, Checkbox, Chip, Divider, IconButton, InputAdornment, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Skeleton, TextField } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ListeItemCard from '../../components/ListeItem/ListeItemCard';
import SideModal from '../../components/SideModal/SideModal';
import './Equipes.content.style.scss';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const selectList = [
  'DRAN',
  'DRAS',
  'DRABO',
  'DRYOP',
  'DRSO',
  'DRBC',
  'DRSE',
  'DRC',
  'DRCO',
  'DRN',
  'DRO',
  'DRCS'
];

type TListLegend = 'en_cours'|'pret'|'utilise' ;

type TFakeList = {
  label:string,
  legend: TListLegend,
  hierarchy:string,
} []


const CtEquipesCampaign: React.FC = () => {

  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasmore, setHasmore] = useState(true);

  const [legend, setLegend] = useState<TListLegend |''>('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isAdd, setIsAdd] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  const [valueFromSelect, setValueFromSelect] = React.useState<string[]>(['DRAN']);
  const handleValueFromSelect = (event: SelectChangeEvent<typeof valueFromSelect>) => {
      const { target: { value } } = event;
      setValueFromSelect(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
      );
  };

  const listList: TFakeList = [
    {
      label: 'liste 1',
      legend: 'en_cours',
      hierarchy: 'DRAN',
    },
    {
      label: 'liste 2',
      legend: 'pret',
      hierarchy: 'DRAN',
    },
    {
      label: 'liste 3',
      legend: 'utilise',
      hierarchy: 'DRAN',
    },
  ];

   // Appel de la fonction d'exportation lorsque vous souhaitez exporter les données
   const handleExportExcel = () => {
  //  exportToExcel(listItems, columns, 'equipement');
  };

  const [fakeItems, setFakeItems] = useState<TFakeList>(listList);
  
  const fetchMoreData = () => {

    setTimeout(() => {
      setHasmore(false);
      setFakeItems( 
        fakeItems.concat(listList)
      );

    }, 500);

  };

  useEffect(() => {
    
    setLoading(true);

    const fakeLoading = setTimeout(() => {
        setLoading(false);
    }, 3000);
  
    return () => {
        clearTimeout(fakeLoading);
    }

  }, [])
  

  return (
    <>
      <div className="ct-equipes-campagne">
        <div className="ct-equipes-campagne__card">

          <div className="header-part">
            <div className="title-part">
                <div className="title-bloc">
                  <span className="title"> Gestion des équipes </span>
                  <Chip label={` ${100} équipes `}
                    className='total-number-chip' />

                  <Badge color="secondary" 
                         //variant="dot"
                         badgeContent={ valueFromSelect?.length }  
                         invisible={ valueFromSelect?.length === 0}>
                      <IconButton className='close-icon'
                            size="small" 
                            color='blue'
                            onClick={()=>{
                              handleOpen();
                              setIsFilter(true);
                              setIsAdd(false);
                            }}>
                        <TuneRounded />
                      </IconButton>
                  </Badge>
                </div>
                <div className="toolbar-part">

                  <div className="actions-part">
                    <Button
                      color='blue'
                      className='btn-option'
                      onClick={()=>{
                          handleOpen();
                          setIsAdd(true);
                          setIsFilter(false);
                      }}
                      //variant='contained'
                      startIcon={<InlineIcon icon="carbon:document-add" />}>
                      Créer une liste
                    </Button>
                    <Divider orientation='vertical' />
                    <Button
                          color='blue'
                          className='btn-option'
                          onClick={handleExportExcel}
                          //variant='contained'
                          startIcon={<InlineIcon icon="carbon:document-import" />}>
                          Exporter la liste
                    </Button>
                    <Divider orientation='vertical' />
                    <Button
                      color='blue'
                      className='btn-option'
                      //variant='contained'
                      startIcon={<InlineIcon icon="icons8:refresh" />}>
                      Actualiser
                    </Button>
                  </div>

                  <TextField
                    value={searchText}
                    onChange = {
                      (e) => {
                        setSearchText(e.target.value);
                      }
                    }
                    className="search-input"
                    placeholder="Recherche ... "
                    variant="outlined"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InlineIcon icon="bi:search" />
                        </InputAdornment>
                      )
                    }}
                  />

                </div>
            </div>
            <Divider className='custom-divider' />
          </div>

          <PerfectScrollbar id="scrollableDiv" className="content-part">
            <div className="kpi-part">
              <div className={classNames('kpi-card ')}
                      onClick={()=>{ 
                              setLegend('');
                          }}>
                  <span className="value">
                    {100}
                  </span>
                  <span className="text">
                    Total 
                  </span>
                  <div className="mini-pie">
                    <MiniPiehart
                          value1={15}
                          value2={50}
                          value3={35}
                          value4={0}
                          color1={'#EF7D00'}
                          color2={'#26353F'}
                          color3={'#008F75'}
                          color4={'#ff1744'}
                          height={30} 
                          width={30}
                          interRadius={5}/>
                  </div>
              </div>
              <div className={classNames('kpi-card tertiary',{'active': legend === 'en_cours',})}
                     onClick={()=>{ 
                            setLegend('en_cours');
                        }}>
                <span className="percentage"> {Math.round((15 / 100) * 100) | 0}%   </span>
                <span className="value">
                  {15}
                </span>
                <span className="text">
                  En cours de traitement
                </span>
              </div>
              <div className={classNames('kpi-card primary',{'active': legend === 'pret',})}
                     onClick={()=>{ 
                            setLegend('pret');
                        }}>
                          <span className="percentage"> {Math.round((50 / 100) * 100) | 0}%   </span>
                <span className="value">
                  {50}
                </span>
                <span className="text">
                  Prêt à utiliser
                </span>
              </div>
              <div className={classNames('kpi-card secondary',{'active': legend === 'utilise',})}
                     onClick={()=>{ 
                            setLegend('utilise');
                        }}>
                <span className="percentage"> {Math.round((35 / 100) * 100) | 0}%   </span>
                <span className="value">
                  {35}
                </span>
                <span className="text">
                  Utilisé
                </span>
              </div>
            </div>
            <>
                {
                    loading && 
                    <>
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="pulse"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                    </>
                }

                <InfiniteScroll
                    className="content-part__infinite-scroll-part"
                    scrollableTarget="scrollableDiv"
                    dataLength={fakeItems?.length}
                    next={fetchMoreData}
                    hasMore={hasmore}
                    loader={
                      fakeItems.filter((value) => {
                        return (value.label.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
                      })
                      .filter((value) => {
                        return (value.legend.indexOf(legend) !== -1) 
                      })
                      .filter((value) => {
                        return (valueFromSelect.join(',').indexOf(value.hierarchy) !== -1) 
                      })?.length > 0 ?
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        :
                        <p> Aucune donnée disponible </p>
                    }>

                        {
                              !loading &&
                              fakeItems.filter((value) => {
                                  return (value.label.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
                                })
                                .filter((value) => {
                                  return (value.legend.indexOf(legend) !== -1) 
                                })
                                .filter((value) => {
                                  return (valueFromSelect.join(',').indexOf(value.hierarchy) !== -1) 
                                })
                                .map((value, index) => {

                                  return <ListeItemCard key={index} 
                                                        label={value.label}
                                                        hierarchy={value.hierarchy}
                                                        legend={value.legend}/>

                                })
                          }
                  </InfiniteScroll>           
            
            
            </>



          </PerfectScrollbar>
        </div>
      </div>

      <SideModal 
          open={open}
          onClose={handleClose}
          closeClick={handleClose}>
            <>
                {
                    isAdd &&
                    <div> Créer </div>
                }
                {
                    isFilter &&
                    <div className="filter-container">
                        <span className="title"> Filtrer la liste </span>

                        <span className='input-title'> Directions Régionales</span>
                        <Select
                            className="filter-select-input"
                            placeholder="DR"
                            multiple
                            value={valueFromSelect}
                            onChange={handleValueFromSelect}
                            input={<OutlinedInput />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} color="secondary" />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {
                                selectList.map((elt, index) => (
                                    <MenuItem key={index} value={elt}>
                                        <Checkbox checked={valueFromSelect.indexOf(elt) > -1} />
                                        <ListItemText primary={elt} />
                                    </MenuItem>
                                ))
                            }
                        </Select>

                        <div className="btn-part">
                              <Button color='secondary'
                                  className='btn-option'
                                  startIcon={<InlineIcon icon="ep:filter" />}
                                  onClick={()=>{
                                    handleClose();
                                  }}
                                  variant='contained'>
                                  Appliquer le filtre
                              </Button>

                              <Button color='light'
                                  className='btn-option'
                                  startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
                                  onClick={()=>{
                                    setValueFromSelect([]);
                                    handleClose();
                                  }}
                                  variant='contained'>
                                  Réinitialiser le filtre
                              </Button>
                        </div>

                    </div>
                }
            </>
      </SideModal>
    </>
  );
};

export default CtEquipesCampaign;
