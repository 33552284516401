import { InlineIcon } from "@iconify/react";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import "./CreateIncindentForm.style.scss";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getAllUsersAsync } from "@/store/reducers/user/user.action";
import { selectUserItemList } from "@/store/reducers/user/user.selector";
import { selectAuthUser } from "@/store/reducers/auth/auth.selector";
// import * as _ from 'lodash';
import moment from "moment";
import "dayjs/locale/fr";
import { selectPersonnelItemList } from "@/store/reducers/personnel/personnel.selector";
import { getAllPersonnelsAsync } from "@/store/reducers/personnel/personnel.action";
import { updateIncidentAsync } from "@/store/reducers/incident/incident.action";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 0;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: '100% !important',
//     },
//   },
// };

const CreateIncidentFormPage: React.FC<any> = ({
  selectedItem,
  updateIncidentAsync,
  getAllPersonnelsAsync,
  getUserListAsync,
  getUserList,
  getPersonnelList,
  userConnected,
  closeModal,
}) => {
  useEffect(() => {
    const url2: any = getAllPersonnelsAsync();
    const url1: any = getUserListAsync();

    const fetchData = async () => {
      try {
        await fetch(url1);
        await fetch(url2);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, [getUserListAsync, getAllPersonnelsAsync]);


  let chefChantierList: any[] = [];
  getUserList.forEach((x: any) => {
    if (x?.profilId && x?.profilId === 3) {
      chefChantierList.push({ id: x.id, value: x.nom + " " + x.prenoms });
    } else {
    }
  });

  const [chefChantier, setChefChantier] = useState<{ id: any; value: string }>(
    selectedItem
      ? {
          id: selectedItem?.utilisateur?.id,
          value: selectedItem?.utilisateur?.nom,
        }
      : { id: null, value: "" }
  );
  const handleChefChantierChange = (
    event: any,
    value: { id: any; value: string } | null
  ) => {
    console.log(value);
    if (value === null) {
      setChefChantier({ id: null, value: "" });
    } else {
      setChefChantier(value as { id: any; value: string });
    }
  };

  const [dincident, setDincident] = useState(
    selectedItem
      ? selectedItem.date_debut
      : moment().format("YYYY-MM-DD HH:m:s")
  );
  const [ddepannage, setDdepannage] = useState(
    selectedItem ? selectedItem.date_fin : moment().format("DD-MM-YYYY HH:m:s")
  );

  const validationSchema = yup.object({
    // nom: yup.string().required(" Le nom du chantier est obligatoire "),
    // numero_chantier: yup
    //   .string()
    //   .required(" Le numero du chantier est obligatoire "),
    // date_debut: yup.string().required(" La date de debut est obligatoire "),
    // // date_fin: yup.string().required(" La date de fin est obligatoire "),
    // type_location: yup
    //   .string()
    //   .required(" Le type de location est obligatoire "),
    // // hebergement_chantier: yup
    // //   .string()
    // //   .required(" L'hebergement doit etre renseigner "),
    // cuve_caburant: yup.string().required(" Precisez si il y a une cuve ou pas"),
  });

  const formik = useFormik({
    initialValues: {
      id: selectedItem ? selectedItem.id : null,
      date_incident: dincident,
      date_depannage: ddepannage,
      observation_panne: selectedItem ? selectedItem.observation_panne : "",
      compteur_depannage: selectedItem ? selectedItem.compteur_depannage : "",
      panne: selectedItem ? selectedItem.panne : "",
      nom_technicien: selectedItem ? selectedItem.nom_technicien : "",
      observation_depannage: selectedItem
        ? selectedItem.observation_depannage
        : "",
      utilisateurId: selectedItem ? selectedItem.utilisateurId : "",
      // ops: userConnected?.user?.nom + " " + userConnected?.user?.prenoms,
    },
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      console.log("data", values);

      if (!selectedItem) {
        delete values.id;
        // createChantierAsync(values);
      } else {
        updateIncidentAsync(values);
      }
      closeModal();
    },
  });

  return (
    <form className="create-incident-form" onSubmit={formik.handleSubmit}>
      <span className="title">
        {" "}
        {selectedItem
          ? "Modification d'un incident"
          : "Création d'un incident"}{" "}
      </span>

      <Grid container rowSpacing={2} columnSpacing={2}>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Panne"
            variant="outlined"
            name="panne"
            label="Panne"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.panne}
            error={formik.touched.panne && Boolean(formik.errors.panne)}
            // helperText={formik.touched.panne && formik.errors.panne}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <TextField
            placeholder="Compteur depannage"
            variant="outlined"
            name="compteur_depannage"
            label="Compteur depannage"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.compteur_depannage}
            error={
              formik.touched.compteur_depannage &&
              Boolean(formik.errors.compteur_depannage)
            }
            // helperText={formik.touched.compteur_depannage && formik.errors.compteur_depannage}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <TextField
            placeholder="Observation depannage"
            variant="outlined"
            name="observation_depannage"
            label="Observation depannage"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.observation_depannage}
            error={
              formik.touched.observation_depannage &&
              Boolean(formik.errors.observation_depannage)
            }
            // helperText={formik.touched.nom && formik.errors.nom}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <TextField
            placeholder="Observation panne"
            variant="outlined"
            name="observation_panne"
            label="Observation panne"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.observation_panne}
            error={
              formik.touched.observation_panne &&
              Boolean(formik.errors.observation_panne)
            }
            // helperText={formik.touched.nom && formik.errors.nom}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DatePicker
              label="Date incident"
              value={dincident}
              onChange={(newValue: any) => {
                setDincident(newValue);
                formik.setFieldValue(
                  "date_incident",
                  newValue.format("YYYY-MM-DD HH:mm:ss")
                );
              }}
              renderInput={(params) => (
                <TextField {...params} className="my-text-field" />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DatePicker
              label="Date depannage"
              value={ddepannage}
              onChange={(newValue: any) => {
                setDdepannage(newValue);

                formik.setFieldValue(
                  "date_depannage",
                  newValue.format("YYYY-MM-DD HH:mm:ss")
                );
              }}
              renderInput={(params) => (
                <TextField {...params} className="my-text-field" />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
        <TextField
            placeholder="Nom du technicien"
            variant="outlined"
            name="nom_technicien"
            label="Nom du technicien"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.nom_technicien}
            error={
              formik.touched.nom_technicien &&
              Boolean(formik.errors.nom_technicien)
            }
            // helperText={formik.touched.nom_technicien && formik.errors.nom_technicien}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={6}>
          <Autocomplete
            id="id-chef-chantier-select"
            options={chefChantierList}
            value={chefChantier}
            onChange={handleChefChantierChange}
            autoHighlight
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value
            }
            getOptionLabel={(option) => option?.value}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder="Chef Chantier"
                label="Chef Chantier"
                variant="outlined"
                name="utilisateurId"
                onChange={formik.handleChange}
                value={(formik.values.utilisateurId = chefChantier.id)}
                error={
                  formik.touched.utilisateurId &&
                  Boolean(formik.errors.utilisateurId)
                }
                // helperText={formik.touched.chefChantierId && formik.errors.chefChantierId}
              />
            )}
          />
        </Grid>
      </Grid>

      <div className="btn-part">
        <Button
          color="secondary"
          className="btn-option"
          type="submit"
          startIcon={
            <InlineIcon
              icon={
                selectedItem
                  ? "iconoir:edit-pencil"
                  : "iconoir:save-floppy-disk"
              }
            />
          }
          variant="contained"
        >
          {selectedItem ? "Modifier" : "Créer"}
        </Button>

        <Button
          color="light"
          className="btn-option"
          startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
          onClick={() => {
            formik.resetForm();
          }}
          variant="contained"
        >
          {"Réinitialiser"}
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = createStructuredSelector({
  getPersonnelList: selectPersonnelItemList,
  getUserList: selectUserItemList,
  userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAllPersonnelsAsync: () => dispatch(getAllPersonnelsAsync()),
  getUserListAsync: () => dispatch(getAllUsersAsync()),
  updateIncidentAsync: (data: any) => dispatch(updateIncidentAsync(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const CreateIncidentForm = connector(CreateIncidentFormPage);

export default CreateIncidentForm;
