import { axios } from "@/lib/axios";
import { store } from "@/store";
import { isDev } from "@/utils/isDev";
import { ToastGeneric } from "@/utils/toast";
import { setLoading, getAllPersonnels, getAllPersonnelsAsync, selectSynthesePersonnel, selectHistoriquePersonnel } from "@/store/reducers/personnel/personnel.action";


export class PersonnelService {

  static getPersonnels() {
    axios.get('/personnel').then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            store.dispatch(getAllPersonnels(response.data));
            // ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static createPersonnel(data:any) {

    axios.post('/personnel',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ", response.message);
            ToastGeneric(response.message.message, 'error', 3000).fire();

        }else{
            store.dispatch(getAllPersonnelsAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message, 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static updatePersonnel(data:any) {

    axios.put('/personnel',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message, 'error', 3000).fire();

        }else{
            store.dispatch(getAllPersonnelsAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message, 'success', 1000).fire();
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static deletePersonnel(data:any) {

    axios.patch('/delete/personnel',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric(response.message, 'error', 3000).fire();

        }else{
            store.dispatch(getAllPersonnelsAsync());
            console.log("Not error ---> ");
            ToastGeneric(response.message, 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static getSynthesePersonnel(id:any) {

    axios.get('/synthese/personnel',{ params: { personnelId: id } } ).then((response:any)=>{
        console.log(response);
        if(response.error){
          console.log("Error ---> ");
          ToastGeneric(response.message, 'error', 3000).fire();

        }else{
            
            ToastGeneric(response.message, 'success', 1000).fire();

            console.log("Not error ---> ");
            store.dispatch(selectSynthesePersonnel(response.data));
        }
       store.dispatch(setLoading(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });
    
  }

  static getHistoriquePersonnel(data: any) {
    console.log("Error> ", data);
    axios.put('/historique/rh',data).then((response:any)=>{
        console.log(response);
        if(response.error){

            console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            

            if(response.data.absences){
                response.data.absences.map((x: any) =>{
                    return {
                        ...x,
                        personnel: x.personnel.nom +' '+ x.personnel.prenoms,
                        chantier: x.chantier.nom
                    }
                });
            }

            store.dispatch(selectHistoriquePersonnel(response.data));
            console.log("Not error ---> ", response.data);
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
            store.dispatch(setLoading(false));
        }
       
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoading(false));
    });

}

}