import { InlineIcon } from '@iconify/react';
import {   Button,  Divider,  FormControlLabel,  FormGroup, Switch,  Grid, TextField, Typography, Autocomplete, Box, } from '@mui/material';
import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import './CreateClientForm.style.scss';
import { connect } from 'react-redux';
import { createClientAsync, updateClientAsync } from '@/store/reducers/client/client.action';
import { PhoneValidator } from '@/utils/validators';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';
import { createStructuredSelector } from 'reselect';
import { getAllTableDonneesAsync } from '@/store/reducers/table-donnee/tableDonnee.action';
import { selectTableDonneeItemList } from '@/store/reducers/table-donnee/tableDonnee.selector';


const CreateClientFormPage: React.FC<any> = ({
    userConnected, 
    selectedItem, 
    getAllTypeClient,
    getAllTableDonneesAsync,
    createClientAsync, 
    updateClientAsync,
    closeModal
}) => {

    useEffect(() => {
        const url:any = getAllTableDonneesAsync();
    
        const fetchData = async () => {
            try {
                await fetch(url);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, [getAllTableDonneesAsync]);


    const typeClientList:any = [];
    getAllTypeClient.forEach((x:any) => {
        if (x.type === 'TYPE CLIENT') {
            typeClientList.push({id: x.libelle , value:x.libelle})
        }
    })

    const [typeClient, setTypeClient] = useState<{ id: string, value: string }>(selectedItem ? {id: selectedItem?.type_client, value: selectedItem?.type_client} : { id: '', value: '' });
    const handleTypeClientChange = (event: any, value: { id: string, value: string } | null) => {
        console.log('setTypeClient', value);
        if (value === null) {
            setTypeClient({ id: '', value: '' });
        } else {
            setTypeClient(value as { id: string, value: string });
            console.log('setFieldValue', value);
            formik.setFieldValue('type_client', value?.value);
        }
    };

    const validationSchema = yup.object({
        nom: yup.string().required(" Le nom est obligatoire "),
        email: yup.string().email("Veuillez rentrer un email valide").required(" L'email est obligatoire "),
        telephone: PhoneValidator(true),
        adresse_societe: yup.string().required(" L' adresse societe est obligatoire "),
        cc_societe: yup.string().required(" Le CC societe est obligatoire "),
        rccc_societe: yup.string().required(" La RCCC societe est obligatoire "),
        // type_client: yup.string().required(" Le type client est obligatoire "),
        // exoneration_tva: yup.string().required(" L'exoneration_tva est obligatoire "),
    });

    const formik = useFormik({
        initialValues: {
            id: selectedItem ? selectedItem.id : null,
            nom: selectedItem ? selectedItem.nom : '',
            email: selectedItem ? selectedItem.email : '',
            telephone: selectedItem ? selectedItem.telephone : '',
            type_client:  selectedItem ? selectedItem.type_client : '',

            adresse_societe: selectedItem ? selectedItem.adresse_societe : '',
            cc_societe: selectedItem ? selectedItem.cc_societe : '',
            rccc_societe: selectedItem ? selectedItem.rccc_societe : '',
            exoneration_tva: selectedItem ? selectedItem.exoneration_tva : false,
            ops: (userConnected?.user?.nom +' '+userConnected?.user?.prenoms)
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            console.log('Values', values);
            if (!selectedItem) {
                delete values.id;
                createClientAsync(values);
            } else {
                updateClientAsync(values);
            }
            closeModal();
        }
    });


    return (
        <form className='create-list-form' onSubmit={formik.handleSubmit}>
            <span className="title">  {selectedItem ? 'Modification d\'un client' : 'Création d\'un client'} </span>

            <Grid container rowSpacing={2} columnSpacing={2} >

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <Autocomplete
                        id="id-service-select"
                        options={typeClientList}
                        value={typeClient}
                        onChange={handleTypeClientChange}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.value}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.value}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='my-text-field'
                                placeholder="Type client"
                                label="Type client"
                                variant="outlined"
                                name="type_client"
                                onChange={formik.handleChange}
                                value={formik.values.type_client = typeClient.id}
                                error={formik.touched.type_client && Boolean(formik.errors.type_client)}
                                // helperText={formik.touched.profilId && formik.errors.profilId}
                            />
                        )} />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    {
                        (selectedItem || typeClient.value === "Société") ?
                        <TextField placeholder="Denomination sociale"
                            variant="outlined"
                            name="nom"
                            label="Denomination sociale"
                            className='my-text-field'
                            onChange={formik.handleChange}
                            value={formik.values.nom}
                            error={formik.touched.nom && Boolean(formik.errors.nom)}
                            type="text" />
                        :
                        <TextField placeholder="Nom et prenoms du client"
                            variant="outlined"
                            name="nom"
                            label="Nom et prenoms"
                            className='my-text-field'
                            onChange={formik.handleChange}
                            value={formik.values.nom}
                            error={formik.touched.nom && Boolean(formik.errors.nom)}
                            type="text" />
                    }
                    
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Email"
                        variant="outlined"
                        name="email"
                        label="Email"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Téléphone"
                        variant="outlined"
                        name="telephone"
                        label="Téléphone"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.telephone}
                        error={formik.touched.telephone && Boolean(formik.errors.telephone)}
                        type="text" />
                </Grid>

            </Grid>

            <span className="sub-title-part"> <InlineIcon icon="bx:map-pin" /> &nbsp; Information comptable </span>
            <Divider className='custom-divider' />

            <Grid container rowSpacing={1} columnSpacing={2} >
                
            <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Adresse société"
                        variant="outlined"
                        name="adresse_societe"
                        label="Adresse societe"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.adresse_societe}
                        error={formik.touched.adresse_societe && Boolean(formik.errors.adresse_societe)}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="CC société"
                        variant="outlined"
                        name="cc_societe"
                        label="CC societe"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.cc_societe}
                        error={formik.touched.cc_societe && Boolean(formik.errors.cc_societe)}
                        type="text" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="RCCC société"
                        variant="outlined"
                        name="rccc_societe"
                        label="RCCC societe"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.rccc_societe}
                        error={formik.touched.rccc_societe && Boolean(formik.errors.rccc_societe)}
                        type="text" />
                </Grid>


                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <div className="switch-container">
                        <Typography>Exonéré de TVA ?</Typography>
                        <FormGroup className='my-toggle'>
                            <FormControlLabel
                            label={formik.values.exoneration_tva ? "Oui" : "Non"}
                            control={<Switch name="exoneration_tva"
                                checked={formik.values.exoneration_tva}
                                onChange={formik.handleChange} />}
                            />
                        </FormGroup>
                    </div>
                </Grid>
                
            </Grid>

            <Divider className='custom-divider' />

            <div className="btn-part">
                <Button color='secondary'
                    className='btn-option'
                    onSubmit={formik.submitForm}
                    type='submit'
                    startIcon={<InlineIcon icon="iconoir:save-floppy-disk" />}
                    variant='contained'>
                    {selectedItem ? 'Modifier' : 'Créer'}
                </Button>

                <Button color='light'
                    className='btn-option'
                    startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
                    onClick={() => { formik.resetForm() }}
                    variant='contained'>
                    Réinitialiser
                </Button>
            </div>

        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    userConnected: selectAuthUser,
    getAllTypeClient: selectTableDonneeItemList,
});

const mapDispatchToProps = (dispatch:any) => ({
    getAllTableDonneesAsync: () => dispatch(getAllTableDonneesAsync()),
    createClientAsync: (data:any) => dispatch(createClientAsync(data)),
    updateClientAsync: (data:any) => dispatch(updateClientAsync(data)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CreateClientForm = connector(CreateClientFormPage);

export default CreateClientForm