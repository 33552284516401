import { InlineIcon } from '@iconify/react';
import {  Autocomplete, Box, Button, Divider, Grid, TextField } from '@mui/material';
import React, { useState , useEffect} from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './CreateCongesForm.style.scss';
import { connect } from 'react-redux';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';
import { createCongesAsync, updateCongesAsync } from '@/store/reducers/conges/conges.action';
import { selectUserItemSelected } from '@/store/reducers/user/user.selector';
import { createStructuredSelector } from 'reselect';
import { getAllPersonnelsAsync } from '@/store/reducers/personnel/personnel.action';
import { selectPersonnelItemList } from '@/store/reducers/personnel/personnel.selector';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
 

const CreateCongesFormPage: React.FC<any> = ({closeModal, selectedItem, createCongesAsync, updateCongesAsync, getAllPersonnelsAsync, personList, userConnected })=> {

    useEffect(() => {
        const url:any = getAllPersonnelsAsync();
    
        const fetchData = async () => {
            try {
                await fetch(url);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, [getAllPersonnelsAsync]);

    const listpersonnel: { id: string, nom: string, nombre_jr_acquis: string  }[] = [];
    personList.forEach((y:any) => {
        listpersonnel.push({
          id: y.id,
          nom: y.nom + ' ' + y.prenoms,
          nombre_jr_acquis: y.nombre_jr_acquis
        })
    });


    const [personnel, setPersonnel] = useState<{ id: string, nom: string, nombre_jr_acquis: string  }>({ id: '', nom: '', nombre_jr_acquis:'' });
    const handlePersonnelChange = (event: any, value:{ id: string, nom: string, nombre_jr_acquis: string  } | null) => {
        console.log(value);
        if (value == null) {
            setPersonnel({ id: '', nom: '', nombre_jr_acquis: '' });
        } else {
            setPersonnel(value as { id: string, nom: string, nombre_jr_acquis: string  });
        }
        formik.setFieldValue('personnelId', value?.id);
        formik.setFieldValue('nombre_jr_acquis', value?.nombre_jr_acquis);
    };


  


    const [date_depart, setDate_depart] =  useState(selectedItem ? selectedItem.date_depart : new Date());
    const [date_retour, setDate_retour] =  useState(selectedItem ? selectedItem.date_retour : new Date());

    const [date_prochain, setDate_prochain] = useState(selectedItem ? selectedItem.date_prochain_conges : new Date());



    const validationSchema = yup.object({
        date_depart: yup.string().required(" La date depart est obligatoire "),
        date_retour: yup.string().required(" La date retour est obligatoire "),
        date_prochain_conges: yup.string().required(" La date prochaine conges est obligatoire ")
    });

    const formik = useFormik({
        initialValues: {
            date_depart:  selectedItem ? selectedItem.date_depart : '',
            date_retour:  selectedItem ? selectedItem.date_retour : '',
            date_prochain_conges:  selectedItem ? selectedItem.date_prochain_conges : '',
           
            nombre_jours:  selectedItem ? selectedItem.nombre_jours : '',
            personnelId:  selectedItem ? selectedItem.personnel.id : '',

            observation:  selectedItem ? selectedItem.observation : '',

            nombre_jr_acquis: selectedItem ? (selectedItem.personnel.nombre_jr_acquis + selectedItem.nombre_jours) : '',
            nombre_jr_restant: selectedItem ? selectedItem.personnel.nombre_jr_acquis : '',

            // End of profil client
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            console.log('values', values);
            values.ops = (userConnected?.user?.nom +' '+userConnected?.user?.prenoms);
            values.date_depart = dayjs(values.date_depart).format('YYYY/MM/DD');
            values.date_retour = dayjs(values.date_retour).format('YYYY/MM/DD');
             values.date_prochain_conges = dayjs(values.date_prochain_conges).format('YYYY/MM/DD');
            delete values.nombre_jr_acquis;
            delete values.nombre_jr_restant;
            console.log('values', values);

            if (!selectedItem) {
                console.log('okay');
                
                delete values.id;
            
                createCongesAsync(values);
            } else {
                values.id = selectedItem.id;
                delete values.nombre_jr_restant;
                updateCongesAsync(values);
            }
            closeModal();
        }
    });

    const resetForm = () => {
        formik.resetForm();
        setPersonnel({ id: '', nom: '', nombre_jr_acquis: '' });
    }  

    return (
        <form className='create-list-form' onSubmit={formik.handleSubmit}>
            <span className="title">{selectedItem ? 'Modification' : 'Création'} </span>

            <Grid container rowSpacing={2} columnSpacing={2}>

            <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <Autocomplete
                        id="id-service-select"
                        options={listpersonnel}
                        value={selectedItem ? {id:selectedItem.personnel.id , nom:selectedItem.personnel.nom + ' ' + selectedItem.personnel.prenoms, nombre_jr_acquis: selectedItem.personnel.nombre_jr_acquis } :personnel}
                        onChange={handlePersonnelChange}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option.nom === value.nom}
                        getOptionLabel={(option) => option.nom}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.nom}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='my-text-field'
                                placeholder="Personnel"
                                label="Personnel"
                                variant="outlined"
                                name="personnelId"
                                onChange={formik.handleChange}
                                value={formik.values.personnelId}
                                error={formik.touched.personnelId && Boolean(formik.errors.personnelId)}
                            />
                        )} />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                    <DatePicker
                        label="Date de départ"
                        value={date_depart}
                        onChange={(newValue: any) => {
                        setDate_depart(newValue);
                        formik.setFieldValue("date_depart", newValue.format('YYYY-MM-DD HH:mm:ss'));
                        }}
                        renderInput={(params) => <TextField {...params} className='my-text-field'
                         name='date_depart'
                         onChange={formik.handleChange}
                         value={formik.values.date_depart}
                         error={formik.touched.date_depart && Boolean(formik.errors.date_depart)}
                          />}
                    />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" >
                    <DatePicker
                        label="Date de reprise"
                        value={date_retour}
                        onChange={(newValue: any) => {
                        setDate_retour(newValue);
                        formik.setFieldValue("date_retour", newValue.format('YYYY-MM-DD HH:mm:ss'));
                        }}
                        renderInput={(params) => <TextField {...params} className='my-text-field'
                         name='date_retour'
                         onChange={formik.handleChange}
                         value={formik.values.date_retour}
                         error={formik.touched.date_retour && Boolean(formik.errors.date_retour)}
                          />}
                    />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" >
                    <DatePicker
                        label="Date congé suivant"
                        value={date_prochain}
                        onChange={(newValue: any) => {
                        setDate_prochain(newValue);
                        formik.setFieldValue("date_prochain_conges", newValue.format('YYYY-MM-DD HH:mm:ss'));
                        }}
                        
                        renderInput={(params) => <TextField {...params} className='my-text-field'
                         name='date_prochain_conges'
                         onChange={formik.handleChange}
                         value={formik.values.date_prochain_conges}
                         error={formik.touched.date_prochain_conges && Boolean(formik.errors.date_prochain_conges)}
                         />}
                    />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <TextField placeholder="Nombre de jour Acquis"
                        variant="outlined"
                        name="nombre_jr_acquis"
                        label="Nombre de jour Acquis"
                        disabled
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.nombre_jr_acquis}
                        error={formik.touched.nombre_jr_acquis && Boolean(formik.errors.nombre_jr_acquis)}
                        type="number" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <TextField placeholder="Nombre de jour pris"
                        variant="outlined"
                        name="nombre_jours"
                        label="Nombre de jour pris"
                        className='my-text-field'
                        onChange={(e) => {
                            formik.handleChange(e);
                            const nombreJrAcquis = parseInt(formik.values.nombre_jr_acquis);
                            const nombreJoursPris = parseInt(e.target.value);
                            formik.setFieldValue("nombre_jr_restant", nombreJrAcquis - nombreJoursPris);
                          }}
                        value={formik.values.nombre_jours}
                        error={formik.touched.nombre_jours && Boolean(formik.errors.nombre_jours)}
                        type="number" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <TextField placeholder="Nombre de jour restant"
                        variant="outlined"
                        name="nombre_jr_restant"
                        label="Nombre de jour restant"
                        disabled
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.nombre_jr_restant}
                        error={formik.touched.nombre_jr_restant && Boolean(formik.errors.nombre_jr_restant)}
                        type="number" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <TextField placeholder="Observation"
                        variant="outlined"
                        name="observation"
                        label="Observation"
                        rows={8}
                        className='my-text-field-large'
                        onChange={formik.handleChange}
                        value={formik.values.observation}
                        error={formik.touched.observation && Boolean(formik.errors.observation)}
                        type="text" />
                </Grid>
            </Grid>


            <Divider className='custom-divider' />

            <div className="btn-part">
                <Button color='secondary'
                    className='btn-option'
                    type='submit'
                    startIcon={<InlineIcon icon="iconoir:save-floppy-disk" />}
                    variant='contained'>
                     {selectedItem ? 'Modifier' : 'Créer'}
                </Button>

                <Button color='light'
                    className='btn-option'
                    startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
                    onClick={() => { resetForm() }}
                    variant='contained'>
                    Réinitialiser
                </Button>
            </div>

        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    getUserSelected: selectUserItemSelected,
    userConnected: selectAuthUser,
    personList: selectPersonnelItemList
});

const mapDispatchToProps = (dispatch:any) => ({
    getAllPersonnelsAsync: () => dispatch(getAllPersonnelsAsync()),
    createCongesAsync: (data:any) => dispatch(createCongesAsync(data)),
    updateCongesAsync: (data:any) => dispatch(updateCongesAsync(data)),
});
  
const connector = connect(mapStateToProps,mapDispatchToProps);
const CreateCongesForm = connector(CreateCongesFormPage);

export default CreateCongesForm