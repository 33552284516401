import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"

type TDialogProps = {
    isOpen: boolean;
    handleClose: any;
    titleDialog: string;
    textDialog?: string;
    buttonYesText?: string;
    buttonNoText?: string;
    functionBtnYes?: any
    children?: any;
}

export const DialogComp:React.FC<TDialogProps> = ({
    isOpen,
    handleClose,
    titleDialog,
    textDialog,
    buttonYesText,
    buttonNoText,
    functionBtnYes,
    children
}) => {

    return (
        <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{titleDialog || "Déconnexion"}</DialogTitle>
        <DialogContent>
          {
            textDialog ? 
            <DialogContentText id="alert-dialog-description">
              {textDialog || `Vous êtes sur le point de vous déconnecter. Êtes-vous sûr(e) de
              vouloir le faire ?`}
            </DialogContentText> : 
            <>{children}</>
            
          }
        </DialogContent>
        <DialogActions>
          <Button color="tertiary" onClick={handleClose}>
            {buttonNoText || 'Annuler'}
          </Button>
          <Button color="tertiary" onClick={functionBtnYes} autoFocus>
            {buttonYesText || 'Valider'}
          </Button>
        </DialogActions>
      </Dialog>
    )
}