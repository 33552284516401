import MiniPiehart from '@/features/admin-iveqi/components/Mini-pie-chart/MiniPieChart';
import { InlineIcon } from '@iconify/react';
import { Delete, Edit, ListAlt, TurnedIn } from '@mui/icons-material';
import { Badge, Button, Chip, Divider, IconButton, InputAdornment, Skeleton, TextField } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import ListeItemCard from '../../components/ListeItem/ListeItemCard';
import SideModal from '../../components/SideModal/SideModal';
import './Roles.content.style.scss';
import { selectLoading, selectProfilItemList, selectUserProfil } from '@/store/reducers/profil/profil.selector';
import { deleteProfilAsync, getAllProfilsAsync } from '@/store/reducers/profil/profil.action';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import ListeItemCardGenerique from '@/features/admin-iveqi/components/ListeItem/ListeItemCardGenerique';
import CreateHabilitationForm from '../../Forms/CreateHabilitationForm/CreateHabilitationForm';
import { Stack } from "@mui/material";
import Swal from 'sweetalert2';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const columns = [
  { field: 'libelle', headerName: 'Libellé', width: 300 },
  { field: 'description', headerName: 'Description', width: 450 },
];


const CtRolesCampaignPage: React.FC<any> = ({loading, profilList, getAllProfilsAsync, deleteProfilAsync, permissions}) => {

  useEffect(() => {
    const url:any = getAllProfilsAsync();

    const fetchData = async () => {
        try {
            await fetch(url);
        } catch (error) {
            console.log("error", error);
        }
    };
    fetchData();
    
  }, [getAllProfilsAsync]);

  const listItems:any[] = profilList.map((x:any) => {
    return {
      ...x,
      searchText: x.libelle +' '+ x.description
    }
  });

  const [searchText, setSearchText] = useState('');
  const [isCardMode, setIsCardMode] = useState(true);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isAdd, setIsAdd] = useState(false);

  const [selection, setSelection] = React.useState<any>(null);

  const getListItemsFilteredSearch = () => {
    return listItems.filter((value: any) => {
      return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
    })
  }

  const selectItemFunc = (idElt: number | any) => {
      const element =  getListItemsFilteredSearch().find((x:any) => x.id === idElt);
      console.log("element", element);
      if (selection?.id === element?.id) {
        isCardMode && setSelection(null);
        return;
      }
      setSelection(element);
  }
  
  const isCardActive = (idCard: any) => {
    // if (selection?.id == idCard) return false
    return selection?.id === idCard
  }

  const confirmBeforeRemove =()=> {
    Swal.fire({
      title: "⚠️ Important ",
      text: "Vous êtes sur le point de supprimer une information, desirez-vous poursuivre cette action ?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
      confirmButtonColor: "red",
      cancelButtonColor: "#91949c",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  const deleteItem = () => {
    // let datatoDelete = {"id": selection.id }

    deleteProfilAsync({"id": selection.id });
  }

  return (
    <>
    <div className="ct-client-campagne">
      <div className="ct-client-campagne__card">

        <div className="header-part">
          <div className="title-part">
              <div className="title-bloc">
                <span className="title"> Gestion des Habilitations </span>
                <Chip label={` ${listItems?.length} ${listItems?.length < 2 ? 'habilitation' : 'habilitations'}  `}
                  className='total-number-chip' />

                  <Badge color="secondary" 
                      variant="dot"
                      //  invisible={ valueFromSelect?.length === 0}
                       >
                    <IconButton 
                      className='close-icon'
                      title={`Mode ${isCardMode ? "liste" : 'carte'}`}
                      size="small" 
                      color='blue'
                      onClick={()=>{
                        setIsCardMode(!isCardMode)
                      }}>
                      {
                        isCardMode ?
                        <ListAlt /> :
                        <TurnedIn />
                      }
                    </IconButton>
                </Badge>

                
              </div>
              <div className="toolbar-part">

                <div className="actions-part">
                {
                    selection ?
                      <>
                        <Divider orientation='vertical' />
                        {
                          permissions?.parametre?.find((o: any) => (o.libelle === 'Habilitation' && o.edit === true)) &&
                          <Button
                            color='blue'
                            className='btn-option'
                            onClick={() => {
                              handleOpen();
                              setIsAdd(true);
                            }}
                            startIcon={<Edit />}>
                            Modifier
                          </Button>
                        }
                        <Divider orientation='vertical' />
                        {
                          permissions?.parametre?.find((o: any) => (o.libelle === 'Habilitation' && o.delete === true)) &&
                          <Button
                            color='blue'
                            className='btn-option'
                            onClick={confirmBeforeRemove}
                            startIcon={<Delete />}>
                            Supprimer
                          </Button>
                        }
                      </> :
                      <>
                        {
                          permissions?.parametre?.find((o: any) => (o.libelle === 'Habilitation' && o.create === true)) &&
                          <Button
                            color='blue'
                            className='btn-option'
                            onClick={() => {
                              handleOpen();
                              setIsAdd(true);
                            }}
                            //variant='contained'
                            startIcon={<InlineIcon icon="carbon:document-add" />}>
                            Créer une habilitation
                          </Button>
                        }
                      </>
                  }
                </div>

                <TextField
                  value={searchText}
                  onChange = {
                    (e) => {
                      setSearchText(e.target.value);
                    }
                  }
                  className="search-input"
                  placeholder="Recherche ... "
                  variant="outlined"
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InlineIcon icon="bi:search" />
                      </InputAdornment>
                    )
                  }}
                />

              </div>
          </div>
          <Divider className='custom-divider' />
        </div>

        <PerfectScrollbar id="scrollableDiv" className="content-part">
          <div className="kpi-part">
            <div className={classNames('kpi-card ')}>
                <span className="value">
                  {listItems?.length}
                </span>
                <span className="text">
                  Total Habilitations
                </span>
                <div className="mini-pie">
                  <MiniPiehart
                        value1={15}
                        value2={50}
                        value3={35}
                        value4={0}
                        color1={'#EF7D00'}
                        color2={'#26353F'}
                        color3={'#008F75'}
                        color4={'#ff1744'}
                        height={30} 
                        width={30}
                        interRadius={5}/>
                </div>
            </div>
            
          </div>
          <>
              {
                  loading && 
                  <>
                      <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                      <Skeleton variant="rounded" animation="pulse"  width={300} height={120} />
                      <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                  </>
              }
              {
                isCardMode ?

                <InfiniteScroll
                  className="content-part__infinite-scroll-part"
                  scrollableTarget="scrollableDiv"
                  dataLength={listItems?.length}
                  next={getAllProfilsAsync}
                  hasMore={true}
                  loader={
                    listItems ?
                      <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                      :
                      <p> Aucune donnée disponible </p>
                  }>

                      {
                            !loading &&
                            listItems.filter((value: any) => {
                                return (value.searchText.toLocaleLowerCase().indexOf( searchText.toLocaleLowerCase() || '') !== -1)
                              })
                              .map((value: any, index:any) => {

                                return <ListeItemCardGenerique id={value.id}
                                key={index}
                                setOpen={setOpen}
                                columns={columns}
                                data={[value]}
                                setIsEdit={selectItemFunc}
                                isActive={isCardActive(value.id)}/>

                              })
                        }
                </InfiniteScroll>  :
                <div style={{ height: '85%', width: '100%' }}>
                <DataGrid  components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            Pas de donnees
                          </Stack>
                        ),
                      }}
                      localeText={{
                        MuiTablePagination: {
                          labelDisplayedRows: ({ from, to, count }) =>
                            `${from} - ${to} Total: ${count}`,
                        },
                      }}
                  rows={
                    getListItemsFilteredSearch()
                  }
                  columns={columns}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 7 } },
                  }}
                  onRowSelectionModelChange={(row) => {
                    selectItemFunc(row[0]);
                  }}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                />
              </div>
              }
          </>

        </PerfectScrollbar>
      </div>
    </div>

    <SideModal 
        open={open}
        onClose={handleClose}
        closeClick={handleClose}>
          <>
              {
                  isAdd &&
                  <CreateHabilitationForm closeModal={handleClose} selectedItem={selection} />
              }
          </>
    </SideModal>
  </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
  profilList: selectProfilItemList,
  permissions: selectUserProfil
});

const mapDispatchToProps = (dispatch:any) => ({
  getAllProfilsAsync: () => dispatch(getAllProfilsAsync()),
  deleteProfilAsync: (data:any) => dispatch(deleteProfilAsync(data)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CtRolesCampaign = connector(CtRolesCampaignPage);

export default CtRolesCampaign;
