import MiniPiehart from '@/features/admin-iveqi/components/Mini-pie-chart/MiniPieChart';
import { Chip, Divider, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './Accueil.content.style.scss';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAlerteCountList, selectAlerteItemList } from "@/store/reducers/alerte/alerte.selector";
import InfiniteScroll from 'react-infinite-scroll-component';
import AlertItem from '@/components/Snackbar/AlertItem';
import { getAllAlertesAsync } from '@/store/reducers/alerte/alerte.action';

const CtAccueilCampaignPage: React.FC<any> = ({alerte, alerteList, getAllAlertesAsync}) => {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    
    setLoading(true);

    const fakeLoading = setTimeout(() => {
        setLoading(false);
    }, 2000);
  
    return () => {
        clearTimeout(fakeLoading);
    }

  }, []);

  let vidangeList:any[] = [];
  alerteList?.vidange?.forEach((o:any) => {
    vidangeList.push({...o, topic: 'vidange'});
  })

  let cuveList:any[] = [];
  alerteList?.cuve?.forEach((o:any) => {
    cuveList.push({...o, topic: 'cuve'});
  })

  const listItems = cuveList.concat(vidangeList);
  console.log('listItems',listItems);
  

  return (
    <>
      <div className="ct-accueil-campagne">
        <div className="ct-accueil-campagne__card">

            <div className="title-bloc">
                <span className="title"> Alertes </span>
                {
                      loading ? 
                        <>
                            <Skeleton variant="text" animation="wave"  width={80} height={20} />
                        </>
                        :
                        <Chip label={` ${alerte?.vidange + alerte?.cuve} ${alerte?.vidange + alerte?.cuve < 2 ? 'alerte' : 'alertes'}  `}
                              className='total-number-chip' />
                }
            </div>
            <Divider className='custom-divider' />
            <PerfectScrollbar id="scrollableDiv" className="content-part">
            <div className="kpi-part">

                {
                      loading ? 
                      <>
                          <Skeleton variant="rounded" animation="wave"  width={200} height={90} />
                          <Skeleton variant="rounded" animation="pulse"  width={200} height={90} />
                          <Skeleton variant="rounded" animation="wave"  width={200} height={90} />
                      </> 
                      :   
                      <>
                          <div className="kpi-card">
                              <span className="value">
                                {alerte?.vidange + alerte?.cuve}
                              </span>
                              <span className="text">
                                Total alertes
                              </span>
                              <div className="mini-pie">
                                <MiniPiehart
                                      value1={15}
                                      value2={50}
                                      value3={35}
                                      value4={0}
                                      color1={'#26353F'}
                                      color2={'#EF7D00'}
                                      color3={'#008F75'}
                                      color4={'#ff1744'}
                                      height={30} 
                                      width={30}
                                      interRadius={5}/>
                              </div>
                          </div>
                          <div className="kpi-card primary">
                            <span className="percentage"> {((alerte?.vidange * 100) / (alerte?.vidange + alerte?.cuve)) | 0}%   </span>
                            <span className="value">
                              {alerte?.vidange}
                            </span>
                            <span className="text">
                              vidanges acheves
                            </span>
                          </div>
                          <div className="kpi-card tertiary">
                                      <span className="percentage"> {((alerte?.cuve * 100) / (alerte?.vidange + alerte?.cuve)) | 0}%   </span>
                            <span className="value">
                              {alerte?.cuve}
                            </span>
                            <span className="text">
                              cuves
                            </span>
                          </div>
                      </>
                }

            </div>

            <>
                {
                    loading && 
                    <>
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="pulse"  width={300} height={120} />
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                    </>
                }
      

                  <InfiniteScroll
                    className="content-part__infinite-scroll-part"
                    scrollableTarget="scrollableDiv"
                    dataLength={listItems?.length}
                    next={getAllAlertesAsync}
                    hasMore={true}
                    loader={
                      listItems ?
                        <Skeleton variant="rounded" animation="wave"  width={300} height={120} />
                        :
                        <p> Aucune donnée disponible </p>
                    }>

                        {
                              !loading &&
                              listItems?.map((value: any, index:any) => {

                                  return <AlertItem 
                                            type={value?.topic === 'vidange' ? 'success': 'warning'} 
                                            msg={ value?.topic === 'vidange' ? ('Alerte vidange - '+ value?.prochain_entretien) : ('Alerte carburant') } 
                                            desc={ value?.topic === 'vidange' ? 
                                            (
                                            `Rappel de vidange de l'engin N° ${value?.num_parc} qui a un intervalle de ${value?.intervalle_entretien} dont le compteur actuel est : ${value?.dernier_compteur} et la derniere vidange était de ${value?.derniere_entretien}`) 
                                            : 
                                            ('La quantite de carburant dans le reservoir du chantier '+ value?.chantier.nom + ' a attient sa limite estimée, capacité : '+ value?.capacite +' quantité : '+ value?.quantite +'  ;Veuillez l\'approvisionner le reservoir ') }
                                            key={index} />
                            
                                })
                          }
                  </InfiniteScroll>  
                  
            </>
            </PerfectScrollbar>
        </div>
      </div>
    </>
  );
};

const mapStateToProps:any = createStructuredSelector({
  alerte: selectAlerteCountList,
  alerteList: selectAlerteItemList,
});

const mapDispatchToProps = (dispatch:any) => ({
  getAllAlertesAsync: () => dispatch(getAllAlertesAsync()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const CtAccueilCampaign = connector(CtAccueilCampaignPage);

export default CtAccueilCampaign;
