import { CongesService } from "../../../services/conges.service";
import { congesTypes } from "./conges.types";

export const setLoading = (loading:any) => ({
    type: congesTypes.LOADING,
    payload: loading,
});

export const getAllConges = (list:any) => ({
    type: congesTypes.GET_CONGES_LIST,
    payload: list,
});

export const selectedItem = (conges:any) => ({
  type: congesTypes.SELECTED_CONGES,
  payload: conges,
});

 
export const  getAllCongesAsync =  () =>
(dispatch:any) => {
  dispatch(setLoading(true));
  CongesService.getConges();
};

export const  createCongesAsync =  (conges:any) =>
(dispatch:any) => {
  dispatch(setLoading(true));
  CongesService.createConges(conges);
};

export const  updateCongesAsync =  (conges:any) =>
(dispatch:any) => {
  console.log("conges to service", conges);
  dispatch(setLoading(true));
  CongesService.updateConges(conges);
};

export const  deleteCongesAsync =  (conges:any) =>
(dispatch:any) => {
  console.log("conges to service", conges);
  dispatch(setLoading(true));
  CongesService.deleteConges(conges);
};



