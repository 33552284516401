import React, { useEffect, useState } from "react";
import { InlineIcon } from "@iconify/react";
import { HighlightOffRounded } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
//   Checkbox,
//   Chip,
  Fade,
//   FormControl,
  Grid,
  IconButton,
//   InputLabel,
//   ListItemText,
//   MenuItem,
  Modal,
//   OutlinedInput,
//   Select,
  SelectChangeEvent,
//   TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styled, { css } from "styled-components";
// import * as yup from 'yup';
// import { useFormik } from 'formik';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getAllEnginsAsync } from "@/store/reducers/engin/engin.action";
import { selectEnginItemList } from "@/store/reducers/engin/engin.selector";
import { getAllTableDonneesAsync } from "@/store/reducers/table-donnee/tableDonnee.action";
import { selectTableDonneeItemList } from "@/store/reducers/table-donnee/tableDonnee.selector";
import { dashboardByTypeAsync } from "@/store/reducers/dashboard/dashboard.action";
import { selectPersonnelItemList } from "@/store/reducers/personnel/personnel.selector";
import { getAllPersonnelsAsync } from "@/store/reducers/personnel/personnel.action";
import {
  selectLoading,
  selectTypedashboard,
} from "@/store/reducers/dashboard/dashboard.selector";
import CModal from "@/components/Modal/Modal";
import TimelineItem from "../Timeline/TimelineItem";
import { Select } from "antd";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DashboardFilterPageNew: React.FC<any> = ({
  getAllEnginsAsync,
  getAllEngins,
  getPersonnelList,
  dashboardByTypeAsync,
  getAllPersonnelsAsync,
  getFilterData,
  loadingData,
}) => {
  console.log("getFilterData", getFilterData);
  const timelineEngins = [
    {
      label: "Numéro",
      children: `${getFilterData?.engin?.num_parc || ""}`,
    },
    {
      label: "Libellé",
      children: `${getFilterData?.engin?.libelle || ""}`,
    },
    {
      label: "Description",
      children: `${getFilterData?.engin?.description || ""}`,
    },
    {
      label: "Compteur Actuel",
      children: `${getFilterData?.engin?.dernier_compteur || ""}`,
    },
    {
      label: "Intervalle Entretien",
      children: `${getFilterData?.engin?.intervalle_entretien || ""}`,
    },
    {
      label: "Dernier entretien",
      children: `${getFilterData?.engin?.derniere_entretien || ""}`,
    },
    {
      label: "Prochain entretien",
      children: `${getFilterData?.engin?.prochain_entretien || ""}`,
    },
    {
      label: "Situation",
      children: `${getFilterData?.chantier?.nom || "Libre (pas loué)"}`,
    },
  ];

  const timelinePersonnels = [
    {
      label: "Matricule",
      children: `${getFilterData?.personnel?.matricule || ""}`,
    },
    {
      label: "Nom et Prénom(s)",
      children: `${getFilterData?.personnel?.nom || ""} ${
        getFilterData?.personnel?.prenoms || ""
      }`,
    },
    {
      label: "Email",
      children: `${getFilterData?.personnel?.email || ""}`,
    },
    {
      label: "Téléphone",
      children: `${getFilterData?.personnel?.telephone || ""}`,
    },
    {
      label: "Fonction",
      children: `${getFilterData?.personnel?.fonction || ""}`,
    },
    {
      label: "Contrat",
      children: `${getFilterData?.personnel?.statut || ""}`,
    },
    {
      label: "Catégorie",
      children: `${getFilterData?.personnel?.categorie_professionnelle || ""}`,
    },
    {
      label: "Situation matrimoniale",
      children: `${getFilterData?.personnel?.etat_matrimoniale || ""}`,
    },
    {
      label: "Situation",
      children: `${
        getFilterData?.personnel?.chantier?.nom || "Libre (Pas sur chantier)"
      }`,
    },
  ];

  useEffect(() => {
    const url: any = getAllEnginsAsync();
    const url1: any = getAllPersonnelsAsync();

    const fetchData = async () => {
      try {
        await fetch(url);
        await fetch(url1);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, [getAllEnginsAsync, getAllPersonnelsAsync]);

  const enginList: any = getAllEngins.map((x: any) => {
    return { id: x.id, value: x.id, label: x.num_parc };
  });
  // const list2: any = getPersonnelList.map((x: any) => {
  //     return { id: x.id, value: (x.nom + " " + x.prenoms) }
  // })

  // const [filter, setFilter] = useState({ searchEngin: "", searchPerson: "" });
  // const handleFilter = (e: any) => {
  //     setFilter((values) => ({ ...values, [e.target.id]: e.target.value }));
  // }

  // const enginList2 = list2.filter((item: any) => {
  //     return (
  //         item?.value?.toLowerCase().indexOf(filter?.searchEngin?.toLowerCase()) >= 0
  //         // || item?.STATUT?.toLowerCase().indexOf(filter?.searchEngin?.toLowerCase()) >= 0
  //     );
  // });

  // const personnelList = list2.filter((item: any) => {
  //     return (
  //         item?.value?.toLowerCase().indexOf(filter?.searchPerson?.toLowerCase()) >= 0
  //         // || item?.STATUT?.toLowerCase().indexOf(filter?.searchPerson?.toLowerCase()) >= 0
  //     );
  // });

  const theme = useTheme();
  const matches_down_lg = useMediaQuery(theme.breakpoints.down("lg"));

  const [open, setOpen] = useState(false);
  const [openResult, setOpenResult] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

//   const typeList: any[] = ["engin", "personnel"];

  const [typeFilter, setTypeFilter] = useState<any>("engin");
//   const handleType = (event: SelectChangeEvent<any>) => {
//     const {
//       target: { value },
//     } = event;
//     console.log("value", value);
//     //  settypeFilter(value);
//     setTypeFilter(value);
//   };

//   const [engin, setEngin] = useState<{ id: number; value: string }>({
//     id: 0,
//     value: "",
//   });
//   const handleEngin = (event: SelectChangeEvent<any>) => {
//     const {
//       target: { value },
//     } = event;
//     console.log("value", value);
//     //  setEngin(value);
//     setEngin(value as { id: 0; value: string });
//   };

//   const [personnel, setPersonnel] = useState<{ id: number; value: string }>({
//     id: 0,
//     value: "",
//   });
//   const handlePersonnel = (event: SelectChangeEvent<any>) => {
//     const {
//       target: { value },
//     } = event;
//     //setPersonnel(value);

//     setPersonnel(value as { id: number; value: string });
//   };

const [enginId, setEnginId] = useState<any>(null);

const onChange = (value: any) => {
    console.log(`selected ${value}`);
    setEnginId(value)
  };

// Filter `option.label` match the user type `input`
const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const handleSubmit = () => {
    let values = {
      type: "engin", //typeFilter,
      personnelId: null, //typeFilter === 'personnel' ? personnel?.id : null,
      enginId: enginId || null, //typeFilter === 'personnel' ? null : engin?.id
    };
    console.log("values formik3", values);
    // store.dispatch(dashboardByTypeAsync(values));
    dashboardByTypeAsync(values);
    handleClose();

    setOpenResult(true);
  };

  const resetForm = () => {
    setEnginId(null);
    // setEngin({ id: 0, value: "" });
    // setPersonnel({ id: 0, value: '' });
    // setTypeFilter("");
    // setFilter({ searchEngin: "", searchPerson: "" });
  };

  return (
    <>
      <FilterRow media_breakpoint_down_lg={matches_down_lg}>
        <span className="title"> Vue d'ensemble </span>

        <Badge
          color="primary"
          className="badge-btn-filter"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          variant="dot"
          invisible={!matches_down_lg}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={handleOpen}
            startIcon={<InlineIcon icon="ep:filter" />}
          >
            Filtre
          </Button>
        </Badge>
      </FilterRow>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        sx={{zIndex: 10}}
      >
        <Fade in={open}>
          <BoxCustom>
            <IconButton
              className="close-icon"
              size="large"
              color="primary"
              onClick={handleClose}
            >
              <HighlightOffRounded />
            </IconButton>

            <Box>
              <form>
                <Grid container rowSpacing={4}>
                  <Grid item xs={12} sm={12} lg={12} xl={12} xxl={12}>
                    <div className="title"> Formulaire de filtre </div>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} xl={12} xxl={12}>
                    <Select
                      style={{ width: "100%", zIndex: 1000 }}
                      showSearch
                      placeholder="Choisissez un engin"
                      optionFilterProp="children"
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={filterOption}
                    //   status="var(--ui-dark)"
                      loading={loadingData}
                      options={enginList}
                      defaultValue={enginId}
                      size="large"
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={12} lg={12} xl={12} xxl={12}>
                                        <FormControl>
                                            <InputLabel id="id-services-filtre" htmlFor="typeId"> Type </InputLabel>
                                            <Select
                                                labelId="id-services-filtre"
                                                name='typeId'
                                                value={typeFilter}
                                                onChange={handleType}
                                                input={<OutlinedInput label="typeId" />}
                                                renderValue={(selected: any) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {Array.isArray(selected) ? (
                                                            selected.map((value: any) => (
                                                                <Chip key={value} label={value} color="primary" />
                                                            ))
                                                        ) : (
                                                            <Chip key={selected} label={selected} color="primary" />
                                                        )}
                                                    </Box>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                {typeList.map((elt: any, index: any) => (
                                                    <MenuItem key={index} value={elt}>
                                                        <Checkbox checked={typeFilter === elt} />
                                                        <ListItemText primary={elt} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid> */}

                  {/* {
                                        typeFilter === 'personnel' ?
                                            <Grid item xs={12} sm={12} lg={12} xl={12} xxl={12}>
                                                <FormControl>
                                                    <InputLabel id="id-services-filtre" htmlFor="personnelId"> personnel </InputLabel>
                                                    <Select
                                                        labelId="id-services-filtre"
                                                        name='personnelId'
                                                        value={personnel.value}
                                                        onChange={handlePersonnel}
                                                        input={<OutlinedInput label="personnelId" />}
                                                        renderValue={(selected: any) => (
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                {Array.isArray(selected) ? (
                                                                    selected.map((value: any) => (
                                                                        <Chip key={value.id} label={value.value} color="primary" />
                                                                    ))
                                                                ) : (
                                                                    <Chip key={selected} label={selected} color="primary" />
                                                                )}
                                                            </Box>
                                                        )}
                                                        MenuProps={MenuProps}
                                                    >
                                                        <div className="m-2">
                                                            <input
                                                                type="text"
                                                                id="searchPerson"
                                                                value={filter.searchPerson}
                                                                className="form-control"
                                                                placeholder="Rechercher"
                                                                onChange={handleFilter}
                                                            />
                                                        </div>
                                                        {personnelList.map((elt: any, index: any) => (
                                                            <MenuItem key={index} value={elt}>
                                                                <Checkbox checked={personnel.id === elt.id} />
                                                                <ListItemText primary={elt.value} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            :
                                            <Grid item xs={12} sm={12} lg={12} xl={12} xxl={12}>
                                                <FormControl>
                                                    <InputLabel id="id-client-filtre" htmlFor="enginId"> Engin </InputLabel>
                                                    <Select
                                                        labelId="id-client-filtre"
                                                        name='enginId'
                                                        value={engin.value}
                                                        onChange={handleEngin}
                                                        input={<OutlinedInput label="enginId" />}
                                                        renderValue={(selected) => (
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                {Array.isArray(selected) ? (
                                                                    selected.map((value: any) => (
                                                                        <Chip key={value.id} label={value.libelle} color="primary" />
                                                                    ))
                                                                ) : (
                                                                    <Chip key={selected} label={selected} color="primary" />
                                                                )}
                                                            </Box>
                                                        )}

                                                        MenuProps={MenuProps}
                                                    >
                                                        <div className="m-2">
                                                            <input
                                                                type="text"
                                                                id="searchEngin"
                                                                value={filter.searchEngin}
                                                                className="form-control"
                                                                placeholder="Rechercher"
                                                                onChange={handleFilter}
                                                            />
                                                        </div>
                                                        {enginList.map((elt: any, index: any) => (
                                                            <MenuItem key={index} value={elt}>
                                                                <Checkbox checked={engin.id === elt.id} />
                                                                <ListItemText primary={elt.value} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                    } */}

                  <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={12} sm={12} lg={6} xl={6} xxl={6}>
                      <Button
                        color="secondary"
                        variant="contained"
                        sx={{ mt: 2 }}
                        onClick={handleSubmit}
                        startIcon={<InlineIcon icon="ep:filter" />}
                      >
                        Appliquer le filtre
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} xl={6} xxl={6}>
                      <Button
                        color="light"
                        className="btn-option"
                        sx={{ mt: 2 }}
                        startIcon={
                          <InlineIcon icon="system-uicons:reset-alt" />
                        }
                        onClick={() => {
                          resetForm();
                        }}
                        variant="contained"
                      >
                        Réinitialiser
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </BoxCustom>
        </Fade>
      </Modal>

      <CModal title="Résultat" open={openResult} setOpen={setOpenResult}>
        {loadingData ? (
          <div className="loading-chart">
            <img
              className="loader-img"
              src={require("@/assets/images/gif/chart-load.gif")}
              alt="load"
            />
          </div>
        ) : (
          <div
            style={{
              padding: "10px",
              height: "350px",
              maxHeight: "350px",
              width: "100%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <TimelineItem
              data={
                typeFilter === "engin" ? timelineEngins : timelinePersonnels
              }
            />
          </div>
        )}
      </CModal>
    </>
  );
};

const FilterRow = styled.div<{
  media_breakpoint_down_lg: boolean;
}>`
  width: 100%;
  height: 65px;
  padding: 0px 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // margin-top: 20px ;
  position: relative;

  ${({ media_breakpoint_down_lg }) =>
    media_breakpoint_down_lg &&
    css`
      padding: 0px 10px;
    `}

  .title {
    font-size: var(--title-size);
    font-weight: normal;
    color: var(--ui-primary-tint);
  }

  .tag-part {
    position: absolute;
    right: 190px;
    top: 50%;
    transform: translateY(-50%);
    height: 40px;
    width: fit-content;
    border-radius: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0px 5px;
    gap: 20px;

    .chip {
      background: var(--ui-secondary-tint);
      color: var(--ui-primary-tint);
    }

    hr {
      margin-left: 10px;
    }
  }

  .badge-btn-filter {
    margin-left: auto;

    button {
      width: 120px;
      height: 40px;
      font-weight: bold;
    }

    ${({ media_breakpoint_down_lg }) =>
      media_breakpoint_down_lg &&
      css`
        .MuiBadge-dot {
          background: var(--ui-secondary-tint);
          border: 1px solid var(--ui-light);
          transform: scale(1.8) translateX(-2px) translateY(-2px);
        }
      `}
  }
`;

const BoxCustom = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background: var(--ui-light);
  padding: 30px 30px 50px 30px;
  width: 80%;
  max-width: 700px;
  height: fit-content;

  .close-icon {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .title {
    width: 100%;
    text-align: center;
    font-size: var(--title-size);
    color: var(--ui-primary);
    margin-bottom: 20px;
  }

  .MuiFormControl-root {
    width: 100%;
    background: var(--ui-white);
    border-radius: 5px;
  }
`;

const mapStateToProps = createStructuredSelector({
  getPersonnelList: selectPersonnelItemList,
  getData: selectTableDonneeItemList,
  getAllEngins: selectEnginItemList,
  getFilterData: selectTypedashboard,
  loadingData: selectLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAllEnginsAsync: () => dispatch(getAllEnginsAsync()),
  getAllTableDonneesAsync: () => dispatch(getAllTableDonneesAsync()),
  getAllPersonnelsAsync: () => dispatch(getAllPersonnelsAsync()),
  dashboardByTypeAsync: (data: any) => dispatch(dashboardByTypeAsync(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const DashboardFilterNew = connector(DashboardFilterPageNew);
export default DashboardFilterNew;
