import { InlineIcon } from '@iconify/react';
import {   Button,  Divider,  Grid, TextField,  } from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import './CreateCategorieEquipementForm.style.scss';
import { connect } from 'react-redux';
import { createCategorieEquipementAsync, updateCategorieEquipementAsync } from '@/store/reducers/categorie-equipement/categorieEquipement.action';
import { selectAuthUser } from '@/store/reducers/auth/auth.selector';
import { createStructuredSelector } from 'reselect';


const CreateCategorieEquipementFormPage: React.FC<any> = ({userConnected, selectedItem, createCategorieEquipementAsync, updateCategorieEquipementAsync,closeModal}) => {

    const validationSchema = yup.object({
        libelle: yup.string().required(" Le nom de la liste est obligatoire "),
        description: yup.string().required(" L'email de la liste est obligatoire ")
    });

    const formik = useFormik({
        initialValues: {
            id: selectedItem ? selectedItem.id : null,
            libelle:  selectedItem ? selectedItem.libelle : '',
            description:  selectedItem ? selectedItem.description : '',
            ops: (userConnected?.user?.nom +' '+userConnected?.user?.prenoms)
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            
            console.log('Form values', values);
            if (!selectedItem) {
                delete values.id;
                createCategorieEquipementAsync(values);
            } else {
                updateCategorieEquipementAsync(values);
            }
            closeModal();
        }
    });


    return (
        <form className='create-list-form' onSubmit={formik.handleSubmit}>
            <span className="title"> {selectedItem ? 'Modification Catégorie Equipement' : 'Création Catégorie Equipement'} </span>

            <Grid container rowSpacing={2} columnSpacing={2} >
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Libellé de la catégorie"
                        variant="outlined"
                        name="libelle"
                        label="Libellé"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.libelle}
                        error={formik.touched.libelle && Boolean(formik.errors.libelle)}
                        type="text" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={6} >
                    <TextField placeholder="Description de la catégorie"
                        variant="outlined"
                        name="description"
                        label="Description"
                        className='my-text-field'
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        type="text" />
                </Grid>

            </Grid>

            <Divider className='custom-divider' />

            <div className="btn-part">
                <Button color='secondary'
                    className='btn-option'
                    type='submit'
                    onSubmit={formik.submitForm}
                    startIcon={<InlineIcon icon="iconoir:save-floppy-disk" />}
                    variant='contained'>
                     {selectedItem ? 'Modifier' : 'Créer'}
                </Button>

                <Button color='light'
                    className='btn-option'
                    startIcon={<InlineIcon icon="system-uicons:reset-alt" />}
                    onClick={() => formik.resetForm()}
                    variant='contained'>
                    Réinitialiser
                </Button>
            </div>

        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    userConnected: selectAuthUser,
});

const mapDispatchToProps = (dispatch:any) => ({
    createCategorieEquipementAsync: (data:any) => dispatch(createCategorieEquipementAsync(data)),
    updateCategorieEquipementAsync: (data:any) => dispatch(updateCategorieEquipementAsync(data)),
});

const connector = connect(mapStateToProps,mapDispatchToProps);
const CreateCategorieEquipementForm = connector(CreateCategorieEquipementFormPage);


export default CreateCategorieEquipementForm